import { useQuery } from '@apollo/client';
import {
  Stack,
  Dropdown,
  Icon,
  IDropdownOption,
  IDropdownProps,
  IDropdownStyleProps,
  SearchBox,
  ActionButton,
  DropdownMenuItemType,
  getTheme,
  NeutralColors,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import SavedFilterContext from '../components/savedFilters/SavedFilterContext';
import { SAVED_FILTER_LIST_QUERY } from '../utils/api/SavedFilters';
import { getTypedFilters } from '../utils/formatters/FilterConverter';
import useLocalStorage, { LocalStorageKeys } from '../utils/hooks/useLocalStorage';
import { FilterType, ISavedFilter } from '../utils/types/SavedFilters';
import LoadingErrorMessage from './errorContent/LoadingErrorMessage';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from './constants/SiteConstants';

interface IAppHeaderProfileSelectorProps {
  isEdited?: boolean;
}

const AppHeaderProfileSelector = (props: IAppHeaderProfileSelectorProps): JSX.Element => {
  const { isEdited } = props; // could be added to savedFilter object if we wanted to move this back to the header

  const [savedFilterIdCache, setSavedFilterIdCache] = useLocalStorage<number>(
    LocalStorageKeys.savedFilterId,
    null,
  );
  const { setSavedFilter } = React.useContext(SavedFilterContext);
  const { data, loading, error } = useQuery(SAVED_FILTER_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const localStorageId = parseInt(
      window.localStorage.getItem(LocalStorageKeys.savedFilterId),
      10,
    );
    if (
      localStorageId !== savedFilterIdCache &&
      !(Number.isNaN(localStorageId) || Number.isNaN(savedFilterIdCache))
    ) {
      setSavedFilterIdCache(localStorageId);

      return;
    }

    if (savedFilterIdCache !== null && data) {
      const selectedSavedFilter = data?.savedFilters?.find(
        (filter: ISavedFilter) => filter.id === savedFilterIdCache,
      );
      if (selectedSavedFilter) {
        const typedFilter = getTypedFilters(selectedSavedFilter.filters, true);
        setSavedFilter({ ...selectedSavedFilter, filters: typedFilter });
      } else {
        setSavedFilter(null);
      }
    } else {
      setSavedFilter(null);
    }
  }, [savedFilterIdCache, data]);

  const onChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
    let savedFilterIndex = parseInt(option.id, 10);
    if (savedFilterIndex === -1) savedFilterIndex = null;
    setSavedFilterIdCache(savedFilterIndex);
  };

  const options = () => {
    const personalFilters = data?.savedFilters
      ?.filter((f: ISavedFilter) => f.filterType === FilterType.PERSONAL)
      ?.map((filter: ISavedFilter) => {
        return {
          key: filter.id,
          id: filter.id,
          text: filter.name,
          data: { icon: 'QueryList' },
          itemType: DropdownMenuItemType.Normal,
        };
      });

    if (personalFilters?.length)
      personalFilters.unshift({
        key: 'PersonalFiltersHeader',
        text: 'Personal Saved Filters',
        itemType: DropdownMenuItemType.Header,
        data: { icon: 'QueryList' },
      });

    const sharedFilters = data?.savedFilters
      ?.filter((f: ISavedFilter) => f.filterType === FilterType.SHARED)
      ?.map((filter: ISavedFilter) => {
        return {
          key: filter.id,
          id: filter.id,
          text: filter.name,
          data: { icon: 'ContactList' },
          itemType: DropdownMenuItemType.Normal,
        };
      });

    if (sharedFilters?.length)
      sharedFilters.unshift({
        key: 'SharedFiltersHeader',
        text: 'Shared Saved Filters',
        itemType: DropdownMenuItemType.Header,
        data: { icon: 'ContactList' },
      });

    const noneOption = data?.savedFilters?.length
      ? [
          { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
          { key: -1, id: -1, text: 'None' },
        ]
      : [];

    return [...(personalFilters || []), ...(sharedFilters || []), ...noneOption];
  };
  const headerStyles = { color: getTheme().palette.themePrimary };
  const iconStyles = { marginRight: '8px' };
  const dropdownStyles = (dropdownProps: IDropdownStyleProps) => {
    const { isOpen } = dropdownProps;
    const focusedBgColor = NeutralColors.white;
    return {
      dropdown: {
        width: 300,
        background: isOpen ? focusedBgColor : 'transparent',
        '&:hover': {
          background: focusedBgColor,
        },
        '&:focus': {
          background: focusedBgColor,
        },
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          width: 'calc(100vw - 60px)',
        },
      },
      callout: {
        marginTop: 5,
      },

      dropdownItemHeader: { padding: '6px 14px', height: 'auto', lineHeight: 'auto' },
      dropdownItem: { padding: '6px 12px', height: 'auto' },
      dropdownItemSelected: { padding: '6px 12px', height: 'auto' },
      title: {
        background: 'transparent',
        borderColor: NeutralColors.gray100,
        '&:hover': {
          background: focusedBgColor,
          borderColor: NeutralColors.gray150,
        },
        span: {
          overflow: 'hidden',
        },
      },
    };
  };

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    let returnEl = (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        styles={{ root: { padding: ' 5px 10px' } }}
        grow
      >
        <Stack
          horizontal
          horizontalAlign="center"
          style={option.itemType === DropdownMenuItemType.Header ? headerStyles : {}}
        >
          {option?.data?.icon && option?.itemType === DropdownMenuItemType.Normal && (
            <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" />
          )}
          <span>{option.text}</span>
        </Stack>
      </Stack>
    );
    switch (option.key) {
      case 'FilterHeader':
        returnEl = <SearchBox onChange={(_ev, newValue) => {}} placeholder="Search" underlined />;
        break;
      case 'FilterFooter':
        returnEl = (
          <ActionButton
            disabled={loading}
            iconProps={{ iconName: 'Add' }}
            title="Add saved filter"
            ariaLabel="Add saved filter"
            onClick={() => {}}
          >
            Add saved filter
          </ActionButton>
        );
        break;
      default:
        break;
    }
    return returnEl;
  };

  const onRenderTitle = (opts: IDropdownOption[]): JSX.Element => {
    const option = opts[0];
    return (
      <Stack horizontal verticalAlign="center">
        {option?.data?.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" />
        )}
        <span>
          {option.text}
          {isEdited ? '*' : ''}
        </span>
      </Stack>
    );
  };

  const onRenderPlaceholder = (dropdownProps: IDropdownProps): JSX.Element => {
    return (
      <div className="dropdownExample-placeholder">
        <Icon style={iconStyles} iconName="QueryList" aria-hidden="true" />
        <span>{dropdownProps.placeholder}</span>
      </div>
    );
  };
  return (
    <Stack horizontal verticalAlign="center">
      {(loading || error) && <LoadingErrorMessage loading={loading} error={error} />}
      {!loading && !error && (
        <Dropdown
          selectedKey={savedFilterIdCache}
          placeholder={options()?.length > 0 ? 'Saved filter not selected' : 'No saved filter'}
          ariaLabel="Saved Filter Dropdown"
          disabled={options().length === 0}
          onRenderPlaceholder={onRenderPlaceholder}
          onRenderTitle={onRenderTitle}
          onRenderOption={onRenderOption}
          onChange={onChange}
          styles={dropdownStyles}
          options={options()}
        />
      )}
    </Stack>
  );
};
export default AppHeaderProfileSelector;
