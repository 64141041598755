import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import PanelTypes from '../../../../utils/types/PanelTypes';
import IDataSource from '../../../../utils/types/IDataSource';
import DataSourcePanel from './DataSourcePanel';
import { GetDataSourcesList } from '../../../../utils/api/DataSourcesApi';

interface IDataSourceEditProps {
  dataSource: IDataSource;
  closePanel: () => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const DataSourceEdit = (props: IDataSourceEditProps): JSX.Element => {
  const { mutation, dataSource, closePanel, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetDataSourcesList }];
  const [updateDataSourceValue, { loading, error }] = useMutation(mutation, {
    onCompleted: closePanel,
    refetchQueries,
  });
  const submitForm = (dataSourceDtoData: IDataSource) => {
    updateDataSourceValue({
      variables: {
        dataSourceDto: {
          id: dataSource.id,
          name: dataSourceDtoData.name,
          perspective: dataSourceDtoData?.perspective,
          perspectiveId: dataSourceDtoData.perspectiveId,
          application: dataSourceDtoData?.application,
          applicationId: dataSourceDtoData.applicationId,
        },
      },
    });
  };

  return (
    <DataSourcePanel
      data={dataSource}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default DataSourceEdit;
