import * as React from 'react';
import { Icon, Link, Stack, mergeStyles } from '@fluentui/react';
import { Route, RouteProps } from 'react-router-dom';
import hasAccess from '../../utils/authorization/authorizationCheck';
import UserContext from '../../utils/authorization/UserContext';
import { IFcwPolicy } from '../../utils/types/IFcwPolicy';
import { VIEWPORT_HEIGHT } from '../../common/constants/SiteConstants';
import SiteError from '../../common/errorContent/SiteError';
import { centeredWrapper } from '../../common/styles/CommonStyleObjects';
import LoadingPageContent from '../../common/LoadingPageContent';
import config from '../../utils/ConfigLoader';

const verticalCenterStack = mergeStyles({
  flexDirection: 'column',
  height: VIEWPORT_HEIGHT,
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
});

export interface ProtectedRouteProps extends RouteProps {
  requestedPermission: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requestedPermission,
  ...props
}: ProtectedRouteProps) => {
  const policies: IFcwPolicy[] = React.useContext(UserContext);

  // the polices will be null until the query finishes and puts them on the context
  if (policies === null) {
    return <LoadingPageContent />;
  }

  return hasAccess(policies, requestedPermission) ? (
    <Route {...props} />
  ) : (
    <div className={`ms-Grid ${verticalCenterStack}`}>
      <Stack className={`ms-Grid-row ${centeredWrapper}`}>
        <SiteError
          className="ms-Grid-col ms-sm11 ms-l10 ms-xl8 ms-xxl6 ms-xxxl6"
          headerMessage="You do not have access to this page."
          contentMessage="Unfortunately you do not have access to this page.  To request access please utilize the EUA portal."
          actionButtonsElement={
            <Link href={config?.settings?.euaUrl} target="_blank" rel="noopener noreferrer">
              Click here to visit EUA <Icon iconName="Unlock" aria-label="EUA Icon" />
            </Link>
          }
        />
      </Stack>
    </div>
  );
};

export default ProtectedRoute;
