import { ApolloError } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { Stack, TextField, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import {
  CoherencePanelStyles,
  formChoiceGroup,
} from '../../../../common/styles/CommonStyleObjects';
import { DateConstants } from '../../../../utils/formatters/DateConstants';
import { ITaskPropertyValue } from '../../../../utils/types/ITaskProperty';
import PanelTypes from '../../../../utils/types/PanelTypes';

interface ITaskPropertiesPanelProps {
  propertyName: string;
  panelType: PanelTypes;
  data: ITaskPropertyValue;
  closePanel: () => void;
  mutation: (taskPropertyInputData: ITaskPropertyValue) => void;
  mutationError?: ApolloError;
  mutationLoading?: boolean;
}

const TaskPropertiesPanel = (props: ITaskPropertiesPanelProps): JSX.Element => {
  const { propertyName, closePanel, data, mutation, panelType, mutationError, mutationLoading } =
    props;

  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;

  const yesNo: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' },
  ];

  const handleOnSaveClick = (formData: ITaskPropertyValue): void => {
    mutation(formData);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelType.toString()} ${propertyName}`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel={`Close ${propertyName}`}
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <Stack tokens={{ childrenGap: 20 }}>
          {panelType === PanelTypes.Edit && (
            <span>
              Last modified {moment(data?.modifiedOn).format(DateConstants.DayBreakout)} by{' '}
              {data?.modifiedBy?.displayName}
            </span>
          )}
          <span>
            <RequiredMarker /> Required
          </span>

          <LoadingErrorMessage
            actionName="saving"
            label="saving"
            error={mutationError}
            loading={mutationLoading}
          />
          {data && (
            <>
              <Controller
                as={TextField}
                label="Name"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a name',
                  },
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                name="isActive"
                control={control}
                defaultValue={data?.isActive}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="isActive"
                      label="Is active"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.isActive ? 'true' : 'false'}
                      required
                      onChange={(e, newValue): void => {
                        setValue('isActive', newValue.key === 'true');
                      }}
                    />
                  );
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default TaskPropertiesPanel;
