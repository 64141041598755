import React from 'react';
import { ApolloError } from '@apollo/client';
import { Stack, TextField } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { CoherencePanelStyles } from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import PanelTypes from '../../../../utils/types/PanelTypes';
import IDataSource from '../../../../utils/types/IDataSource';
import IDataSourceApplication from '../../../../utils/types/IDataSourceApplication';

export interface IDataSourceApplicationPanelProps {
  data: IDataSourceApplication;
  mutation: (dataSourceApplicationDtoData: IDataSource) => void;
  closePanel: () => void;
  mutationError: ApolloError | undefined;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const DataSourceApplicationPanel: React.FunctionComponent<IDataSourceApplicationPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: IDataSourceApplicationPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors } = form;
  const handleOnSaveClick = (formData: IDataSource): void => {
    mutation(formData);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelMode} application`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close application"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="Saving application"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                as={TextField}
                label="Name"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a name',
                  },
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default DataSourceApplicationPanel;
