import React from 'react';
import { IDashboardFilters } from 'fcw-dashboard-components';
import TileClientApp from '../TileClientApp';
import config from '../../../utils/ConfigLoader';

const ReconAtAGlanceCards = (props: { filters: IDashboardFilters }): JSX.Element => {
  const { filters } = props;
  const microUiSettings = config?.settings?.reconIntelligenceConfig;

  return (
    <>
      <TileClientApp
        scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
        appName="RIExternalApp"
        data={{
          filters,
          baseUrl: microUiSettings?.siteUrl,
          baseName: 'reconIntelligence',
          moduleType: 'reconciliations',
        }}
        supportLink={`${microUiSettings?.siteUrl}`}
        friendlyAppName="ReconIntelligence Reconciliations"
      />
      <TileClientApp
        scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
        appName="RIExternalApp"
        data={{
          filters,
          baseUrl: microUiSettings?.siteUrl,
          baseName: 'reconIntelligence',
          moduleType: 'reviews',
        }}
        supportLink={`${microUiSettings?.siteUrl}`}
        friendlyAppName="ReconIntelligence Reviews"
      />
      <TileClientApp
        scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
        appName="RIExternalApp"
        data={{
          filters,
          baseUrl: microUiSettings?.siteUrl,
          baseName: 'reconIntelligence',
          moduleType: 'approvals',
        }}
        supportLink={`${microUiSettings?.siteUrl}`}
        friendlyAppName="ReconIntelligence Approvals"
      />
    </>
  );
};

export default ReconAtAGlanceCards;
