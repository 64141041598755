import { Callout, IIconProps, PrimaryButton } from '@fluentui/react';

import React from 'react';

interface IFilterCalloutProps {
  children: JSX.Element;
  triggerButtonId: string;
  triggerIconName: string;
  label?: string;
  toggleIsCalloutVisible: (isCalloutVisible: boolean) => void;
  isCalloutVisible: boolean;
}
const ButtonCallout = (props: IFilterCalloutProps): JSX.Element => {
  const {
    label,
    children,
    triggerIconName,
    triggerButtonId,
    toggleIsCalloutVisible,
    isCalloutVisible,
  } = props;
  const triggerIcon: IIconProps = { iconName: triggerIconName || 'Filter' };
  return (
    <>
      <PrimaryButton
        id={triggerButtonId}
        iconProps={triggerIcon}
        ariaLabel={label}
        onClick={() => {
          toggleIsCalloutVisible(!isCalloutVisible);
        }}
      >
        {label}
      </PrimaryButton>
      {isCalloutVisible && (
        <Callout
          styles={{ calloutMain: { padding: 15 } }}
          target={`#${triggerButtonId}`}
          onDismiss={() => {
            toggleIsCalloutVisible(!isCalloutVisible);
          }}
          role="status"
          aria-live="assertive"
          preventDismissOnEvent={() => false}
          setInitialFocus
        >
          {children}
        </Callout>
      )}
    </>
  );
};
export default ButtonCallout;
