import React from 'react';
import YesNoChecklist from '../../../../common/formFields/YesNoChecklist';
import ListFilters from '../../../../common/lists/ListFilters';
import { GetOpsOrgListSearch, GetOpsSummaryListSearch } from '../../../../utils/api/HierarchiesApi';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import CollapsibleGenericChecklist from '../../../../common/formFields/CollapsibleGenericChecklist';

/** move to central place */
export interface IListFiltersState {
  filterTags: IGenericFilterTag[];
  filterPanelVisible: boolean;
}
export interface IListPageFiltersProps {
  onClosePanel?: () => void;
  currentFilterValues?: IGenericFilterTag[];
  onFilterUpdate?: (formData: IGenericFilterTag[]) => void;
}
const OpsOrgFilters = (props: IListPageFiltersProps): JSX.Element => {
  const { onClosePanel, currentFilterValues, onFilterUpdate } = props;
  return (
    <ListFilters
      onClosePanel={onClosePanel}
      onFilterUpdate={onFilterUpdate}
      currentFilterValues={currentFilterValues}
    >
      <CollapsibleGenericChecklist
        dataKey="opsSummaries"
        label="Ops summaries"
        query={GetOpsSummaryListSearch}
        queryKey="opsSummaryNames"
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgs"
        label="Ops org"
        query={GetOpsOrgListSearch}
        queryKey="opsOrgNames"
      />
      <YesNoChecklist dataKey="isActive" label="Is active" />
    </ListFilters>
  );
};
export default OpsOrgFilters;
