const enumFormatter = (text: string): string => {
  if (!text || text.length === 0) {
    return text;
  }

  if (text.length === 1) {
    return text.toUpperCase();
  }

  const lowerText = text.toLowerCase();

  const spacesAdded = lowerText.replace(/_/g, ' ');

  return spacesAdded.replace(/\w\S*/g, function formatter(textToChange) {
    return textToChange.charAt(0).toUpperCase() + textToChange.substring(1);
  });
};

export default enumFormatter;
