import React from 'react';
import { Switch } from 'react-router-dom';
import DraftTaskFiscalYearListPage from './list/DraftTaskFiscalYearListPage';
import DraftTaskListPage from './list/DraftTaskListPage';
import Page from '../../common/page/Page';
import DraftTaskDetailPage from './details/DraftTaskDetailPage';

const DraftTaskRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Page
          path="/draftTasks/:fiscalYear/:period/details/:draftTaskId"
          component={DraftTaskDetailPage}
          title="Draft Task Details"
          requestedPermission="ViewDraftTask"
        />
        <Page
          path="/draftTasks/:fiscalYear/:period"
          component={DraftTaskListPage}
          title="Draft Task List"
          requestedPermission="ViewDraftTask"
        />
        <Page
          path="/draftTasks"
          component={DraftTaskFiscalYearListPage}
          title="Fiscal Year Summary Draft Task List"
          requestedPermission="ViewDraftTask"
        />
      </Switch>
    </>
  );
};

export default DraftTaskRoutes;
