import { ITag } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

const InvalidUsersChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;
  const InvalidUserType = [
    { key: 'PRIMARY', name: 'Primary' },
    { key: 'BACKUP', name: 'Backup' },
  ];

  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        items={InvalidUserType}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default InvalidUsersChecklist;
