import React, { useContext } from 'react';
import { MetricColor, getMetricColor } from 'fcw-dashboard-components';
import { IColumn, Link, NeutralColors, Stack } from '@fluentui/react';
import JESummary from '../../../utils/types/JESummary';
import JemTaskSummary from '../../../utils/types/JemTaskSummary';
import AppConfigValues from '../../../utils/appConfigValues/AppConfigValues';
import AppConfigValueContext from '../../../utils/appConfigValues/AppConfigValuesContext';

function getAccentColor(index: number): string {
  const accentColors = ['#76DD78', '#B4E9A9', '#FBF7BD', '#FFB84F', '#FFA001', '#F37142'];
  return accentColors[index % accentColors.length];
}

export const getPostingColumns = (cardType: string): IColumn[] => {
  const getLink = (item: JESummary, valueKey: string, jeStatus: string): JSX.Element => {
    const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);

    const { flags } = appConfigValues;
    const itemValue = parseInt(item[valueKey].toString(), 10);
    const displayValue = Intl.NumberFormat('en-US').format(itemValue);
    const jeStatusString = jeStatus ? `&jeStatus=${jeStatus.toLowerCase()}` : '';

    const showLink = itemValue > 0 && flags.JemIntegration;
    return (
      <>
        {showLink ? (
          <Link
            href={`/jem/dashboard?moduleType=${cardType.toLowerCase()}${jeStatusString}&jeType=${item.jeType
              .toString()
              .toLowerCase()}`}
          >
            {displayValue}
          </Link>
        ) : (
          <>{displayValue}</>
        )}
      </>
    );
  };
  return [
    {
      key: 'jeType',
      name: 'JE Type',
      fieldName: 'jeType',
      minWidth: 80,
      isPadded: false,
      flexGrow: 1,
      onRender: (item: JESummary, index): JSX.Element => {
        const textStyles = {
          root: {
            borderLeft: `2px solid ${getAccentColor(index) || NeutralColors.black}`,
            paddingLeft: '4px',
          },
        };
        return <Stack styles={textStyles}>{item.jeType.toString()}</Stack>;
      },
    },

    {
      key: 'totalOverDue',
      name: 'Overdue',
      fieldName: 'totalOverDue',
      minWidth: 45,
      isPadded: false,
      onRender: (item: JESummary, index): JSX.Element => {
        return <>{getLink(item, 'totalOverDue', 'overdue')}</>;
      },
    },
    {
      key: 'totalPending',
      name: 'Pending',
      fieldName: 'totalPending',
      minWidth: 45,
      isPadded: false,
      onRender: (item: JESummary, index): JSX.Element => {
        return <>{getLink(item, 'totalPending', 'pending')}</>;
      },
    },
    {
      key: 'totalComplete',
      name: 'Completed',
      fieldName: 'totalComplete',
      minWidth: 45,
      isPadded: false,
      onRender: (item: JESummary, index): JSX.Element => {
        return <>{getLink(item, 'totalComplete', 'completed')}</>;
      },
    },
    {
      key: 'totalNotRequired',
      name: 'Not Req',
      fieldName: 'totalNotRequired',
      minWidth: 45,
      isPadded: false,
      onRender: (item: JESummary, index): JSX.Element => {
        return <>{getLink(item, 'totalNotRequired', 'notrequired')}</>;
      },
    },
    {
      key: 'totalTasks',
      name: 'Total',
      fieldName: 'totalTasks',
      minWidth: 45,
      isPadded: false,
      onRender: (item: JESummary, index): JSX.Element => {
        return <>{getLink(item, 'totalTasks', null)}</>;
      },
    },
  ];
};

export const getMetricItems = (summary: JemTaskSummary) => {
  return [
    {
      label: 'Overdue',
      value: summary?.overdue,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.danger),
    },
    {
      label: 'Pending',
      value: summary?.pending,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.warning),
    },
    {
      label: 'Not Required',
      value: summary?.notRequired,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.success),
    },
    {
      label: 'Completed',
      value: summary?.complete,
      showInChart: true,
      colorHex: getMetricColor(MetricColor.success),
    },
    {
      label: 'Total Tasks',
      value: summary?.total,
      showInChart: false,
      colorHex: getMetricColor(MetricColor.success),
    },
  ];
};
