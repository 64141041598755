import { ITag } from '@fluentui/react';
import React from 'react';
import { INamable } from '../../utils/types/IHierarchy';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';
import { GetTaskStates } from '../../utils/api/PublishedTaskApi';

interface ITaskPropertyValuesChecklistProps extends IChecklistImplementationProps {
  taskPropertyName: string;
}

const TaskStatesChecklist = (props: ITaskPropertyValuesChecklistProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, taskPropertyName, disabledValues } =
    props;
  const mapToTag = (item: INamable): ITag => {
    return { key: item.id, name: item.name };
  };

  const mapData = (data: { taskStates: { values: INamable[] } }): ITag[] => {
    if (!data) {
      return [];
    }
    return data.taskStates?.values?.map((tpv: INamable) => {
      return mapToTag(tpv);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        query={GetTaskStates}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        taskPropertyName={taskPropertyName}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default TaskStatesChecklist;
