import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DetailsListLayoutMode, ICommandBarItemProps, IObjectWithKey } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../../common/constants/SiteConstants';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../../common/lists/GeneralEntityList';
import PaginationWrapperManaged from '../../../../common/lists/PaginationWrapperManaged';
import { GetDefaultCompany } from '../../../../utils/api/CompaniesApi';
import {
  AddOpsOrgDetailValue,
  EditOpsOrgDetailBulk,
  EditOpsOrgDetailRemoveBulk,
  EditOpsOrgDetailValue,
  GetOpsOrgDetailList,
} from '../../../../utils/api/HierarchiesApi';
import paginatedResultFormatter from '../../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../../utils/hooks/useLocalStorage';
import ICompany from '../../../../utils/types/ICompany';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import { IOpsOrgDetail } from '../../../../utils/types/IHierarchy';
import { IQuerySortDefinition, SortDirection } from '../../../../utils/types/IListSortDefinition';
import { IEntityListState, IListQueryVariables } from '../../../../utils/types/IList';
import { PaginatedData, PaginationQueryVariables } from '../../../../utils/types/PaginationTypes';
import PanelTypes from '../../../../utils/types/PanelTypes';
import ColumnConfigPanel from '../../../tasks/list/ColumnConfigPanel';
import OpsOrgDetailEdit from '../../details/operations/OpsOrgDetailEdit';
import { mapOpsDetailTagsToIds } from '../HierarchiesListfilters.config';
import getHierarchiesMenu, {
  IHierarchiesMenuProps,
  getOpsOrgDetailColumnsList,
} from '../HierarchiesList.config';
import OpsOrgDetailFilters from './OpsOrgDetailFilters';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../../utils/listHelpers';
import { IDownloadButtonProps } from '../../../buttons/DownloadButton';
import BulkEditMode from '../../../../utils/types/IBulkEdit';
import {
  getFormInputConfig,
  getRemoveOperationsMapper,
  getUpdateOperationsMapper,
} from '../../edit/operations/OpsOrgDetailBulkEdit.config';
import BulkEdit, { BulkEditType } from '../../../../common/bulkEdit/BulkEdit';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../../common/lists/GenericFilterTagHandler';

interface IOpsOrgDetailListState extends IEntityListState {
  showEditPanel: boolean;
  showAddPanel: boolean;
  selectedItem: IOpsOrgDetail;
}
const OpsOrgDetail = (): JSX.Element => {
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'name',
  };
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.opsOrgDetailListColumns,
    [],
  );

  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.opsOrgDetailQueryState}`,
    { ...defaultPaginationProps, ...defaultFiltersAndSort } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IOpsOrgDetailListState>({
    showAddPanel: false,
    showEditPanel: false,
    selectedItem: null,
    ...getDefaultStateProps(taskQueryCache),
  });

  const [getData, { data, loading, error }] = useLazyQuery(GetOpsOrgDetailList, {
    variables: {
      keyword: taskQueryCache.keyword,
      filters: {},
      ...taskQueryCache?.pageInfo,
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { data: defaultCompanyData } = useQuery<{ defaultCompany: ICompany }>(GetDefaultCompany, {
    fetchPolicy: 'cache-only',
  });
  let filterTimeout: string | number | NodeJS.Timeout;

  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = mapOpsDetailTagsToIds(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    getData({
      variables: {
        keyword,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };

  const closePanels = (shouldRefresh: boolean): void => {
    setPagesState({
      ...pageState,
      showAddPanel: false,
      showEditPanel: false,
    });
    if (shouldRefresh) {
      refreshPage();
    }
  };
  const [selectedOpsDetail, setSelectedOpsDetail] = useState([]);

  const [dataResult, setDataResult] = useState<PaginatedData<IOpsOrgDetail>>();
  const cachedListColumns = getColumnConfigByKey(
    getOpsOrgDetailColumnsList((selectedItem: IOpsOrgDetail) => {
      setPagesState({
        ...pageState,
        showEditPanel: true,
        selectedItem,
      });
    }, selectedOpsDetail.length > 1),
    listColumnsCache,
  );

  const itemsSelected = selectedOpsDetail?.length > 0;
  const onAddItem = (): void => {
    setPagesState({
      ...pageState,
      showAddPanel: true,
    });
  };
  const onBulkEditClick = (editMode: BulkEditMode) => {
    setPagesState({
      ...pageState,
      bulkEditMode: editMode,
    });
  };
  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };
  const commandBarItems = React.useMemo((): ICommandBarItemProps[] => {
    const exportButtonProps: IDownloadButtonProps = {
      key: 'ExportTaskPropertyOperationOrgDetail',
      fileEndpoint: 'OperationOrganizationDetail?OpsDetailFull',
      fileName: 'OperationOrgDetail',
    };

    const commandBarItemConfig: IHierarchiesMenuProps = {
      itemsSelected,
      itemsSelectedCount: selectedOpsDetail?.length,
      totalItemsCount: dataResult?.totalCount,
      onAddItem,
      onRefreshClick,
      onBulkEditClick,
      exportButtonProps,
    };
    return getHierarchiesMenu(commandBarItemConfig);
  }, [
    itemsSelected,
    selectedOpsDetail?.length,
    pageState.keyword,
    pageState.filterTags,
    dataResult?.totalCount,
  ]);
  const detailsListConfig: IGeneralEntityListProps<IOpsOrgDetail> = {
    data: dataResult?.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems,
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSearchBoxChange: (ev, searchTerm: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
          ...getResetPaginationProps(taskQueryCache?.paginationSize),
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    layoutMode: DetailsListLayoutMode.fixedColumns,
    loading,
    error,
    onChangeSelectedItems: (selection: IObjectWithKey[]) => {
      setSelectedOpsDetail(selection);
    },
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <OpsOrgDetailFilters />
        </GenericFilterTagHandler>
      );
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
  };
  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };

  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  useEffect(() => {
    // i had to do this because selected items were breaking if i referenced formatted data directly
    const formattedData = paginatedResultFormatter<IOpsOrgDetail>(data?.opsOrgDetails);
    setDataResult(formattedData);
    setSelectedOpsDetail([]);
  }, [loading, data]);
  const selectedOpsDetailList = selectedOpsDetail?.map((opsDetail: IOpsOrgDetail) => {
    return opsDetail?.id;
  });
  return (
    <>
      <PaginationWrapperManaged<IOpsOrgDetail>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>
      {pageState.showEditPanel && (
        <OpsOrgDetailEdit
          opsOrgDetail={pageState.selectedItem}
          closePanel={closePanels}
          mutation={EditOpsOrgDetailValue}
          panelMode={PanelTypes.Edit}
        />
      )}
      <BulkEdit
        type={BulkEditType.operations}
        getFormInputs={getFormInputConfig}
        bulkEditMode={pageState?.bulkEditMode}
        hideBulkEditModal={() => {
          const stateCopy = structuredClone(pageState);
          setPagesState({
            ...stateCopy,
            bulkEditMode: null,
          });
        }}
        selectedItemsCount={
          pageState.bulkEditMode === BulkEditMode.BulkEditSelected
            ? selectedOpsDetail.length
            : dataResult?.totalCount
        }
        selectedItems={selectedOpsDetailList}
        keyword={pageState.keyword}
        filters={mapOpsDetailTagsToIds(pageState.filterTags)}
        updateMutation={useMutation(EditOpsOrgDetailBulk)}
        removeMutation={useMutation(EditOpsOrgDetailRemoveBulk)}
        getUpdateMapper={getUpdateOperationsMapper}
        getRemoveMapper={getRemoveOperationsMapper}
      />

      {pageState.showAddPanel && (
        <OpsOrgDetailEdit
          opsOrgDetail={{
            id: -1,
            name: '',
            isActive: true,
            autoPublish: false,
            companyCodes: defaultCompanyData?.defaultCompany
              ? [defaultCompanyData?.defaultCompany]
              : [],
          }}
          closePanel={closePanels}
          mutation={AddOpsOrgDetailValue}
          panelMode={PanelTypes.Add}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </>
  );
};
export default OpsOrgDetail;
