import { useQuery } from '@apollo/client';
import {
  TagPicker,
  ITag,
  SharedColors,
  FontSizes,
  FontWeights,
  ValidationState,
  Label,
} from '@fluentui/react';
import React, { useState } from 'react';
import GetTaskTags from '../../utils/api/TaskTagsApi';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';

const labelStyles = {
  fontWeight: FontWeights.semibold,
  fontSize: FontSizes.size14,
  paddingTop: '5px',
  paddingBottom: '5px',
};

const pickerErrorStyles = {
  text: {
    borderColor: SharedColors.red20,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '2px',
    selectors: {
      '&:hover': {
        borderColor: SharedColors.red20,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '2px',
      },
      '::after': {
        borderColor: SharedColors.red20,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '2px',
        inset: '-1px',
      },
    },
  },
};

export interface ITaskTagPickerProps {
  pickerId: string;
  defaultSelectedKey: ITag[];
  onChange?: (tags: ITag[]) => void;
  errorMessage?: string;
  label?: string;
  enableNew?: boolean;
  disabled?: boolean;
}

const TaskTagPicker = (props: ITaskTagPickerProps): JSX.Element => {
  const { pickerId, defaultSelectedKey, onChange, errorMessage, label, enableNew, disabled } =
    props;
  const [newTagsLength, setNewTagsLength] = useState<number>(0);

  const { refetch } = useQuery(GetTaskTags, { skip: true, variables: { keyword: '' } });

  const duplicateCheck = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }

    return tagList.some((item) => item.name.toLowerCase() === tag.name.toLowerCase());
  };

  const onValidateInput = (input: string): ValidationState => {
    if (input && input.length > 1 && input.length < 30) {
      return ValidationState.valid;
    }

    return ValidationState.invalid;
  };

  const searchTaskTags = (keyword: string, tagList: ITag[]): ITag[] | Promise<ITag[]> => {
    if (keyword) {
      return refetch({
        keyword,
      }).then((result): ITag[] => {
        const existingTags = result.data.taskTags
          .filter((item: ITag) => {
            return !duplicateCheck(item, tagList);
          })
          .map((theTag: ITag): ITag => {
            return {
              key: theTag.key,
              name: theTag.name,
            };
          });
        if (enableNew) {
          const keyValue = newTagsLength - 1;
          setNewTagsLength(keyValue);
          const newTag = { key: keyValue, name: keyword };
          if (duplicateCheck(newTag, result.data.taskTags.concat(tagList))) {
            return existingTags;
          }
          existingTags.splice(0, 0, newTag);
        }
        return existingTags;
      });
    }

    return [];
  };
  const styles = errorMessage ? pickerErrorStyles : {};

  return (
    <>
      {label && (
        <Label style={labelStyles} disabled={disabled}>
          {label}
        </Label>
      )}
      <TagPicker
        aria-label={label}
        removeButtonAriaLabel="Remove"
        selectionAriaLabel="Selected Tags"
        onResolveSuggestions={searchTaskTags}
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
          suggestionsHeaderText: 'Suggested tags',
          forceResolveText: 'Tab to create new',
        }}
        resolveDelay={THROTTLE_SEARCH_TIMEOUT}
        inputProps={{
          id: pickerId,
          'aria-label': label || 'Value',
          placeholder: 'Type one or more tag',
        }}
        styles={styles}
        onChange={onChange}
        defaultSelectedItems={defaultSelectedKey}
        onValidateInput={onValidateInput}
        disabled={disabled}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export default TaskTagPicker;
