import { ICommandBarItemProps, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import SpanWithLabel from '../../common/cardContents/SpanWithLabel';
import ValueTag from '../../common/cards/ValueTag';
import { EDIT_SAVED_FILTER_MUTATION } from '../../utils/api/SavedFilters';
import { IGenericFilterTag, ITypedTag } from '../../utils/types/IGenericFilterTag';
import { FilterType, ISavedFilter } from '../../utils/types/SavedFilters';
import SavedFilterPanel from './panels/SavedFilterPanel';
import SavedFilterDeleteModal from './SavedFilterDeleteModal';
import ShareSavedFilterPanel from './panels/ShareFilterPanel';
import SharedFilterRemovePanel from './panels/SharedFilterRemovePanel';
import InformationCard, { infoCardButtonStyles } from '../users/InformationCard';

interface ISavedFilterCardProps {
  savedFilter: ISavedFilter;
  filterType: FilterType;
  allCollapsed: boolean;
}

const SavedFilterCard = (props: ISavedFilterCardProps): JSX.Element => {
  const { savedFilter, filterType, allCollapsed } = props;

  const [panelState, setPanelState] = useState({
    isEditPanelOpen: false,
    isDeletePanelOpen: false,
    isSharePanelOpen: false,
    isRemoveSharePanelOpen: false,
  });

  const openEditSavedFilterPanel = (): void => {
    setPanelState({ ...panelState, isEditPanelOpen: true });
  };

  const openShareSavedFilterPanel = (): void => {
    setPanelState({ ...panelState, isSharePanelOpen: true });
  };

  const closePanels = (): void => {
    setPanelState({
      isEditPanelOpen: false,
      isDeletePanelOpen: false,
      isSharePanelOpen: false,
      isRemoveSharePanelOpen: false,
    });
  };

  const onDeleteFilterClicked = (): void => {
    setPanelState({ ...panelState, isDeletePanelOpen: true });
  };

  const onRemoveSharedClicked = (): void => {
    setPanelState({ ...panelState, isRemoveSharePanelOpen: true });
  };

  const sharedMenuProps: ICommandBarItemProps[] = [
    {
      key: 'onRemoveSharedClicked',
      text: 'Remove',
      iconProps: { iconName: 'Delete' },
      onClick: onRemoveSharedClicked,
      buttonStyles: infoCardButtonStyles,
    },
  ];

  const personalMenuProps: ICommandBarItemProps[] = [
    {
      key: 'editSavedFilter',
      text: 'Edit',
      iconProps: { iconName: 'Edit' },
      onClick: openEditSavedFilterPanel,
      buttonStyles: infoCardButtonStyles,
    },
    {
      key: 'shareSavedFilter',
      text: 'Share',
      iconProps: { iconName: 'Share' },
      onClick: openShareSavedFilterPanel,
      buttonStyles: infoCardButtonStyles,
    },
    {
      key: 'onDeleteFilterClicked',
      text: 'Delete',
      iconProps: { iconName: 'Delete' },
      onClick: onDeleteFilterClicked,
      buttonStyles: infoCardButtonStyles,
    },
  ];

  return (
    <>
      <InformationCard
        cardTitle={savedFilter.name}
        commandBarItems={filterType === FilterType.PERSONAL ? personalMenuProps : sharedMenuProps}
        allCollapsed={allCollapsed}
      >
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
          {savedFilter.filters
            .filter((filter) => !filter?.hideFromBar)
            ?.map((filter: IGenericFilterTag) => {
              return (
                <SpanWithLabel labelText={filter.title} key={filter.dataKey}>
                  <Stack tokens={{ childrenGap: 5 }}>
                    {filter.values.map((filerValue: ITypedTag) => {
                      return (
                        <ValueTag
                          key={filerValue.key}
                          tagString={filerValue.name}
                          useWhiteBackground
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              );
            })}
        </Stack>
      </InformationCard>
      {panelState.isEditPanelOpen && (
        <SavedFilterPanel
          closePanel={closePanels}
          savedFilter={structuredClone(savedFilter)}
          mutation={EDIT_SAVED_FILTER_MUTATION}
          mode="Edit"
        />
      )}
      {panelState.isSharePanelOpen && (
        <ShareSavedFilterPanel
          closePanel={closePanels}
          savedFilter={structuredClone(savedFilter)}
        />
      )}
      <SavedFilterDeleteModal
        selectedFilterId={savedFilter.id}
        showDeleteModal={panelState.isDeletePanelOpen}
        hideSavedFilterDeleteModal={closePanels}
      />
      <SharedFilterRemovePanel
        selectedFilterId={savedFilter.id}
        showDeleteModal={panelState.isRemoveSharePanelOpen}
        hideSavedFilterDeleteModal={closePanels}
      />
    </>
  );
};

export default SavedFilterCard;
