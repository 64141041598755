import React from 'react';
import { Stack, Link } from '@fluentui/react';
import { ComponentProvider } from '@micro-frontend-react/core/lib/ComponentProvider';
import { DashboardCard, ErrorPage } from 'fcw-dashboard-components';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IFCWMicroFrontendProps } from '../integrations/ClientAppLoader';

interface ITileClientAppProps {
  scriptUrl: string;
  appName: string;
  friendlyAppName: string;
  supportLink: string;
  data: Omit<IFCWMicroFrontendProps, 'msalProvider'>;
}
const TileClientApp = (props: ITileClientAppProps): JSX.Element => {
  const [componentState, setComponentState] = React.useState({
    loadingComponent: true,
    errorLoadingComponent: false,
  });
  const { scriptUrl, appName, friendlyAppName, data, supportLink } = props;
  return (
    <Stack styles={{ root: { minWidth: 470, margin: 0 } }}>
      {scriptUrl && (
        <ComponentProvider
          config={{
            script: scriptUrl,
            name: appName,
          }}
          data={data}
          onError={() => {
            setComponentState({
              ...componentState,
              loadingComponent: false,
              errorLoadingComponent: true,
            });
          }}
          onLoad={() => {
            setComponentState({
              ...componentState,
              loadingComponent: true,
              errorLoadingComponent: false,
            });
          }}
          onLoaded={() => {
            setComponentState({
              ...componentState,
              loadingComponent: false,
              errorLoadingComponent: false,
            });
          }}
        />
      )}
      {componentState.errorLoadingComponent && (
        <DashboardCard
          title={friendlyAppName}
          subTitle="Client Component Error"
          filters={undefined}
          supportedFilters={[]}
        >
          <ErrorPage
            contentHeader="Error"
            contentMessage="Could not load component"
            actionItemsElement={
              <Link target="_blank" href={supportLink}>
                Contact Support
              </Link>
            }
          />
        </DashboardCard>
      )}
      {componentState.loadingComponent && (
        <DashboardCard
          title={friendlyAppName}
          subTitle="Loading"
          filters={undefined}
          supportedFilters={[]}
        >
          <Stack>
            <LoadingErrorMessage loading />
          </Stack>
        </DashboardCard>
      )}
    </Stack>
  );
};
export default TileClientApp;
