import { useQuery } from '@apollo/client';
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { GetDataSourcePerspectivesByKeyword } from '../../utils/api/DataSourcesApi';
import IDataSourceApplication from '../../utils/types/IDataSourceApplication';
import IDataSourcePerspective from '../../utils/types/IDataSourcePerspective';

export interface IDataSourcePerspectiveDropdownReference extends IDropdownOption {
  dataSourcePerspective: IDataSourceApplication;
}

export const mapEntityToDropdown = (DataSourcePerspectives: IDataSourcePerspective[]) => {
  let perspectives = [];
  perspectives = DataSourcePerspectives?.map(
    (perspective: IDataSourcePerspective): IDataSourcePerspectiveDropdownReference => {
      return {
        key: perspective.id,
        text: perspective.name,
        dataSourcePerspective: perspective,
      };
    },

    perspectives.unshift({
      key: 'FilterHeader',
      itemType: DropdownMenuItemType.Header,
      text: '',
    }),
  );
  return perspectives;
};

const DataSourcePerspectiveDropdown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(
    GetDataSourcePerspectivesByKeyword,
    {
      fetchPolicy: 'network-only',
      variables: {
        order: [{ name: 'ASC' }],
      },
    },
  );
  const { dataSourcePerspectives } = !dropdownLoading && dropdownData ? dropdownData : [];

  return (
    <QueryBasedDropdown
      required={required}
      value={value}
      label={label}
      ariaLabel={label}
      outputKey={outputKey}
      dropdownOptionsData={mapEntityToDropdown(dataSourcePerspectives)}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default DataSourcePerspectiveDropdown;
