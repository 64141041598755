import { MutationTuple, OperationVariables } from '@apollo/client';
import {
  Stack,
  Label,
  ActionButton,
  PrimaryButton,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import React, { useState } from 'react';
import { DeepPartial, UnpackNestedValue, useForm } from 'react-hook-form';
import BulkEditMode from '../../utils/types/IBulkEdit';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import BulkEditRow, { IFormEditInputRow } from './BulkEditRow';
import { IOpsDetailFormInputChoice } from '../../components/hierarchies/edit/operations/OpsOrgDetailBulkEdit.config';
import { ITaskFormInputChoice } from '../../components/tasks/edit/StagedTaskBulkEdit.config';
import EnhanceModal from '../modals/EnhancedModal';

interface IBulkEditProps<T> {
  type: string;
  selectedItems?: number[];
  keyword?: string;
  filters?: unknown;
  selectedItemsCount: number;
  bulkEditMode: BulkEditMode;
  hideBulkEditModal: () => void;
  updateMutation: MutationTuple<T, Record<string, unknown>>;
  removeMutation: MutationTuple<T, Record<string, unknown>>;
  getUpdateMapper: (formData: UnpackNestedValue<T>) => OperationVariables;
  getRemoveMapper: (formData: UnpackNestedValue<T>) => OperationVariables;
  getFormInputs: (
    setValue: (name: string, value: UnpackNestedValue<DeepPartial<T>>) => void,
  ) => IOpsDetailFormInputChoice[] | ITaskFormInputChoice[];
}

export enum BulkEditActionKeys {
  override = 'override',
  append = 'append',
  remove = 'remove',
}
export enum BulkEditType {
  operations = 'operations',
  tasks = 'tasks',
}
const BulkEdit = <T,>(props: IBulkEditProps<T>): JSX.Element => {
  const {
    type,
    keyword,
    filters,
    selectedItems,
    bulkEditMode,
    hideBulkEditModal,
    selectedItemsCount,
    updateMutation,
    removeMutation,
    getUpdateMapper,
    getRemoveMapper,
    getFormInputs,
  } = props;
  const [actionMode, setActionMode] = useState<string>(BulkEditActionKeys.override);
  const [update, { loading, error }] = updateMutation;
  const [remove, { loading: loadingRemove, error: errorRemove }] = removeMutation;

  const modalBodyStyle = { padding: '20px', minWidth: '600px' };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto 50px auto 50px',
    gridAutoRows: 'auto',
    rowGap: '10px',
  };

  const messageBarStyle = { root: { margin: '10px 0' } };

  const form = useForm<T>();
  const { control, handleSubmit, setValue, getValues, errors } = form;
  const formInputs = getFormInputs(setValue);
  const [bulkEditState, setBulkEditState] = useState<IFormEditInputRow[]>([]);
  const resetComponent = () => {
    setBulkEditState([]);
    hideBulkEditModal();
    setActionMode(BulkEditActionKeys.override);
  };

  const getModeVariables = () => {
    return {
      selectedItems: bulkEditMode === BulkEditMode.BulkEditSelected ? selectedItems : null,
      keyword: bulkEditMode === BulkEditMode.BulkEditAllFiltered ? keyword : null,
      filters: bulkEditMode === BulkEditMode.BulkEditAllFiltered ? filters : null,
    };
  };

  const submitForm = (inputData: UnpackNestedValue<T>) => {
    if (actionMode !== BulkEditActionKeys.remove) {
      update({
        variables: {
          itemBulkDto: {
            ...getUpdateMapper(inputData),
            ...getModeVariables(),
            appendOnly: actionMode === BulkEditActionKeys.append,
          },
        },
      }).then(() => {
        resetComponent();
      });
    } else {
      remove({
        variables: {
          itemBulkDto: {
            ...getRemoveMapper(inputData),
            ...getModeVariables(),
            appendOnly: false,
          },
        },
      }).then(() => {
        resetComponent();
      });
    }
  };
  const handleOnSaveClick = (formData: UnpackNestedValue<T>): void => {
    submitForm(formData);
  };
  const renderFormFields = () => {
    const activeFormInputs = bulkEditState.map((item) => {
      return item.selectedInputKey;
    });
    const unusedFormInputs = formInputs.filter((input) => {
      const isUnused = !activeFormInputs.includes(input.key.toString());
      const showValue = actionMode === BulkEditActionKeys.override ? true : input.isMultiValue;
      return isUnused && showValue;
    });
    return (
      <div style={modalBodyStyle}>
        <div style={gridStyle}>
          <div style={{ gridColumn: '1' }}>
            <Label>Field</Label>
          </div>
          <div style={{ gridColumn: '3' }}>
            <Label>Value</Label>
          </div>
          {bulkEditState?.map((bulkEditRow) => {
            return (
              <BulkEditRow
                key={bulkEditRow.selectedInputKey}
                inputRowProps={bulkEditRow}
                formInputs={formInputs}
                showSingleValueOptions={actionMode === BulkEditActionKeys.override}
                activeFormInputs={activeFormInputs}
                control={control}
                bulkEditState={bulkEditState}
                setBulkEditState={setBulkEditState}
                getValues={getValues}
                errors={errors}
              />
            );
          })}
          {unusedFormInputs.length > 0 && (
            <div style={{ gridColumn: '1' }}>
              <ActionButton
                text="Add more"
                iconProps={{ iconName: 'Add' }}
                onClick={() => {
                  const currentState = bulkEditState;
                  currentState.push({
                    selectedInputKey: unusedFormInputs[0]?.key.toString(),
                  });
                  setBulkEditState([...currentState]);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  const getMessage = (actionModeString: string) => {
    switch (actionModeString) {
      case BulkEditActionKeys.remove:
        return (
          <>
            Click save to remove selected values from fields on{' '}
            <strong> {selectedItemsCount} </strong> items.
          </>
        );
      case BulkEditActionKeys.override:
        return (
          <>
            Click save to replace the fields with selected values on{' '}
            <strong> {selectedItemsCount} </strong> items.
          </>
        );
      default:
        return (
          <>
            Click save to add selected values to fields on <strong> {selectedItemsCount} </strong>{' '}
            items.
          </>
        );
    }
  };
  return (
    <EnhanceModal
      header={`Bulk edit ${type}`}
      isOpen={bulkEditMode !== null}
      onDismiss={() => {
        setBulkEditState([]);
        hideBulkEditModal();
      }}
    >
      <>
        <Pivot
          onLinkClick={(item: PivotItem) => {
            setBulkEditState([]);
            setActionMode(item.props.itemKey);
          }}
        >
          <PivotItem headerText="Change" itemKey={BulkEditActionKeys.override}>
            {renderFormFields()}
          </PivotItem>
          <PivotItem headerText="Append" itemKey={BulkEditActionKeys.append}>
            {renderFormFields()}
          </PivotItem>
          <PivotItem headerText="Remove" itemKey={BulkEditActionKeys.remove}>
            {renderFormFields()}
          </PivotItem>
        </Pivot>
        <LoadingErrorMessage
          loading={loading || loadingRemove}
          error={error || errorRemove}
          label={`Saving ${type}`}
        />
        <MessageBar messageBarType={MessageBarType.warning} styles={messageBarStyle}>
          {getMessage(actionMode)}
        </MessageBar>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Save"
            onClick={handleSubmit(handleOnSaveClick)}
            ariaLabel="Save"
            disabled={loading}
          />
          <DefaultButton
            disabled={loading}
            text="Cancel"
            onClick={() => {
              resetComponent();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </>
    </EnhanceModal>
  );
};
export default BulkEdit;
