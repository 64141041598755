import { Stack, StackItem } from '@fluentui/react';
import React from 'react';
import {
  formGridTripleColumnStyles,
  formGridDoubleColumnStyles,
  formGridSingleColumnStyles,
  formGridColumnWrapperStyles,
  formGridHiddenColumnStyles,
} from '../styles/CommonStyleObjects';

export enum FormGridColumnWidth {
  Hide = 0,
  Single = 1,
  Double = 2,
  Triple = 3,
}
interface IFormGridColumnProps {
  columnWidth: FormGridColumnWidth;
  children: JSX.Element;
  centerText?: boolean;
}
const FormGridColumn = (props: IFormGridColumnProps): JSX.Element => {
  const { columnWidth, children, centerText } = props;
  const getColumnStyles = () => {
    switch (columnWidth) {
      case FormGridColumnWidth.Hide:
        return formGridHiddenColumnStyles;
      case FormGridColumnWidth.Triple:
        return formGridTripleColumnStyles;
      case FormGridColumnWidth.Double:
        return formGridDoubleColumnStyles;
      default:
        return formGridSingleColumnStyles;
    }
  };
  return (
    <Stack styles={getColumnStyles()} verticalAlign={centerText ? 'center' : 'start'}>
      <StackItem styles={formGridColumnWrapperStyles}>{children}</StackItem>
    </Stack>
  );
};
export default FormGridColumn;
