import { useMutation } from '@apollo/client';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Stack } from '@fluentui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ActionButtons from '../../../common/buttons/ActionButtons';
import SpanWithLabel from '../../../common/cardContents/SpanWithLabel';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { EditDraftTask, GetDraftTask } from '../../../utils/api/DraftTaskApi';
import { IPublishedTask } from '../../../utils/types/ITask';
import SmallTaskEditForm from './SmallTaskEditForm';

interface IDraftTaskEditPanelProps {
  closePanel: () => void;
  task: IPublishedTask;
}

const DraftTaskEditPanel = ({ closePanel, task }: IDraftTaskEditPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit } = form;

  const [updateDraftTask, { loading, error }] = useMutation(EditDraftTask, {
    refetchQueries: [{ query: GetDraftTask, variables: { draftTaskId: task?.id } }],
    onCompleted: (): void => {
      closePanel();
    },
  });

  const submitForm = (draftTaskInputData: IPublishedTask) => {
    updateDraftTask({
      variables: {
        draftTaskDto: {
          id: task?.id,
          dueTime: draftTaskInputData.dueTime,
          dueDate: draftTaskInputData.dueDate,
          primaries: draftTaskInputData.primaries,
          backupTaskPerformers: draftTaskInputData.backupTaskPerformers,
          stakeholders: draftTaskInputData.stakeholders,
          publishedTaskNotes: draftTaskInputData.publishedTaskNotes,
        },
      },
    });
  };

  return (
    <CoherencePanel
      titleText={`Edit Task - ID ${task?.id}`}
      isOpen
      hasCloseButton
      closeButtonAriaLabel="Close Edit Task"
      onDismiss={closePanel}
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            closePanel={closePanel}
            handleSubmit={handleSubmit(submitForm)}
            saveLabel="Save"
            saveTitle="Save"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
            mutationLoading={loading}
          />
        </Stack>
      )}
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <SpanWithLabel labelText="Task Name">
        <Link to={`/draftTasks/${task?.fiscalYear}/${task?.fiscalPeriod}/details/${task?.id}`}>
          {task?.name}
        </Link>
      </SpanWithLabel>
      <SmallTaskEditForm form={form} publishedTask={task} />
    </CoherencePanel>
  );
};

export default DraftTaskEditPanel;
