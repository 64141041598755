import { gql } from '@apollo/client';

const USER_FRAGMENT = gql`
  fragment UserInfoFragment on UserInfoDto {
    graphGuid
    alias
    displayName
    euaClaims {
      enddate
      roleId
      role
      scopes {
        scopeName
        scopeValue
      }
    }
    fcwClaims {
      enddate
      roleId
      role
      scopes {
        scopeName
        scopeValue
      }
    }
  }
`;
export const GET_USER_QUERY = gql`
  query currentUserInfo {
    currentUserInfo {
      ...UserInfoFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER_POLICIES = gql`
  query currentUserPolicies {
    currentUserPolicies {
      name
      isScoped
      source
    }
  }
`;

export const GET_USER_QUERY_WITH_POLICIES = gql`
  query currentUserInfoWithPolicies {
    currentUserInfo {
      ...UserInfoFragment
    }
    currentUserPolicies {
      name
      isScoped
      source
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_ALL_ROLES = gql`
  query allRoles {
    allRoles {
      roleId
      role
      policies {
        name
        isScoped
        source
      }
    }
  }
`;

export const REFRESH_EUA_PERMISSIONS = gql`
  mutation refreshEuaPermissions {
    refreshEuaPermissions {
      ...UserInfoFragment
    }
  }
  ${USER_FRAGMENT}
`;
