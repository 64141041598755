import { Panel, PanelType, Stack } from '@fluentui/react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import ActionButtons from '../../../common/buttons/ActionButtons';
import { panelFooter } from '../../../common/styles/CommonStyleObjects';
import { ISavedFilter } from '../../../utils/types/SavedFilters';
import GraphUsersPeoplePicker from '../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../utils/types/IAadUser';
import {
  EDIT_SAVED_FILTER_SHARING_MUTATION,
  SAVED_FILTER_LIST_QUERY,
} from '../../../utils/api/SavedFilters';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

interface IShareSavedFilterPanelProps {
  closePanel: () => void;
  savedFilter: ISavedFilter;
}

const ShareSavedFilterPanel = (props: IShareSavedFilterPanelProps): JSX.Element => {
  const { closePanel, savedFilter } = props;
  const { control, setValue, handleSubmit } = useForm();
  const [saveSharing, { loading, error }] = useMutation(EDIT_SAVED_FILTER_SHARING_MUTATION, {
    onCompleted: (): void => {
      closePanel();
    },
    refetchQueries: [SAVED_FILTER_LIST_QUERY],
  });

  const submitForm = (formData: ISavedFilter): void => {
    const sharingInput = {
      savedFilterId: savedFilter.id,
      peopleSharedWith: formData.peopleSharedWith,
    };

    saveSharing({
      variables: {
        savedFilterShareInput: sharingInput,
      },
    });
  };

  return (
    <Panel
      title="Share Saved Filter"
      headerText="Share Saved Filter"
      onDismiss={closePanel}
      onLightDismissClick={closePanel}
      isOpen
      isLightDismiss
      type={PanelType.medium}
      onRenderFooterContent={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={handleSubmit(submitForm)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          className={panelFooter}
          mutationLoading={loading}
        />
      )}
      isFooterAtBottom
    >
      <h2>Filter sharing for: {savedFilter.name}</h2>
      <LoadingErrorMessage error={error} loading={loading} />
      <Controller
        name="peopleSharedWith"
        valueKey="peopleSharedWith"
        control={control}
        defaultValue={savedFilter?.peopleSharedWith}
        render={({ value }) => {
          return (
            <Stack tokens={{ childrenGap: 5 }}>
              <GraphUsersPeoplePicker
                id="peopleSharedWith"
                itemLimit={50}
                removeButtonAriaLabel="Remove person shared with"
                controlName="peopleSharedWith"
                onChange={(users: IAadUser[]): void => {
                  setValue('peopleSharedWith', users);
                }}
                defaultValue={value}
                placeholder="Type one or more alias"
                label="Persons shared with"
              />
            </Stack>
          );
        }}
      />
    </Panel>
  );
};

export default ShareSavedFilterPanel;
