import { useQuery } from '@apollo/client';
import { ActionButton, Icon, Stack, Link } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ModifiedDetails from '../../../common/auditedEntity/ModifiedDetails';
import BackButton from '../../../common/buttons/BackButton';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import {
  bodyContentContainerAlternateBg,
  cardBackground,
} from '../../../common/styles/CommonStyleObjects';
import { GetDraftTask } from '../../../utils/api/DraftTaskApi';
import config from '../../../utils/ConfigLoader';
import { IPublishedTask, IDraftTaskRouteParams } from '../../../utils/types/ITask';
import DraftTaskEditPanel from '../edit/DraftTaskEditPanel';
import TaskDetailCards from './TaskDetailCards';

const DraftTaskDetailPage = (): JSX.Element => {
  const { draftTaskId } = useParams<IDraftTaskRouteParams>();
  const { data, loading, error } = useQuery<{ draftTask: IPublishedTask }>(GetDraftTask, {
    variables: { draftTaskId: parseInt(draftTaskId, 10) },
  });

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const draftTask = data?.draftTask;

  const onEditClick = (): void => {
    setIsPanelOpen(true);
  };

  const closeEditPanel = (): void => {
    setIsPanelOpen(false);
  };

  const renderHeader = (): JSX.Element => {
    const taskType = 'Draft Task';

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
          <BackButton ariaLabel={`Back to ${taskType} list`} backDepth={2}>
            <h1>{`${taskType}`}</h1>
          </BackButton>
          <ModifiedDetails
            modifiedOn={draftTask?.modifiedOn}
            modifiedBy={draftTask?.modifiedBy?.displayName}
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <>
      <FullWidthHeader title={renderHeader} alternateBackground />
      <div className={bodyContentContainerAlternateBg}>
        <LoadingErrorMessage error={error} loading={loading} />
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
          styles={{ root: { marginLeft: '10px' } }}
        >
          <Stack.Item styles={cardBackground}>
            <ActionButton
              disabled={loading || error?.message !== undefined || draftTask === null}
              iconProps={{ iconName: 'Edit' }}
              title="Edit Draft Task"
              onClick={onEditClick}
              text="Edit task details"
            />
          </Stack.Item>
          {draftTask?.publishType === 'JEM_CHECKLIST' && (
            <Stack.Item>
              <Link
                href={config?.settings?.jemConfig?.siteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to visit JEM <Icon iconName="Diamond" aria-label="JEM Icon" />
              </Link>
            </Stack.Item>
          )}
        </Stack>
        <br />
        <TaskDetailCards task={draftTask} />
      </div>
      {isPanelOpen && <DraftTaskEditPanel closePanel={closeEditPanel} task={draftTask} />}
    </>
  );
};

export default DraftTaskDetailPage;
