import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
  ICommandBarItemProps,
  Icon,
  IContextualMenuItem,
  IIconProps,
  Stack,
} from '@fluentui/react';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import { IPublishedTask } from '../../utils/types/ITask';
import getBaseTaskListColumns, { IBaseTaskListColumns } from './BaseTasks.config';
import { DateConstants, TimezoneConstants } from '../../utils/formatters/DateConstants';
import { ChangeStateMode } from './edit/TasksChangeState';
import { ILocalStorageColumnConfig } from '../../common/lists/ColumnConfigHelper';
import { momentDateToTimezone } from '../../utils/formatters/DateFormatters';
import enumFormatter from '../../utils/formatters/EnumFormatter';
import BulkEditMode from '../../utils/types/IBulkEdit';
import DownloadButton, { IDownloadButtonProps } from '../buttons/DownloadButton';
import RemainingTime from '../../common/dates/RemainingTime';
import config from '../../utils/ConfigLoader';
import { formatFiscalInfoString } from '../../common/formFields/FiscalPeriodDropdown';

export const getStateIcon = (state: string): IIconProps => {
  const iconProps = {
    style: { color: 'blue', fontSize: 19, height: 19, width: 19, paddingRight: 6 },
    iconName: 'CircleHalfFull',
  };
  switch (state) {
    case 'NOT_STARTED':
      iconProps.style.color = 'grey';
      iconProps.iconName = 'CircleRing';
      break;
    case 'STARTED':
      iconProps.style.color = 'blue';
      iconProps.iconName = 'CircleHalfFull';
      break;
    case 'STARTED_AUTOMATION':
      iconProps.style.color = 'green';
      iconProps.iconName = 'Robot';
      break;
    case 'CLOSED':
      iconProps.style.color = 'green';
      iconProps.iconName = 'SkypeCircleCheck';
      break;
    case 'NOT_PERFORMED':
      iconProps.style.color = 'green';
      iconProps.iconName = 'SkypeCircleSlash';
      break;
    default:
  }

  return iconProps;
};

export const getPublishedTaskListColumns = (
  props: IBaseTaskListColumns,
  baseUrl: string,
): ILocalStorageColumnConfig[] => {
  const columns = [...getBaseTaskListColumns(props)] as ILocalStorageColumnConfig[];

  const nameColumn = columns.find((c) => c.key === 'name');
  nameColumn.onRender = (item: IPublishedTask) => {
    return <Link to={`${baseUrl}/details/${item?.id}`}>{item?.name}</Link>;
  };

  const stagedTaskColumn = {
    key: 'stagedTaskId',
    fieldName: 'stagedTaskId',
    name: 'Staged task ID',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    onRender: (item: IPublishedTask) => {
      return (
        (item?.stagedTaskId && (
          <a
            href={`/stagedTasks/edit/${item.stagedTaskId}`}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
            target="_blank"
            rel="noreferrer"
            style={{ padding: ' 0px 5px' }}
          >
            {item.stagedTaskId}
          </a>
        )) || <></>
      );
    },
  } as ILocalStorageColumnConfig;
  const dueDate = {
    key: 'dueDate',
    name: 'Due date',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      // To Do: Revisit once we figure out where to implement timezone rendering
      return item.dueDate ? moment(item.dueDate).format(DateConstants.DayBreakout) : '--';
    },
  } as ILocalStorageColumnConfig;
  const publishedTaskNotes = {
    key: 'publishedTaskNotes',
    name: 'Published task notes',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return item.publishedTaskNotes;
    },
  } as ILocalStorageColumnConfig;

  const remainingTime = {
    key: 'remainingTime',
    name: 'Remaining time',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return <RemainingTime publishedTask={item} />;
    },
  } as ILocalStorageColumnConfig;

  const currentState = {
    key: 'lastAction.state',
    name: 'State',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      const iconProps = getStateIcon(item.lastAction?.state);
      return (
        <Stack horizontal>
          <Icon {...iconProps} />
          {enumFormatter(item.lastAction?.state)}
        </Stack>
      );
    },
  } as ILocalStorageColumnConfig;

  const stateNotes = {
    key: 'lastAction.notes',
    name: 'State notes',
    maxWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return item.lastAction?.notes;
    },
    minWidth: 130,
  } as ILocalStorageColumnConfig;

  const stateChangedOn = {
    key: 'lastAction.modifiedOn',
    name: 'State changed date',
    minWidth: 180,
    maxWidth: 180,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return momentDateToTimezone(
        item.lastAction.modifiedOn,
        DateConstants.DayBreakoutWithShortTimeAndTimezone,
        TimezoneConstants.PST,
        true,
      );
    },
  } as ILocalStorageColumnConfig;

  const stateChangedBy = {
    key: 'lastAction.modifiedBy.displayName',
    name: 'State changed by',
    maxWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return item.lastAction.modifiedBy.displayName;
    },
    minWidth: 130,
  } as ILocalStorageColumnConfig;

  const fiscalPeriod = {
    key: 'fiscalPeriod',
    name: 'Fiscal period',
    maxWidth: 150,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IPublishedTask) => {
      return <span>{formatFiscalInfoString(item.fiscalPeriod, item.fiscalYear)}</span>;
    },
    minWidth: 150,
  } as ILocalStorageColumnConfig;

  columns.push(stateChangedBy);
  columns.push(stateChangedOn);
  columns.push(stagedTaskColumn);
  columns.splice(4, 0, dueDate);
  columns.splice(5, 0, fiscalPeriod);
  columns.splice(6, 0, remainingTime);
  columns.splice(7, 0, currentState);
  columns.splice(8, 0, stateNotes);
  columns.splice(16, 0, publishedTaskNotes);

  return columns;
};
export interface IPublishedTaskMenuProps {
  itemsSelected: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onBulkEditClick?: (editMode: BulkEditMode) => void;
  onChangeTaskStateClick?: (editMode: ChangeStateMode) => void;
  exportAllPublishedTasksProps?: IDownloadButtonProps;
  exportFilteredPublishedTasksProps?: IDownloadButtonProps;
  onExportPublishedTasksClick?: (exportAll?: boolean) => void;
  onRefreshTasksClick?: () => void;
  onNewTaskClick?: () => void;
  downloadTemplateProps: IDownloadButtonProps;
  onShowImportPanel?: () => void;
  canImportSpreadsheet: boolean;
  onCopyTaskClick?: () => void;
}
const getPublishedTasksMenu = (props: IPublishedTaskMenuProps): ICommandBarItemProps[] => {
  const {
    itemsSelected,
    itemsSelectedCount,
    onBulkEditClick,
    onChangeTaskStateClick,
    totalItemsCount,
    exportAllPublishedTasksProps,
    exportFilteredPublishedTasksProps,
    onRefreshTasksClick,
    onNewTaskClick,
    downloadTemplateProps,
    onShowImportPanel,
    canImportSpreadsheet,
    onCopyTaskClick,
  } = props;
  const maxExportSize = parseInt(config?.settings?.maxExportTaskSize, 10);
  const exportExceedsLimit = totalItemsCount >= maxExportSize;
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefreshTasksClick,
    },
  ];
  if (onNewTaskClick) {
    listCommandBarItems.push({
      key: 'newTask',
      text: 'New task',
      cacheKey: 'newTask', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onNewTaskClick,
    });
  }

  listCommandBarItems.push({
    key: 'exportPublishedTasks',
    text: 'Export to Excel',
    cacheKey: 'exportPublishedTasks', // changing this key will invalidate this item's cache
    iconProps: { iconName: 'ExcelLogo' },
    onRender: (item: IContextualMenuItem) => {
      const items = [exportFilteredPublishedTasksProps];
      if (exportAllPublishedTasksProps) {
        items.push(exportAllPublishedTasksProps);
      }
      return (
        <DownloadButton
          text="Export to Excel"
          subItems={items}
          renderedInOverflow={item.renderedInOverflow}
          {...downloadTemplateProps}
        />
      );
    },
  });

  if (canImportSpreadsheet) {
    listCommandBarItems.splice(
      2,
      0,
      {
        key: 'importSpreadsheet',
        text: 'Import tasks',
        cacheKey: 'importSpreadsheet', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'ExcelLogo' },
        onClick: onShowImportPanel,
      },
      {
        key: 'exportImportTemplate',
        text: 'Download template',
        cacheKey: 'exportImportTemplate',
        iconProps: { iconName: 'ExcelLogo' },
        onRender: (item: IContextualMenuItem) => {
          return (
            <DownloadButton
              text="Download Template"
              renderedInOverflow={item.renderedInOverflow}
              {...downloadTemplateProps}
            />
          );
        },
        fileEndpoint: downloadTemplateProps.fileEndpoint,
        fileName: downloadTemplateProps.fileName,
      },
    );
  }

  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'editMain',
      text: 'Bulk edit',
      cacheKey: 'editBulk', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Edit' },
      subMenuProps: {
        items: [
          {
            key: 'edit',
            text: `Bulk edit selected (${itemsSelectedCount})`,
            cacheKey: 'editBulk', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Edit' },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'editAll',
            text: exportExceedsLimit
              ? `Max actionable tasks of ${maxExportSize} exceeded (${totalItemsCount})`
              : `Bulk edit all filtered (${totalItemsCount})`,
            cacheKey: 'editAll', // changing this key will invalidate this item's cache
            disabled: exportExceedsLimit,
            iconProps: {
              iconName: 'TripleColumnEdit',
            },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'changeTaskState',
      text: 'Change state',
      cacheKey: 'changeTaskStateBulk', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ThumbnailView' },
      subMenuProps: {
        items: [
          {
            key: 'changeTaskStateSelected',
            text: ` Change state selected (${itemsSelectedCount})`,
            cacheKey: 'changetaskStateSelected', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'MultiSelect' },
            onClick: (ev) => {
              return onChangeTaskStateClick(ChangeStateMode.ChangeStateSelected);
            },
          },
          {
            key: 'changeStateAll',
            text: exportExceedsLimit
              ? `Max actionable tasks of ${maxExportSize} exceeded (${totalItemsCount})`
              : `Change state all filtered (${totalItemsCount})`,
            cacheKey: 'changeStateAll', // changing this key will invalidate this item's cache
            disabled: exportExceedsLimit,
            iconProps: {
              iconName: 'ThumbnailView',
            },
            onClick: (ev) => {
              return onChangeTaskStateClick(ChangeStateMode.ChangeStateAllFiltered);
            },
          },
        ],
      },
    },
  ];

  if (itemsSelected && itemsSelectedCount === 1 && onCopyTaskClick) {
    selectedCommandBarItems.unshift({
      key: 'copyTask',
      text: 'Copy task',
      cacheKey: 'copytask',
      iconProps: { iconName: 'Copy' },
      onClick: onCopyTaskClick,
    });
  }

  return itemsSelected ? selectedCommandBarItems : listCommandBarItems;
};
export default getPublishedTasksMenu;
