import {
  ColumnActionsMode,
  IColumn,
  ICommandBarItemProps,
  IContextualMenuItem,
} from '@fluentui/react';
import React from 'react';
import FacepileWrapper from '../../../../common/lists/FacepileWrapper';
import { sharedEditButton, sharedIsActive } from '../../../../common/lists/SharedDataGridColumns';
import { rowClasses } from '../../../../common/styles/CommonStyleObjects';
import ICompanyCodeAssignment from '../../../../utils/types/ICompanyCodeAssignment';
import DownloadButton, { IDownloadButtonProps } from '../../../buttons/DownloadButton';

export const getCompanyCodeColumnList = (
  onEditClick: (item: ICompanyCodeAssignment) => void,
): IColumn[] => {
  const configReturn = [sharedEditButton<ICompanyCodeAssignment>(onEditClick)];

  return configReturn.concat([
    {
      key: 'companyCode',
      name: 'Co. code',
      fieldName: 'company',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ICompanyCodeAssignment) => {
        return item.companyCode;
      },
    },
    {
      key: 'name',
      name: 'Co. name',
      fieldName: 'name',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ICompanyCodeAssignment) => {
        return item.name;
      },
    },
    {
      key: 'countryCode',
      name: 'Country',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      onRender(item?) {
        return <span>{item.countryCode}</span>;
      },
    },

    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    {
      key: 'inGlobalChecklist',
      name: 'In global checklist',
      maxWidth: 170,
      minWidth: 170,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ICompanyCodeAssignment) => {
        const flag = item.inGlobalChecklist === true ? 'Yes' : 'No';
        return flag;
      },
    },
    {
      key: 'inLocalChecklist',
      name: 'In local checklist',
      maxWidth: 160,
      minWidth: 160,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ICompanyCodeAssignment) => {
        const flag = item.inLocalChecklist === true ? 'Yes' : 'No';
        return flag;
      },
    },
    {
      key: 'owners',
      name: 'Owner(s)',
      fieldName: 'owners',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ICompanyCodeAssignment) => {
        return <FacepileWrapper users={item.owners} ariaLabel="Owner(s) list" />;
      },
    },
  ]);
};

export const getCompanyCodeMenu = (
  onRefetch: () => void,
  exportButtonProps: IDownloadButtonProps,
) => {
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefetch,
    },
    {
      key: 'exportToExcel',
      text: 'Export to Excel',
      cacheKey: 'export', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            renderedInOverflow={item.renderedInOverflow}
            {...exportButtonProps}
          />
        );
      },
      fileEndpoint: exportButtonProps.fileEndpoint,
      fileName: exportButtonProps.fileName,
      postBody: exportButtonProps.postBody,
    },
  ];
  return listCommandBarItems;
};
