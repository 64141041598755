import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { PublishDraftTasksMutation } from '../../../utils/api/PublishedTaskApi';
import { IDraftTaskFilterIds } from '../../../utils/types/ITask';

export enum PublishDraftTasksMode {
  PublishDraftsSelected,
  PublishDraftsAllFiltered,
  PublishDraftsAll,
}

interface IPublishDraftTasksProps {
  selectedTasks: number[];
  keyword: string;
  filters: unknown;
  publishMode: PublishDraftTasksMode;
  selectedTasksCount: number;
  hideModal: () => void;
  fiscalYear: number;
  period: number;
  refetchPage: () => void;
  selectedInActiveStagedTasksCount?: number[];
}
const PublishDraftTasks = (props: IPublishDraftTasksProps): JSX.Element => {
  const [mutationError, setMutationError] = useState(null);
  const {
    selectedTasksCount,
    selectedInActiveStagedTasksCount,
    selectedTasks,
    keyword,
    filters,
    publishMode,
    hideModal,
    fiscalYear,
    period,
    refetchPage,
  } = props;

  const [publishDraftTasks, { loading, error }] = useMutation(PublishDraftTasksMutation, {
    onCompleted: () => {
      refetchPage();
    },
  });

  const resetComponent = () => {
    setMutationError(null);
    hideModal();
  };
  const getModeVariables = (): {
    selectedItems: number[];
    keyword: string;
    filters: Partial<IDraftTaskFilterIds>;
  } => {
    return {
      selectedItems:
        publishMode === PublishDraftTasksMode.PublishDraftsSelected ? selectedTasks : null,
      keyword: publishMode === PublishDraftTasksMode.PublishDraftsAllFiltered ? keyword : null,
      filters: publishMode === PublishDraftTasksMode.PublishDraftsAllFiltered ? filters : null,
    };
  };

  const submitForm = () => {
    publishDraftTasks({
      variables: {
        generateTasksDto: {
          ...getModeVariables(),
        },
      },
    })
      .then(() => {
        resetComponent();
      })
      .catch((e) => {
        setMutationError(e); // set error state if an error occurs while publishing the task(s)
      });
  };

  const getMessage = () => {
    return (
      <>
        {selectedInActiveStagedTasksCount && selectedInActiveStagedTasksCount.length > 0 && (
          <MessageBar messageBarType={MessageBarType.error}>
            <Stack tokens={{ childrenGap: 10 }}>
              <div>
                <strong>{selectedInActiveStagedTasksCount.length}</strong> of{' '}
                <strong>{selectedTasksCount}</strong> selected tasks are not in the active stage and
                cannot be published. Please remove these tasks from the selection and try again.
              </div>
            </Stack>
          </MessageBar>
        )}
        {selectedInActiveStagedTasksCount && selectedInActiveStagedTasksCount.length === 0 && (
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 10 }}>
              <div>
                Click Publish draft tasks button to publish <strong>{selectedTasksCount}</strong>
                draft tasks to{' '}
                <strong>
                  {' '}
                  P{period} FY{fiscalYear.toString().slice(2)}
                </strong>{' '}
                period. Once published, these tasks will no longer have the draft status and will
                either need to be closed or marked Not Performed.
              </div>
            </Stack>
          </MessageBar>
        )}
      </>
    );
  };
  return (
    <CoherenceModal
      isOpen={publishMode !== null}
      modalWidth="small"
      height="responsive"
      title="Publish draft tasks"
      onDismiss={() => {
        resetComponent();
      }}
    >
      <Stack>
        <span>{getMessage()}</span>
        {mutationError != null && (
          <LoadingErrorMessage loading={loading} error={error} label="Publishing tasks" />
        )}
        <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Publish draft tasks"
            onClick={submitForm}
            ariaLabel="Publish draft tasks"
            disabled={loading || selectedInActiveStagedTasksCount?.length > 0}
          />
          <DefaultButton
            disabled={loading}
            text="Cancel"
            onClick={() => {
              resetComponent();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};
export default PublishDraftTasks;
