import React from 'react';
import moment from 'moment';
import { Stack } from '@fluentui/react';
import InformationCard from './InformationCard';
import SpanWithLabel from '../../common/cardContents/SpanWithLabel';
import ValueTag from '../../common/cards/ValueTag';
import { IEuaRole } from '../../utils/types/IUser';
import { DateConstants } from '../../utils/formatters/DateConstants';

interface IRoleCardProps {
  role: IEuaRole;
}

const RoleCard = (props: IRoleCardProps): JSX.Element => {
  const { role } = props;

  const formatRenewalDate = (dateString: string): string => {
    if (!dateString) {
      return '';
    }

    return ` - Auto-renews: ${moment(dateString).utc().format(DateConstants.DayBreakout)}`;
  };

  return (
    <InformationCard
      cardTitle={`${role.role}${formatRenewalDate(role.enddate)}`}
      key={role.roleId}
      commandBarItems={[]}
      allCollapsed={false}
    >
      <Stack tokens={{ childrenGap: 10 }}>
        {role?.scopes?.map((scope) => {
          return (
            <SpanWithLabel labelText={scope.scopeName} key={`${scope.scopeName}-${role.roleId}`}>
              <Stack tokens={{ childrenGap: 5 }} horizontal wrap>
                {scope?.scopeValue?.map((scopeValue) => {
                  return (
                    <ValueTag
                      key={`${scope.scopeName}-${role.roleId}-${scopeValue}`}
                      tagString={scopeValue === '0' ? 'All Active' : scopeValue}
                      useWhiteBackground
                    />
                  );
                })}
              </Stack>
            </SpanWithLabel>
          );
        })}
      </Stack>
    </InformationCard>
  );
};

export default RoleCard;
