import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { useForm, UseFormMethods } from 'react-hook-form';
import { Stack, ActionButton, ITag } from '@fluentui/react';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import {
  bodyContentContainerAlternateBg,
  cardBackground,
} from '../../../common/styles/CommonStyleObjects';
import BackButton from '../../../common/buttons/BackButton';
import { retrieveUrl } from '../../../utils/Helpers';
import { IDataSourceOption, IDraftTask, IPublishedTask } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { RequiredMarker } from '../../../common/labels/RequiredMarker';
import TaskDetailLocationCard, { getCountriesLabel } from './TaskDetailLocationCard';
import TaskDetailTaskCard from './TaskDetailTaskCard';
import { mapUsersNoTypename } from '../../../common/formFields/GraphUsersPeoplePicker';
import { mapCompanyNoTypename } from '../../../common/formFields/CompanyPicker';
import { mapFunctionsSummaryNoTypename } from '../../../common/formFields/FunctionsSummaryPicker';
import TaskDetailNotesCard from './TaskDetailNotesCard';
import ModifiedDetails from '../../../common/auditedEntity/ModifiedDetails';
import StagedtaskDetailScheduleCard from './StagedtaskDetailScheduleCard';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import { TaskType } from '../list/BaseTaskListfilters.config';
import TasksChangeState, { ChangeStateMode } from '../edit/TasksChangeState';
import { TaskChangeStateOption } from '../../../utils/types/ITaskChangeStateOption';
import PublishedTaskDetailScheduleCard from './PublishedTaskDetailScheduleCard';
import { getLastWeekdayOfMonth } from '../../../utils/formatters/DateFormatters';
import { mapSalesLocationNoTypename } from '../../../common/formFields/SalesLocationPicker';

interface ITaskDetailFormProps<T> {
  task: IBaseTask;
  taskType: TaskType;
  panelMode: PanelTypes;
  getOverrideDefaults: () => Partial<T>;
  updateTask: (newTask: T) => void;
  loading: boolean;
  error: ApolloError | undefined;
  loadingStart?: boolean;
  errorStart?: ApolloError | undefined;
  publishTypes: string;
}
const TaskDetailForm = <T,>(props: ITaskDetailFormProps<T>): JSX.Element => {
  const {
    task,
    updateTask,
    taskType,
    panelMode,
    publishTypes,
    getOverrideDefaults,
    error,
    loading,
    loadingStart,
    errorStart,
  } = props;
  const [pageState, setPageState] = useState({
    taskChangesStateMode: null,
  });
  const match = useRouteMatch();
  const history = useHistory();
  const parentRouteDepth = panelMode === PanelTypes.Add ? 1 : 2;

  const form = useForm<IBaseTask>({
    defaultValues: {
      ...task,
      operationsDetailId: task.operationsDetail?.id,
      supplierId: task.supplier?.id,
      typeId: task.type?.id,
      levelId: task.level?.id,
      scopeOfActivityId: task.scopeOfActivity?.id,
      opsSummaryAndOrgName: task.operationsDetail
        ? `${task.operationsDetail?.opsOrg?.opsSummary?.name} / ${task.operationsDetail?.opsOrg?.name}`
        : null,
      countryName: getCountriesLabel(task.companies),
      primaries: mapUsersNoTypename(task.primaries),
      backupTaskPerformers: mapUsersNoTypename(task.backupTaskPerformers),
      stakeholders: mapUsersNoTypename(task.stakeholders),
      companies: mapCompanyNoTypename(task.companies),
      dataSources: task.dataSources?.map((ds: IDataSourceOption) => {
        return {
          id: ds.id,
          name: ds.name,
          perspectiveId: ds.perspectiveId,
          applicationId: ds.applicationId,
          isEditable: ds.isEditable,
        };
      }),
      taskTags: task.taskTags?.map((tt: ITag) => {
        return { key: tt.key, name: tt.name };
      }),
      functionsSummaries: mapFunctionsSummaryNoTypename(task.functionsSummaries),
      timeZoneId: task.timeZone?.id,
      bcpCategoryId: task.bcpCategory?.id,
      dueTime: task.dueTime || '17:00',
      autoPublishDate: task.operationsDetail?.autoPublish === true ? getLastWeekdayOfMonth() : '--',
      reportTypeId: task.reportType?.id,
      analysisTypeId: task.analysisType?.id,
      outputId: task.output?.id,
      salesLocations: mapSalesLocationNoTypename(task.salesLocations),
      ...getOverrideDefaults(),
      workflowId: task.workflowId,
    },
    shouldUnregister: true,
  });
  const { handleSubmit } = form;
  const handleOnSaveClick = (formData: IBaseTask): void => {
    updateTask(formData as unknown as T);
  };
  const actionButtonIconStyles = {
    root: {
      color: CoherenceTheme.palette.themeSecondary,
    },
  };
  const actionButtonPaddingStyles = { root: { padding: '0 10px' } };
  const renderSaveButton = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
        <Stack.Item styles={cardBackground}>
          <ActionButton
            disabled={loading}
            iconProps={{
              iconName: 'Save',
              styles: actionButtonIconStyles,
            }}
            title="Save and continue"
            onClick={handleSubmit(handleOnSaveClick)}
            ariaLabel="Save and continue"
            styles={actionButtonPaddingStyles}
          >
            Save and continue
          </ActionButton>
          <ActionButton
            disabled={loading}
            iconProps={{
              iconName: 'Cancel',
              styles: actionButtonIconStyles,
            }}
            title="Cancel"
            ariaLabel="Cancel"
            onClick={() => {
              history.push(retrieveUrl(null, parentRouteDepth, match));
            }}
            styles={actionButtonPaddingStyles}
          >
            Cancel
          </ActionButton>
        </Stack.Item>
        <span>
          <RequiredMarker /> Required
        </span>
      </Stack>
    );
  };
  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
        <Stack horizontal verticalAlign="center">
          <BackButton ariaLabel={`Back to ${taskType} list`} backDepth={parentRouteDepth}>
            <h1>{`${panelMode} ${taskType}`}</h1>
          </BackButton>
        </Stack>
        {(panelMode === PanelTypes.Edit || panelMode === PanelTypes.Copy) && (
          <ModifiedDetails modifiedOn={task.modifiedOn} modifiedBy={task.modifiedBy.displayName} />
        )}
      </Stack>
    );
  };

  const editablePanelTypes = [PanelTypes.Add, PanelTypes.Copy];
  const disableStagedTaskFields = !(
    taskType === TaskType.StagedTasks ||
    (taskType === TaskType.PublishedTasks && editablePanelTypes.some((x) => x === panelMode))
  );
  return (
    <>
      <FullWidthHeader title={renderHeader} alternateBackground />
      <div className={`${bodyContentContainerAlternateBg}`}>
        {renderSaveButton()}
        <LoadingErrorMessage loading={loading} error={error} label={`Saving ${taskType}`} />
        <LoadingErrorMessage
          loading={loadingStart}
          error={errorStart}
          label={`Starting ${taskType}`}
        />
        <TaskDetailLocationCard
          form={form}
          disableStagedTaskFields={disableStagedTaskFields}
          panelMode={panelMode}
        />
        {taskType === TaskType.StagedTasks && <StagedtaskDetailScheduleCard form={form} />}
        {taskType !== TaskType.StagedTasks && (
          <PublishedTaskDetailScheduleCard
            form={form as unknown as UseFormMethods<IDraftTask>}
            taskState={
              (task as IPublishedTask).lastAction?.state || TaskChangeStateOption.NOT_STARTED
            }
          />
        )}
        <TaskDetailTaskCard
          panelMode={panelMode}
          id={task.id}
          form={form}
          taskType={taskType}
          publishTypes={publishTypes}
          disableStagedTaskFields={disableStagedTaskFields}
        />
        <TaskDetailNotesCard form={form} taskType={taskType} panelMode={panelMode} />
        {pageState.taskChangesStateMode !== null && !error && (
          <TasksChangeState
            hideModal={() =>
              setPageState({
                ...pageState,
                taskChangesStateMode: null,
              })
            }
            selectedTasksCount={1}
            selectedTasks={[task.id]}
            changeStateMode={pageState.taskChangesStateMode}
            onChangeStateComplete={() => {
              setPageState({
                ...pageState,
                taskChangesStateMode: null,
              });
              if (pageState.taskChangesStateMode === ChangeStateMode.ChangeStateCopy) {
                history.push(retrieveUrl(null, parentRouteDepth, match));
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default TaskDetailForm;
