import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import {
  MetricsDetailsList,
  MetricsTile,
  ExpandableFooterContent,
  IFCWFilters,
  DashboardCard,
  IDashboardFilters,
} from 'fcw-dashboard-components';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import GetJemTaskSummary from '../../../utils/api/JemTaskSummaryApi';
import JESummary from '../../../utils/types/JESummary';
import JemTaskSummary from '../../../utils/types/JemTaskSummary';
import { getMetricItems, getPostingColumns } from './jemCards.config';
import sortRowsByKeySimple from '../../../utils/formatters/ArrayFormatters';
import config from '../../../utils/ConfigLoader';

const JemCard: FunctionComponent<{ filters: IDashboardFilters; cardType: string }> = ({
  filters,
  cardType,
}) => {
  const cardTitle = `Journal Entry Management ${cardType}`;
  const {
    companyCodes,
    opsOrgDetailIds,
    dueDateRangeStart,
    dueDateRangeEnd,
    forMeOnly,
    fiscalPeriod,
    fiscalYear,
    filterByPeriod,
  } = filters;
  const applicableFilters = {
    companyCodes,
    opsOrgDetailIds,
    dueDateRangeStart,
    dueDateRangeEnd,
    forMeOnly,
    fiscalPeriod,
    fiscalYear,
    filterByPeriod,
  };
  const {
    data: jemData,
    loading: jemLoading,
    error: jemError,
  } = useQuery(GetJemTaskSummary, {
    variables: {
      filters: applicableFilters,
    },
    fetchPolicy: 'network-only',
  });
  const summary = jemData?.jemTaskSummary.find(
    (summaryItem: JemTaskSummary) => summaryItem.summaryType === cardType,
  );
  const supportedFilters = [
    IFCWFilters.CompanyCodes,
    IFCWFilters.OpsDetails,
    IFCWFilters.dueDateRange,
    IFCWFilters.forMeOnly,
    IFCWFilters.fiscalPeriod,
    IFCWFilters.filterByPeriod,
  ];

  const renderLevelTwo = (): JSX.Element => {
    return (
      <MetricsDetailsList
        items={sortRowsByKeySimple(summary.jeSummary, 'jeTypeInt' as keyof JESummary, false) || []}
        columns={getPostingColumns(cardType)}
      />
    );
  };
  return (
    <DashboardCard
      title={cardTitle}
      subTitle={cardType}
      filters={filters}
      supportedFilters={supportedFilters}
    >
      <>
        <LoadingErrorMessage loading={jemLoading} error={jemError} />
        {!jemLoading && !jemError && jemData && (
          <>
            <MetricsTile
              metricItems={getMetricItems(summary)}
              actionItem={{
                label: 'Total Tasks Remaining',
                value: summary.remaining,
                showInChart: false,
              }}
              donutChartLabel={`${summary.percentage}% Complete`}
            />

            <ExpandableFooterContent
              extendedContent={
                summary.jeSummary && summary.jeSummary.length > 0 ? renderLevelTwo() : null
              }
              extendedContentLabel="JE Details"
              detailsLink={config?.settings?.jemConfig?.siteUrl}
              detailsLinkText="Go to JEM Tool"
            />
          </>
        )}
      </>
    </DashboardCard>
  );
};

export default JemCard;
