import { IColumn } from '@fluentui/react';

export type ILocalStorageColumnConfig = IColumn & {
  key: string;
  order?: number;
  active?: boolean;
};
export const getColumnConfigByKey = (
  columns: IColumn[],
  localStorageConfig: ILocalStorageColumnConfig[],
): ILocalStorageColumnConfig[] => {
  if (localStorageConfig?.length < 1) {
    return columns.map((col) => {
      return {
        ...col,
        active: true,
      };
    });
  }
  let returnColumns = [] as ILocalStorageColumnConfig[];
  returnColumns = columns.map((col) => {
    const matchingConfig = localStorageConfig.find((lsc: ILocalStorageColumnConfig) => {
      return lsc.key === col.key && lsc.active;
    });
    return {
      ...col,
      active: matchingConfig?.active,
      order: matchingConfig?.order,
    };
  });
  returnColumns.sort((a, b) => {
    const aHas: boolean = typeof a.order !== 'undefined';
    const bHas: boolean = typeof b.order !== 'undefined';
    return Number(bHas) - Number(aHas) || (aHas === true && a.order - b.order) || 0;
  });

  return returnColumns;
};
