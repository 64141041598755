import React from 'react';
import {
  ColumnActionsMode,
  IColumn,
  ICommandBarItemProps,
  IContextualMenuItem,
  IconButton,
} from '@fluentui/react';
import { rowClasses } from '../../../../common/styles/CommonStyleObjects';
import DownloadButton, { IDownloadButtonProps } from '../../../buttons/DownloadButton';
import IDataSource from '../../../../utils/types/IDataSource';
import IDataSourcePerspective from '../../../../utils/types/IDataSourcePerspective';

export const getDataSourcePerspectivesColumnList = (
  onEditClick: (item: IDataSourcePerspective) => void,
): IColumn[] => {
  const configReturn = [
    {
      key: 'edit',
      name: 'Edit',
      maxWidth: 50,
      minWidth: 50,
      className: rowClasses.medFont,
      isResizable: false,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IDataSource) => {
        return (
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            ariaLabel="Edit"
            title="edit"
            disabled={item.isEditable === false}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditClick(item);
            }}
          />
        );
      },
    },
    {
      key: 'id',
      name: 'Id',
      fieldName: 'id',
      maxWidth: 30,
      minWidth: 30,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IDataSourcePerspective) => {
        return item.id;
      },
    },
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      maxWidth: 250,
      minWidth: 250,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IDataSource) => {
        return item.name;
      },
    },
  ];
  return configReturn;
};

export const getDataSourcePerspectivesMenu = (
  onAddItem: () => void,
  onRefetch: () => void,
  exportButtonProps: IDownloadButtonProps,
) => {
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New Item',
      cacheKey: 'newItem', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onAddItem,
    },
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefetch,
    },
    {
      key: 'exportToExcel',
      text: 'Export to Excel',
      cacheKey: 'export', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            renderedInOverflow={item.renderedInOverflow}
            {...exportButtonProps}
          />
        );
      },
      fileEndpoint: exportButtonProps.fileEndpoint,
      fileName: exportButtonProps.fileName,
      postBody: exportButtonProps.postBody,
    },
  ];
  return listCommandBarItems;
};
