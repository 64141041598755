import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import { defaultPaginationProps } from '../../../utils/listHelpers';
import { SortDirection } from '../../../utils/types/IListSortDefinition';
import { ITaskRouteParams } from '../../../utils/types/ITask';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import PublishedTaskListPageTemplate, {
  IPublishedTaskListQueryVariables,
} from './PublishedTaskListPageTemplate';
import addFiltersForPeriodAndYear from '../../../utils/formatters/AddFiscalYearPeriod';
import { fiscalPeriodToMonth } from '../../../utils/formatters/DateFormatters';

const PublishedTaskListPage = (): JSX.Element => {
  const { fiscalYear, period } = useParams<ITaskRouteParams>();
  const history = useHistory();
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'dueDate',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IPublishedTaskListQueryVariables>(
    `${LocalStorageKeys.publishedTaskQueryState}${fiscalYear}${period}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IPublishedTaskListQueryVariables,
  );

  taskQueryCache.filterTags = addFiltersForPeriodAndYear(
    taskQueryCache.filterTags,
    fiscalYear,
    period,
  );
  const onNewTaskClick = () => {
    history.push(`/tasks/${fiscalYear}/${period}/new`);
  };

  const onCopyTaskClick = (taskToCopyId: number) => {
    if (taskToCopyId) {
      history.push(`/tasks/${fiscalYear}/${period}/${taskToCopyId}/new`);
    }
  };

  const fiscalMonth = fiscalPeriodToMonth(parseInt(period, 10));
  return (
    <PublishedTaskListPageTemplate
      queryVariables={taskQueryCache}
      setTaskQueryCache={setTaskQueryCache}
      onNewTaskClick={onNewTaskClick}
      onCopyTaskClick={onCopyTaskClick}
      pageTitle={`Tasks for ${fiscalMonth} (P${period} FY${fiscalYear?.toString().slice(2)})`}
    />
  );
};
export default PublishedTaskListPage;
