import { ColumnActionsMode, IColumn, IconButton } from '@fluentui/react';
import React from 'react';
import { DateConstants, TimezoneConstants } from '../../utils/formatters/DateConstants';
import { momentDateToTimezone } from '../../utils/formatters/DateFormatters';
import { rowClasses } from '../styles/CommonStyleObjects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertNestedStringReference(obj: any, path: string): any {
  if (!path) return obj;
  const properties = path.split('.');
  return convertNestedStringReference(obj[properties.shift()], properties.join('.'));
}

export const sharedEditButton = <T extends object>(
  onEditClick: (item: T) => void,
  disableEditButton?: boolean,
  titleIdentifier?: keyof T,
): IColumn => {
  return {
    key: 'edit',
    name: 'Edit',
    maxWidth: 50,
    minWidth: 50,
    className: rowClasses.medFont,
    isResizable: false,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: T) => {
      return (
        <IconButton
          iconProps={{ iconName: 'Edit' }}
          ariaLabel={titleIdentifier ? `Edit ${item[titleIdentifier as keyof T]}` : 'Edit'}
          title="edit"
          disabled={disableEditButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEditClick(item);
          }}
        />
      );
    },
  };
};

export const sharedActionsButton = <T extends object>(
  onEditClick: (item: T) => void,
  onDeleteClick: (item: T) => void,
): IColumn => {
  return {
    key: 'actions',
    name: 'Actions',
    ariaLabel: 'Actions',
    maxWidth: 80,
    minWidth: 80,
    className: rowClasses.medFont,
    isResizable: false,
    onRender: (item: T) => {
      return (
        <>
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            title="edit"
            ariaLabel="edit"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditClick(item);
            }}
          />
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            title="delete"
            ariaLabel="delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteClick(item);
            }}
          />
        </>
      );
    },
  };
};

export const sharedIsActive = <ItemType extends { [key: string]: boolean }>(
  activeKey: string,
): IColumn => {
  return {
    key: 'isActive',
    name: 'Is active',
    maxWidth: 80,
    minWidth: 80,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: ItemType) => {
      const flag = item[activeKey] === true ? 'Yes' : 'No';
      return <span>{flag}</span>;
    },
  };
};

export const sharedLastModified = <ItemType extends { [key: string]: Date }>(
  lastModifiedDateKey: string,
  lastModifiedByKey: string,
): IColumn[] => [
  {
    key: lastModifiedDateKey,
    name: 'Last modified date',
    maxWidth: 180,
    minWidth: 180,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: ItemType) => {
      // To Do: Revisit once we figure out where to implement timezone rendering
      return momentDateToTimezone(
        item[lastModifiedDateKey],
        DateConstants.DayBreakoutWithShortTimeAndTimezone,
        TimezoneConstants.PST,
        true,
      );
    },
  },
  {
    key: lastModifiedByKey,
    name: 'Last modified by',
    maxWidth: 150,
    minWidth: 150,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: ItemType) => {
      return convertNestedStringReference(item, lastModifiedByKey);
    },
  },
];
