import React from 'react';
import { IDashboardFilters } from 'fcw-dashboard-components';
import IntegratedClientApp from '../IntegratedClientApp';
import { DateRangeType } from '../../home/Home';
import config from '../../../utils/ConfigLoader';

export interface IJemDashboardFilters extends IDashboardFilters {
  [key: string]: unknown | string | boolean | null | undefined | number | (string | number)[];
  jeType?: string;
  jeStatus?: string;
  moduleType?: string;
  dateRangeType: DateRangeType;
}
export interface IJemIntegratedExperienceProps {
  filters: IJemDashboardFilters;
}
const JemIntegratedExperience = (props: IJemIntegratedExperienceProps): JSX.Element => {
  const { filters } = props;

  return (
    <IntegratedClientApp
      scriptUrl={`${config?.settings?.jemConfig?.microUiUrl}${config?.settings?.jemConfig?.integratedFilePath}`}
      appName="JEMAAG3Dashboard"
      data={{
        baseUrl: config?.settings?.jemConfig?.microUiUrl,
        baseName: 'jem',
        filters,
      }}
      supportLink="" // TODO Need to update this link
    />
  );
};

export default JemIntegratedExperience;
