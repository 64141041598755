import React, { useState } from 'react';
import { Stack } from '@fluentui/react';
import { CoherencePanel } from '@coherence-design-system/controls';
import { useForm } from 'react-hook-form';
import ActionButtons from '../../../common/buttons/ActionButtons';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import SalesLocationGroupedChecklist from '../../../common/formFields/SalesLocationGroupedChecklist';
import ISalesLocation from '../../../utils/types/ISalesLocation';
import { mapSalesLocationsToTags } from '../../../common/formFields/SalesLocationPicker';
import { IGroupedChecklistItem } from '../../../utils/types/IGroupedChecklistItem';

interface ISalesLocationPanelProps {
  selectedSalesLocations: ISalesLocation[];
  onSave: (selectedSalesLocations: ISalesLocation[]) => void;
  closePanel: () => void;
}

const SalesLocationPanel = (props: ISalesLocationPanelProps): JSX.Element => {
  const { closePanel, onSave, selectedSalesLocations } = props;
  const [currentSalesLocations, setCurrentSalesLocations] = useState<IGroupedChecklistItem[]>(
    mapSalesLocationsToTags(selectedSalesLocations),
  );
  const form = useForm();
  const { handleSubmit } = form;

  const handleOnSave = () => {
    onSave(currentSalesLocations);
    closePanel();
  };

  const onCheckChanged = (items: IGroupedChecklistItem[]) => {
    setCurrentSalesLocations(items);
  };

  const onClearAllSelections = () => {
    setCurrentSalesLocations([]);
  };

  return (
    <CoherencePanel
      panelSize="400px"
      titleText="Select Sales Locations"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Select Sales Locations"
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            mutationLoading={false}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSave)}
            saveLabel="Save"
            saveTitle="Save"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
          />
        </Stack>
      )}
    >
      <SalesLocationGroupedChecklist
        selections={currentSalesLocations}
        onCheckChanged={onCheckChanged}
        onClearAllSelections={onClearAllSelections}
      />
    </CoherencePanel>
  );
};
export default SalesLocationPanel;
