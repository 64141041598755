import { ICommandBarItemProps } from '@fluentui/react';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import { ILocalStorageColumnConfig } from '../../common/lists/ColumnConfigHelper';

export const getInactiveUserListColumns = (): ILocalStorageColumnConfig[] => {
  return [
    {
      key: 'alias',
      fieldName: 'alias',
      name: 'Alias',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'displayName',
      fieldName: 'displayName',
      name: 'Name',
      maxWidth: 125,
      minWidth: 125,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'opsDetailCount',
      fieldName: 'opsDetailCount',
      name: 'OpsDetail Assigned',
      maxWidth: 130,
      minWidth: 130,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'primaryTaskCount',
      fieldName: 'primaryTaskCount',
      name: 'Primary Task Assigned',
      maxWidth: 130,
      minWidth: 130,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'backupTaskCount',
      fieldName: 'backupTaskCount',
      name: 'Backup Task Assigned',
      maxWidth: 130,
      minWidth: 130,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'stakeholderTaskCount',
      fieldName: 'stakeholderTaskCount',
      name: 'Stakeholder Task Assigned',
      maxWidth: 130,
      minWidth: 130,
      className: rowClasses.medFont,
      isResizable: true,
    },
  ];
};

export interface IInactiveUsersMenuProps {
  itemsSelected: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onReplaceClick?: () => void;
}
const getInactiveUsersMenu = (props: IInactiveUsersMenuProps): ICommandBarItemProps[] => {
  const { itemsSelected, itemsSelectedCount, onReplaceClick } = props;
  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'replace',
      text: `Replace (${itemsSelectedCount}) User`,
      cacheKey: 'replace',
      iconProps: { iconName: 'EditContact' },
      onClick: onReplaceClick,
    },
  ];

  return itemsSelected ? selectedCommandBarItems : [];
};
export default getInactiveUsersMenu;
