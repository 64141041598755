import React from 'react';
import { Redirect } from 'react-router-dom';

const AuthCallback: React.FunctionComponent = () => {
  const redirectUrl = window.sessionStorage.getItem('return-url') || '/';
  window.sessionStorage.removeItem('return-url');
  return <Redirect to={redirectUrl} />;
};

export default AuthCallback;
