import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import PaginationWrapperManaged from '../../../common/lists/PaginationWrapperManaged';
import GetCalendarDates from '../../../utils/api/CalendarApi';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
  toggleColSortDir,
} from '../../../utils/listHelpers';
import { ICalendarDate } from '../../../utils/types/ICalendarDate';
import { IListQueryVariables, IEntityListState } from '../../../utils/types/IList';
import { IQuerySortDefinition, SortDirection } from '../../../utils/types/IListSortDefinition';
import { PaginationQueryVariables } from '../../../utils/types/PaginationTypes';
import ColumnConfigPanel from '../../tasks/list/ColumnConfigPanel';

import { getCalendarDateColumnList, getFiscalCalendarListMenu } from './CalendarList.config';

const CalendarList = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.fiscalCalendarListColumns,
    [],
  );

  const defaultFiltersAndSort = {
    sortDir: SortDirection.DESC,
    sortKey: 'fiscalYear',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.fiscalCalendarQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IEntityListState>({
    ...getDefaultStateProps(taskQueryCache),
  });
  /** Lazy Query */

  const [getData, { data, loading, error }] = useLazyQuery(GetCalendarDates, {
    variables: {
      ...taskQueryCache?.pageInfo,
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
  });

  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };

  const dataLoaded = !loading && !error;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<ICalendarDate>(data?.fiscalCalendarDatesPagination)
    : getEmptyResults<ICalendarDate>();

  const cachedListColumns = getColumnConfigByKey(getCalendarDateColumnList(), listColumnsCache);
  const detailsListConfig: IGeneralEntityListProps<ICalendarDate> = {
    data: dataResult.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems: getFiscalCalendarListMenu(onRefreshClick, {
      key: 'ExportFiscalCalendarDates',
      fileEndpoint: 'FiscalCalendarDates',
      fileName: 'FiscalCalendarDates',
    }),
    disableSelect: true,
    loading,
    error,

    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
  };
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { sortDir, sortKey } = pageState;
    let sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];
    if (sortKey === 'fiscalYear' || sortKey === 'fiscalPeriod') {
      sortArray = [
        { fiscalYear: toggleColSortDir(sortDir).toString() },
        { fiscalPeriod: toggleColSortDir(sortDir).toString() },
      ];
    }

    getData({
      variables: {
        ...pageState.pageInfo,
        order: sortArray,
      },
    });
  };
  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  return (
    <>
      <PaginationWrapperManaged<ICalendarDate>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </>
  );
};

export default CalendarList;
