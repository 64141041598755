import { useQuery } from '@apollo/client';
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import { GetOpsOrgList } from '../../utils/api/HierarchiesApi';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { IOpsOrg } from '../../utils/types/IHierarchy';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../utils/formatters/PaginatedResultFormatter';

export interface IOpsOrgDropdownReference extends IDropdownOption {
  opsSummaryName: string;
}

export const mapsOpsOrgToDropdown = (opsOrgList: IOpsOrg[]): IOpsOrgDropdownReference[] => {
  let opsSummaryName = '';

  const options: IOpsOrgDropdownReference[] = [];

  opsOrgList.forEach((opsOrg: IOpsOrg) => {
    if (opsOrg.opsSummary.name !== opsSummaryName) {
      if (opsSummaryName !== '') {
        options.push({
          key: `OpsSummary${opsOrg.id}Divider`,
          text: opsOrg.opsSummary.name,
          opsSummaryName: opsOrg.opsSummary.name,
          itemType: DropdownMenuItemType.Divider,
        });
      }
      opsSummaryName = opsOrg.opsSummary.name;
      options.push({
        key: `OpsSummary${opsOrg.id}`,
        text: opsOrg.opsSummary.name,
        opsSummaryName: opsOrg.opsSummary.name,
        itemType: DropdownMenuItemType.Header,
      });
    }

    options.push({
      key: opsOrg.id,
      text: opsOrg.name,
      opsSummaryName: opsOrg.opsSummary.name,
    });
  });

  return options;
};

const OpsOrgDropDown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(GetOpsOrgList, {
    variables: {
      first: 1000, // hack to get all the data
      after: null,
      last: null,
      before: null,
      order: [
        {
          opsSummary: { name: 'ASC' },
          name: 'ASC',
        },
      ],
    },
  });
  const { opsOrgs } = !dropdownLoading && dropdownData ? dropdownData : [];
  const dataLoaded = !dropdownLoading;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<IOpsOrg>(opsOrgs)
    : getEmptyResults<IOpsOrg>();
  return (
    <QueryBasedDropdown
      required={required}
      value={value}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={mapsOpsOrgToDropdown(dataResult.data)}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
    />
  );
};

export default OpsOrgDropDown;
