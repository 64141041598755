import { ITag } from '@fluentui/react';
import React from 'react';
import { GetCountryTagsByFilter } from '../../utils/api/CompaniesApi';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

export interface ICountryChecklistProps extends IChecklistImplementationProps {
  isActive: boolean;
}

const CountryChecklist = (props: ICountryChecklistProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, isActive, disabledValues } = props;

  const mapToTag = (item: { countryCode: string }): ITag => {
    return {
      key: item.countryCode,
      name: item.countryCode.toString(),
    };
  };

  const mapData = (data: { countryTags: { countryCode: string }[] }): ITag[] => {
    return data?.countryTags?.map((os: { countryCode: string }) => {
      return mapToTag(os);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text="Country">
      <GenericChecklist
        query={GetCountryTagsByFilter}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        isActiveQueryParam={isActive}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default CountryChecklist;
