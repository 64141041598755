const arrayMustNotBeEmpty = (values: []) => {
  return values?.length > 0 || 'Please select at least one';
};
export const timeIn24HourFormat = (value: string) => {
  return (
    value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) ||
    'Time must be entered in 24hr format HH:MM'
  );
};
export const urlMustBeValid = (value: string) => {
  let givenURL;
  try {
    givenURL = new URL(value);
  } catch (error) {
    return 'The url must be valid and contain contain http or https.';
  }
  return givenURL.protocol === 'http:' || givenURL.protocol === 'https:'
    ? true
    : 'The url must contain http or https.';
};
export default arrayMustNotBeEmpty;
