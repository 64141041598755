import React from 'react';
import IPaginationMetadata, {
  PaginatedData,
  PaginationQueryVariables,
} from '../../utils/types/PaginationTypes';
import DetailListPaginationBanner, {
  getPaginationInfo,
  getTotalPageCount,
} from './SharedPagination';

interface IPaginationWrapperProps<O> {
  children: JSX.Element;
  dataResult: PaginatedData<O>;
  selectedPage: number;
  loadingData: boolean;
  onSelectedPageChange: (
    newValue: number,
    paginationQueryVariables?: PaginationQueryVariables,
  ) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationSize: number;
}

const PaginationWrapperManaged = <O,>(props: IPaginationWrapperProps<O>): JSX.Element => {
  const {
    children,
    dataResult,
    loadingData,
    selectedPage,
    onSelectedPageChange,
    onPageSizeChange,
    paginationSize,
  } = props;

  const pageCount = getTotalPageCount(dataResult?.totalCount, paginationSize);

  const paginationMetadata: IPaginationMetadata = {
    pageCount,
    selectedPage,
    pageSize: paginationSize,
    loadingData,
    onPageChange: (startItemIndex: number, endItemIndex: number, newPageNumber: number): void => {
      const { pageInfo } = dataResult;
      const paginationInfo = getPaginationInfo(
        pageInfo,
        selectedPage,
        newPageNumber,
        paginationSize,
      );

      if (pageInfo != null) {
        onSelectedPageChange(newPageNumber, { ...paginationInfo });
      }
    },
    onPageSizeChange,
  };
  return (
    <>
      {children}
      {paginationMetadata && <DetailListPaginationBanner {...paginationMetadata} />}
    </>
  );
};
export default PaginationWrapperManaged;
