/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { RemoveDraftTasksMutation } from '../../../utils/api/DraftTaskApi';
import BulkEditMode from '../../../utils/types/IBulkEdit';

interface IDraftTaskDeleteProps {
  selectedTasks?: number[];
  selectedTaskCount?: number;
  keyword?: string;
  bulkDeleteMode?: BulkEditMode;
  filters?: unknown;
  showDeleteModal: boolean;
  hideBulkDeleteModal: (shouldRefetch: boolean) => void;
}

const DraftTaskDeleteModal = (props: IDraftTaskDeleteProps): JSX.Element => {
  const {
    selectedTasks,
    selectedTaskCount,
    keyword,
    bulkDeleteMode,
    filters,
    showDeleteModal,
    hideBulkDeleteModal,
  } = props;

  const [deleteTasks, { loading, error }] = useMutation(RemoveDraftTasksMutation, {
    onCompleted: () => {
      hideBulkDeleteModal(true);
    },
  });

  const onDeleteClick = () => {
    deleteTasks({
      variables: {
        itemBulkDto: {
          selectedItems: bulkDeleteMode === BulkEditMode.BulkEditSelected ? selectedTasks : null,
          keyword: bulkDeleteMode === BulkEditMode.BulkEditAllFiltered ? keyword : null,
          filters: bulkDeleteMode === BulkEditMode.BulkEditAllFiltered ? filters : null,
          appendOnly: false,
        },
      },
    });
  };

  const getMessage = () => {
    return (
      <>
        Clicking Delete will <strong>delete {selectedTaskCount} draft tasks</strong>, and they will
        need to be regenerated if needed.
      </>
    );
  };

  return (
    <CoherenceModal
      isOpen={showDeleteModal}
      modalWidth="small"
      height="responsive"
      title="Delete draft tasks"
      onDismiss={() => {
        hideBulkDeleteModal(false);
      }}
    >
      <Stack verticalFill>
        <Stack grow>
          <LoadingErrorMessage loading={loading} error={error} label="Deleting draft tasks" />
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 10 }}>
              <span>{getMessage()}</span>
            </Stack>
          </MessageBar>
          <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
            <PrimaryButton
              text="Delete"
              onClick={onDeleteClick}
              ariaLabel="Delete"
              disabled={loading}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                hideBulkDeleteModal(false);
              }}
              ariaLabel="Cancel"
              disabled={loading}
            />
          </Stack>
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export default DraftTaskDeleteModal;
