import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import { GetOpsOrgList } from '../../../../utils/api/HierarchiesApi';
import { IOpsOrg } from '../../../../utils/types/IHierarchy';
import PanelTypes from '../../../../utils/types/PanelTypes';
import OpsOrgPanel from './OpsOrgPanel';

interface IOpsOrgEditProps {
  opsOrg: IOpsOrg;
  closePanel: (shouldRefresh: boolean) => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const OpsOrgEdit = (props: IOpsOrgEditProps): JSX.Element => {
  const { closePanel, opsOrg, mutation, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetOpsOrgList }];
  const [updateOpsOrgValue, { loading, error }] = useMutation(mutation, {
    onCompleted: () => closePanel(true),
    refetchQueries,
  });
  const submitForm = (opsOrgInputData: IOpsOrg) => {
    const opsSummaryId = parseInt(opsOrgInputData.opsSummaryId.toString(), 10);

    updateOpsOrgValue({
      variables: {
        opsOrgDto: {
          id: opsOrg.id,
          name: opsOrgInputData.name,
          summaryId: opsSummaryId,
          isActive: opsOrgInputData.isActive,
        },
      },
    });
  };

  return (
    <OpsOrgPanel
      data={opsOrg}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default OpsOrgEdit;
