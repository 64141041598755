import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { IFiscalCalendar } from '../../../utils/types/ICalendarDate';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../common/buttons/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import ModifiedDetails from '../../../common/auditedEntity/ModifiedDetails';
import PanelTypes from '../../../utils/types/PanelTypes';
import { RequiredMarker } from '../../../common/labels/RequiredMarker';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';
import {
  calculateDate,
  calculateFiscalYear,
  yearToFiscalYear,
} from '../../../utils/formatters/DateFormatters';
import FiscalYearDropDown from '../../../common/dateRangePicker/FiscalYearDropDown';

export interface ICalendarListPanelProps {
  data: IFiscalCalendar;
  mutation: (variableDateDtoData: IFiscalCalendar) => void;
  closePanel: (shouldRefresh: boolean) => void;
  mutationError: ApolloError | undefined;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const CalendarListPanel: React.FunctionComponent<ICalendarListPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: ICalendarListPanelProps): JSX.Element => {
  const [fYear, setfYear] = useState(
    yearToFiscalYear(new Date().getFullYear(), new Date().getMonth()),
  );
  const dfy = data?.fiscalYear
    ? data?.fiscalYear
    : yearToFiscalYear(new Date().getFullYear(), new Date().getMonth());
  const form = useForm({
    defaultValues: {
      ...data,
      taskDateCode: data?.taskDateCode,
      description: data?.description,
      fiscalYear: dfy,
    },
    shouldUnregister: true,
  });
  const { handleSubmit, control, errors, setValue, getValues } = form;
  const { taskDateCode, description, fiscalYear } = getValues();

  const handleOnSaveClick = (formData: IFiscalCalendar): void => {
    mutation(formData);
  };

  const updateDateYear = (extantDate: Date, newYear: string): string => {
    if (!extantDate || !newYear) {
      return null;
    }

    const newDate = moment(extantDate).utc();

    newDate.utc().year(calculateFiscalYear(newYear, newDate.utc().month() + 1));

    return newDate.utc().format('YYYY-MM-DD');
  };

  const setNewValueFromYear = (
    key: string,
    newValue: string,
    periodIndex: number,
    period: number,
  ) => {
    const dateCalculation =
      panelMode === PanelTypes.Add
        ? calculateDate(Number(newValue), period)
        : updateDateYear(data?.fiscalPeriod[periodIndex]?.assignedDate, newValue);

    setValue(key, dateCalculation);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`${panelMode} Item`}
      isOpen
      onDismiss={() => closePanel(false)}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Item"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={() => closePanel(false)}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="Saving calendar"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          {panelMode === PanelTypes.Edit && (
            <ModifiedDetails
              modifiedOn={data?.modifiedOn}
              modifiedBy={data?.modifiedBy?.displayName}
            />
          )}
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                as={TextField}
                label="Task Date Code"
                id="taskDateCode"
                required
                name="taskDateCode"
                disabled={panelMode === PanelTypes.Edit}
                control={control}
                resizable={false}
                defaultValue={taskDateCode}
                value={taskDateCode}
                errorMessage={errors?.taskDateCode?.message}
                rules={{
                  required: 'Please provide Task Date Code',
                  maxLength: {
                    value: 12,
                    message: 'Task Date Code cannot have more than 12 characters',
                  },
                }}
              />
              <Controller
                as={TextField}
                label="Description"
                id="description"
                required
                name="description"
                control={control}
                resizable={false}
                defaultValue={description}
                value={description}
                errorMessage={errors?.description?.message}
                rules={{
                  required: 'Please provide description',
                  maxLength: {
                    value: 75,
                    message: 'Description cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                name="fiscalYear"
                required
                control={control}
                resizable={false}
                defaultValue={fiscalYear}
                value={fiscalYear}
                render={({ value }) => {
                  return (
                    <FiscalYearDropDown
                      id="fiscalYear"
                      label="Fiscal Year"
                      required
                      value={
                        value?.toString()
                          ? value.toString()
                          : yearToFiscalYear(moment().utc().year(), moment().utc().month())
                      }
                      handleChange={(newValue: IDropdownOption): void => {
                        setValue('fiscalYear', Number(newValue.text));
                        setNewValueFromYear('assignedDateP1', newValue.text, 0, 7);
                        setNewValueFromYear('assignedDateP2', newValue.text, 1, 8);
                        setNewValueFromYear('assignedDateP3', newValue.text, 2, 9);
                        setNewValueFromYear('assignedDateP4', newValue.text, 3, 10);
                        setNewValueFromYear('assignedDateP5', newValue.text, 4, 11);
                        setNewValueFromYear('assignedDateP6', newValue.text, 5, 12);
                        setNewValueFromYear('assignedDateP7', newValue.text, 6, 1);
                        setNewValueFromYear('assignedDateP8', newValue.text, 7, 2);
                        setNewValueFromYear('assignedDateP9', newValue.text, 8, 3);
                        setNewValueFromYear('assignedDateP10', newValue.text, 9, 4);
                        setNewValueFromYear('assignedDateP11', newValue.text, 10, 5);
                        setNewValueFromYear('assignedDateP12', newValue.text, 11, 6);
                        setfYear(Number(newValue.text));
                      }}
                      disabled={panelMode === PanelTypes.Edit}
                      width={550}
                    />
                  );
                }}
              />
              <>
                <Stack>
                  <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Label> Fiscal Period</Label>
                    <Label> Assigned date</Label>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    horizontalAlign="space-between"
                    styles={{ root: { width: 300 } }}
                  >
                    <Label>July (P1)</Label>
                    <Controller
                      id="assignedDateP1"
                      key="assignedDateP1"
                      name="assignedDateP1"
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 7)
                          : data?.fiscalPeriod[0].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 7)
                          : data?.fiscalPeriod[0].assignedDate
                      }
                      control={control}
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="July P1"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP1',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP1', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label> August (P2)</Label>
                    <Controller
                      key="assignedDateP2"
                      name="assignedDateP2"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 8)
                          : data?.fiscalPeriod[1].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 8)
                          : data?.fiscalPeriod[1].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="August P2"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP2',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP2', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label> September (P3)</Label>
                    <Controller
                      key="assignedDateP3"
                      name="assignedDateP3"
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 9)
                          : data?.fiscalPeriod[2].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 9)
                          : data?.fiscalPeriod[2].assignedDate
                      }
                      control={control}
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="September P3"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP3',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP3', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label> October (P4)</Label>
                    <Controller
                      key="assignedDateP4"
                      name="assignedDateP4"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 10)
                          : data?.fiscalPeriod[3].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 10)
                          : data?.fiscalPeriod[3].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="October P4"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP4',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP4', dateString);
                            }}
                          />
                        );
                      }}
                    />
                    <Label> November (P5)</Label>
                    <Controller
                      key="assignedDateP5"
                      name="assignedDateP5"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 11)
                          : data?.fiscalPeriod[4].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 11)
                          : data?.fiscalPeriod[4].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="November P5"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP5',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP5', dateString);
                            }}
                          />
                        );
                      }}
                    />
                    <Label> December (P6)</Label>
                    <Controller
                      key="assignedDateP6"
                      name="assignedDateP6"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 12)
                          : data?.fiscalPeriod[5].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 12)
                          : data?.fiscalPeriod[5].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="December P6"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP6',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP6', dateString);
                            }}
                          />
                        );
                      }}
                    />
                    <Label> January (P7)</Label>
                    <Controller
                      key="assignedDateP7"
                      name="assignedDateP7"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 1)
                          : data?.fiscalPeriod[6].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 1)
                          : data?.fiscalPeriod[6].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="January P7"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP7',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP7', dateString);
                            }}
                          />
                        );
                      }}
                    />
                    <Label> February (P8)</Label>
                    <Controller
                      key="assignedDateP8"
                      name="assignedDateP8"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 2)
                          : data?.fiscalPeriod[7].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 2)
                          : data?.fiscalPeriod[7].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="February P8"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP8',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP8', dateString);
                            }}
                          />
                        );
                      }}
                    />
                    <Label> March (P9)</Label>
                    <Controller
                      key="assignedDateP9"
                      name="assignedDateP9"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 3)
                          : data?.fiscalPeriod[8].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 3)
                          : data?.fiscalPeriod[8].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="March P9"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP9',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP9', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label>April (P10)</Label>
                    <Controller
                      key="assignedDateP10"
                      name="assignedDateP10"
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 4)
                          : data?.fiscalPeriod[9].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 4)
                          : data?.fiscalPeriod[9].assignedDate
                      }
                      control={control}
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="April P10"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP10',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP10', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label> May (P11)</Label>
                    <Controller
                      key="assignedDateP11"
                      name="assignedDateP11"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 5)
                          : data?.fiscalPeriod[10].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 5)
                          : data?.fiscalPeriod[10].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="May P11"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP11',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP11', dateString);
                            }}
                          />
                        );
                      }}
                    />

                    <Label> June (P12)</Label>
                    <Controller
                      key="assignedDateP12"
                      name="assignedDateP12"
                      control={control}
                      value={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 6)
                          : data?.fiscalPeriod[11].assignedDate
                      }
                      defaultValue={
                        panelMode === PanelTypes.Add
                          ? calculateDate(fYear, 6)
                          : data?.fiscalPeriod[11].assignedDate
                      }
                      render={({ value }) => {
                        return (
                          <UtcDatePicker
                            ariaLabel="June P12"
                            value={value}
                            allowTextInput
                            textField={{
                              name: 'assignedDateP12',
                            }}
                            onChange={(dateString: string): void => {
                              setValue('assignedDateP12', dateString);
                            }}
                          />
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </>
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default CalendarListPanel;
