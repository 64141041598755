import { IGroupedChecklistItem } from './IGroupedChecklistItem';

export interface IProjectNileWorkflow extends IGroupedChecklistItem {
  id?: string;
  lineOfBusiness?: string;
}

export const getWorkflowListFromId = (workflowId: string): IProjectNileWorkflow[] => {
  return [
    {
      id: workflowId,
      name: workflowId,
      selected: false,
    } as IProjectNileWorkflow,
  ];
};
