import { useQuery } from '@apollo/client';
import { IComboBox, IComboBoxOption, ComboBox, DefaultButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import GetTaskProperties from '../../../utils/api/TaskPropertiesApi';
import { hierarchyViewOptions } from './HierarchiesList.config';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../../common/constants/SiteConstants';

interface IHierarchiesListViewDropdownProps {
  onUpdateViewClick: (newViewKey: string) => void;
  activeViewKey: string;
}

const comboBoxStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    paddingRight: '3px',
  },
};

const stackStyles = {
  root: {
    marginLeft: '40px',
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      marginLeft: '0px',
    },
  },
};

const HierarchiesListViewDropdown = (props: IHierarchiesListViewDropdownProps): JSX.Element => {
  const { onUpdateViewClick, activeViewKey } = props;
  const [selectedViewKey, setSelectedViewKey] = useState<string>(activeViewKey);
  const { data, loading, error } = useQuery(GetTaskProperties);
  const onHierarchyDropdownChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
  ) => {
    const temp: string = option?.key ? option?.key.toString() : '';
    setSelectedViewKey(temp);
  };
  const appendOptions = data?.taskProperties.map((property: { id: number; name: string }) => {
    const { id, name } = property;
    return {
      key: id.toString(),
      text: name,
    };
  });
  const comboBoxOptions = [...hierarchyViewOptions].concat(appendOptions);
  return (
    <Stack horizontal tokens={{ childrenGap: 3 }} styles={stackStyles}>
      <LoadingErrorMessage label="Loading View Types" loading={loading} error={error} />
      {data && !loading && (
        <>
          <ComboBox
            defaultSelectedKey={selectedViewKey}
            options={comboBoxOptions}
            onChange={onHierarchyDropdownChange}
            label="View"
            styles={comboBoxStyles}
            isButtonAriaHidden
          />
          <DefaultButton
            text="Update"
            onClick={() => {
              onUpdateViewClick(selectedViewKey);
            }}
          />
        </>
      )}
    </Stack>
  );
};
export default HierarchiesListViewDropdown;
