import {
  DefaultButton,
  Dropdown,
  FocusZone,
  FocusZoneTabbableElements,
  IDropdownOption,
  PrimaryButton,
  Stack,
  Toggle,
} from '@fluentui/react';
import React, { useState } from 'react';
import { FiscalPeriodValues } from '../../utils/types/IFrequencyChoices';
import { DateRangeType } from '../../components/home/Home';
import UtcDatePicker from '../formFields/UTCDatePicker';
import FiscalYearDropDown from './FiscalYearDropDown';

interface IDateRangeFiscalYearPickerProps {
  onUpdateDate: (
    startDate: string,
    endDate: string,
    selectedDateRangeOption: DateRangeType,
    fiscalYear: number,
    fiscalPeriod: number,
  ) => void;
  initialSelectedOption: DateRangeType;
  initialStartDate?: string;
  initialEndDate?: string;
  initialFiscalYear?: number;
  initialFiscalPeriod?: number;
  onClose?: () => void;
}
const DateRangeFiscalPeriodPicker = (props: IDateRangeFiscalYearPickerProps): JSX.Element => {
  const {
    onUpdateDate,
    initialSelectedOption,
    initialStartDate,
    initialEndDate,
    initialFiscalPeriod,
    initialFiscalYear,
    onClose,
  } = props;
  const [componentState, setComponentState] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    fiscalYear: initialFiscalYear,
    fiscalPeriod: initialFiscalPeriod,
  });
  const [isFiscalYear, setIsFiscalYear] = useState(
    initialSelectedOption === DateRangeType.rangePeriod,
  );

  const datePickerStyles = {
    root: { width: 150, marginBottom: 0, paddingBottom: 0 },
    statusMessage: { margin: 0 },
  };
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
        <Toggle
          label="Switch to fiscal period selector"
          inlineLabel
          onText="on"
          offText="off"
          defaultChecked={isFiscalYear}
          onChange={(event, checked) => {
            setIsFiscalYear(checked);
          }}
        />
        {!isFiscalYear && (
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <UtcDatePicker
              label="Due Date From"
              value={componentState.startDate}
              onChange={(date) => {
                setComponentState({ ...componentState, startDate: date });
              }}
              allowTextInput
            />
            <UtcDatePicker
              label="Due Date To"
              value={componentState.endDate}
              onChange={(date) => {
                setComponentState({ ...componentState, endDate: date });
              }}
              allowTextInput
            />
          </Stack>
        )}
        {isFiscalYear && (
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Dropdown
              id="fiscalPeriod"
              label="Fiscal Period"
              placeholder="Select One"
              options={FiscalPeriodValues}
              required
              defaultSelectedKey={componentState.fiscalPeriod}
              onChange={(e, newValue: IDropdownOption): void => {
                setComponentState({
                  ...componentState,
                  fiscalPeriod: Number(newValue.key),
                });
              }}
              styles={datePickerStyles}
            />
            <FiscalYearDropDown
              id="fiscalYear"
              label="Fiscal Year"
              required
              value={componentState.fiscalYear?.toString()}
              handleChange={(newValue: IDropdownOption): void => {
                setComponentState({
                  ...componentState,
                  fiscalYear: Number(newValue.key),
                });
              }}
              width={150}
            />
          </Stack>
        )}
        <Stack horizontal tokens={{ childrenGap: 20 }} styles={{ root: { marginTop: 30 } }}>
          <PrimaryButton
            onClick={() => {
              onUpdateDate(
                componentState.startDate,
                componentState.endDate,
                isFiscalYear ? DateRangeType.rangePeriod : DateRangeType.rangeDueDate,
                componentState.fiscalYear,
                componentState.fiscalPeriod,
              );
            }}
          >
            Update
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </DefaultButton>
        </Stack>
      </FocusZone>
    </Stack>
  );
};
export default DateRangeFiscalPeriodPicker;
