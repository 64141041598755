import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { EditStagedTask, GetStagedTask } from '../../../utils/api/StagedTaskApi';
import { IStagedTask, IStagedTaskRouteParams } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import StagedTasksDetailForm from './StagedTasksDetailForm';

const StagedTasksEdit = (): JSX.Element => {
  const { stagedTaskId } = useParams<IStagedTaskRouteParams>();
  const { data, loading, error } = useQuery<{ stagedTask: IStagedTask }>(GetStagedTask, {
    variables: { stagedTaskId: parseInt(stagedTaskId, 10) },
  });
  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} label="Loading staged task item" />
      {data?.stagedTask && (
        <StagedTasksDetailForm
          panelMode={PanelTypes.Edit}
          stagedTask={data?.stagedTask}
          mutation={EditStagedTask}
        />
      )}
    </>
  );
};

export default StagedTasksEdit;
