import { Stack, Toggle, NeutralColors, IconButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import { bodyContentContainer } from '../../common/styles/CommonStyleObjects';
import {
  getFiscalInfoFromDate,
  getSelectedFiscalPeriod,
} from '../../utils/formatters/DateFormatters';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import ListFilters from '../../common/lists/ListFilters';
import { GetOpsOrgDetailListSearch } from '../../utils/api/HierarchiesApi';
import CompanyChecklist from '../../common/formFields/CompanyChecklist';
import FeatureFlagged from '../featureFlags/FeatureFlagged';
import ButtonCallout from '../../common/callout/ButtonCallout';
import DateRangeFiscalPeriodPicker from '../../common/dateRangePicker/DateRangeFiscalPeriodPicker';
import { mapPublishedTaskFilterTagsToIds } from '../tasks/list/BaseTaskListfilters.config';
import { IPublishedTaskFilterIds } from '../../utils/types/ITask';
import { getDefaultStateProps } from '../../utils/listHelpers';
import useLocalStorage, { LocalStorageKeys } from '../../utils/hooks/useLocalStorage';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../common/constants/SiteConstants';
import TileClientApp from '../clientApps/TileClientApp';
import GenericFilterTagHandler from '../../common/lists/GenericFilterTagHandler';
import StatProcessesCard from '../clientApps/stat/StatProcessesCard';
import { IPublishedTaskListQueryVariables } from '../tasks/list/PublishedTaskListPageTemplate';
import ReconAtAGlanceCards from '../clientApps/reconIntelligence/ReconAtAGlanceCards';
import DashboardSettingsPanel from '../savedFilters/panels/DashboardSettingsPanel';
import CollapsibleGenericChecklist from '../../common/formFields/CollapsibleGenericChecklist';
import config from '../../utils/ConfigLoader';
import JemAtAGlanceCards from '../clientApps/jem/JemAtAGlanceCards';
import JemPostingCard from '../clientApps/JemPostingCard';
import JemReviewCard from '../clientApps/JemReviewCard';
import GsrAtAGlanceCards from '../clientApps/gsr/GsrAtAGlanceCard';

export enum DateRangeType {
  rangeDueDate = 'DueDate',
  rangePeriod = 'Period',
}

export enum DashboardCardType {
  jemReview = 1,
  jemPosting = 2,
  statProcess = 3,
  closeChecklist = 4,
  reconIntelligence = 5,
  exa = 6,
  jem = 7,
  gsr = 8,
}

export interface IDashboardCardConfig {
  name: string;
  type: DashboardCardType;
  visible: boolean;
  featureFlag: string;
}

export interface IHomeQueryVariables extends IPublishedTaskListQueryVariables {
  dueDateRangeStart: string;
  dueDateRangeEnd: string;
  dateRangeType: DateRangeType;
  fiscalYear: number;
  fiscalPeriod: number;
  forMeOnly: boolean;
  dashboardCardConfigs?: IDashboardCardConfig[];
}

export interface IHomePageState {
  filterPanelVisible: boolean;
  filterTags: IGenericFilterTag[];
  dueDateRangeStart: string;
  dueDateRangeEnd: string;
  dateRangeType: DateRangeType;
  fiscalYear: number;
  fiscalPeriod: number;
  forMeOnly: boolean;
  isCalloutVisible: boolean;
  dashboardSettingsPanelOpen: boolean;
  dashboardCardConfigs: IDashboardCardConfig[];
}

export interface IDashboardCardWrapper {
  type: DashboardCardType;
  card: JSX.Element;
  isVisible: boolean;
  featureFlag: string;
}

const defaultDashboardCardConfigs: IDashboardCardConfig[] = [
  {
    name: 'JEM Review',
    featureFlag: 'JEMCard',
    type: DashboardCardType.jemReview,
    visible: true,
  },
  {
    name: 'JEM Posting',
    featureFlag: 'JEMCard',
    type: DashboardCardType.jemPosting,
    visible: true,
  },
  {
    name: 'JEM',
    featureFlag: 'JEMMicroUICard',
    type: DashboardCardType.jem,
    visible: true,
  },
  {
    name: 'Close Checklist',
    featureFlag: 'CloseChecklistCard',
    type: DashboardCardType.closeChecklist,
    visible: true,
  },
  {
    name: 'EXA',
    featureFlag: 'EXACard',
    type: DashboardCardType.exa,
    visible: true,
  },
  {
    name: 'STAT Process',
    featureFlag: 'StatProcessesCard',
    type: DashboardCardType.statProcess,
    visible: true,
  },
  {
    name: 'Recon Intelligence',
    featureFlag: 'RICard',
    type: DashboardCardType.reconIntelligence,
    visible: true,
  },
  {
    name: 'Goods and Services Receipting',
    featureFlag: 'GSRCard',
    type: DashboardCardType.gsr,
    visible: true,
  },
];

function getDashboardCardConfigs(
  currentDashboardCardConfigs: IDashboardCardConfig[],
): IDashboardCardConfig[] {
  let mergedCardConfigs: IDashboardCardConfig[] = [];
  mergedCardConfigs = [
    // For each default card config, find the matching user's current card config
    ...defaultDashboardCardConfigs.map((defaultConfig) => {
      const matchingCurrentCardConfig = currentDashboardCardConfigs?.find(
        (currentConfig) =>
          currentConfig.name === defaultConfig.name &&
          currentConfig.featureFlag === defaultConfig.featureFlag,
      );
      // If there is a matching current card config,
      // add that current card config to the list.
      return mergedCardConfigs
        ? { ...defaultConfig, ...matchingCurrentCardConfig }
        : // If there is no matching current card config
          // add the default card config to the list.
          defaultConfig;
    }),
  ];
  return mergedCardConfigs;
}

const Home = (): JSX.Element => {
  const today = new Date(); // There is a small edge case around midnight at the end of a month. We can probably safely ignore
  const momentDate = moment()
    .utc(true)
    .year(today.getFullYear())
    .month(today.getMonth())
    .date(today.getDate())
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  const selectedDateString = momentDate.utc().format('YYYY-MM-DD');
  const defaultFiltersAndSort = {
    filterTags: [] as IGenericFilterTag[],
    dueDateRangeEnd: selectedDateString,
    dueDateRangeStart: selectedDateString,
    dateRangeType: DateRangeType.rangePeriod,
    fiscalYear: getFiscalInfoFromDate(today).fiscalYear,
    fiscalPeriod: getFiscalInfoFromDate(today).fiscalPeriod,
    forMeOnly: false,
    dashboardCardConfigs: defaultDashboardCardConfigs,
  } as IHomeQueryVariables;

  const [homeQueryCache, setHomeQueryCache] = useLocalStorage<IHomeQueryVariables>(
    `${LocalStorageKeys.homeQueryState}`,
    {
      ...defaultFiltersAndSort,
    } as IHomeQueryVariables,
    false,
  );

  const [pageState, setPagesState] = useState<IHomePageState>({
    ...getDefaultStateProps(homeQueryCache),
    dueDateRangeStart: homeQueryCache.dueDateRangeStart,
    dueDateRangeEnd: homeQueryCache.dueDateRangeEnd,
    dateRangeType: homeQueryCache.dateRangeType,
    fiscalYear: homeQueryCache.fiscalYear,
    fiscalPeriod: homeQueryCache.fiscalPeriod,
    forMeOnly: homeQueryCache.forMeOnly,
    dashboardCardConfigs: getDashboardCardConfigs(homeQueryCache.dashboardCardConfigs),
    isCalloutVisible: false,
    filterPanelVisible: false,
    dashboardSettingsPanelOpen: false,
  });

  const onFilterUpdate = (formData: IGenericFilterTag[]) => {
    setPagesState({
      ...pageState,
      filterTags: formData,
      filterPanelVisible: false,
    });
  };

  const handleSettingsPanelOpen = () => {
    setPagesState({
      ...pageState,
      dashboardSettingsPanelOpen: true,
    });
  };

  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="space-around" tokens={{ childrenGap: 5 }}>
        <h1>Dashboard</h1>
        <IconButton
          iconProps={{
            iconName: 'Settings',
          }}
          title="Dashboard Settings"
          ariaLabel="Dashboard Settings"
          styles={{ root: { margin: '4px 0px' } }}
          onClick={handleSettingsPanelOpen}
        />
      </Stack>
    );
  };
  const getButtonCalloutContent = (): string => {
    return pageState.dateRangeType === DateRangeType.rangeDueDate
      ? `Items due between ${moment(pageState.dueDateRangeStart).format('MM/DD/YYYY')} and ${moment(
          pageState.dueDateRangeEnd,
        ).format('MM/DD/YYYY')}`
      : `Items assigned to ${getSelectedFiscalPeriod(pageState.fiscalPeriod)} FY${
          pageState.fiscalYear
        }`;
  };
  const getFilters = (): IPublishedTaskFilterIds => {
    const mappedFilters = mapPublishedTaskFilterTagsToIds(pageState.filterTags);
    // get rid of the company codes if all company codes is selected
    const hasAllCompanyCodes = mappedFilters?.companyCodes?.filter((code) => code === 0).length > 0;
    if (hasAllCompanyCodes) {
      mappedFilters.companyCodes = [];
    }
    return mappedFilters;
  };

  const handleSettingsPanelClose = () => {
    setPagesState({
      ...pageState,
      dashboardSettingsPanelOpen: false,
    });
  };

  const handleDashboardCardConfigChange = (cardType: DashboardCardType, checked: boolean) => {
    const dashboardCardConfigs: IDashboardCardConfig[] = pageState?.dashboardCardConfigs;
    const updatedDashboardCardConfigs = dashboardCardConfigs?.map((cardConfig) => {
      if (cardConfig?.type === cardType) {
        return { ...cardConfig, visible: checked };
      }
      return cardConfig;
    });
    setPagesState({
      ...pageState,
      dashboardCardConfigs: updatedDashboardCardConfigs,
    });
  };

  const setCache = () => {
    const {
      filterTags,
      dateRangeType,
      dueDateRangeEnd,
      dueDateRangeStart,
      fiscalPeriod,
      fiscalYear,
      forMeOnly,
      dashboardCardConfigs,
    } = pageState;
    setHomeQueryCache({
      ...homeQueryCache,
      filterTags,
      dateRangeType,
      dueDateRangeEnd,
      dueDateRangeStart,
      fiscalPeriod,
      fiscalYear,
      forMeOnly,
      dashboardCardConfigs,
    });
    localStorage.removeItem(LocalStorageKeys.level3PageCache);
  };
  useEffect(() => {
    setCache();
  }, [
    pageState.filterTags,
    pageState.dateRangeType,
    pageState.dueDateRangeStart,
    pageState.dueDateRangeEnd,
    pageState.fiscalYear,
    pageState.fiscalPeriod,
    pageState.forMeOnly,
    pageState.dashboardCardConfigs,
  ]);

  const actionBarMobileStyles = {
    root: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        alignItems: 'flex-start',
        flexFlow: 'column',
      },
    },
  };
  const renderFilters = () => {
    return (
      <GenericFilterTagHandler
        onChange={(filterTags: IGenericFilterTag[]) =>
          setPagesState({
            ...pageState,
            filterTags,
          })
        }
        allFilterTags={pageState.filterTags}
      >
        <ListFilters
          onClosePanel={() => {
            setPagesState({
              ...pageState,
              filterPanelVisible: false,
            });
          }}
          onFilterUpdate={onFilterUpdate}
          currentFilterValues={pageState.filterTags}
        >
          <CollapsibleGenericChecklist
            dataKey="opsOrgDetails"
            label="Ops org detail"
            query={GetOpsOrgDetailListSearch}
            queryKey="opsDetailNames"
          />
          <CompanyChecklist dataKey="companies" label="Co. code" isActive={false} />
        </ListFilters>
      </GenericFilterTagHandler>
    );
  };
  const isCardVisible = (cardType: DashboardCardType) => {
    let isVisible = false;
    isVisible = pageState?.dashboardCardConfigs?.some(
      (cardConfig) => cardConfig?.type === cardType && cardConfig?.visible === true,
    );
    return isVisible;
  };

  const dashboardCards: IDashboardCardWrapper[] = [
    {
      type: DashboardCardType.jemPosting,
      isVisible: isCardVisible(DashboardCardType.jemPosting),
      featureFlag: 'JEMCard',
      card: (
        <JemPostingCard
          {...getFilters()}
          // to do for all cards, we need to merge the dueDateRange from saved filters here
          dueDateRangeStart={pageState.dueDateRangeStart}
          dueDateRangeEnd={pageState.dueDateRangeEnd}
          filterByPeriod={pageState.dateRangeType === DateRangeType.rangePeriod}
          fiscalPeriod={pageState.fiscalPeriod}
          fiscalYear={pageState.fiscalYear}
          forMeOnly={pageState.forMeOnly}
        />
      ),
    },
    {
      type: DashboardCardType.jemReview,
      isVisible: isCardVisible(DashboardCardType.jemReview),
      featureFlag: 'JEMCard',
      card: (
        <JemReviewCard
          {...getFilters()}
          dueDateRangeStart={pageState.dueDateRangeStart}
          dueDateRangeEnd={pageState.dueDateRangeEnd}
          filterByPeriod={pageState.dateRangeType === DateRangeType.rangePeriod}
          fiscalPeriod={pageState.fiscalPeriod}
          fiscalYear={pageState.fiscalYear}
          forMeOnly={pageState.forMeOnly}
        />
      ),
    },
    {
      type: DashboardCardType.jem,
      isVisible: isCardVisible(DashboardCardType.jem),
      featureFlag: 'JEMMicroUICard',
      card: (
        <JemAtAGlanceCards
          filters={{
            ...getFilters(),
            dueDateRangeStart: pageState.dueDateRangeStart,
            dueDateRangeEnd: pageState.dueDateRangeEnd,
            filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
            fiscalPeriod: pageState.fiscalPeriod,
            fiscalYear: pageState.fiscalYear,
            forMeOnly: pageState.forMeOnly,
          }}
        />
      ),
    },
    {
      type: DashboardCardType.closeChecklist,
      isVisible: isCardVisible(DashboardCardType.closeChecklist),
      featureFlag: 'CloseChecklistCard',
      card: (
        <TileClientApp
          scriptUrl={`${config?.settings?.closeCheckListConfig?.microUiUrl}${config?.settings?.closeCheckListConfig?.microUiFilePath}`}
          appName="CloseChecklistTile"
          supportLink="https://aka.ms/fccwresources"
          friendlyAppName="Close Checklist"
          data={{
            filters: {
              ...getFilters(),
              dueDateRangeStart: pageState.dueDateRangeStart,
              dueDateRangeEnd: pageState.dueDateRangeEnd,
              filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
              fiscalPeriod: pageState.fiscalPeriod,
              fiscalYear: pageState.fiscalYear,
              forMeOnly: pageState.forMeOnly,
            },
          }}
        />
      ),
    },
    {
      type: DashboardCardType.exa,
      isVisible: isCardVisible(DashboardCardType.exa),
      featureFlag: 'EXACard',
      card: (
        <TileClientApp
          scriptUrl={`${config?.settings?.exaConfig?.microUiUrl}${config?.settings?.exaConfig?.microUiFilePath}`}
          appName="ExaTile"
          supportLink="https://aka.ms/fccwresources"
          friendlyAppName="EXA Tile"
          data={{
            filters: {
              ...getFilters(),
              dueDateRangeStart: pageState.dueDateRangeStart,
              dueDateRangeEnd: pageState.dueDateRangeEnd,
              filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
              fiscalPeriod: pageState.fiscalPeriod,
              fiscalYear: pageState.fiscalYear,
              forMeOnly: pageState.forMeOnly,
            },
          }}
        />
      ),
    },
    {
      type: DashboardCardType.statProcess,
      isVisible: isCardVisible(DashboardCardType.statProcess),
      featureFlag: 'StatProcessesCard',
      card: (
        <StatProcessesCard
          filters={{
            ...getFilters(),
            dueDateRangeStart: pageState.dueDateRangeStart,
            dueDateRangeEnd: pageState.dueDateRangeEnd,
            filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
            fiscalPeriod: pageState.fiscalPeriod,
            fiscalYear: pageState.fiscalYear,
            forMeOnly: pageState.forMeOnly,
          }}
        />
      ),
    },
    {
      type: DashboardCardType.reconIntelligence,
      isVisible: isCardVisible(DashboardCardType.reconIntelligence),
      featureFlag: 'RICard',
      card: (
        <ReconAtAGlanceCards
          filters={{
            ...getFilters(),
            dueDateRangeStart: pageState.dueDateRangeStart,
            dueDateRangeEnd: pageState.dueDateRangeEnd,
            filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
            fiscalPeriod: pageState.fiscalPeriod,
            fiscalYear: pageState.fiscalYear,
            forMeOnly: pageState.forMeOnly,
          }}
        />
      ),
    },
    {
      type: DashboardCardType.gsr,
      isVisible: isCardVisible(DashboardCardType.gsr),
      featureFlag: 'GSRCard',
      card: (
        <GsrAtAGlanceCards
          filters={{
            ...getFilters(),
            dueDateRangeStart: pageState.dueDateRangeStart,
            dueDateRangeEnd: pageState.dueDateRangeEnd,
            filterByPeriod: pageState.dateRangeType === DateRangeType.rangePeriod,
            fiscalPeriod: pageState.fiscalPeriod,
            fiscalYear: pageState.fiscalYear,
            forMeOnly: pageState.forMeOnly,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <FullWidthHeader title={renderHeader} alternateBackground />
      <div className={`${bodyContentContainer}`} style={{ background: NeutralColors.gray10 }}>
        <Stack horizontal horizontalAlign="space-between" styles={actionBarMobileStyles}>
          <Stack
            verticalAlign="center"
            horizontalAlign="center"
            styles={actionBarMobileStyles}
            horizontal
            tokens={{ childrenGap: 20 }}
          >
            <Stack.Item>
              <ButtonCallout
                triggerButtonId="dateRangeFilter"
                triggerIconName="Calendar"
                label={getButtonCalloutContent()}
                isCalloutVisible={pageState.isCalloutVisible}
                toggleIsCalloutVisible={(isCalloutVisible: boolean) => {
                  setPagesState({ ...pageState, isCalloutVisible });
                }}
              >
                <DateRangeFiscalPeriodPicker
                  onUpdateDate={(
                    startDate: string,
                    endDate: string,
                    selectedDateRangeOption: DateRangeType,
                    fiscalYear: number,
                    fiscalPeriod: number,
                  ) => {
                    setPagesState({
                      ...pageState,
                      dueDateRangeStart: startDate,
                      dueDateRangeEnd: endDate,
                      dateRangeType: selectedDateRangeOption,
                      fiscalPeriod,
                      fiscalYear,
                      isCalloutVisible: false,
                    });
                  }}
                  initialStartDate={pageState.dueDateRangeStart}
                  initialEndDate={pageState.dueDateRangeEnd}
                  initialFiscalPeriod={pageState.fiscalPeriod}
                  initialFiscalYear={pageState.fiscalYear}
                  initialSelectedOption={pageState.dateRangeType}
                  onClose={() => {
                    setPagesState({
                      ...pageState,
                      isCalloutVisible: false,
                    });
                  }}
                />
              </ButtonCallout>
            </Stack.Item>
            <Toggle
              label="Only show my items"
              inlineLabel
              onText="On"
              offText="Off"
              defaultChecked={pageState.forMeOnly}
              onChange={(event, checked) => {
                setPagesState({
                  ...pageState,
                  forMeOnly: checked,
                });
              }}
              styles={{ root: { marginBottom: 5 } }}
            />
          </Stack>
        </Stack>
        {renderFilters()}
        <Stack
          horizontal
          tokens={{ childrenGap: 30 }}
          styles={{ root: { padding: ' 30px 20px' } }}
          wrap
        >
          {dashboardCards?.map((cardWrapper, index) => {
            let card = null;
            if (cardWrapper.isVisible === true) {
              card = (
                <FeatureFlagged flagName={cardWrapper?.featureFlag}>
                  {cardWrapper.card}
                </FeatureFlagged>
              );
            }
            return card;
          })}
        </Stack>
      </div>
      {pageState?.dashboardSettingsPanelOpen && (
        <DashboardSettingsPanel
          dashboardCardConfigs={pageState?.dashboardCardConfigs}
          onChange={handleDashboardCardConfigChange}
          onClose={handleSettingsPanelClose}
        />
      )}
    </>
  );
};
export default Home;
