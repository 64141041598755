import { Stack, Label, Separator } from '@fluentui/react';
import React from 'react';
import { formGridLabel, formInputCardStyles } from '../styles/CommonStyleObjects';

interface IFormGridCardProps {
  children: JSX.Element;
  cardLabel: string | (() => JSX.Element);
}
const FormGridCard = (props: IFormGridCardProps): JSX.Element => {
  const { children, cardLabel } = props;
  return (
    <Stack styles={formInputCardStyles}>
      {typeof cardLabel === 'string' ? (
        <h2>
          <Label styles={formGridLabel}>{cardLabel}</Label>
        </h2>
      ) : (
        cardLabel()
      )}
      <Separator />
      {children}
    </Stack>
  );
};
export default FormGridCard;
