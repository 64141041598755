import React from 'react';
import {
  ColumnActionsMode,
  IColumn,
  IComboBoxOption,
  ICommandBarItemProps,
  IContextualMenuItem,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import {
  sharedEditButton,
  sharedIsActive,
  sharedLastModified,
} from '../../../common/lists/SharedDataGridColumns';
import { rowClasses } from '../../../common/styles/CommonStyleObjects';
import { IOpsOrg, IOpsOrgDetail, IOpsSummary } from '../../../utils/types/IHierarchy';
import FacepileWrapper from '../../../common/lists/FacepileWrapper';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';
import BulkEditMode from '../../../utils/types/IBulkEdit';

export const getOpsSummaryColumnsList = (
  onEditClick: (opsSummary: IOpsSummary) => void,
): IColumn[] => {
  return [
    sharedEditButton<IOpsSummary>(onEditClick, false, 'name'),
    {
      key: 'name',
      name: 'Ops summary',
      fieldName: 'name',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};

export const getOpsOrgColumnsList = (onEditClick: (IOpsOrg: IOpsOrg) => void): IColumn[] => {
  return [
    sharedEditButton<IOpsOrg>(onEditClick, false, 'name'),
    {
      key: 'name',
      name: 'Ops org',
      fieldName: 'name',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'opsSummary.name',
      name: 'Ops summary',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item) => {
        return <span>{item.opsSummary.name}</span>;
      },
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};

export const getOpsOrgDetailColumnsList = (
  onEditClick: (opsDetail: IOpsOrgDetail) => void,
  disabled: boolean,
): IColumn[] => {
  return [
    sharedEditButton<IOpsOrgDetail>(onEditClick, disabled, 'name'),
    {
      key: 'name',
      name: 'Ops detail',
      fieldName: 'name',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
      onRender(item?) {
        return <Link to={`/hierarchies/operations/details/${item?.id}`}>{item?.name}</Link>;
      },
    },
    {
      key: 'opsOrg.name',
      name: 'Ops org',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
      onRender(item?) {
        return <span>{item.opsOrg.name}</span>;
      },
    },
    {
      key: 'opsOrg.opsSummary.name',
      name: 'Ops summary',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
      onRender(item?) {
        return <span>{item.opsOrg.opsSummary.name}</span>;
      },
    },
    {
      key: 'companyCodes',
      name: 'Default co. code(s)',
      fieldName: 'companyCodes',
      maxWidth: 150,
      minWidth: 150,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return item.companyCodes
          ?.map((cc) => (cc.companyCode === 0 ? 'All Active' : cc.companyCode))
          .join(', ');
      },
    },
    {
      key: 'deadline',
      name: 'Deadline',
      maxWidth: 80,
      minWidth: 80,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return item.deadline;
      },
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    {
      key: 'autoPublish',
      name: 'Auto publish',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        const flag = item.autoPublish === true ? 'Yes' : 'No';
        return flag;
      },
    },
    {
      key: 'checklistOwners',
      name: 'Checklist owner(s)',
      fieldName: 'checklistOwners',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.checklistOwners} ariaLabel="Checklist owner(s) list" />;
      },
    },
    {
      key: 'backupTaskPerformers',
      name: 'Backup task performer(s)',
      fieldName: 'backupTaskPerformers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper
            users={item.backupTaskPerformers}
            ariaLabel="Backup task performers list"
          />
        );
      },
    },
    {
      key: 'backupJeReviewers',
      name: 'Backup JE reviewer(s)',
      fieldName: 'backupJeReviewers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper users={item.backupJeReviewers} ariaLabel="Backup JE reviewers list" />
        );
      },
    },
    {
      key: 'backupJePosters',
      name: 'Backup JE poster(s)',
      fieldName: 'backupJePosters',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupJePosters} ariaLabel="Backup JE posters list" />;
      },
    },
    {
      key: 'backupRiReconcilers',
      name: 'Backup reconciler(s)',
      fieldName: 'backupRiReconcilers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper users={item.backupRiReconcilers} ariaLabel="Backup JE posters list" />
        );
      },
    },
    {
      key: 'backupRiReviewers',
      name: 'Backup reviewer(s)',
      fieldName: 'backupRiReviewers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupRiReviewers} ariaLabel="Backup reviewers list" />;
      },
    },
    {
      key: 'backupRiApprovers',
      name: 'Backup approver(s)',
      fieldName: 'backupRiApprovers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupRiApprovers} ariaLabel="Backup approvers list" />;
      },
    },
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};
export const getFullViewColumnsList = (): IColumn[] => {
  return [
    {
      key: 'opsName',
      name: 'Name',
      fieldName: 'name',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'companyCodes',
      name: 'Default co. code(s)',
      fieldName: 'companyCodes',
      maxWidth: 150,
      minWidth: 150,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return item.companyCodes?.map((cc) => cc.companyCode).join(', ');
      },
    },
    {
      key: 'deadline',
      name: 'Deadline',
      maxWidth: 80,
      minWidth: 80,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return item.deadline;
      },
    },
    sharedIsActive<{ [key: string]: boolean }>('isActive'),
    {
      key: 'autoPublish',
      name: 'Auto publish',
      maxWidth: 100,
      minWidth: 100,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        const flag = item.autoPublish === true ? 'Yes' : 'No';
        return flag;
      },
    },
    {
      key: 'checklistOwners',
      name: 'Checklist owner(s)',
      fieldName: 'checklistOwners',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.checklistOwners} ariaLabel="Checklist owner(s) list" />;
      },
    },
    {
      key: 'backupTaskPerformers',
      name: 'Backup task performer(s)',
      fieldName: 'backupTaskPerformers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper
            users={item.backupTaskPerformers}
            ariaLabel="Backup task performers list"
          />
        );
      },
    },
    {
      key: 'backupJeReviewers',
      name: 'Backup JE reviewer(s)',
      fieldName: 'backupJeReviewers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper users={item.backupJeReviewers} ariaLabel="Backup JE reviewers list" />
        );
      },
    },
    {
      key: 'backupJePosters',
      name: 'Backup JE poster(s)',
      fieldName: 'backupJePosters',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupJePosters} ariaLabel="Backup JE posters list" />;
      },
    },
    {
      key: 'backupRiReconcilers',
      name: 'Backup reconciler(s)',
      fieldName: 'backupRiReconcilers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return (
          <FacepileWrapper users={item.backupRiReconcilers} ariaLabel="Backup reconcilers list" />
        );
      },
    },
    {
      key: 'backupRiReviewers',
      name: 'Backup reviewer(s)',
      fieldName: 'backupRiReviewers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupRiReviewers} ariaLabel="Backup reviewers list" />;
      },
    },
    {
      key: 'backupRiApprovers',
      name: 'Backup approver(s)',
      fieldName: 'backupRiApprovers',
      maxWidth: 200,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: IOpsOrgDetail) => {
        return <FacepileWrapper users={item.backupRiApprovers} ariaLabel="Backup approvers list" />;
      },
    },
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};
export enum HierarchiesPivotKeys {
  opsSummary = 'opssummary',
  opsOrg = 'opsOrg',
  opsDetail = 'opsDetail',
  fullView = 'fullView',
  application = 'application',
  perspective = 'perspective',
  dataSource = 'dataSource',
}
export interface IHierarchiesMenuProps {
  itemsSelected?: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onAddItem?: () => void;
  onRefreshClick?: () => void;
  onBulkEditClick?: (editMode: BulkEditMode) => void;
  exportButtonProps: IDownloadButtonProps;
}
const getHierarchiesMenu = (props: IHierarchiesMenuProps): ICommandBarItemProps[] => {
  const {
    itemsSelected,
    itemsSelectedCount,
    totalItemsCount,
    onAddItem,
    onRefreshClick,
    onBulkEditClick,
    exportButtonProps,
  } = props;
  const listCommandBarItems: ICommandBarItemProps[] = [];
  listCommandBarItems.push(
    {
      key: 'newItem',
      text: 'New Item',
      cacheKey: 'newItem', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onAddItem,
    },
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefreshClick,
    },
    {
      key: 'exportToExcel',
      text: 'Export to Excel',
      cacheKey: 'export', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            renderedInOverflow={item.renderedInOverflow}
            {...exportButtonProps}
          />
        );
      },
      fileEndpoint: exportButtonProps.fileEndpoint,
      fileName: exportButtonProps.fileName,
      postBody: exportButtonProps.postBody,
    },
  );
  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'editBulkMain',
      text: 'Bulk edit',
      cacheKey: 'editBulkMain', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Edit' },
      subMenuProps: {
        items: [
          {
            key: 'editBulk',
            text: `Bulk edit selected (${itemsSelectedCount})`,
            cacheKey: 'editBulk', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Edit' },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'editAll',
            text: `Bulk edit all filtered (${totalItemsCount})`,
            cacheKey: 'editAll', // changing this key will invalidate this item's cache
            iconProps: {
              iconName: 'TripleColumnEdit',
            },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
  ];

  return itemsSelected ? selectedCommandBarItems : listCommandBarItems;
};
export const hierarchyViewOptions: IComboBoxOption[] = [
  { key: 'operations', text: 'Operations' },
  { key: 'executivefunction', text: 'Executive Function' },
  { key: 'geographical', text: 'Geographical' },
  { key: 'deliverycenter', text: 'Delivery Center' },
  { key: 'companycodeassignment', text: 'Company Code Assignment' },
  { key: 'datasource', text: 'Data Source' },
];
export default getHierarchiesMenu;
