import { useMutation, useQuery } from '@apollo/client';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { ActionButton, Icon, Link, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ModifiedDetails from '../../../common/auditedEntity/ModifiedDetails';
import BackButton from '../../../common/buttons/BackButton';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import {
  bodyContentContainerAlternateBg,
  cardBackground,
} from '../../../common/styles/CommonStyleObjects';
import {
  GetPublishedTask,
  GetPublishedTaskActions,
  TaskChangeState,
  TriggerAutomationStart,
} from '../../../utils/api/PublishedTaskApi';
import config from '../../../utils/ConfigLoader';
import { IPublishedTask, ITaskRouteParams } from '../../../utils/types/ITask';
import { TaskChangeStateOption } from '../../../utils/types/ITaskChangeStateOption';
import TasksChangeState, { ChangeStateMode } from '../edit/TasksChangeState';
import PublishedTaskEditPanel from '../edit/PublishedTaskEditPanel';
import TaskDetailCards from './TaskDetailCards';
import FeatureFlagged from '../../featureFlags/FeatureFlagged';

const actionButtonPaddingStyles = { root: { padding: '0 10px' } };
const actionButtonIconStyles = {
  root: {
    color: CoherenceTheme.palette.themeSecondary,
  },
};

const PublishedTaskDetailPage: React.FunctionComponent = () => {
  const { publishedTaskId, fiscalYear, period } = useParams<ITaskRouteParams>();
  const { data, loading, error } = useQuery<{ publishedTask: IPublishedTask }>(GetPublishedTask, {
    variables: { publishedTaskId: parseInt(publishedTaskId, 10) },
  });

  const publishedTask = data?.publishedTask;

  const [updateTaskState, { loading: loadingStartTask, error: errorStartTask }] = useMutation(
    TaskChangeState,
    {
      refetchQueries: [
        { query: GetPublishedTask, variables: { publishedTaskId: publishedTask?.id } },
        { query: GetPublishedTaskActions, variables: { publishedTaskId: publishedTask?.id } },
      ],
    },
  );
  const [triggerAutomationStart, { loading: loadingStartAutomation, error: errorStartAutomation }] =
    useMutation(TriggerAutomationStart, {
      refetchQueries: [
        { query: GetPublishedTask, variables: { publishedTaskId: publishedTask?.id } },
        { query: GetPublishedTaskActions, variables: { publishedTaskId: publishedTask?.id } },
      ],
    });

  const [pageState, setPageState] = useState({
    taskChangesStateMode: null,
    editPanelOpen: false,
  });

  const onTaskStateChangeClick = (newState: TaskChangeStateOption): void => {
    if (newState !== publishedTask?.lastAction?.state) {
      const year = parseInt(fiscalYear, 10);
      const fiscalPeriod = parseInt(period, 10);

      updateTaskState({
        variables: {
          changeStateTaskDto: {
            selectedItems: [publishedTask.id],
            taskState: newState.toUpperCase(),
            fiscalYear: year,
            fiscalPeriod,
          },
        },
      });
    }
  };

  const onAutomationStartClick = (): void => {
    triggerAutomationStart({
      variables: {
        publishedTaskIdDto: {
          id: publishedTask?.id,
        },
      },
    });
  };
  const onEditClick = (): void => {
    setPageState({ taskChangesStateMode: null, editPanelOpen: true });
  };

  const closeEditPanel = (): void => {
    setPageState({ taskChangesStateMode: null, editPanelOpen: false });
  };

  const renderHeader = (): JSX.Element => {
    const taskType = 'Published Task';

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
          <BackButton ariaLabel={`Back to ${taskType} list`} backDepth={2}>
            <h1>{`${taskType}`}</h1>
          </BackButton>
          <ModifiedDetails
            modifiedOn={publishedTask?.modifiedOn}
            modifiedBy={publishedTask?.modifiedBy?.displayName}
          />
        </Stack>
      </Stack>
    );
  };
  const disableActionButtons = loading || error?.message !== undefined || publishedTask === null;
  return (
    <>
      <FullWidthHeader title={renderHeader} alternateBackground />
      <div className={bodyContentContainerAlternateBg}>
        <LoadingErrorMessage error={error} loading={loading} />
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
          styles={{ root: { marginLeft: '10px' } }}
        >
          <Stack.Item styles={cardBackground}>
            {publishedTask?.lastAction?.state === TaskChangeStateOption.NOT_STARTED && (
              <ActionButton
                disabled={disableActionButtons || loadingStartTask}
                iconProps={{ iconName: 'Play', styles: actionButtonIconStyles }}
                title="Start task"
                ariaLabel="Start task"
                styles={actionButtonPaddingStyles}
                onClick={() => {
                  onTaskStateChangeClick(TaskChangeStateOption.STARTED);
                }}
              >
                Start task
              </ActionButton>
            )}
            <ActionButton
              disabled={disableActionButtons}
              iconProps={{ iconName: 'ThumbnailView', styles: actionButtonIconStyles }}
              title="Change task state"
              ariaLabel="Change task state"
              styles={actionButtonPaddingStyles}
              onClick={() => {
                setPageState({
                  taskChangesStateMode: ChangeStateMode.ChangeStateSelected,
                  editPanelOpen: false,
                });
              }}
            >
              Change task state
            </ActionButton>

            <ActionButton
              disabled={disableActionButtons}
              iconProps={{ iconName: 'Edit' }}
              title="Edit Published Task"
              onClick={onEditClick}
              text="Edit task details"
            />
            <LoadingErrorMessage loading={loadingStartTask} error={errorStartTask} />
          </Stack.Item>
          <FeatureFlagged flagName="ProjectNileWorkflows">
            <>
              {publishedTask?.workflowId &&
                publishedTask?.lastAction?.state !== TaskChangeStateOption.STARTED_AUTOMATION && (
                  <Stack.Item>
                    {!loadingStartAutomation && !errorStartAutomation && (
                      <PrimaryButton
                        onClick={onAutomationStartClick}
                        text="Start automated workflow"
                        iconProps={{ iconName: 'Robot' }}
                        disabled={
                          publishedTask?.lastAction?.state !== TaskChangeStateOption.STARTED
                        }
                      />
                    )}
                    <LoadingErrorMessage
                      loading={loadingStartAutomation}
                      error={errorStartAutomation}
                    />
                  </Stack.Item>
                )}

              {publishedTask?.lastAction?.state === TaskChangeStateOption.STARTED_AUTOMATION && (
                <Link
                  href={
                    config?.settings?.automationPortalConfig?.baseUrl +
                    config?.settings?.automationPortalConfig?.pipelineRunsRoute +
                    publishedTask?.lastAutomationRun?.correlationId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to view progress in automation portal
                </Link>
              )}
            </>
          </FeatureFlagged>
          {publishedTask?.publishType === 'JEM_CHECKLIST' && (
            <Stack.Item>
              <Link
                href={config?.settings?.jemConfig?.siteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to visit JEM <Icon iconName="Diamond" aria-label="JEM Icon" />
              </Link>
            </Stack.Item>
          )}
        </Stack>
        <br />
        <TaskDetailCards task={publishedTask} />
      </div>
      {pageState.taskChangesStateMode !== null && (
        <TasksChangeState
          hideModal={() =>
            setPageState({
              taskChangesStateMode: null,
              editPanelOpen: false,
            })
          }
          selectedTasksCount={1}
          selectedTasks={[publishedTask?.id]}
          changeStateMode={pageState.taskChangesStateMode}
          onChangeStateComplete={() => {
            setPageState({
              taskChangesStateMode: null,
              editPanelOpen: false,
            });
          }}
          refetchPublishedTaskId={publishedTask?.id}
        />
      )}
      {pageState.editPanelOpen && (
        <PublishedTaskEditPanel closePanel={closeEditPanel} task={publishedTask} />
      )}
    </>
  );
};

export default PublishedTaskDetailPage;
