import { gql } from '@apollo/client';

const GetFunctionsSummaryTagsByFilter = gql`
  query FunctionsSummaries($keyword: String!) {
    functionsSummaries(keyword: $keyword) {
      id
      name
    }
  }
`;
export default GetFunctionsSummaryTagsByFilter;
