import { gql } from '@apollo/client';

const SAVED_FILTER_FRAGMENT = gql`
  fragment SavedFilter on SavedFilterDto {
    id
    name
    modifiedBy {
      displayName
      graphGuid
    }
    modifiedOn
    filterType
    filters {
      dataKey
      dataType
      title
      hideFromBar
      parentDataKey
      values {
        key
        name
      }
    }
    peopleSharedWith {
      displayName
      graphGuid
      alias
      emailAddress
    }
  }
`;

export const SAVED_FILTER_LIST_QUERY = gql`
  query savedFilters($filterType: FilterType) {
    savedFilters(filterType: $filterType) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;

export const CREATE_SAVED_FILTER_MUTATION = gql`
  mutation createSavedFilter($savedFilterInputDto: SavedFilterInputDtoInput!) {
    createSavedFilter(savedFilterInputDto: $savedFilterInputDto) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;

export const EDIT_SAVED_FILTER_MUTATION = gql`
  mutation editSavedFilter($savedFilterInputDto: SavedFilterInputDtoInput!) {
    editSavedFilter(savedFilterInputDto: $savedFilterInputDto) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;

export const EDIT_SAVED_FILTER_SHARING_MUTATION = gql`
  mutation editSavedFilterSharing($savedFilterShareInput: SavedFilterShareInput!) {
    editSavedFilterSharing(dto: $savedFilterShareInput) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;

export const DELETE_SAVED_FILTER_MUTATION = gql`
  mutation removeSavedFilter($savedFilterInputDto: SavedFilterDeleteInputDtoInput!) {
    removeSavedFilter(savedFilterInputDto: $savedFilterInputDto) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;

export const REMOVE_SHARED_FILTER_MUTATION = gql`
  mutation removeSelfFromSavedFilter($savedFilterId: Int!) {
    removeSelfFromSavedFilter(savedFilterId: $savedFilterId) {
      ...SavedFilter
    }
  }
  ${SAVED_FILTER_FRAGMENT}
`;
