import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdown,
  IDropdownOption,
  SearchBox,
} from '@fluentui/react';
import IFieldsetProps from '../../utils/types/IFieldsetProps';

const QueryBasedDropdown: React.FunctionComponent<IFieldsetProps> = (
  props: IFieldsetProps,
): JSX.Element => {
  const {
    id,
    label,
    ariaLabel,
    required,
    value,
    handleChange,
    valueKey,
    dropdownOptionsData,
    errors,
    errorMessage,
    width,
    disabled,
    getFocusOnRender,
  } = props;
  const [defaultKey, setDefaultKey] = useState<string>(value);
  const [searchText, setSearchText] = React.useState<string>('');
  const noOptions = dropdownOptionsData && dropdownOptionsData.length === 0;
  let dropdownOptions: IDropdownOption[] = [];

  const dropdownRef = useRef<IDropdown>(null);

  useEffect(() => {
    if (getFocusOnRender && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, []);

  if (dropdownOptionsData) {
    dropdownOptions = dropdownOptionsData.map((dropdownOption): IDropdownOption => {
      return {
        ...(dropdownOption as IDropdownOption),
        ariaLabel: dropdownOption.text === '--' ? 'None' : dropdownOption.text,
      };
    });
  }
  const renderOption = (option: IDropdownOption): JSX.Element => {
    return option.itemType === DropdownMenuItemType.Header && option.key === 'FilterHeader' ? (
      <SearchBox
        onChange={(_ev, newValue) => setSearchText(newValue)}
        placeholder="Search"
        underlined
      />
    ) : (
      <>{option.text} </>
    );
  };
  const getErrors = (): string => {
    if (errors && errors[valueKey]) {
      return errors[valueKey].message;
    }
    return '';
  };

  const styles = {
    root: { width: width || 300 },
    dropdownItemHeader: { color: CoherenceTheme.palette.themePrimary },
  };
  const dropdownWrapperStyle: CSSProperties = {
    display: 'flex',
  };

  useEffect(() => {
    setDefaultKey(value || null);
  }, [value]);
  return (
    <div style={dropdownWrapperStyle}>
      <Dropdown
        id={id}
        componentRef={getFocusOnRender ? dropdownRef : null}
        label={label}
        ariaLabel={ariaLabel === 'Field' ? 'Field' : label || 'Value'}
        required={required}
        selectedKey={defaultKey}
        onChange={(event, item): void => {
          setDefaultKey(item.key as string);
          handleChange(item, valueKey);
        }}
        disabled={disabled || noOptions}
        placeholder={noOptions ? 'There are no options' : 'Select an option'}
        options={[
          { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
          ...dropdownOptions.map((option) =>
            !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              ? option
              : { ...option, hidden: true },
          ),
        ]}
        onRenderOption={renderOption}
        onDismiss={() => setSearchText('')}
        styles={styles}
        errorMessage={errorMessage || getErrors()}
        calloutProps={{
          calloutMaxHeight: 215,
          calloutMinWidth: 260,
        }}
      />
    </div>
  );
};
export default QueryBasedDropdown;
