import { ActionButton } from '@fluentui/react';
import React from 'react';
import { showMoreActionButtonStyles } from '../styles/CommonStyleObjects';
import FormGridColumn, { FormGridColumnWidth } from './FormGridColumn';

interface IFormGridShowMoreProps {
  expanded: boolean;
  onClick: () => void;
  count?: number;
}
const FormGridShowMore = (props: IFormGridShowMoreProps): JSX.Element => {
  const { count, expanded, onClick } = props;
  const actionButtonText = count
    ? `${expanded ? '- Hide' : '+ Show'} optional fields (${count})`
    : `${expanded ? '- Hide' : '+ Show'} optional fields`;
  return (
    <FormGridColumn columnWidth={FormGridColumnWidth.Single} centerText>
      <ActionButton styles={showMoreActionButtonStyles} text={actionButtonText} onClick={onClick} />
    </FormGridColumn>
  );
};
export default FormGridShowMore;
