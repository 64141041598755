import React from 'react';
import { CoherenceHeader, FarRightSettings } from '@coherence-design-system/controls';
import { IGraphUserObject } from '../utils/types/GraphUser';
import HelpPanel from './headers/HelpPanel';
import ToastContainer from './notifications/ToastContainer';
import { NotificationConsumer } from './notifications/NotificationsContext';

interface IAppHeaderProps {
  onLogOut: () => void;
  graphProfileData: IGraphUserObject;
  imageUrl: string;
}

const AppHeaders = ({ onLogOut, graphProfileData, imageUrl }: IAppHeaderProps): JSX.Element => {
  const name = graphProfileData?.displayName || '';
  const email = graphProfileData?.mail?.toLowerCase() || '';
  const farRightSettings: FarRightSettings = {
    helpSettings: {
      panelSettings: {
        titleText: 'Help',
        body: HelpPanel,
        hasCloseButton: true,
        closeButtonAriaLabel: 'Close Panel',
      },
      buttonSettings: {
        title: 'Help',
        ariaLabel: 'Help',
      },
    },
    profileSettings: {
      panelSettings: {
        fullName: name,
        emailAddress: email,
        imageUrl,
        onLogOut,
        logOutLink: '#',
        hasCloseButton: true,
        closeButtonAriaLabel: 'Close Panel',
        viewAccountLink: '/useraccount',
        hideSignOutDivider: true,
      },
      buttonSettings: {
        title: 'Profile',
        ariaLabel: 'Profile',
      },
    },
  };

  farRightSettings.notificationsSettings = {
    panelSettings: {
      titleText: 'Notifications',
      items: [],
      newNotifications: 0,
      updateItem: () => {},
      coherencePanelStyles: {},
    },
  };

  return (
    <div>
      <CoherenceHeader
        headerLabel="FCCW Banner"
        appNameSettings={{
          label: 'FCCW',
          linkUrl: '/',
        }}
        farRightSettings={farRightSettings}
        dismissOpenedPanel
        onDismissOpenedPanel={(): void => {}}
      />
      <NotificationConsumer>
        {({ toasts }) => <ToastContainer toasts={toasts} />}
      </NotificationConsumer>
    </div>
  );
};

export default AppHeaders;
