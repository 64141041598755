import React from 'react';
import { ISavedFilter } from '../../utils/types/SavedFilters';

export interface ISavedFilterContext {
  savedFilter: ISavedFilter;
  setSavedFilter: (_savedFilter: ISavedFilter) => void;
}

const SavedFilterContext = React.createContext<ISavedFilterContext>({
  savedFilter: {} as ISavedFilter,
  setSavedFilter: (_savedFilter: ISavedFilter) => {},
});

export const SavedFilterProvider = SavedFilterContext.Provider;

export default SavedFilterContext;
