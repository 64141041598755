import IFrequencyChoices from '../types/IFrequencyChoices';

const generateFrequencyCode = (frequencyChoices: IFrequencyChoices) => {
  let newFrequencyCode = '';
  newFrequencyCode += frequencyChoices.repeat ? `${frequencyChoices?.repeat?.[0]}=` : '';
  const startPeriodSelection = frequencyChoices?.startPeriodSelection;
  newFrequencyCode +=
    startPeriodSelection?.startPeriod && startPeriodSelection?.startYear
      ? `P${startPeriodSelection.startPeriod}FY${startPeriodSelection.startYear
          .toString()
          .substring(2)}`
      : '';
  newFrequencyCode += frequencyChoices?.weeklyCadence?.[0] ?? '';
  newFrequencyCode += newFrequencyCode.length ? ':' : '';
  const calendarCodes: string[] = [
    ...(frequencyChoices?.closeDaySelection ?? []),
    ...(frequencyChoices?.rasCloseDaySelection ?? []),
    ...(frequencyChoices?.calendarDaySelection ?? []),
    ...(frequencyChoices?.workingDaySelection ?? []),
    ...(frequencyChoices?.variableDaySelection ?? []),
  ];
  newFrequencyCode += calendarCodes.join(',');
  const chosenDays: string[] = [];
  if (frequencyChoices?.weeklyCadenceDayMonChecked) chosenDays.push('Mo');
  if (frequencyChoices?.weeklyCadenceDayTueChecked) chosenDays.push('Tu');
  if (frequencyChoices?.weeklyCadenceDayWedChecked) chosenDays.push('We');
  if (frequencyChoices?.weeklyCadenceDayThuChecked) chosenDays.push('Th');
  if (frequencyChoices?.weeklyCadenceDayFriChecked) chosenDays.push('Fr');
  if (frequencyChoices?.weeklyCadenceDaySatChecked) chosenDays.push('Sa');
  if (frequencyChoices?.weeklyCadenceDaySunChecked) chosenDays.push('Su');
  newFrequencyCode += chosenDays.length ? chosenDays.join(',') : '';
  return newFrequencyCode;
};

export const deconstructFrequencyCode = (frequencyCode: string): string[] => {
  if (!frequencyCode?.length) return [];
  const initialIndex = frequencyCode.indexOf(':') + 1;
  if (initialIndex >= frequencyCode.length) return [];
  const code = frequencyCode.substring(initialIndex >= 0 ? initialIndex : 0);
  return code.split(',');
};

export default generateFrequencyCode;
