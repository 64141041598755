import { gql } from '@apollo/client';

const OPS_SUMMARY_FRAGMENT = gql`
  fragment OpsSummary on OpsSummaryDto {
    id
    name
  }
`;

const OPS_SUMMARY_NESTED_FRAGMENT = gql`
  fragment OpsSummaryNested on OpsOrgDto {
    opsSummary {
      ...OpsSummary
    }
  }
  ${OPS_SUMMARY_FRAGMENT}
`;

const OPS_ORG_FRAGMENT = gql`
  fragment OpsOrg on OpsOrgDetailDto {
    opsOrg {
      id
      name
      ...OpsSummaryNested
    }
  }
  ${OPS_SUMMARY_NESTED_FRAGMENT}
`;

const OPS_SUMMARY_FULL_FRAGMENT = gql`
  fragment OpsSummaryFull on OpsSummaryDto {
    id
    name
    isActive
    modifiedOn
    modifiedBy {
      graphGuid
      displayName
      alias
      emailAddress
    }
  }
`;

const OPS_ORG_FULL_FRAGMENT = gql`
  fragment OpsOrgFull on OpsOrgDto {
    id
    name
    ...OpsSummaryNested
    isActive
    modifiedOn
    modifiedBy {
      graphGuid
      displayName
      alias
      emailAddress
    }
  }
  ${OPS_SUMMARY_NESTED_FRAGMENT}
`;

// base fragment should be uses in entities
// that return ops detail data but dont need
// the entire dependency tree. ie: dropdowns, tabular
// data references in staged tasks
export const OPS_DETAIL_BASE_FRAGMENT = gql`
  fragment OpsDetailBase on OpsOrgDetailDto {
    id
    name
    ...OpsOrg
    isActive
    deadline
    autoPublish
    modifiedOn
    modifiedBy {
      graphGuid
      displayName
      alias
      emailAddress
    }
  }
  ${OPS_ORG_FRAGMENT}
`;
export const OPS_DETAIL_FULL_FRAGMENT = gql`
  fragment OpsDetailFull on OpsOrgDetailDto {
    id
    name
    ...OpsOrg
    isActive
    deadline
    autoPublish
    modifiedOn
    modifiedBy {
      graphGuid
      displayName
      alias
      emailAddress
    }
    companyCodes {
      name
      companyCode
      countryCode
    }
    checklistOwners {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupTaskPerformers {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupJeReviewers {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupJePosters {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupRiReconcilers {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupRiReviewers {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
    backupRiApprovers {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
  }
  ${OPS_ORG_FRAGMENT}
`;

export const GetOpsSummaryList = gql`
  query OpsSummaries(
    $keyword: String
    $filters: OpsSummaryFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [OpsSummaryDtoSortInput!]
  ) {
    opsSummaries(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...OpsSummaryFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${OPS_SUMMARY_FULL_FRAGMENT}
`;
export const GetOpsSummaryListSearch = gql`
  query OpsSummaryFilterSearch($keyword: String!) {
    opsSummaryNames(keyword: $keyword) {
      id
      name
    }
  }
`;
export const GetOpsOrgList = gql`
  query OpsOrgs(
    $keyword: String
    $filters: OpsOrgFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [OpsOrgDtoSortInput!]
  ) {
    opsOrgs(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...OpsOrgFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${OPS_ORG_FULL_FRAGMENT}
`;
export const GetOpsOrgListSearch = gql`
  query OpsOrgFilter($keyword: String!) {
    opsOrgNames(keyword: $keyword) {
      id
      name
    }
  }
`;

export const GetOpsOrgDetailList = gql`
  query OpsOrgDetails(
    $keyword: String
    $filters: OpsOrgDetailFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [OpsOrgDetailDtoSortInput!]
  ) {
    opsOrgDetails(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...OpsDetailFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
export const GetAllOpsOrgDetails = gql`
  query OpsOrgDetailsList($order: [OpsOrgDetailDtoSortInput!]) {
    opsOrgDetailsList(order: $order) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
export const GetOpsOrgDetailListSearch = gql`
  query OpsOrgDetailsFilter($keyword: String!) {
    opsDetailNames(keyword: $keyword) {
      id
      name
    }
  }
`;
export const GetOpsSummary = gql`
  query OpsSummaryById($summaryId: Int!) {
    opsSummaryById(summaryId: $summaryId) {
      id
      name
      isActive
    }
  }
`;

export const GetOpsOrg = gql`
  query OpsOrgById($opsOrgId: Int!) {
    opsOrgById(opsOrgId: $opsOrgId) {
      ...OpsOrgFull
    }
  }
  ${OPS_ORG_FULL_FRAGMENT}
`;

export const GetOpsOrgDetail = gql`
  query OpsOrgDetailById($opsOrgDetailId: Int!) {
    opsOrgDetailById(opsOrgDetailId: $opsOrgDetailId) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;

export const AddOpsSummary = gql`
  mutation addOpsSummary($opsSummaryDto: OpsSummaryInputDtoInput!) {
    addOpsSummary(opsSummaryDto: $opsSummaryDto) {
      ...OpsSummaryFull
    }
  }
  ${OPS_SUMMARY_FULL_FRAGMENT}
`;

export const EditOpsSummaryValue = gql`
  mutation editOpsSummaryValue($opsSummaryDto: OpsSummaryInputDtoInput!) {
    editOpsSummaryValue(opsSummaryDto: $opsSummaryDto) {
      ...OpsSummaryFull
    }
  }
  ${OPS_SUMMARY_FULL_FRAGMENT}
`;
export const EditOpsOrgValue = gql`
  mutation editOpsOrgValue($opsOrgDto: OpsOrgInputDtoInput!) {
    editOpsOrgValue(opsOrgDto: $opsOrgDto) {
      ...OpsOrgFull
    }
  }
  ${OPS_ORG_FULL_FRAGMENT}
`;

export const EditOpsOrgDetailValue = gql`
  mutation editOpsOrgDetailValue($opsOrgDetailDto: OpsOrgDetailInputDtoInput!) {
    editOpsOrgDetailValue(opsOrgDetailDto: $opsOrgDetailDto) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
export const AddOpsOrgValue = gql`
  mutation addOpsOrgValue($opsOrgDto: OpsOrgInputDtoInput!) {
    addOpsOrgValue(opsOrgDto: $opsOrgDto) {
      ...OpsOrgFull
    }
  }
  ${OPS_ORG_FULL_FRAGMENT}
`;
export const AddOpsOrgDetailValue = gql`
  mutation addOpsOrgDetailValue($opsOrgDetailDto: OpsOrgDetailInputDtoInput!) {
    addOpsOrgDetailValue(opsOrgDetailDto: $opsOrgDetailDto) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
export const EditOpsOrgDetailBulk = gql`
  mutation editOpsOrgDetailBulk($itemBulkDto: OpsOrgDetailBulkInputDtoInput!) {
    editOpsOrgDetailBulk(itemBulkDto: $itemBulkDto) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
export const EditOpsOrgDetailRemoveBulk = gql`
  mutation editOpsOrgDetailRemoveBulk($itemBulkDto: OpsOrgDetailBulkInputDtoInput!) {
    editOpsOrgDetailRemoveBulk(itemBulkDto: $itemBulkDto) {
      ...OpsDetailFull
    }
  }
  ${OPS_DETAIL_FULL_FRAGMENT}
`;
