import React from 'react';
import { Switch } from 'react-router-dom';
import PublishedTaskListPage from './list/PublishedTaskListPage';
import PublishedTaskFiscalYearListPage from './list/PublishedTaskFiscalYearListPage';
import PublishedTaskNew from './details/PublishedTaskNew';
import PublishedTaskDetailPage from './details/PublishedTaskDetailPage';
import PublishedTaskNewFromCopy from './details/PublishedTaskNewFromCopy';
import Page from '../../common/page/Page';
import PublishedAllTaskListPage from './list/PublishedAllTaskListPage';
import PublishedAllAAGTaskListPage from './list/PublishedAllAAGTaskListPage';

const PublishedTaskRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Page
          path="/tasks/:fiscalYear/:period/details/:publishedTaskId"
          component={PublishedTaskDetailPage}
          title="Task Detail"
          requestedPermission="ViewPublishedTask"
        />
        <Page
          path="/tasks/:fiscalYear/:period/new"
          component={PublishedTaskNew}
          title="New Task"
          requestedPermission="AddPublishedTask"
        />
        <Page
          path="/tasks/:fiscalYear/:period/:publishedTaskId/new"
          component={PublishedTaskNewFromCopy}
          title="New Task (from copy)"
          requestedPermission="AddPublishedTask"
        />
        <Page
          exact
          path="/tasks/:fiscalYear/:period"
          component={PublishedTaskListPage}
          title="Task List"
          requestedPermission="ViewPublishedTask"
        />
        <Page
          path="/tasks/all/details/:publishedTaskId"
          component={PublishedTaskDetailPage}
          title="Task Detail"
          requestedPermission="ViewPublishedTask"
        />
        <Page
          exact
          path="/tasks/all"
          component={PublishedAllTaskListPage}
          title="Task List"
          requestedPermission="ViewPublishedTask"
        />

        <Page
          path="/tasks/level3/details/:publishedTaskId"
          component={PublishedTaskDetailPage}
          title="Task Detail"
          requestedPermission="ViewPublishedTask"
        />
        <Page
          exact
          path="/tasks/level3"
          component={PublishedAllAAGTaskListPage}
          title="Task List"
          requestedPermission="ViewPublishedTask"
        />
        <Page
          path="/tasks"
          component={PublishedTaskFiscalYearListPage}
          title="Fiscal Summary Task List"
          requestedPermission="ViewPublishedTask"
        />
      </Switch>
    </>
  );
};

export default PublishedTaskRoutes;
