import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import { GetOpsSummaryList } from '../../../../utils/api/HierarchiesApi';
import { IOpsSummary } from '../../../../utils/types/IHierarchy';
import PanelTypes from '../../../../utils/types/PanelTypes';
import OpsSummaryPanel from './OpsSummaryPanel';

interface IOpsSummaryEditProps {
  opsSummary: IOpsSummary;
  closePanel: (shouldRefresh: boolean) => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const OpsSummaryEdit = (props: IOpsSummaryEditProps): JSX.Element => {
  const { closePanel, opsSummary, mutation, panelMode } = props;

  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetOpsSummaryList }];

  const [updateOpsSummaryValue, { loading, error }] = useMutation(mutation, {
    onCompleted: () => closePanel(true),
    refetchQueries,
  });

  const submitForm = (opsSummaryInputData: IOpsSummary) => {
    updateOpsSummaryValue({
      variables: {
        opsSummaryDto: {
          id: opsSummary.id,
          name: opsSummaryInputData.name,
          isActive: opsSummaryInputData.isActive,
        },
      },
    });
  };

  return (
    <OpsSummaryPanel
      data={opsSummary}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default OpsSummaryEdit;
