import React from 'react';
import { Checkbox, Panel, PanelType, Stack, Text } from '@fluentui/react';
import { DashboardCardType, IDashboardCardConfig } from '../../home/Home';
import FeatureFlagged from '../../featureFlags/FeatureFlagged';

interface IDashboardSettingsPanelProps {
  dashboardCardConfigs: IDashboardCardConfig[];
  onChange: (cardType: DashboardCardType, checked: boolean) => void;
  onClose: () => void;
}

const helpSectionHeaderStyles = {
  root: { fontWeight: '600', paddingBottom: 10 },
};

const DashboardSettingsPanel = ({
  dashboardCardConfigs,
  onChange,
  onClose,
}: IDashboardSettingsPanelProps): JSX.Element => {
  return (
    <>
      <Panel
        title="Dashboard Settings"
        headerText="Dashboard Settings"
        onDismiss={onClose}
        onLightDismissClick={onClose}
        isOpen
        isLightDismiss
        type={PanelType.medium}
        isFooterAtBottom
      >
        <Stack>
          <Text variant="mediumPlus" styles={helpSectionHeaderStyles}>
            Active Cards
          </Text>
          <Stack />
          <Stack tokens={{ childrenGap: 10 }}>
            {dashboardCardConfigs?.map((cardConfig, index) => {
              return (
                <FeatureFlagged flagName={cardConfig?.featureFlag}>
                  <Checkbox
                    label={cardConfig?.name}
                    defaultChecked={cardConfig?.visible}
                    onChange={(_, checked) => onChange(cardConfig?.type, checked)}
                  />
                </FeatureFlagged>
              );
            })}
          </Stack>
        </Stack>
      </Panel>
    </>
  );
};
export default DashboardSettingsPanel;
