import React from 'react';

export interface Toast {
  className?: string;
  id: number;
  header?: string;
  content: string;
  iconName?: string;
}

interface INotificationsContext {
  toasts: Toast[];
  addToast: (toastItem: Toast) => void;
}

const NotificationContext = React.createContext<INotificationsContext>({
  toasts: [],
  addToast: (toastItem: Toast) => {},
});

export const NotificationProvider = NotificationContext.Provider;
export const NotificationConsumer = NotificationContext.Consumer;

export default NotificationContext;
