import { useQuery } from '@apollo/client';
import {
  CoherencePanel,
  CoherenceTimeline,
  DateRange,
  ITimelineEvent,
} from '@coherence-design-system/controls';
import { Stack } from '@fluentui/react';
import moment from 'moment';
import React, { useState } from 'react';
import SpanWithLabel from '../../../common/cardContents/SpanWithLabel';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { GetPublishedTaskActions } from '../../../utils/api/PublishedTaskApi';
import { DateConstants } from '../../../utils/formatters/DateConstants';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import { IPublishedTaskAction, ITaskStateReason } from '../../../utils/types/IPublishedTaskAction';
import { getStateIcon } from '../PublishedTasks.config';

const spanStyles: React.CSSProperties = {
  marginTop: 3,
};

interface IPublishedTaskActionsProps {
  publishedTaskId: number;
}

const PublishedTaskActions = ({ publishedTaskId }: IPublishedTaskActionsProps): JSX.Element => {
  const [selectedAction, setSelectedAction] = useState<IPublishedTaskAction>();
  const { data, loading, error } = useQuery(GetPublishedTaskActions, {
    variables: { publishedTaskId },
    fetchPolicy: 'network-only',
  });

  const clearSelectedAction = (): void => {
    setSelectedAction(null);
  };

  let sumOfTimeTaken = 0;
  let sumOfAutoTimeTaken = 0;
  let sumOfManualTimeTaken = 0;

  const eventStyles = {
    root: { whiteSpace: 'pre-line' },
  };
  const timelineData: ITimelineEvent[] = data?.publishedTaskActions?.map(
    (pta: IPublishedTaskAction) => {
      const iconProps = getStateIcon(pta.state);
      sumOfTimeTaken += pta.manualTime + pta.automatedTime;
      sumOfAutoTimeTaken += pta.automatedTime;
      sumOfManualTimeTaken += pta.manualTime;
      const ptaNotes = pta?.notes ? `Notes: ${pta.notes}` : '';
      const manualTime = pta?.manualTime ? `Manual Time: ${pta?.manualTime} minutes\n` : '';
      const autoTime = pta?.automatedTime ? `Automated Time: ${pta?.automatedTime} minutes\n` : '';

      return {
        eventIcon: { iconName: iconProps.iconName },
        subject: `${enumFormatter(pta.state)} by ${pta?.modifiedBy?.displayName}`,
        body: `${manualTime}${autoTime}${ptaNotes}`,
        timestamp: new Date(pta.modifiedOn),
        actions: [
          {
            text: 'More Details',
            onClick: (): void => {
              setSelectedAction(pta);
            },
          },
        ],
      };
    },
  );

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {!loading && !error && (
        <>
          <span style={spanStyles}>Sum of time taken entered</span>
          <span style={spanStyles}>
            <span style={{ fontWeight: 'bold' }}>Total: </span>
            {`${sumOfTimeTaken} minutes`}
          </span>
          <span style={spanStyles}>
            <span style={{ fontWeight: 'bold' }}>Manual: </span>
            {`${sumOfManualTimeTaken} minutes`}
          </span>
          <span style={spanStyles}>
            <span style={{ fontWeight: 'bold' }}>Auto: </span>
            {`${sumOfAutoTimeTaken} minutes`}
          </span>
          <CoherenceTimeline
            events={timelineData}
            enablePagination={false}
            styles={eventStyles}
            dateFilterDropdown={{
              options: [
                {
                  key: DateRange.TODAY,
                  text: 'Today',
                },
                {
                  key: DateRange.LAST_WEEK,
                  text: 'Last week',
                },
                {
                  key: DateRange.CURRENT_MONTH,
                  text: 'Current month',
                },
                {
                  key: DateRange.ALL_TIME,
                  text: 'All time',
                },
              ],
              selectedKey: DateRange.ALL_TIME,
            }}
          />
        </>
      )}
      {selectedAction && (
        <CoherencePanel isOpen onDismiss={clearSelectedAction} titleText="Task Action">
          <Stack tokens={{ childrenGap: 10 }}>
            <SpanWithLabel labelText="Action" value={enumFormatter(selectedAction?.state)} />
            <SpanWithLabel
              labelText="Performed by"
              value={selectedAction?.modifiedBy?.displayName}
            />
            <SpanWithLabel
              labelText="Performed on"
              value={
                selectedAction?.modifiedOn
                  ? moment(selectedAction?.modifiedOn).format(DateConstants.DayBreakoutWithTime)
                  : '--'
              }
            />
            <span style={spanStyles}>Time Taken</span>
            <span style={spanStyles}>
              <span style={{ fontWeight: 'bold' }}>Total: </span>
              {`${selectedAction?.manualTime + selectedAction?.automatedTime} minutes`}
            </span>
            <span style={spanStyles}>
              <span style={{ fontWeight: 'bold' }}>Manual: </span>
              {`${selectedAction?.manualTime} minutes`}
            </span>
            <span style={spanStyles}>
              <span style={{ fontWeight: 'bold' }}>Auto: </span>
              {`${selectedAction?.automatedTime} minutes`}
            </span>
            <SpanWithLabel labelText="Notes" value={selectedAction?.notes} />
            {selectedAction?.reasons.map((reason: ITaskStateReason): JSX.Element => {
              return (
                <SpanWithLabel
                  labelText={enumFormatter(reason.reasonType)}
                  value={reason.reasonSelection}
                />
              );
            })}
          </Stack>
        </CoherencePanel>
      )}
    </>
  );
};

export default PublishedTaskActions;
