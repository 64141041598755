import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import { bodyContentContainer } from '../../../common/styles/CommonStyleObjects';
import { GetTaskProperty } from '../../../utils/api/TaskPropertiesApi';
import { IHierarchiesRouteParams } from '../../../utils/types/IHierarchy';
import { ITaskProperty, ITaskPropertyValue } from '../../../utils/types/ITaskProperty';
import TaskPropertyAdd from '../details/taskProperties/TaskPropertiesAdd';
import TaskPropertiesEdit from '../details/taskProperties/TaskPropertiesEdit';
import { getTaskPropertyColumnList, getTaskPropertiesMenu } from './TaskPropertyList.config';

const TaskPropertyListPage = () => {
  const { hierarchyViewId } = useParams<IHierarchiesRouteParams>();
  const { data, loading, error, refetch } = useQuery<{ taskProperty: ITaskProperty }>(
    GetTaskProperty,
    {
      variables: { taskPropertyId: parseInt(hierarchyViewId, 10) },
    },
  );
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [showAddPanel, setShowAddPanel] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number>(null);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const detailsListConfig: IGeneralEntityListProps<ITaskPropertyValue> = {
    data: data?.taskProperty?.values || [],
    listColumns: getTaskPropertyColumnList((item: ITaskPropertyValue) => {
      setShowEditPanel(true);
      setActiveItem(item.id);
    }),
    commandBarItems: getTaskPropertiesMenu(
      () => {
        setShowAddPanel(true);
      },
      () => {
        setIsRefreshing(true);
        refetch().then(() => {
          setIsRefreshing(false);
        });
      },
      {
        key: `ExportTaskProperty${hierarchyViewId}`,
        fileEndpoint: `TaskValueProperties?taskPropertyId=${data?.taskProperty?.id}`,
        fileName: data?.taskProperty?.name,
      },
    ),
    disableSelect: true,
    loading: loading || isRefreshing,
    error,
  };
  const findItemById = (id: number): ITaskPropertyValue => {
    return data?.taskProperty?.values?.find((tpv) => {
      return tpv.id === activeItem;
    });
  };
  return (
    <div className={`${bodyContentContainer}`}>
      {GeneralEntityList(detailsListConfig)}
      {showEditPanel && (
        <TaskPropertiesEdit
          data={findItemById(activeItem)}
          parentGuid={data?.taskProperty?.id as string}
          closePanel={() => {
            setActiveItem(null);
            setShowEditPanel(false);
          }}
          propertyName={data?.taskProperty?.name as string}
        />
      )}
      {showAddPanel && (
        <TaskPropertyAdd
          parentGuid={data?.taskProperty?.id as string}
          closePanel={() => {
            setShowAddPanel(false);
          }}
          propertyName={data?.taskProperty?.name as string}
        />
      )}
    </div>
  );
};

export default TaskPropertyListPage;
