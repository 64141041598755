import { MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';
import FeatureFlagged from '../../components/featureFlags/FeatureFlagged';
import { AppConfigValueConsumer } from '../../utils/appConfigValues/AppConfigValuesContext';

const OutageBanner = (): JSX.Element => {
  return (
    <FeatureFlagged flagName="OutageBanner">
      <AppConfigValueConsumer>
        {({ outageBannerMessage }) => (
          <MessageBar messageBarType={MessageBarType.warning}>{outageBannerMessage}</MessageBar>
        )}
      </AppConfigValueConsumer>
    </FeatureFlagged>
  );
};

export default OutageBanner;
