import { useMutation, useQuery } from '@apollo/client';
import {
  ActionButton,
  DefaultButton,
  IButtonStyles,
  Icon,
  Link,
  NeutralColors,
  Stack,
} from '@fluentui/react';
import { Card, CardStandardHeader } from '@coherence-design-system/controls';
import React, { useState } from 'react';
import { GET_USER_QUERY_WITH_POLICIES, REFRESH_EUA_PERMISSIONS } from '../../utils/api/UsersApi';
import PageContainer from '../../common/page/PageContainer';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import StackColumn from '../../common/cardContents/StackColumn';
import SpanWithLabel from '../../common/cardContents/SpanWithLabel';
import { IFccwUserInfo } from '../../utils/types/IUser';
import config from '../../utils/ConfigLoader';
import RoleCard from './RoleCard';
import RoleInfoPanel from './RoleInfoPanel';
import { IFcwPolicy } from '../../utils/types/IFcwPolicy';
import InformationCard from './InformationCard';

const UserDebug = (): JSX.Element => {
  const { data, loading, error } = useQuery(GET_USER_QUERY_WITH_POLICIES, {
    fetchPolicy: 'cache-and-network',
  });

  const [refreshCache, { loading: refreshLoading, error: refreshError }] = useMutation(
    REFRESH_EUA_PERMISSIONS,
    {
      refetchQueries: [GET_USER_QUERY_WITH_POLICIES],
    },
  );

  const [isRoleInfoPanelOpen, setIsRoleInfoPanelOpen] = useState(false);

  const toggleRoleInfoPanel = () => {
    setIsRoleInfoPanelOpen(!isRoleInfoPanelOpen);
  };

  const userInfo: IFccwUserInfo = data?.currentUserInfo;
  const userPolicies: IFcwPolicy[] = data?.currentUserPolicies;

  const onRefreshClick = (): void => {
    refreshCache();
  };

  const cardStyles = {
    root: {
      background: NeutralColors.white,
      height: 'fit-content',
    },
  };

  const linkButtonStyles: IButtonStyles = {
    root: {
      color: '#0078d4',
      cursor: 'pointer',
    },
    rootHovered: {
      textDecoration: 'underline',
    },
  };

  return (
    <>
      <PageContainer title="User Account Information">
        <LoadingErrorMessage loading={loading} error={error} />
        <LoadingErrorMessage loading={refreshLoading} error={refreshError} />
        {userInfo && (
          <>
            <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Name details" />}>
              <Stack horizontal wrap horizontalAlign="space-between">
                <StackColumn>
                  <SpanWithLabel labelText="Display name" value={userInfo.displayName} />
                  <SpanWithLabel labelText="Alias" value={userInfo.alias} />
                </StackColumn>
                <StackColumn>
                  <SpanWithLabel labelText="Graph ID" value={userInfo.graphGuid} />
                </StackColumn>
              </Stack>
            </Card>
            <br />
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <h2>EUA Roles</h2>
                <DefaultButton
                  iconProps={{ iconName: 'Refresh' }}
                  text="Refresh"
                  onClick={onRefreshClick}
                />
              </Stack>
              <Link href={config?.settings?.euaUrl} target="_blank" rel="noopener noreferrer">
                Click here to visit EUA <Icon iconName="Unlock" aria-label="EUA Icon" />
              </Link>
            </Stack>
            {userInfo?.euaClaims?.map((role) => {
              return <RoleCard key={role.roleId} role={role} />;
            })}

            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
              <h2>FCCW Roles</h2>
              <Link
                href="https://microsoft.sharepoint.com/teams/FCCW/SitePages/Access-for-FCCW-Explained.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for more details <Icon iconName="Info" aria-label="Information Icon" />
              </Link>
            </Stack>
            {userInfo?.fcwClaims?.map((role) => {
              return <RoleCard key={role.roleId} role={role} />;
            })}
            {userInfo?.fcwClaims?.length === 0 && <p>No current FCCW Claims.</p>}
          </>
        )}
        {userPolicies && (
          <>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
              <h2>Policies</h2>
              <ActionButton
                text="View policies for all roles"
                onClick={toggleRoleInfoPanel}
                styles={linkButtonStyles}
              />
            </Stack>
            <InformationCard cardTitle="My Policies" commandBarItems={[]} allCollapsed={false}>
              <ul>
                {userPolicies?.map((policy) => {
                  return <li>{policy.name}</li>;
                })}
              </ul>
            </InformationCard>
            {userPolicies?.length === 0 && <p>No current policies.</p>}
          </>
        )}
      </PageContainer>
      <RoleInfoPanel isOpen={isRoleInfoPanelOpen} onDismiss={() => setIsRoleInfoPanelOpen(false)} />
    </>
  );
};

export default UserDebug;
