import { IColumn, ICommandBarItemProps, IContextualMenuItem } from '@fluentui/react';
import React from 'react';
import { ILocalStorageColumnConfig } from '../../common/lists/ColumnConfigHelper';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import BulkEditMode from '../../utils/types/IBulkEdit';
import DownloadButton, { IDownloadButtonProps } from '../buttons/DownloadButton';
import { IStagedTask } from '../../utils/types/ITask';
import getBaseTaskListColumns, { IBaseTaskListColumns } from './BaseTasks.config';
import { GenerateDraftsMode } from './generators/GenerateDraftTasks';

export const getStagedTaskListColumns = (
  props: IBaseTaskListColumns,
): ILocalStorageColumnConfig[] => {
  const stagedTaskListColumns = [...getBaseTaskListColumns(props)];

  stagedTaskListColumns.splice(12, 0, {
    key: 'permanentNotes',
    fieldName: 'permanentNotes',
    name: 'Permanent notes',
    maxWidth: 125,
    minWidth: 125,
    className: rowClasses.medFont,
    isResizable: true,
  });
  stagedTaskListColumns.splice(20, 0, {
    key: 'frequencyCode',
    fieldName: 'frequencyCode',
    name: 'Frequency code',
    maxWidth: 125,
    minWidth: 125,
    className: rowClasses.medFont,
    isResizable: true,
  } as IColumn);
  const isActive = {
    key: 'isActive',
    fieldName: 'isActive',
    name: 'Is active',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IStagedTask) => {
      return item.isActive ? 'Yes' : 'No';
    },
  } as IColumn;
  stagedTaskListColumns.splice(stagedTaskListColumns.length - 2, 0, isActive);
  return stagedTaskListColumns as ILocalStorageColumnConfig[];
};

export interface IStagedTaskMenuProps {
  itemsSelected?: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onNewTaskClick?: () => void;
  onShowImportPanel?: () => void;
  onBulkEditClick?: (editMode: BulkEditMode) => void;
  onGenerateDraftTasks?: (generateMode: GenerateDraftsMode) => void;
  onBulkDeleteClick?: (deleteMode: BulkEditMode) => void;
  downloadTemplateProps: IDownloadButtonProps;
  exportStagedTasksProps?: IDownloadButtonProps;
  exportAllStagedTasksProps?: IDownloadButtonProps;
  onRefreshTasksClick?: () => void;
  onActivateTasksClick?: (activateMode: BulkEditMode) => void;
}

const getStagedTasksMenu = (props: IStagedTaskMenuProps): ICommandBarItemProps[] => {
  const {
    itemsSelected,
    itemsSelectedCount,
    totalItemsCount,
    onNewTaskClick,
    onShowImportPanel,
    onBulkEditClick,
    onGenerateDraftTasks,
    onBulkDeleteClick,
    downloadTemplateProps,
    exportStagedTasksProps,
    exportAllStagedTasksProps,
    onRefreshTasksClick,
    onActivateTasksClick,
  } = props;
  const listCommandBarItems: ICommandBarItemProps[] = [];

  listCommandBarItems.push(
    {
      key: 'newTask',
      text: 'New task',
      cacheKey: 'newTask', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onNewTaskClick,
    },
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefreshTasksClick,
    },

    {
      key: 'importSpreadsheet',
      text: 'Import tasks',
      cacheKey: 'importSpreadsheet', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onClick: onShowImportPanel,
    },
    {
      key: 'exportImportTemplate',
      text: 'Download template',
      cacheKey: 'exportImportTemplate',
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Download Template"
            renderedInOverflow={item.renderedInOverflow}
            {...downloadTemplateProps}
          />
        );
      },
      fileEndpoint: downloadTemplateProps.fileEndpoint,
      fileName: downloadTemplateProps.fileName,
    },
    {
      key: 'exportStagedTasks',
      text: 'Export to Excel',
      cacheKey: 'exportStagedTasks',
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            subItems={[exportStagedTasksProps, exportAllStagedTasksProps]}
            renderedInOverflow={item.renderedInOverflow}
            {...downloadTemplateProps}
          />
        );
      },
    },
  );

  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'editBulkMain',
      text: 'Bulk edit',
      cacheKey: 'editBulkMain', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Edit' },
      subMenuProps: {
        items: [
          {
            key: 'editBulk',
            text: `Bulk edit selected (${itemsSelectedCount})`,
            cacheKey: 'editBulk', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Edit' },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'editAll',
            text: `Bulk edit all filtered (${totalItemsCount})`,
            cacheKey: 'editAll', // changing this key will invalidate this item's cache
            iconProps: {
              iconName: 'TripleColumnEdit',
            },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'generateDraftTasksMain',
      text: 'Generate draft tasks',
      cacheKey: 'generateDraftTasksMain', // changing this key will invalidate this item's cache
      iconProps: {
        iconName: 'CalendarWeek',
      },
      subMenuProps: {
        items: [
          {
            key: 'generateDraftTasksSelected',
            text: `Generate draft tasks selected (${itemsSelectedCount})`,
            cacheKey: 'generateBulk', // changing this key will invalidate this item's cachea
            iconProps: {
              iconName: 'CalendarWeek',
            },
            onClick: (ev) => {
              return onGenerateDraftTasks(GenerateDraftsMode.GenerateDraftsSelected);
            },
          },

          {
            key: 'generateDraftTasksFiltered',
            text: `Generate draft tasks all filtered (${totalItemsCount})`,
            cacheKey: 'generateBulkAll', // changing this key will invalidate this item's cache
            iconProps: {
              iconName: 'CalendarWeek',
            },
            onClick: (ev) => {
              return onGenerateDraftTasks(GenerateDraftsMode.GenerateDraftsAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'activateMain',
      text: 'Bulk activation',
      cacheKey: 'activateBulk',
      iconProps: { iconName: 'ActivateOrders' },
      subMenuProps: {
        items: [
          {
            key: 'activate',
            text: `Activate selected (${itemsSelectedCount})`,
            cacheKey: 'activateBulk',
            iconProps: { iconName: 'ActivateOrders' },
            onClick: (ev) => {
              return onActivateTasksClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'activateAll',
            text: `Activate all filtered (${totalItemsCount})`,
            cacheKey: 'activateBulk',
            iconProps: { iconName: 'ActivateOrders' },
            onClick: (ev) => {
              return onActivateTasksClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'deleteMain',
      text: 'Bulk delete/inactivate',
      cacheKey: 'deleteBulk',
      iconProps: { iconName: 'Delete' },
      subMenuProps: {
        items: [
          {
            key: 'delete',
            text: `Bulk delete/inactivate selected (${itemsSelectedCount})`,
            cacheKey: 'deleteBulk',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return onBulkDeleteClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'deleteAll',
            text: `Bulk delete/inactivate all filtered (${totalItemsCount})`,
            cacheKey: 'deleteAll',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return onBulkDeleteClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
  ];

  return itemsSelected ? selectedCommandBarItems : listCommandBarItems;
};

export default getStagedTasksMenu;
