import React from 'react';
import { Stack, Link } from '@fluentui/react';
import { ComponentProvider } from '@micro-frontend-react/core/lib/ComponentProvider';
import { ErrorPage } from 'fcw-dashboard-components';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { IFCWMicroFrontendProps } from '../integrations/ClientAppLoader';

interface IIntegratedClientAppProps {
  scriptUrl: string;
  appName: string;
  supportLink: string;
  data: Omit<IFCWMicroFrontendProps, 'msalProvider'>;
}
const IntegratedClientApp = (props: IIntegratedClientAppProps): JSX.Element => {
  const [componentState, setComponentState] = React.useState({
    loadingComponent: true,
    errorLoadingComponent: false,
  });
  const { scriptUrl, appName, data, supportLink } = props;
  return (
    <Stack styles={{ root: { minWidth: '100%', margin: 0 } }}>
      {scriptUrl && (
        <ComponentProvider
          config={{
            script: scriptUrl,
            name: appName,
          }}
          data={data}
          onError={() => {
            setComponentState({
              ...componentState,
              loadingComponent: false,
              errorLoadingComponent: true,
            });
          }}
          onLoad={() => {
            setComponentState({
              ...componentState,
              loadingComponent: true,
              errorLoadingComponent: false,
            });
          }}
          onLoaded={() => {
            setComponentState({
              ...componentState,
              loadingComponent: false,
              errorLoadingComponent: false,
            });
          }}
        />
      )}
      {componentState.errorLoadingComponent && (
        <ErrorPage
          contentHeader="Error"
          contentMessage="Could not load component"
          actionItemsElement={
            <Link target="_blank" href={supportLink}>
              Contact Support
            </Link>
          }
        />
      )}
      {componentState.loadingComponent && (
        <Stack>
          <LoadingErrorMessage loading />
        </Stack>
      )}
    </Stack>
  );
};
export default IntegratedClientApp;
