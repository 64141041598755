import { useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import {
  DELETE_SAVED_FILTER_MUTATION,
  SAVED_FILTER_LIST_QUERY,
} from '../../utils/api/SavedFilters';
import SavedFilterContext from './SavedFilterContext';

interface ISavedFilterDeleteProps {
  selectedFilterId?: number;
  showDeleteModal: boolean;
  hideSavedFilterDeleteModal: () => void;
}

const SavedFilterDeleteModal = (props: ISavedFilterDeleteProps): JSX.Element => {
  const { selectedFilterId, showDeleteModal, hideSavedFilterDeleteModal } = props;

  const { savedFilter, setSavedFilter } = React.useContext(SavedFilterContext);

  const [deleteFilter, { loading, error }] = useMutation(DELETE_SAVED_FILTER_MUTATION, {
    onCompleted: () => {
      hideSavedFilterDeleteModal();
    },
    refetchQueries: [SAVED_FILTER_LIST_QUERY],
  });

  const onDeleteClick = () => {
    if (savedFilter?.id === selectedFilterId) setSavedFilter(null);
    deleteFilter({
      variables: {
        savedFilterInputDto: {
          id: selectedFilterId,
        },
      },
    });
  };

  const onRenderFooterContent = (): JSX.Element => {
    return (
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <PrimaryButton
          text="Delete"
          onClick={onDeleteClick}
          ariaLabel="Delete"
          disabled={loading}
        />
        <DefaultButton
          text="Cancel"
          onClick={hideSavedFilterDeleteModal}
          ariaLabel="Cancel"
          disabled={loading}
        />
      </Stack>
    );
  };

  return (
    <CoherenceModal
      isOpen={showDeleteModal}
      modalWidth="small"
      height="responsive"
      title="Delete saved filter"
      onDismiss={hideSavedFilterDeleteModal}
      onRenderFooter={onRenderFooterContent}
    >
      <div>
        <LoadingErrorMessage loading={loading} error={error} label="Deleting saved filter" />
        <MessageBar messageBarType={MessageBarType.warning}>
          <Stack tokens={{ childrenGap: 10 }}>
            <p>
              Clicking Delete will <strong>permanently delete</strong> this saved filter. It will
              need to be recreated if needed later.
            </p>
          </Stack>
        </MessageBar>
      </div>
    </CoherenceModal>
  );
};

export default SavedFilterDeleteModal;
