import React, { useEffect, useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, Stack } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import GetFunctionsSummaryTagsByFilter from '../../utils/api/FunctionsSummaryApi';
import IFunctionsSummary from '../../utils/types/IFunctionsSummary';
import { getPickerStyles } from '../styles/CommonStyleObjects';

export interface IFSTagPickerProps {
  defaultValue: IFunctionsSummary[];
  onChange: (functionSummaries: IFunctionsSummary[]) => void;
  itemLimit?: number;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder: string;
  isActive?: boolean;
  value?: IFunctionsSummary[];
  disabled?: boolean;
}

type ITagWithFunctionsSummary = ITag;

export const mapFunctionsSummaryToTags = (
  functionsSummaries: IFunctionsSummary[],
): ITagWithFunctionsSummary[] => {
  return functionsSummaries?.map((fs) => {
    return {
      key: fs.id,
      name: fs.name,
    };
  });
};

export const mapFunctionsSummaryNoTypename = (
  functionsSummaries: IFunctionsSummary[],
): IFunctionsSummary[] => {
  return functionsSummaries?.map((fs) => {
    return {
      id: fs.id,
      name: fs.name,
    };
  });
};

export const mapTagToFunctionsSummary = (tags: ITagWithFunctionsSummary[]): IFunctionsSummary[] => {
  return tags?.map((cc) => {
    const id = cc.key;
    const { name } = cc;
    return { id, name } as IFunctionsSummary;
  });
};

const FunctionsSummaryPicker: React.FunctionComponent<IFSTagPickerProps> = ({
  defaultValue,
  itemLimit,
  required,
  errorMessage,
  label,
  onChange,
  placeholder,
  isActive = true,
  value,
  disabled,
}: IFSTagPickerProps): JSX.Element => {
  const [loadFunctionsSummaryTags, { error }] = useLazyQuery(GetFunctionsSummaryTagsByFilter);
  const [selectedFunctionsSummaryTags, setSelectedFunctionsSummaryTags] = useState<
    ITagWithFunctionsSummary[]
  >(mapFunctionsSummaryToTags(defaultValue));
  const onResolveSuggestions = async (filterText: string): Promise<ITagWithFunctionsSummary[]> => {
    return loadFunctionsSummaryTags({
      variables: {
        keyword: filterText,
        isActive,
      },
    }).then(
      (result: QueryResult<{ functionsSummaries: IFunctionsSummary[] }, OperationVariables>) => {
        const functionsSummaryTags: IFunctionsSummary[] = result?.data?.functionsSummaries;
        return mapFunctionsSummaryToTags(functionsSummaryTags).filter((functionsSummaryTag) => {
          const duplicate = selectedFunctionsSummaryTags?.find(
            (fs) => fs.key === functionsSummaryTag.key,
          );
          return !duplicate;
        });
      },
    );
  };

  useEffect(() => {
    if (value) {
      setSelectedFunctionsSummaryTags(mapFunctionsSummaryToTags(value));
    }
  }, [value]);

  return (
    <Stack>
      {label && (
        <Label htmlFor="functionsSummaryPicker" required={required} disabled={disabled}>
          {label}
        </Label>
      )}
      <TagPicker
        disabled={disabled}
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Functions Summary"
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
        }}
        inputProps={{
          id: 'companyPicker',
          'aria-label': label || 'Value',
          placeholder: placeholder || 'Search Functions Summary',
          required: true,
        }}
        itemLimit={itemLimit}
        resolveDelay={300}
        selectedItems={selectedFunctionsSummaryTags}
        onChange={(items?: ITagWithFunctionsSummary[]): void => {
          setSelectedFunctionsSummaryTags(items);
          onChange(mapTagToFunctionsSummary(items));
        }}
        styles={getPickerStyles(errorMessage)}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage || error?.message} />
    </Stack>
  );
};

export default FunctionsSummaryPicker;
