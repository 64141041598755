import { DocumentNode, useMutation } from '@apollo/client';
import {
  ITag,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Separator,
  TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ActionButtons from '../../../common/buttons/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import GenericFilterTagItems from '../../../common/lists/GenericFilterTagItems';
import { panelFooter } from '../../../common/styles/CommonStyleObjects';
import { SAVED_FILTER_LIST_QUERY } from '../../../utils/api/SavedFilters';
import { deepCopy } from '../../../utils/Helpers';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { ISavedFilter } from '../../../utils/types/SavedFilters';
import TaskFilterOptions from './TaskFilterOptions';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';

interface ISavedFilterPanelProps {
  closePanel: () => void;
  savedFilter: ISavedFilter;
  mutation: DocumentNode;
  mode: string;
  isOnSaveAs?: boolean;
}

interface ISavedFiltersPanelState {
  filterTags: IGenericFilterTag[];
}

const SavedFilterPanel = (props: ISavedFilterPanelProps): JSX.Element => {
  const { closePanel, savedFilter, mutation, mode, isOnSaveAs } = props;
  const [state, setState] = useState<ISavedFiltersPanelState>({ filterTags: savedFilter.filters });
  const { control, handleSubmit, errors, setError, clearErrors } = useForm();
  // Disabling so that we can use the setSavedFilterIdCache function
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSavedFilterIdCache] = useLocalStorage<number>(LocalStorageKeys.savedFilterId, null);
  const [saveSavedFilter, { loading, error }] = useMutation(mutation, {
    onCompleted: (data): void => {
      if (isOnSaveAs) {
        setSavedFilterIdCache(data?.createSavedFilter?.id);
      }
      closePanel();
    },
    refetchQueries: [SAVED_FILTER_LIST_QUERY],
  });

  const getChildFilters = (parentDataKey: string): IGenericFilterTag[] => {
    return state?.filterTags?.filter((filter) => filter?.parentDataKey === parentDataKey);
  };

  const getUpdatedFilters = (filterValues: IGenericFilterTag[]): IGenericFilterTag[] => {
    let filters: IGenericFilterTag[] = deepCopy(state.filterTags ?? []);

    const updateFilters = (filterValue: IGenericFilterTag) => {
      // Get any associated child filters for this filter
      const childFilters = getChildFilters(filterValue?.dataKey);

      // Recursively update any child filters
      childFilters?.forEach((childFilter) => {
        childFilter.values.length = 0;
        updateFilters(childFilter);
      });

      const indexOfValue = filters?.findIndex((item) => item.dataKey === filterValue.dataKey);
      if (indexOfValue === -1) {
        // Add new filter
        filters = filters.concat([filterValue]);
      } else {
        // Update previous filter
        filters.splice(indexOfValue, 1, {
          dataKey: filters[indexOfValue].dataKey,
          title: filters[indexOfValue].title,
          hideFromBar: filters[indexOfValue].hideFromBar,
          parentDataKey: filters[indexOfValue].parentDataKey,
          values: filterValue.values,
        });
      }
    };

    // For each flter tag update it and append it to the list of tags
    filterValues.forEach((filterValue) => {
      updateFilters(filterValue);
    });
    return filters;
  };

  const getInputFilters = (filters: IGenericFilterTag[]): IGenericFilterTag[] => {
    let inputFilters: IGenericFilterTag[] = [];

    // Add filters to input filters
    filters?.forEach((filterTag) => {
      inputFilters = inputFilters.concat({
        dataKey: filterTag.dataKey,
        dataType: typeof filterTag.values[0].key,
        title: filterTag.title,
        hideFromBar: filterTag.hideFromBar ?? false,
        parentDataKey: filterTag.parentDataKey ?? null,
        values: filterTag.values.map((filterValue) => {
          return {
            key: filterValue.key.toString(),
            name: filterValue.name,
          };
        }),
      });

      // Add any child filters to input filters
      const childFilters = getChildFilters(filterTag?.dataKey);
      if (childFilters?.length > 0) {
        getInputFilters(childFilters);
      }
    });
    return inputFilters;
  };

  const getSaveInputFilters = (): IGenericFilterTag[] => {
    let filters: IGenericFilterTag[] = deepCopy(state.filterTags ?? []);
    filters = filters.filter((filter: IGenericFilterTag) => {
      return filter.values?.length > 0;
    });

    return getInputFilters(filters);
  };

  const onFilterItemUpdate = (filterValue: IGenericFilterTag) => {
    const updatedFilters = getUpdatedFilters([filterValue]);
    clearErrors('filters');
    setState({ filterTags: updatedFilters });
  };

  const onFilterItemsUpdate = (filterValues: IGenericFilterTag[]) => {
    const updatedFilters = getUpdatedFilters(filterValues);
    clearErrors('filters');
    setState({ filterTags: updatedFilters });
  };

  const getFilterByKey = (filterKey: string) => {
    const filterIndex = state.filterTags?.findIndex((f) => f.dataKey === filterKey);
    return state?.filterTags?.[filterIndex];
  };

  const onFilterTagsChange = (filterTagKey: string, filterTagItems: ITag[]): void => {
    const tag: IGenericFilterTag = {
      title: filterTagKey,
      dataKey: filterTagKey,
      values: filterTagItems,
    };
    onFilterItemUpdate(tag);
  };

  const onClearAllFilters = (): void => {
    setState({ filterTags: [] });
  };

  const submitForm = (formData: ISavedFilter): void => {
    if (state.filterTags?.length <= 0) {
      setError('filters', {
        type: 'required',
        message: 'Please add some filters before saving.',
      });
      return;
    }

    const savedFilterInput = {
      id: savedFilter.id,
      name: formData?.name,
      filterType: 'PERSONAL',
      filters: getSaveInputFilters(),
    };

    saveSavedFilter({
      variables: {
        savedFilterInputDto: savedFilterInput,
      },
    });
  };

  return (
    <Panel
      title={`${mode} New Saved Filter`}
      headerText={`${mode} New Saved Filter`}
      onDismiss={closePanel}
      onLightDismissClick={closePanel}
      isOpen
      isLightDismiss
      type={PanelType.medium}
      onRenderFooterContent={(): JSX.Element => (
        <ActionButtons
          closePanel={closePanel}
          handleSubmit={handleSubmit(submitForm)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          className={panelFooter}
          mutationLoading={loading}
        />
      )}
      isFooterAtBottom
    >
      <LoadingErrorMessage error={error} loading={loading} />
      <Controller
        as={TextField}
        label="Saved Filter Name"
        id="name"
        name="name"
        defaultValue={savedFilter.name}
        control={control}
        errorMessage={errors?.name?.message}
        rules={{
          required: 'Please provide a name for the saved filter',
          maxLength: {
            value: 100,
            message: 'Name cannot have more than 100 characters',
          },
        }}
      />
      <Separator>Filters Selected</Separator>
      {state?.filterTags?.length > 0 && (
        <GenericFilterTagItems
          filterTagItems={state.filterTags}
          onFilterTagsChange={onFilterTagsChange}
          onClearAllFilters={onClearAllFilters}
        />
      )}
      {state?.filterTags?.length <= 0 && (
        <>
          <p>No filters currently are selected</p>
          {errors?.filters?.message && (
            <MessageBar messageBarType={MessageBarType.error}>
              {errors?.filters?.message}
            </MessageBar>
          )}
        </>
      )}
      <Separator>Filter Options</Separator>
      <TaskFilterOptions
        currentFilterValues={state?.filterTags}
        onFilterUpdate={onFilterItemUpdate}
        onFiltersUpdate={onFilterItemsUpdate}
        getFilterByKey={getFilterByKey}
      />
    </Panel>
  );
};

export default SavedFilterPanel;
