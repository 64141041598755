import React from 'react';
import { IDashboardFilters } from 'fcw-dashboard-components';
import TileClientApp from '../TileClientApp';
import config from '../../../utils/ConfigLoader';

const GsrAtAGlanceCards = (props: { filters: IDashboardFilters }): JSX.Element => {
  const { filters } = props;
  const microUiSettings = config?.settings?.gsrConfig;

  return (
    <>
      <TileClientApp
        scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
        appName="GsrTile"
        data={{
          filters,
          baseUrl: microUiSettings?.microUiUrl,
          baseName: 'gsr',
        }}
        supportLink={`${microUiSettings?.siteUrl}`}
        friendlyAppName="Goods and Services Receipting"
      />
    </>
  );
};

export default GsrAtAGlanceCards;
