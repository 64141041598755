import { ITag } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';
import IDataSourcePerspective from '../../utils/types/IDataSourcePerspective';
import { GetDataSourcePerspectivesByKeyword } from '../../utils/api/DataSourcesApi';

const DataSourcePerspectivesChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;

  const mapToTag = (item: IDataSourcePerspective): ITag => {
    return {
      key: item.id,
      name: item.name,
    };
  };

  const mapData = (data: { dataSourcePerspectives: IDataSourcePerspective[] }): ITag[] => {
    return data?.dataSourcePerspectives?.map((perspective: IDataSourcePerspective) => {
      return mapToTag(perspective);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text="Perspectives">
      <GenericChecklist
        query={GetDataSourcePerspectivesByKeyword}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default DataSourcePerspectivesChecklist;
