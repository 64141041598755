import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../../common/constants/SiteConstants';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../../common/lists/GeneralEntityList';
import PaginationWrapperManaged from '../../../../common/lists/PaginationWrapperManaged';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../../utils/hooks/useLocalStorage';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../../utils/listHelpers';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import { IListQueryVariables, IEntityListState } from '../../../../utils/types/IList';
import { IQuerySortDefinition, SortDirection } from '../../../../utils/types/IListSortDefinition';
import { PaginationQueryVariables } from '../../../../utils/types/PaginationTypes';
import ColumnConfigPanel from '../../../tasks/list/ColumnConfigPanel';
import { filterOutSavedTags } from '../../../../common/lists/GenericFilterTagHandler';
import {
  CreateDataSourceApplication,
  EditDataSourceApplication,
  GetDataSourceApplicationsList,
} from '../../../../utils/api/DataSourcesApi';
import IDataSourceApplication from '../../../../utils/types/IDataSourceApplication';
import {
  getDataSourceApplicationsColumnList,
  getDataSourceApplicationsMenu,
} from './DataSourceApplicationsList.config';
import DataSourceApplicationEdit from '../../details/datasource/DataSourceApplicationEdit';
import PanelTypes from '../../../../utils/types/PanelTypes';

interface IPanelState extends IEntityListState {
  showEditPanel: boolean;
  showAddPanel: boolean;
  selectedItem: IDataSourceApplication;
}
const DataSourceApplicationsListPage = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.dataSourceApplicationListColumns,
    [],
  );
  let filterTimeout: string | number | NodeJS.Timeout;
  const defaultFiltersAndSort = {
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'name',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.dataSourceApplicationQueryState}`,
    { ...defaultPaginationProps, ...defaultFiltersAndSort } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IPanelState>({
    showAddPanel: false,
    showEditPanel: false,
    selectedItem: null,
    ...getDefaultStateProps(taskQueryCache),
  });

  const openAddPanel = (): void => {
    setPagesState({
      ...pageState,
      showAddPanel: true,
      selectedItem: null,
    });
  };

  const closePanels = (): void => {
    setPagesState({
      ...pageState,
      showAddPanel: false,
      showEditPanel: false,
    });
  };

  const [getData, { data, loading, error }] = useLazyQuery(GetDataSourceApplicationsList, {
    variables: {
      ...taskQueryCache?.pageInfo,
      keyword: taskQueryCache.keyword,
      filters: {},
      order: [
        convertDotPathToNestedObject(taskQueryCache?.sortKey, taskQueryCache?.sortDir),
      ] as IQuerySortDefinition[],
    },
  });

  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };
  const dataLoaded = !loading && !error;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<IDataSourceApplication>(data?.dataSourceApplicationsList)
    : getEmptyResults<IDataSourceApplication>();

  const cachedListColumns = getColumnConfigByKey(
    getDataSourceApplicationsColumnList((selectedItem: IDataSourceApplication) => {
      setPagesState({
        ...pageState,
        selectedItem,
        showEditPanel: true,
        showAddPanel: false,
      });
    }),
    listColumnsCache,
  );
  const detailsListConfig: IGeneralEntityListProps<IDataSourceApplication> = {
    data: dataResult.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
    commandBarItems: getDataSourceApplicationsMenu(openAddPanel, onRefreshClick, {
      key: 'ExportTaskPropertyDataSourceApplications',
      fileEndpoint: 'DataSourceApplications',
      fileName: 'Applications',
    }),
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSearchBoxChange: (ev, searchTerm) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    disableSelect: true,
    loading,
    error,
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
  };
  const getTagValues = (tagName: string, tags: IGenericFilterTag[]): (string | number)[] => {
    const ids = tags?.find((tag) => tag.dataKey === tagName)?.values?.map((tag) => tag.key);
    return ids;
  };
  const getFilters = (filters: IGenericFilterTag[]) => {
    return {
      applicationIds: getTagValues('dataSourceApplicationIds', filters),
    };
  };

  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = getFilters(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];
    getData({
      variables: {
        keyword,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };
  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  return (
    <>
      <PaginationWrapperManaged<IDataSourceApplication>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>
      {pageState.showEditPanel && (
        <DataSourceApplicationEdit
          dataSourceApplication={pageState.selectedItem}
          closePanel={closePanels}
          mutation={EditDataSourceApplication}
          panelMode={PanelTypes.Edit}
        />
      )}
      {pageState.showAddPanel && (
        <DataSourceApplicationEdit
          dataSourceApplication={{
            id: -1,
            name: null,
          }}
          closePanel={closePanels}
          mutation={CreateDataSourceApplication}
          panelMode={PanelTypes.Add}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </>
  );
};
export default DataSourceApplicationsListPage;
