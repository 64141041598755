import { gql } from '@apollo/client';

const TaskPropertyValueFragment = gql`
  fragment TaskPropertyValue on TaskPropertyValueDto {
    id
    name
    isActive
    modifiedOn
    modifiedBy {
      graphGuid
      displayName
      alias
      emailAddress
    }
  }
`;

const GetTaskProperties = gql`
  query TaskProperties {
    taskProperties {
      id
      name
    }
  }
`;

// change the key and text to just be id and name and map in dropdown
export const GetTaskProperty = gql`
  query TaskProperty($taskPropertyId: Int!) {
    taskProperty(taskPropertyId: $taskPropertyId) {
      id
      name
      values {
        ...TaskPropertyValue
      }
    }
  }
  ${TaskPropertyValueFragment}
`;
export const GetTaskPropertyByName = gql`
  query TaskPropertyByName($taskPropertyName: String!) {
    taskPropertyByName(taskPropertyName: $taskPropertyName) {
      id
      name
      values {
        ...TaskPropertyValue
      }
    }
  }
  ${TaskPropertyValueFragment}
`;
export const GetTaskPropertyByNameSearchValues = gql`
  query TaskPropertyByNameSearchValues($taskPropertyName: String!, $keyword: String!) {
    taskPropertyByNameSearchValues(taskPropertyName: $taskPropertyName, keyword: $keyword) {
      id
      name
      values {
        ...TaskPropertyValue
      }
    }
  }
  ${TaskPropertyValueFragment}
`;
export const EditTaskPropertyValue = gql`
  mutation editTaskPropertyValue($taskPropertyValue: TaskPropertyValueInputDtoInput!) {
    editTaskPropertyValue(taskPropertyValue: $taskPropertyValue) {
      id
      name
      values {
        ...TaskPropertyValue
      }
    }
  }
  ${TaskPropertyValueFragment}
`;
export const AddTaskPropertyValue = gql`
  mutation addTaskPropertyValue($taskPropertyValue: TaskPropertyValueInputDtoInput!) {
    addTaskPropertyValue(taskPropertyValue: $taskPropertyValue) {
      id
      name
      values {
        ...TaskPropertyValue
      }
    }
  }
  ${TaskPropertyValueFragment}
`;
export const GetDefaultTimeZone = gql`
  query DefaultTimeZone {
    defaultTimeZone {
      id
      name
      isActive
      modifiedOn
      modifiedBy {
        graphGuid
        displayName
        alias
        emailAddress
      }
    }
  }
`;
export default GetTaskProperties;
