import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Stack,
  DetailsListLayoutMode,
  IObjectWithKey,
  ICommandBarItemProps,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackButton from '../../../common/buttons/BackButton';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../common/constants/SiteConstants';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import {
  ILocalStorageColumnConfig,
  getColumnConfigByKey,
} from '../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import { bodyContentContainer } from '../../../common/styles/CommonStyleObjects';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../utils/formatters/PaginatedResultFormatter';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { IPublishedTask, IPublishedTaskFilterIds } from '../../../utils/types/ITask';
import { PaginatedData, PaginationQueryVariables } from '../../../utils/types/PaginationTypes';
import getPublishedTaskMenu, {
  IPublishedTaskMenuProps,
  getPublishedTaskListColumns,
} from '../PublishedTasks.config';
import ColumnConfigPanel from './ColumnConfigPanel';
import { mapPublishedTaskFilterTagsToIds, TaskType } from './BaseTaskListfilters.config';
import {
  EditPublishedTaskBulk,
  EditPublishedTaskRemoveBulk,
  GetPublishedTasks,
} from '../../../utils/api/PublishedTaskApi';
import TasksChangeState, { ChangeStateMode } from '../edit/TasksChangeState';
import {
  getFormInputConfig,
  getRemoveTaskMapper,
  getUpdateTaskMapper,
} from '../edit/DraftTaskBulkEdit.config';
import PaginationWrapperManaged from '../../../common/lists/PaginationWrapperManaged';
import PublishedTaskListPageFilters from './PublishedTaskListPageFilters';
import { IListQueryVariables, IEntityListState } from '../../../utils/types/IList';
import { IQuerySortDefinition } from '../../../utils/types/IListSortDefinition';
import BulkEditMode from '../../../utils/types/IBulkEdit';
import {
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
  toggleColSortDir,
} from '../../../utils/listHelpers';

import { sharedEditButton } from '../../../common/lists/SharedDataGridColumns';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';
import { convertDotPathToNestedObject } from '../../../utils/Helpers';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import PublishedTaskEditPanel from '../edit/PublishedTaskEditPanel';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../common/lists/GenericFilterTagHandler';
import AppConfigValueContext from '../../../utils/appConfigValues/AppConfigValuesContext';
import AppConfigValues from '../../../utils/appConfigValues/AppConfigValues';
import BulkEdit, { BulkEditType } from '../../../common/bulkEdit/BulkEdit';
import { IBaseTaskListColumns } from '../BaseTasks.config';
import config from '../../../utils/ConfigLoader';

export interface IPublishedTaskListQueryVariables extends IListQueryVariables {
  taskStateFilter: string[];
}

interface IPublishedTaskListState extends IEntityListState {
  taskChangesStateMode: ChangeStateMode;
  editTask?: IPublishedTask;
  showImportPanel: boolean;
}

interface IPublishedTaskListPageProps {
  queryVariables: IPublishedTaskListQueryVariables;
  setTaskQueryCache: React.Dispatch<React.SetStateAction<IPublishedTaskListQueryVariables>>;
  pageTitle: string;
  hideSavedFilter?: boolean;
  onNewTaskClick?: () => void;
  onCopyTaskClick?: (taskToCopyId: number) => void;
  hideExportAll?: boolean;
}
const PublishedTaskListPageTemplate = (props: IPublishedTaskListPageProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const {
    queryVariables,
    setTaskQueryCache,
    hideSavedFilter,
    onNewTaskClick,
    onCopyTaskClick,
    pageTitle,
    hideExportAll,
  } = props;
  const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);
  const { flags } = appConfigValues;
  const canImportSpreadsheet = flags.ImportPublishedTasks;

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [dataResult, setDataResult] = useState<PaginatedData<IPublishedTask>>();
  let filterTimeout: string | number | NodeJS.Timeout;
  const itemsSelected = selectedTasks?.length > 0;
  const selectedTaskIds = selectedTasks?.map((task: IPublishedTask) => {
    return task?.id;
  });
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.publishedTaskListColumns,
    [],
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IPublishedTaskListState>({
    ...getDefaultStateProps(queryVariables),
    taskChangesStateMode: null,
    editTask: null,
    showImportPanel: false,
  });

  /** Lazy Query */
  const [getTasks, { data, loading, error }] = useLazyQuery(GetPublishedTasks, {
    variables: {
      keyword: queryVariables.keyword,
      filters: mapPublishedTaskFilterTagsToIds(queryVariables.filterTags),
      status: 0, // Published  or draft
      ...queryVariables?.pageInfo,
      order: [{ [queryVariables.sortKey]: queryVariables.sortDir } as IQuerySortDefinition],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: (queryError) => {
      // We should handle errors in the future
    },
  });

  /** Configure the components */
  const onBulkEditClick = (editMode: BulkEditMode) => {
    setPagesState({
      ...pageState,
      bulkEditMode: editMode,
    });
  };
  const onRefreshTasksClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(queryVariables?.paginationSize),
    });
  };

  const onChangeTaskStateClick = (editMode: ChangeStateMode) => {
    setPagesState({
      ...pageState,
      taskChangesStateMode: editMode,
    });
  };

  const onShowImportPanel = () => {
    setPagesState({
      ...pageState,
      showImportPanel: true,
    });
  };

  const copyTask = () => {
    if (selectedTaskIds.length > 0) {
      onCopyTaskClick(selectedTaskIds[0]);
    }
  };

  const changeCasingOnEnums = (
    filters: IGenericFilterTag[],
    exportAll: boolean,
  ): IPublishedTaskFilterIds => {
    if (exportAll) {
      const nonBarFilters = filters.filter((filter) => filter.hideFromBar === true);
      return mapPublishedTaskFilterTagsToIds(nonBarFilters);
    }

    const queryParams = mapPublishedTaskFilterTagsToIds(filters);

    queryParams.publishType = queryParams.publishType?.map((publishType: string) => {
      return enumFormatter(publishType).replace(/ /g, '');
    });
    queryParams.taskState = queryParams.taskState?.map((filterItem: string) => {
      return enumFormatter(filterItem).replace(/ /g, '');
    });

    return queryParams;
  };

  const gridMenuItems = React.useMemo((): ICommandBarItemProps[] => {
    const { keyword, filterTags } = pageState;

    const downloadTemplateProps: IDownloadButtonProps = {
      key: 'PublishedTasksTemplate',
      fileEndpoint: 'PublishedTasksTemplate',
      fileName: 'PublishedTasksImportTemplate',
      cacheKey: 'TasksAllDownload',
      iconProps: { iconName: 'ExcelLogo' },
      commandBarButtonAs: DownloadButton,
    };
    const maxExportSize = parseInt(config?.settings?.maxExportTaskSize, 10);
    const exportExceedsLimit = dataResult?.totalCount >= maxExportSize;

    const downloadAllButtonProps: IDownloadButtonProps = {
      key: 'TasksAllDownload',
      fileEndpoint: 'Tasks?status=Published',
      fileName: 'Tasks',
      text: 'Export All',
      cacheKey: 'TasksAllDownload',
      iconProps: { iconName: 'ExcelLogo' },
      commandBarButtonAs: DownloadButton,
      postBody: JSON.stringify(changeCasingOnEnums(filterTags, true)),
    };

    const downloadFilteredButtonProps: IDownloadButtonProps = {
      key: 'TasksFilteredDownload',
      fileEndpoint: `Tasks?status=Published${keyword != null ? `&keyword=${keyword}` : ''}`,
      fileName: 'Tasks',
      text: exportExceedsLimit
        ? `Max exportable tasks of ${maxExportSize} exceeded (${dataResult?.totalCount})`
        : `Export filtered (${dataResult?.totalCount})`,
      disabled: exportExceedsLimit,
      cacheKey: 'TasksFilteredDownload',
      iconProps: { iconName: 'ExcelLogo' },
      commandBarButtonAs: DownloadButton,
      postBody: JSON.stringify(changeCasingOnEnums(filterTags, false)),
    };

    const commandBarItemConfig: IPublishedTaskMenuProps = {
      itemsSelected,
      itemsSelectedCount: selectedTasks?.length,
      totalItemsCount: dataResult?.totalCount,
      onBulkEditClick,
      onChangeTaskStateClick,
      exportFilteredPublishedTasksProps: downloadFilteredButtonProps,
      exportAllPublishedTasksProps: !hideExportAll ? downloadAllButtonProps : null, // hide button on all tasks page since its no different from downloadfiltered
      onRefreshTasksClick,
      onNewTaskClick,
      downloadTemplateProps,
      onShowImportPanel,
      canImportSpreadsheet,
      onCopyTaskClick: onCopyTaskClick ? copyTask : null,
    };
    return getPublishedTaskMenu(commandBarItemConfig);
  }, [
    itemsSelected,
    selectedTasks?.length,
    pageState.keyword,
    pageState.filterTags,
    dataResult?.totalCount,
  ]);

  const columnConfig: IBaseTaskListColumns = {
    taskType: TaskType.PublishedTasks,
    onDetailsClick: (selectedItem: IPublishedTask) => {
      history.push(`/tasks/details/${selectedItem.id}`);
    },
  };

  const cachedListColumns = getColumnConfigByKey(
    getPublishedTaskListColumns(columnConfig, location.pathname),
    listColumnsCache,
  );
  const listColumns = cachedListColumns.filter((col) => {
    return (col as ILocalStorageColumnConfig).active;
  });

  listColumns.unshift(
    sharedEditButton<IBaseTask>(
      (selectedItem: IPublishedTask) => {
        setPagesState({
          ...pageState,
          editTask: selectedItem,
        });
      },
      selectedTasks?.length > 1,
      'name',
    ),
  );

  const closeEditPanel = (): void => {
    const stateCopy = structuredClone(pageState);
    setPagesState({
      ...stateCopy,
      editTask: null,
    });
  };

  const detailsListConfig: IGeneralEntityListProps<IPublishedTask> = {
    data: dataResult?.data || [],
    listColumns,
    commandBarItems: gridMenuItems,
    onSearchBoxChange: (ev, searchTerm: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
          ...getResetPaginationProps(queryVariables?.paginationSize),
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: queryVariables.keyword,
    loading,
    layoutMode: DetailsListLayoutMode.fixedColumns,
    error,
    lockActionsColumn: true,
    onChangeSelectedItems: (selection: IObjectWithKey[]) => {
      setSelectedTasks(selection);
    },
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
    frozenColumnsFromStart: 1,
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) => {
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(queryVariables?.paginationSize),
            });
          }}
          allFilterTags={pageState.filterTags}
          hideSavedFilter={hideSavedFilter}
        >
          <PublishedTaskListPageFilters />
        </GenericFilterTagHandler>
      );
    },
  };

  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    // Has potential to have empty data key if saved filter has the only value in the list
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...queryVariables,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = mapPublishedTaskFilterTagsToIds(filterTags);
    let sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];
    if (sortKey === 'remainingTime') {
      sortArray = [
        { dueDate: toggleColSortDir(sortDir).toString() },
        { dueTime: toggleColSortDir(sortDir).toString() },
      ];
    }
    if (sortKey === 'fiscalPeriod') {
      sortArray = [
        { fiscalYear: toggleColSortDir(sortDir).toString() },
        { fiscalPeriod: toggleColSortDir(sortDir).toString() },
      ];
    }

    getTasks({
      variables: {
        keyword,
        status: 0,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };
  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);

  /** I would like to make sure that we need this */
  useEffect(() => {
    const dataLoaded = !loading && !error;
    const formattedData =
      dataLoaded && data
        ? paginatedResultFormatter<IPublishedTask>(data?.publishedTasks)
        : getEmptyResults<IPublishedTask>();
    setDataResult(formattedData);
    setSelectedTasks([]);
  }, [loading, data]);

  /** Render */
  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <BackButton ariaLabel={`Back to ${TaskType.PublishedTasks} list`} backDepth={2}>
          <h1 style={{ padding: '0 20px 0 0' }}>{pageTitle}</h1>
        </BackButton>
      </Stack>
    );
  };
  return (
    <>
      <FullWidthHeader title={renderHeader} />

      <div className={`${bodyContentContainer}`}>
        <PaginationWrapperManaged<IPublishedTask>
          dataResult={dataResult}
          selectedPage={pageState.selectedPaginationPage}
          loadingData={loading}
          onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
            setPagesState({
              ...pageState,
              selectedPaginationPage: value,
              pageInfo: {
                ...variables,
              },
            });
          }}
          paginationSize={pageState.paginationSize}
          onPageSizeChange={(newPageSize: number) => {
            setPagesState({
              ...pageState,
              ...getResetPaginationProps(newPageSize),
            });
          }}
        >
          {GeneralEntityList(detailsListConfig)}
        </PaginationWrapperManaged>
      </div>

      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
      <BulkEdit
        type={BulkEditType.tasks}
        getFormInputs={getFormInputConfig}
        bulkEditMode={pageState?.bulkEditMode}
        hideBulkEditModal={() => {
          const stateCopy = structuredClone(pageState);
          setPagesState({
            ...stateCopy,
            bulkEditMode: null,
          });
        }}
        selectedItemsCount={
          pageState.bulkEditMode === BulkEditMode.BulkEditSelected
            ? selectedTasks.length
            : dataResult?.totalCount
        }
        selectedItems={selectedTaskIds}
        keyword={pageState.keyword}
        updateMutation={useMutation(EditPublishedTaskBulk)}
        removeMutation={useMutation(EditPublishedTaskRemoveBulk)}
        getUpdateMapper={getUpdateTaskMapper}
        getRemoveMapper={getRemoveTaskMapper}
        filters={mapPublishedTaskFilterTagsToIds(pageState.filterTags)}
      />
      {pageState.taskChangesStateMode !== null && (
        <TasksChangeState
          hideModal={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              taskChangesStateMode: null,
            });
          }}
          selectedTasksCount={
            pageState.taskChangesStateMode === ChangeStateMode.ChangeStateSelected
              ? selectedTasks.length
              : dataResult?.totalCount
          }
          selectedTasks={selectedTaskIds}
          onChangeStateComplete={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              taskChangesStateMode: null,
              ...getResetPaginationProps(queryVariables?.paginationSize),
            });
          }}
          changeStateMode={pageState.taskChangesStateMode}
          keyword={pageState.keyword}
          filters={mapPublishedTaskFilterTagsToIds(pageState.filterTags)}
          taskStateFilters={[]}
        />
      )}

      {pageState.editTask !== null && (
        <PublishedTaskEditPanel task={pageState?.editTask} closePanel={closeEditPanel} />
      )}
    </>
  );
};
export default PublishedTaskListPageTemplate;
