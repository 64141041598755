import { gql } from '@apollo/client';

const GetCalendarDates = gql`
  query FiscalCalendarDatesPagination(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [FiscalCalendarDateDtoSortInput!]
  ) {
    fiscalCalendarDatesPagination(
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          id
          fiscalYear
          fiscalPeriod
          assignedDate
          description
          taskDateCode
          modifiedOn
          modifiedBy {
            graphGuid
            displayName
            alias
            emailAddress
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetVariableDates = gql`
  query VariableDatesPagination(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [VariableDateDtoSortInput!]
  ) {
    variableDatesPagination(
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          description
          taskDateCode
          fiscalYear
          fiscalPeriod {
            period
            assignedDate
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetFiscalCalendarDateCodes = gql`
  query FiscalCalendarDateCodes($dateCodeType: String) {
    fiscalCalendarDateCodes(dateCodeType: $dateCodeType)
  }
`;

export const GetFiscalCalendarDates = gql`
  query FiscalCalendarDates($dateCodes: [String!], $period: Int, $fiscalYear: Int) {
    fiscalCalendarDates(dateCodes: $dateCodes, period: $period, fiscalYear: $fiscalYear) {
      assignedDate
    }
  }
`;

export const AddVariableDateValue = gql`
  mutation addVariableDateValue($variableDateDto: VariableDateInputDtoInput!) {
    addVariableDateValue(variableDateDto: $variableDateDto) {
      fiscalYear
      description
      taskDateCode
      fiscalPeriod {
        period
        assignedDate
      }
    }
  }
`;
export const EditVariableDateValue = gql`
  mutation editVariableDateValue($variableDateDto: VariableDateInputDtoInput!) {
    editVariableDateValue(variableDateDto: $variableDateDto) {
      fiscalYear
      description
      taskDateCode
    }
  }
`;
export const GetFiscalPeriods = gql`
  query FiscalPeriods {
    fiscalPeriods {
      fiscalYear
      fiscalPeriod
    }
  }
`;

export const GetFiscalYears = gql`
  query FiscalYears {
    fiscalYears {
      key: year
      text: year
    }
  }
`;
export const GetTaskFiscalYearPeriods = gql`
  query TaskFiscalYearPeriods($status: PublishStatus!, $filters: TaskFilterDtoInput) {
    taskFiscalYearPeriods(status: $status, filters: $filters) {
      fiscalYear
      period
      taskCount
      tasksNotStarted
      tasksNotPerformed
      tasksCompleted
      tasksStarted
      tasksCompletePercentage
    }
  }
`;
export const GetFrequencyCode = gql`
  query FrequencyCode($frequencyCodeString: String!) {
    frequencyCode(frequencyCodeString: $frequencyCodeString) {
      cadence
      dateCodes
      repetitionValue
      startPeriod
      startYear
    }
  }
`;
export default GetCalendarDates;
