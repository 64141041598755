import React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from './Collapsible';

interface IStringFilterProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag;
  dataKey?: string;
  wrapperLabel?: string | null;
  label?: string;
}

const StringFilter = (props: IStringFilterProps): JSX.Element => {
  const { dataKey, wrapperLabel, label, onSelectedItems, initialValues } = props;

  if (typeof label === undefined || label === null || label === '') {
    return <span>String Filter requires a label value.</span>;
  }
  if (typeof dataKey === undefined || dataKey === null || dataKey === '') {
    return <span>String Filter requires a data key.</span>;
  }

  const initialValuesArray = initialValues?.values ?? [];
  if (initialValuesArray.length > 1) {
    return <span>String Filter only supports a single value.</span>;
  }

  const firstValue = initialValuesArray.length > 0 ? initialValuesArray[0].name : '';

  const handleChange = (value: string, key: string, title: string) => {
    onSelectedItems({ dataKey, title, values: [{ key: value, name: value }] });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Collapsible text={wrapperLabel}>
        <TextField
          defaultValue={firstValue}
          label={label}
          onChange={(ev, newValue) => {
            handleChange(newValue, dataKey, label);
          }}
          width={400}
        />
      </Collapsible>
    </Stack>
  );
};

export default StringFilter;
