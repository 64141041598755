const sortRowsByKeySimple = <T>(
  rowsToSort: T[],
  columnKey: keyof T,
  isSortedDescending?: boolean,
): T[] => {
  return rowsToSort
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1,
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arraysEqual<T extends Record<string, any>>(a: T[], b: T[]): boolean {
  if (a?.length !== b?.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    const aElement = a[i];
    const bElement = b[i];

    if (Array.isArray(aElement) && Array.isArray(bElement)) {
      if (!arraysEqual(aElement, bElement)) {
        return false;
      }
    } else if (typeof aElement === 'object' && typeof bElement === 'object') {
      if (!objectsEqual(aElement, bElement)) {
        return false;
      }
    } else if (aElement !== bElement) {
      return false;
    }
  }

  return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectsEqual<T extends Record<string, any>>(a: T, b: T): boolean {
  const aKeys = a === null || a === undefined ? [] : Object.keys(a);
  const bKeys = b === null || b === undefined ? [] : Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of aKeys) {
    const aPropertyValue = a[key];
    const bPropertyValue = b[key];

    if (Array.isArray(aPropertyValue) && Array.isArray(bPropertyValue)) {
      if (!arraysEqual(aPropertyValue, bPropertyValue)) {
        return false;
      }
    } else if (typeof aPropertyValue === 'object' && typeof bPropertyValue === 'object') {
      if (!objectsEqual(aPropertyValue, bPropertyValue)) {
        return false;
      }
    } else if (aPropertyValue !== bPropertyValue) {
      return false;
    }
  }

  return true;
}

export default sortRowsByKeySimple;
