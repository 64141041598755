import React from 'react';

const requiredStyles = { color: '#A4262C', fontSize: 12 };

export const RequiredMarker = (): JSX.Element => {
  return <span style={requiredStyles}> *</span>;
};

export const DoubleRequiredMarker = (): JSX.Element => {
  return <span style={requiredStyles}> **</span>;
};
