import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ICommandBarItemProps } from '@fluentui/react';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../../common/constants/SiteConstants';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../../common/lists/GeneralEntityList';
import PaginationWrapperManaged from '../../../../common/lists/PaginationWrapperManaged';
import {
  AddOpsSummary,
  EditOpsSummaryValue,
  GetOpsSummaryList,
} from '../../../../utils/api/HierarchiesApi';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../../utils/hooks/useLocalStorage';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import { IOpsSummary } from '../../../../utils/types/IHierarchy';
import { IQuerySortDefinition, SortDirection } from '../../../../utils/types/IListSortDefinition';
import { IListQueryVariables, IEntityListState } from '../../../../utils/types/IList';
import { PaginationQueryVariables } from '../../../../utils/types/PaginationTypes';
import PanelTypes from '../../../../utils/types/PanelTypes';
import ColumnConfigPanel from '../../../tasks/list/ColumnConfigPanel';
import OpsSummaryEdit from '../../details/operations/OpsSummaryEdit';
import getHierarchiesMenu, {
  IHierarchiesMenuProps,
  getOpsSummaryColumnsList,
} from '../HierarchiesList.config';
import { mapOpsSummaryFilterTagsToIds } from '../HierarchiesListfilters.config';
import OpsSummaryFilters from './OpsSummaryFilters';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../../utils/listHelpers';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../../common/lists/GenericFilterTagHandler';
import { IDownloadButtonProps } from '../../../buttons/DownloadButton';

interface IOpsSummaryListState extends IEntityListState {
  showEditPanel: boolean;
  showAddPanel: boolean;
  selectedItem: IOpsSummary;
}

const OpsSummary = (): JSX.Element => {
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'name',
  };
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.opsSummaryListColumns,
    [],
  );

  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.opsSummaryQueryState}`,
    { ...defaultPaginationProps, ...defaultFiltersAndSort } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IOpsSummaryListState>({
    showAddPanel: false,
    showEditPanel: false,
    selectedItem: null,
    ...getDefaultStateProps(taskQueryCache),
  });

  const [getData, { data, loading, error }] = useLazyQuery(GetOpsSummaryList, {
    variables: {
      keyword: taskQueryCache.keyword,
      filters: {},
      ...taskQueryCache?.pageInfo,
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  let filterTimeout: string | number | NodeJS.Timeout;
  const onAddItem = (): void => {
    setPagesState({
      ...pageState,
      showAddPanel: true,
    });
  };

  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = mapOpsSummaryFilterTagsToIds(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    getData({
      variables: {
        keyword,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };

  const closePanels = (shouldRefresh: boolean): void => {
    setPagesState({
      ...pageState,
      showAddPanel: false,
      showEditPanel: false,
    });
    if (shouldRefresh) {
      refreshPage();
    }
  };
  const dataLoaded = !loading && !error;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<IOpsSummary>(data?.opsSummaries)
    : getEmptyResults<IOpsSummary>();
  const cachedListColumns = getColumnConfigByKey(
    getOpsSummaryColumnsList((selectedItem: IOpsSummary) => {
      setPagesState({
        ...pageState,
        showEditPanel: true,
        selectedItem,
      });
    }),
    listColumnsCache,
  );
  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };
  const commandBarItems = React.useMemo((): ICommandBarItemProps[] => {
    const exportButtonProps: IDownloadButtonProps = {
      key: 'ExportOperationSummary',
      fileEndpoint: 'OperationSummary?opsSummary',
      fileName: 'OperationSummary',
    };

    const commandBarItemConfig: IHierarchiesMenuProps = {
      onAddItem,
      onRefreshClick,
      exportButtonProps,
    };
    return getHierarchiesMenu(commandBarItemConfig);
  }, [pageState.keyword, pageState.filterTags]);

  const detailsListConfig: IGeneralEntityListProps<IOpsSummary> = {
    data: dataResult.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems,
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSearchBoxChange: (ev, searchTerm: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
          ...getResetPaginationProps(taskQueryCache?.paginationSize),
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    disableSelect: true,
    loading,
    error,
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <OpsSummaryFilters />
        </GenericFilterTagHandler>
      );
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
  };
  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };

  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);

  return (
    <>
      <PaginationWrapperManaged<IOpsSummary>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>
      {pageState.showEditPanel && (
        <OpsSummaryEdit
          opsSummary={pageState.selectedItem}
          closePanel={closePanels}
          mutation={EditOpsSummaryValue}
          panelMode={PanelTypes.Edit}
        />
      )}
      {pageState.showAddPanel && (
        <OpsSummaryEdit
          opsSummary={{ id: -1, name: '', isActive: true }}
          closePanel={closePanels}
          mutation={AddOpsSummary}
          panelMode={PanelTypes.Add}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </>
  );
};
export default OpsSummary;
