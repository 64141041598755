import { DocumentNode, useQuery } from '@apollo/client';
import {
  Checkbox,
  ITag,
  mergeStyleSets,
  NeutralColors,
  normalize,
  SearchBox,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import GetTaskProperties from '../../utils/api/TaskPropertiesApi';
import useDebounce from '../../utils/hooks/useDebounce';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

const styles = mergeStyleSets({
  searchContainer: {
    marginBottom: 20,
  },
  container: {
    overflow: 'auto',
    maxHeight: 400,
  },
  itemContent: [
    normalize,
    {
      padding: '10px 5px 0 5px',
      height: 40,
      borderBottom: `1px solid ${NeutralColors.gray30}`,
      overflow: 'hidden',
    },
  ],
  checkLabel: {
    label: {
      whiteSpace: 'nowrap',
    },
  },
});

interface IChecklistItemProps {
  tag: ITag;
  onChange: (tag: ITag, isChecked: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
}

const ChecklistItem = (props: IChecklistItemProps): JSX.Element => {
  const { tag, onChange, isChecked, isDisabled } = props;

  const onCheckChanged = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
  ): void => {
    onChange(tag, checked);
  };

  return (
    <div data-is-focusable className={styles.itemContent}>
      <Checkbox
        label={tag.name}
        checked={isChecked || isDisabled}
        onChange={onCheckChanged}
        className={styles.checkLabel}
        disabled={isDisabled}
      />
    </div>
  );
};

interface IGenericChecklistProps {
  label?: string;
  query?: DocumentNode;
  items?: ITag[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapData?: (item: any) => ITag[];
  onCheckChanged?: (currentSelections: ITag[]) => void;
  selections?: ITag[];
  isActiveQueryParam?: boolean;
  taskPropertyName?: string;
  disabledItems?: ITag[];
}

const GenericChecklist = (props: IGenericChecklistProps): JSX.Element => {
  const {
    label,
    query,
    items,
    mapData,
    onCheckChanged,
    selections,
    isActiveQueryParam,
    taskPropertyName,
    disabledItems,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, THROTTLE_SEARCH_TIMEOUT);

  const getVariables = () => {
    if (taskPropertyName?.length > 0) {
      return {
        taskPropertyName,
        keyword: '',
      };
    }

    if (isActiveQueryParam === undefined) {
      return {
        keyword: debouncedSearchTerm,
      };
    }

    return {
      keyword: debouncedSearchTerm,
      isActive: isActiveQueryParam,
    };
  };

  const { data, loading, error, refetch } = useQuery(query ?? GetTaskProperties, {
    variables: getVariables(),
    skip: items?.length > 0,
  });

  useEffect(() => {
    if (!loading && !error) {
      refetch(getVariables());
    }
  }, [debouncedSearchTerm]);

  const onChange = (tag: ITag, isChecked: boolean): void => {
    const indexOfValue = selections?.findIndex(
      (item) => (item.key ?? item.name) === (tag.key ?? tag.name),
    );

    if (isChecked) {
      selections.push(tag);
    } else {
      selections.splice(indexOfValue, 1);
    }

    if (onCheckChanged) {
      onCheckChanged(selections);
    }
  };

  const onSearchChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
    setSearchTerm(newValue);
  };

  const onRenderRow = (item: ITag) => {
    const stateIndex = selections?.findIndex((s) => (s.key ?? s.name) === (item.key ?? item.name));
    const disabledIndex = disabledItems?.findIndex(
      (s) => (s.key ?? s.name) === (item.key ?? item.name),
    );
    return (
      <ChecklistItem
        key={`${item.key ?? item.name}:ChecklistItem`}
        tag={item}
        onChange={onChange}
        isChecked={stateIndex >= 0}
        isDisabled={disabledIndex >= 0}
      />
    );
  };

  return (
    <div>
      {label && label?.length && <div>{label}</div>}
      {!items?.length && (
        <SearchBox
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search items"
          ariaLabel={label}
        />
      )}
      <LoadingErrorMessage loading={loading} error={error} />
      <div className={styles.container} data-is-scrollable>
        {items?.length > 0 &&
          items.map((item: ITag) => {
            return onRenderRow(item);
          })}
        {!items?.length &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          mapData(data)?.map((item: any) => {
            return onRenderRow(item);
          })}
      </div>
    </div>
  );
};

export default GenericChecklist;
