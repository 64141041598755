import { useMutation } from '@apollo/client';
import React from 'react';
import { AddTaskPropertyValue } from '../../../../utils/api/TaskPropertiesApi';
import { ITaskPropertyValue } from '../../../../utils/types/ITaskProperty';
import PanelTypes from '../../../../utils/types/PanelTypes';
import TaskPropertiesPanel from './TaskPropertiesPanel';

interface ITaskPropertyAddProps {
  parentGuid: string;
  closePanel: () => void;
  propertyName: string;
}
const TaskPropertyAdd = (props: ITaskPropertyAddProps): JSX.Element => {
  const { parentGuid, closePanel, propertyName } = props;
  const [addTaskPropertyValue, { error, loading }] = useMutation(AddTaskPropertyValue, {
    onCompleted: closePanel,
    refetchQueries: ['TaskProperty'],
  });
  const submitForm = (taskPropertyInputData: ITaskPropertyValue) => {
    addTaskPropertyValue({
      variables: {
        taskPropertyValue: {
          taskPropertyId: parentGuid,
          id: -1,
          name: taskPropertyInputData.name,
          isActive: taskPropertyInputData.isActive,
        },
      },
    });
  };
  return (
    <TaskPropertiesPanel
      data={{ id: -1, isActive: true, name: '' }}
      closePanel={closePanel}
      propertyName={propertyName}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelType={PanelTypes.Add}
    />
  );
};
export default TaskPropertyAdd;
