import {
  CommandBar,
  FontSizes,
  FontWeights,
  ICommandBarItemProps,
  IIconProps,
  IconButton,
  NeutralColors,
  Stack,
  getTheme,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../common/constants/SiteConstants';

const filterCardStyles = mergeStyles({
  backgroundColor: NeutralColors.gray20,
  borderLeftColor: getTheme().palette.themePrimary,
  borderLeftWidth: '4px',
  borderLeftStyle: 'solid',
  padding: '15px',
  boxShadow: `rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px`,
  marginBottom: '15px',
});

const filterTitleStyles = mergeStyles({
  fontSize: FontSizes.medium,
  fontWeight: FontWeights.bold,
});

export const infoCardButtonStyles = {
  root: { backgroundColor: 'transparent' },
  rootHovered: { backgroundColor: NeutralColors.white },
};

const commandBarStyle = mergeStyleSets({
  root: { backgroundColor: 'transparent' },
  primarySet: { [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: { flexDirection: 'column' } },
});

const chevronRight: IIconProps = {
  iconName: 'chevronRight',
};

const chevronDown: IIconProps = {
  iconName: 'chevronDown',
};

interface IInformationCardProps {
  cardTitle: string;
  commandBarItems: ICommandBarItemProps[];
  children: React.ReactNode;
  allCollapsed: boolean;
}

const InformationCard = (props: IInformationCardProps): JSX.Element => {
  const { cardTitle, commandBarItems, children, allCollapsed } = props;

  const [collapseState, setCollapseState] = useState(false);

  useEffect(() => {
    setCollapseState(allCollapsed);
  }, [allCollapsed]);

  const toggleCollapseState = (): void => {
    setCollapseState(!collapseState);
  };

  return (
    <div className={filterCardStyles}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <IconButton
            styles={infoCardButtonStyles}
            iconProps={collapseState ? chevronRight : chevronDown}
            title={collapseState ? `Expand ${cardTitle}` : `Collapse ${cardTitle}`}
            ariaLabel={collapseState ? `Expand ${cardTitle}` : `Collapse ${cardTitle}`}
            aria-expanded={!collapseState}
            onClick={toggleCollapseState}
          />
          <div className={filterTitleStyles}>{cardTitle}</div>
        </Stack>
        <CommandBar
          items={commandBarItems}
          ariaLabel={`${cardTitle} actions`}
          styles={commandBarStyle}
        />
      </Stack>
      {collapseState && (
        <>
          <br />
          {children}
        </>
      )}
    </div>
  );
};

export default InformationCard;
