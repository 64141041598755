import { useMutation } from '@apollo/client';
import React from 'react';
import { EditTaskPropertyValue } from '../../../../utils/api/TaskPropertiesApi';
import { ITaskPropertyValue } from '../../../../utils/types/ITaskProperty';
import PanelTypes from '../../../../utils/types/PanelTypes';
import TaskPropertiesPanel from './TaskPropertiesPanel';

interface ITaskPropertiesEditProps {
  data: ITaskPropertyValue;
  parentGuid: string;
  closePanel: () => void;
  propertyName: string;
}
const TaskPropertiesEdit = (props: ITaskPropertiesEditProps): JSX.Element => {
  const { data, parentGuid, closePanel, propertyName } = props;
  const [updateTaskPropertyValue, { loading, error }] = useMutation(EditTaskPropertyValue, {
    onCompleted: closePanel,
  });

  const submitForm = (taskPropertyInputData: ITaskPropertyValue) => {
    updateTaskPropertyValue({
      variables: {
        taskPropertyValue: {
          taskPropertyId: parentGuid,
          id: data.id,
          name: taskPropertyInputData.name,
          isActive: taskPropertyInputData.isActive,
        },
      },
    });
  };

  return (
    <TaskPropertiesPanel
      data={data}
      closePanel={closePanel}
      propertyName={propertyName}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelType={PanelTypes.Edit}
    />
  );
};
export default TaskPropertiesEdit;
