import { ICoherencePanelStyles } from '@coherence-design-system/controls';
import {
  DefaultPalette,
  Depths,
  FontSizes,
  FontWeights,
  getTheme,
  IChoiceGroupStyles,
  IDetailsColumnStyles,
  IDetailsListStyles,
  IPivotStyles,
  IProcessedStyleSet,
  IScrollablePaneStyles,
  IStackItemStyles,
  IStackStyles,
  IStyle,
  mergeStyles,
  mergeStyleSets,
  NeutralColors,
} from '@fluentui/react';
import {
  DEFAULT_NAV_MIN_WIDTH_BREAKPOINT,
  FORM_GRID_FULL_MIN_WIDTH,
  FORM_GRID_HALF_MIN_WIDTH,
  FORM_GRID_MIN_WIDTH,
  FORM_GRID_THIRDS_MIN_WIDTH,
  LG_BREAK_POINT,
  MD_BREAK_POINT,
  MOBILE_NAV_MAX_WIDTH_BREAKPOINT,
  SM_BREAK_POINT,
  VIEWPORT_HEIGHT,
  XL_BREAK_POINT,
  XSM_BREAK_POINT,
  XXL_BREAK_POINT,
} from '../constants/SiteConstants';

interface ISiteHeroContentStyle {
  largeHeroHeader: IStyle;
  content: IStyle;
  actionItemsWrapper: IStyle;
}

const mobileContentContainerMargins = {
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      marginTop: 0,
      marginRight: 19, // because 20px causes a double scroll bar on really pages with a grid
      marginLeft: 19,
    },
  },
};
const mobileContentMargins = {
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
};
const contentMarginStyles = mergeStyleSets({
  mainContent: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingLeft: 40,
    paddingRight: 40,
    minWidth: 0,
    selectors: mobileContentMargins.selectors,
  },
  mainContentWrapper: {
    padding: '15px 25px',
    backgroundColor: NeutralColors.white,
  },
});
export const flexWrapper = {
  flexGrow: 1,
  display: 'flex',

  flexDirection: 'column' as const,
};
export const mainContentContainer = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minWidth: 0,
  marginLeft: '0',
  overflowY: 'auto',
  selectors: mobileContentContainerMargins.selectors,
});

export const mainContentContainerExtended = mergeStyles({
  marginLeft: '0',
  flex: '1 1 auto',
  minWidth: 0,
  overflowY: 'auto',
  selectors: mobileContentContainerMargins.selectors,
});

export const applicationWrapper = mergeStyles({
  display: 'flex',
  height: VIEWPORT_HEIGHT,
  overflowY: 'auto',
  selectors: {
    [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
      height: 'auto',
    },
  },
});
export const alternateBackgroundColor = {
  background: NeutralColors.gray10,
};
export const bodyContentContainer = mergeStyles(contentMarginStyles.mainContent, flexWrapper);
export const bodyContentContainerAlternateBg = mergeStyles(
  contentMarginStyles.mainContent,
  alternateBackgroundColor,
);
export const bodyContentWrapper = mergeStyles(contentMarginStyles.mainContentWrapper);

export const leftNavContainer = mergeStyles({
  display: 'flex',
});
export const defaultNavWrapper = mergeStyles({
  display: 'flex',
});

/**
 * Error page styles
 */
export const siteHeroContent = (): IProcessedStyleSet<ISiteHeroContentStyle> => {
  return mergeStyleSets({
    largeHeroHeader: {
      marginTop: 0,
      marginBottom: 48,
      lineHeight: '124%',
      selectors: {
        [XXL_BREAK_POINT]: FontSizes.size68,
        [XL_BREAK_POINT]: FontSizes.size42,
        [LG_BREAK_POINT]: FontSizes.size32,
        [MD_BREAK_POINT]: FontSizes.size28,
        [SM_BREAK_POINT]: FontSizes.size20,
        [XSM_BREAK_POINT]: FontSizes.size18,
      },
    },
    content: {
      marginBottom: 0,
    },
    actionItemsWrapper: {
      marginTop: 32,
      selectors: {
        button: {
          margin: 6,
        },
      },
    },
  });
};
export const centeredWrapper = mergeStyles({
  alignItems: 'center',
  textAlign: 'center',
});

export const verticalCenterStack = mergeStyles({
  flexDirection: 'column',
  height: VIEWPORT_HEIGHT,
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
});

export const CoherencePanelStyles: ICoherencePanelStyles = {
  subComponentStyles: {
    panelStyles: {
      contentInner: {
        'h1+div': {
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          padding: '14px 24px 20px',
          margin: '0',
        },
      },
    },
  },
};

export const rowClasses = mergeStyleSets({
  medFont: {
    fontSize: FontSizes.size14,
    color: NeutralColors.black,
  },
});
export const styledDetailsList: Partial<IDetailsListStyles> = {
  root: { '.ms-DetailsHeader': { paddingTop: 0 } },
  focusZone: {
    selectors: {
      '.ms-DetailsRow-cell': {
        padding: '0 12px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      },
      '.ms-DetailsRow-cellCheck': { height: 'auto', padding: 0 },
      '.ms-DetailsRow-fields': {},
    },
  },
};

export const lockedStyledDetailsList: Partial<IDetailsListStyles> = {
  root: {
    selectors: {
      '.ms-DetailsHeader': { paddingTop: 0 },
      '.ms-DetailsHeader-cell:first-child, .ms-DetailsRow-cellCheck, .ms-DetailsRow-cell:nth-of-type(1), .ms-DetailsHeader-cell:nth-of-type(2)':
        {
          position: 'sticky',
          inset: '0',
          backgroundColor: 'white',
          zIndex: '1',
          marginTop: '0 !important',
        },
      '.ms-DetailsRow-fields .ms-DetailsRow-cell:nth-of-type(1), .ms-DetailsHeader-cell:nth-of-type(2)':
        {
          left: 48,
        },
      '.ms-DetailsRow-fields .ms-DetailsRow-cell:nth-of-type(1)': {
        padding: '7px 13px 7px 7px',
        borderRight: '1px solid #eee',
      },
    },
  },
  focusZone: {
    root: {
      minHeight: 'auto',
    },
    selectors: {
      '.ms-DetailsRow-cell': {
        padding: '0 12px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      },
      '.ms-DetailsRow-cellCheck': { height: 'auto', padding: 0 },
      '.ms-DetailsRow-fields': {},
    },
  },
};
export const separatorStyles = {
  root: {
    background: 'white',
    margin: '0 0 10px',
    padding: 0,
    height: 2,
  },
};

export const formChoiceGroup: IChoiceGroupStyles = {
  root: {
    selectors: {
      '.ms-ChoiceField': {
        marginTop: 2,
      },
    },
  },
};

export const cardBackground: IStackItemStyles = {
  root: {
    backgroundColor: NeutralColors.white,
    boxShadow: Depths.depth4,
  },
};
export const formInputCardStyles: IStackItemStyles = {
  root: {
    backgroundColor: NeutralColors.white,
    boxShadow: Depths.depth4,
    padding: '15px 0',
    marginTop: 32,
  },
};
export const formGridLabel = { root: { padding: '0 15px' } };
export const formGridRowStyles = { root: { padding: ' 0px 3px' } };
export const formGridColumnWrapperStyles = { root: { padding: '8px 12px' } };
export const formGridHiddenColumnStyles = {
  root: {
    display: 'none',
  },
};
export const formGridSingleColumnStyles = {
  root: {
    selectors: {
      [FORM_GRID_MIN_WIDTH]: {
        flexBasis: '16.66%',
        maxWidth: '16.66%',
      },
      [FORM_GRID_THIRDS_MIN_WIDTH]: {
        flexBasis: '33.33%',
        maxWidth: '33.33%',
      },
      [FORM_GRID_HALF_MIN_WIDTH]: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
      [FORM_GRID_FULL_MIN_WIDTH]: {
        flexBasis: '100%',
        width: '100%',
      },
    },
  },
};
export const formGridDoubleColumnStyles = {
  root: {
    selectors: {
      [FORM_GRID_MIN_WIDTH]: {
        flexBasis: '33.33%',
        maxWidth: '33.33%',
      },
      [FORM_GRID_THIRDS_MIN_WIDTH]: {
        flexBasis: '66.66%',
        maxWidth: '66.66%',
      },
      [FORM_GRID_HALF_MIN_WIDTH]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
      [FORM_GRID_FULL_MIN_WIDTH]: {
        flexBasis: '100%',
        width: '100%',
      },
    },
  },
};
export const formGridTripleColumnStyles = {
  root: {
    selectors: {
      [FORM_GRID_MIN_WIDTH]: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
      [FORM_GRID_THIRDS_MIN_WIDTH]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
      [FORM_GRID_HALF_MIN_WIDTH]: {
        flexBasis: '100%',
        maxWidth: '100%',
      },
      [FORM_GRID_FULL_MIN_WIDTH]: {
        flexBasis: '100%',
        width: '100%',
      },
    },
  },
};

export const showMoreActionButtonStyles = {
  root: {
    textAlign: 'left',
    fontSize: FontSizes.size14,
    padding: 0,
    margin: 0,
    color: DefaultPalette.blue,
  },
};

export const errorLabelStyles = {
  root: {
    // color: MessagingColors.errorBlockIcon,
    fontWeight: '400',
    fontSize: FontSizes.size12,
    margin: '5px 0px 0px',
  },
};

export const accordionFilterHeaderStyles = {
  root: {
    textAlign: 'left',
    width: '100%',
    height: 50,
    fontWeight: '600',
    fontSize: FontSizes.size16,
  },
};

export const getSortableColumnHeaderStyles = (isSorted: boolean): Partial<IDetailsColumnStyles> => {
  return {
    root: {
      background: isSorted ? NeutralColors.gray20 : 'inherit',
      paddingTop: 0,
      selectors: {
        '.ms-DetailsHeader-cellName': {
          '&.is-actionable': {
            cursor: 'pointer',
          },
        },
        '.is-icon-visible': {
          fontWeight: FontWeights.semibold,
        },
      },
    },
  };
};

export const desktopOnlyScrollable: Partial<IScrollablePaneStyles> = {
  root: {
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        position: 'relative',
      },
    },
  },
  contentContainer: {
    selectors: {
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        position: 'relative',
      },
    },
  },
};
export const resetScrollablePanelStyles: Partial<IScrollablePaneStyles> = {
  root: { position: 'relative' },
  contentContainer: { position: 'relative' },
};

export const defaultListContainerStyles: IStackStyles = {
  root: { marginTop: 10, position: 'relative', flexGrow: 1 },
};

export const pivotWrapperStyles: Partial<IPivotStyles> = {
  root: { marginBottom: 10, display: 'flex' },
  itemContainer: {
    selectors: {
      [DEFAULT_NAV_MIN_WIDTH_BREAKPOINT]: {
        flexGrow: 1,
        display: 'flex',
      },
    },
  },
};

export const getPickerStyles = (errorMessage: string) => {
  return errorMessage
    ? { text: { borderColor: getTheme().semanticColors.errorIcon }, root: { marginTop: '0' } }
    : { root: { marginTop: '0' } };
};

export const panelFooter = mergeStyles({
  backgroundColor: NeutralColors.white,
  margin: '-16px -12px -16px -12px',
  padding: '16px 24px',
});

export const expandButtonStyle = mergeStyles({
  width: '200px',
  padding: 5,
});
