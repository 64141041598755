/* eslint-disable react/prop-types */
import { DefaultButton, Icon, Link, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { GET_ALL_ROLES } from '../../utils/api/UsersApi';
import { IRole } from '../../utils/types/IUser';
import InformationCard from './InformationCard';

interface IRoleInfoPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const RoleInfoPanel: React.FC<IRoleInfoPanelProps> = ({ isOpen, onDismiss }) => {
  const { data, loading, error } = useQuery(GET_ALL_ROLES);
  const roles: IRole[] = data?.allRoles;
  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.smallFixedFar}
      headerText="All Roles"
      closeButtonAriaLabel="Close"
    >
      <p>
        Listed are all the roles available to choose in FCCW. Expand a role to view its policies. To
        change the roles or policies you have assigned&nbsp;
        <Link
          href="https://microsoft.sharepoint.com/teams/FCCW/SitePages/Access-for-FCCW-Explained.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here for more details <Icon iconName="Info" aria-label="Information Icon" />
        </Link>
      </p>
      <LoadingErrorMessage loading={loading} error={error} />
      {!loading &&
        !error &&
        roles?.map((role) => {
          return (
            <InformationCard cardTitle={role.role} commandBarItems={[]} allCollapsed={false}>
              <ul>
                {role.policies?.map((policy) => {
                  return <li>{policy.name}</li>;
                })}
              </ul>
            </InformationCard>
          );
        })}
      <DefaultButton text="Close" onClick={onDismiss} />
    </Panel>
  );
};

export default RoleInfoPanel;
