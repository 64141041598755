import { IFcwPolicy } from '../types/IFcwPolicy';

export default function hasAccess(userPermissions: IFcwPolicy[], action = ''): boolean {
  if (!userPermissions) {
    return false;
  }

  const hasPermission = userPermissions.find((permission) => {
    return permission.name === action;
  });
  return !!hasPermission;
}
