import React from 'react';
import IntegratedClientApp from '../IntegratedClientApp';
import config from '../../../utils/ConfigLoader';

const RIIntegratedExperience = (): JSX.Element => {
  const microUiSettings = config?.settings?.reconIntelligenceConfig;

  return (
    <IntegratedClientApp
      scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.integratedFilePath}`}
      appName="RIGridApp"
      data={{
        baseUrl: microUiSettings?.siteUrl,
        baseName: 'ri',
      }}
      supportLink="https://reconintelligence.microsoft.com/"
    />
  );
};

export default RIIntegratedExperience;
