import { IDropdownOption } from '@fluentui/react';
import moment, { Moment } from 'moment-timezone';
import { FiscalPeriodMonths, FiscalPeriodValues } from '../types/IFrequencyChoices';
import { TimezoneConstants, DateConstants } from './DateConstants';

const monthIndexCorrection = 1;

export const fiscalPeriodToMonth = (fiscalPeriod: number): string => {
  if (fiscalPeriod <= 0 || fiscalPeriod > 12) {
    return `Invalid Period (${fiscalPeriod})`;
  }

  return FiscalPeriodMonths[fiscalPeriod - 1];
};

export const monthToFiscalPeriod = (month: number) => {
  let fiscalPeriod = month + 6 + monthIndexCorrection;
  if (fiscalPeriod > 12) fiscalPeriod -= 12;
  return fiscalPeriod;
};

export const yearToFiscalYear = (year: number, month: number) => {
  if (month > 6 - monthIndexCorrection) return year + 1;
  return year;
};

export const fiscalMonthtoPeriod = (fiscalPeriod: string) => {
  return Number(fiscalPeriod.replace(/[^0-9]/g, ''));
};
const momentUtc = (
  date: Date | string | number,
  format = DateConstants.DaySimple,
  isDisplayOnly = false,
): string => {
  if (!date && isDisplayOnly) {
    return '--';
  }
  const parsedDate = moment(date).utc().format(format);
  return parsedDate === 'Invalid date' && isDisplayOnly ? '--' : parsedDate;
};
export const momentDateToTimezone = (
  date: Date | string | number,
  format = DateConstants.DaySimple,
  timezone = TimezoneConstants.PST,
  isDisplayOnly = false,
): string => {
  if (!date && isDisplayOnly) {
    return '--';
  }
  const parsedDate = moment.utc(date).tz(timezone).format(format);
  return parsedDate === 'Invalid date' && isDisplayOnly ? '--' : parsedDate;
};

export const dateTimeToMoment = (
  date: Date | string | number,
  time: string,
  timezone: string = TimezoneConstants.PST,
): Moment => {
  if (!date) {
    return null;
  }

  return moment.tz(`${date} ${time}`, timezone);
};

export const calculateDate = (year: number, month: number): string => {
  if (month > 6) {
    year -= 1;
  }
  const fiscalDate = `${month}/12/${year}`;
  return fiscalDate;
};

export const calculateFiscalYear = (year: number | string, month: number): number => {
  if (month > 6) {
    return Number(year) - 1;
  }
  return Number(year);
};

export const getFiscalInfoFromDate = (date: Date) => {
  const month = date.getMonth() + monthIndexCorrection;
  const isFirstFiscalHalf = month > 6;
  const fiscalPeriod = isFirstFiscalHalf ? month - 6 : month + 6;
  const fiscalYear = isFirstFiscalHalf ? date.getFullYear() + 1 : date.getFullYear();
  return {
    fiscalYear,
    fiscalPeriod,
  };
};

export const fiscalYearValues = (): IDropdownOption[] => {
  const thisYear = getFiscalInfoFromDate(new Date()).fiscalYear;
  const fiscalYearList = Array.from(new Array(3), (val, index) => index + thisYear);
  let fiscalYearDropdownValues = [] as IDropdownOption[];
  fiscalYearDropdownValues = fiscalYearList.map((year, index) => {
    return { key: year, text: year.toString() };
  });
  return fiscalYearDropdownValues;
};
export const getSelectedFiscalPeriod = (fiscalPeriod: number): string => {
  const flist = FiscalPeriodValues;
  const c = flist.find((k) => {
    return k.key === fiscalPeriod;
  });
  return c?.text;
};

export const defaultFiscalYear = (year: number): string | number => {
  const flist = fiscalYearValues();
  let fyear = null;
  const c = Object.values(flist).find((k) => {
    if (k.text === year.toString()) {
      return k.text;
    }
    return '';
  });
  fyear = c.key;
  return fyear;
};

export const getCalendarInfoFromFiscalInfo = (fiscalPeriod: number, fiscalYear: number) => {
  const isFirstFiscalHalf = fiscalPeriod < 7;
  const month = (isFirstFiscalHalf ? fiscalPeriod + 6 : fiscalPeriod - 6) - monthIndexCorrection;
  const year = isFirstFiscalHalf ? fiscalYear - 1 : fiscalYear;
  return {
    month,
    year,
  };
};
export const datediff = (date1: string, date2: string) => {
  if (!date1 || !date2) return '';
  const fromDate = moment(date1);
  const toDate = moment(date2);
  const diffInDays = toDate.diff(fromDate, 'days');
  if (diffInDays === 0) return '(1 day)';
  return `(${diffInDays + 1} days)`;
};
export const formatDate = (date: string) => {
  if (!date) return '';
  return momentUtc(date, DateConstants.TwoDigitDate);
};
export const formatDateReadable = (date: string) => {
  if (!date) return '';
  return moment(date).format(DateConstants.DayBreakout);
};
export const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0);
};
export const getLastWeekdayOfMonth = (year?: number, month?: number) => {
  let currentDate = new Date();
  if (year || month) currentDate = new Date(year, month);

  let lastDay = moment(currentDate).endOf('month');

  // Subtract day until weekday is reached
  while (lastDay.day() === 0 || lastDay.day() === 6) {
    lastDay = lastDay.subtract(1, 'day');
  }

  return lastDay.format(DateConstants.DayBreakout);
};
export const getMostRecentMonday = (date: Date) => {
  if (date.getDay() === 1) {
    return date;
  }
  const resultDate = new Date(date);
  resultDate.setDate(date.getDate() + (date.getDay() === 0 ? -6 : -(date.getDay() - 1)));
  return resultDate;
};
export const getSoonestSunday = (date: Date) => {
  // in javascript, when looking at date.getDay(), 0 = sunday, 6 = saturday.
  const resultDate = new Date(date);
  if (date.getDay() === 0) {
    // It's already Saturday
    return date;
  }
  resultDate.setDate(date.getDate() + (7 - date.getDay()));
  return resultDate;
};
export const getAutoPublishDate = () => {
  const currentDate = new Date();
  const autoPublishDate = moment(currentDate)?.date(28);
  if (!autoPublishDate) {
    return '--';
  }
  return autoPublishDate.format(DateConstants.DayBreakout);
};
