import React, { useEffect, useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, Stack } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import { getPickerStyles } from '../styles/CommonStyleObjects';
import ISalesLocation from '../../utils/types/ISalesLocation';
import GetSalesLocationsByFilter from '../../utils/api/GeographyApi';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';

export interface ISalesLocationTagPickerProps {
  defaultValue: ISalesLocation[];
  onChange: (salesLocations: ISalesLocation[]) => void;
  itemLimit?: number;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder: string;
  isActive?: boolean;
  value?: ISalesLocation[];
  disabled?: boolean;
}

export interface ITagWithSalesLocation extends ITag {
  code?: string;
  area?: string;
  region?: string;
  subRegion?: string;
  selected?: boolean;
}

export const mapSalesLocationToTag = (salesLocation: ISalesLocation): IGroupedChecklistItem => {
  return {
    key: salesLocation.code,
    name: `${salesLocation.name} (${salesLocation.area})`,
    selected: salesLocation.selected,
  } as ITag;
};

export const mapTagsToSalesLocations = (tags: IGroupedChecklistItem[]): ISalesLocation[] => {
  return tags?.map((salesLocationTag) => {
    return {
      code: salesLocationTag.key,
      name: salesLocationTag.name,
      selected: salesLocationTag.selected,
    } as ISalesLocation;
  });
};

export const mapSalesLocationsToTags = (
  salesLocations: ISalesLocation[],
): IGroupedChecklistItem[] => {
  return salesLocations?.map((salesLocation) => {
    return mapSalesLocationToTag(salesLocation);
  });
};

export const mapSalesLocationNoTypename = (saleLocations: ISalesLocation[]): ISalesLocation[] => {
  return saleLocations?.map((location) => {
    return {
      key: location.code,
      name: location.name,
      code: location.code,
      area: location.area,
      region: location.region,
      subRegion: location.subRegion,
      selected: location.selected,
    };
  });
};

const SalesLocationPicker: React.FunctionComponent<ISalesLocationTagPickerProps> = ({
  defaultValue,
  itemLimit,
  required,
  errorMessage,
  label,
  onChange,
  placeholder,
  isActive = true,
  value,
  disabled,
}: ISalesLocationTagPickerProps): JSX.Element => {
  const [loadSalesLocationTags, { error }] = useLazyQuery(GetSalesLocationsByFilter);
  const [selectedSalesLocationTags, setSelectedSalesLocationTags] = useState<
    ITagWithSalesLocation[]
  >(mapSalesLocationsToTags(defaultValue));
  const onResolveSuggestions = async (filterText: string): Promise<ITagWithSalesLocation[]> => {
    return loadSalesLocationTags({
      variables: {
        keyword: filterText,
        isActive,
      },
    }).then((result: QueryResult<{ salesLocations: ISalesLocation[] }, OperationVariables>) => {
      const salesLocationTags: ISalesLocation[] = result?.data?.salesLocations;
      return mapSalesLocationsToTags(salesLocationTags).filter((salesLocationTag) => {
        const duplicate = selectedSalesLocationTags?.find((fs) => fs.key === salesLocationTag.key);
        return !duplicate;
      });
    });
  };

  useEffect(() => {
    if (value) {
      setSelectedSalesLocationTags(mapSalesLocationsToTags(value));
    }
  }, [value]);

  return (
    <Stack>
      {label && (
        <Label htmlFor="salesLocationPicker" required={required} disabled={disabled}>
          {label}
        </Label>
      )}
      <TagPicker
        disabled={disabled}
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Functions Summary"
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
        }}
        inputProps={{
          id: 'companyPicker',
          'aria-label': label || 'Value',
          placeholder: placeholder || 'Search Functions Summary',
          required: true,
        }}
        itemLimit={itemLimit}
        resolveDelay={300}
        selectedItems={selectedSalesLocationTags}
        onChange={(items?: ITagWithSalesLocation[]): void => {
          setSelectedSalesLocationTags(items);
          onChange(mapTagsToSalesLocations(items));
        }}
        styles={getPickerStyles(errorMessage)}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage || error?.message} />
    </Stack>
  );
};

export default SalesLocationPicker;
