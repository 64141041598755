import { IDropdownOption, ITextFieldProps, TextField } from '@fluentui/react';
import { FieldValue } from 'react-hook-form';
import { FormEvent } from 'react';
import GraphUsersPeoplePicker, {
  IGraphUsersPeoplePicker,
} from '../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../utils/types/IAadUser';
import { IDraftTask } from '../../../utils/types/ITask';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';

export interface IFormInputChoice extends IDropdownOption {
  component: (props: unknown) => React.ReactElement;
  props?: () => IGraphUsersPeoplePicker | ITextFieldProps;
  isMultiValue?: boolean;
}

export const getFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>,
  ) => void,
): IFormInputChoice[] => {
  return [
    {
      key: 'primaries',
      text: 'Primary',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'primary',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('primaries', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupTaskPerformers',
      text: 'Backup',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backup',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('backupTaskPerformers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'stakeholders',
      text: 'Stakeholder',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'stakeholder',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('stakeholders', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'dueTime',
      text: 'Time due',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dueTime', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dueDate',
      text: 'Due Date',
      component: UtcDatePicker,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dueDate', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'publishedTaskNotes',
      text: 'Published task notes',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('publishedTaskNotes', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
  ];
};

export const getUpdateTaskMapper = (taskInputData: IDraftTask) => {
  return {
    primaries: taskInputData.primaries,
    backupTaskPerformers: taskInputData.backupTaskPerformers,
    stakeholders: taskInputData.stakeholders,
    dueTime: taskInputData.dueTime,
    dueDate: taskInputData.dueDate,
    publishedTaskNotes: taskInputData.publishedTaskNotes,
  };
};

export const getRemoveTaskMapper = (taskInputData: IDraftTask) => {
  return {
    primaries: taskInputData.primaries,
    backupTaskPerformers: taskInputData.backupTaskPerformers,
    stakeholders: taskInputData.stakeholders,
  };
};
