import React, { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  Label,
  MaskedTextField,
  Stack,
  TextField,
} from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  CoherencePanelStyles,
  formChoiceGroup,
} from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import { IOpsOrgDetail } from '../../../../utils/types/IHierarchy';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import CompanyPicker from '../../../../common/formFields/CompanyPicker';
import GraphUsersPeoplePicker, {
  mapUsersNoTypename,
} from '../../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../../utils/types/IAadUser';
import arrayMustNotBeEmpty, {
  timeIn24HourFormat,
} from '../../../../utils/validation/CustomValidations';
import ICompany from '../../../../utils/types/ICompany';
import ModifiedDetails from '../../../../common/auditedEntity/ModifiedDetails';
import PanelTypes from '../../../../utils/types/PanelTypes';
import OpsOrgDropDown, {
  IOpsOrgDropdownReference,
} from '../../../../common/formFields/OpsOrgDropdown';
import AllActiveCompanyCodes from '../../../../common/formFields/AllActiveCompanyCodes';
import yesNo from '../../../../common/formFields/YesNoOptions';
import { PEOPLE_PICKER_LIMIT } from '../../../../common/constants/SiteConstants';

export interface IOpsOrgDetailPanelProps {
  data: IOpsOrgDetail;
  mutation: (opsOrgDetailDtoData: IOpsOrgDetail) => void;
  closePanel: (shouldRefresh: boolean) => void;
  mutationError: ApolloError | undefined;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const OpsOrgDetailPanel: React.FunctionComponent<IOpsOrgDetailPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: IOpsOrgDetailPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const [opsSummaryName, setOpsSummaryName] = useState(null);
  const [allActiveChecked, setAllActiveChecked] = useState(false);
  const handleOnSaveClick = (formData: IOpsOrgDetail): void => {
    mutation(formData);
  };

  if (panelMode === PanelTypes.Edit) {
    useEffect(() => {
      if (
        data?.companyCodes !== undefined &&
        data?.companyCodes.filter((company: { name: string }) => company.name === 'All Active')
          .length > 0
      ) {
        setAllActiveChecked(true);
      }
    }, []);
  }
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.medium}
      titleText={`${panelMode} ops detail`}
      isOpen
      onDismiss={() => {
        closePanel(false);
      }}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Ops Org Detail"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={() => {
            closePanel(false);
          }}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="Saving ops org detail"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          {panelMode === PanelTypes.Edit && (
            <ModifiedDetails
              modifiedOn={data?.modifiedOn}
              modifiedBy={data?.modifiedBy?.displayName}
            />
          )}
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                as={TextField}
                label="Ops detail"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: 'Please provide an ops detail',
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                label="Ops org"
                id="opsOrgId"
                required
                name="opsOrgId"
                control={control}
                resizable={false}
                defaultValue={data?.opsOrg?.id}
                render={(fieldProps) => (
                  <OpsOrgDropDown
                    label="Ops org"
                    valueKey="opsOrgId"
                    required
                    value={fieldProps.value}
                    handleChange={(newValue: IOpsOrgDropdownReference, valueKey: string): void => {
                      setValue('opsOrgId', newValue.key);
                      setOpsSummaryName(newValue.opsSummaryName);
                      setAllActiveChecked(false);
                    }}
                    errors={errors}
                  />
                )}
                rules={{
                  required: 'Please provide an ops org',
                }}
              />
              <Label>Ops summary</Label>
              <>{opsSummaryName || data?.opsOrg?.opsSummary?.name || 'Please select an ops org'}</>
              <Controller
                id="companyCodes"
                name="companyCodes"
                control={control}
                defaultValue={data?.companyCodes?.map((c): ICompany => {
                  return {
                    companyCode: c.companyCode,
                    countryCode: c.countryCode,
                    name: c.name,
                  };
                })}
                render={({ value }): React.ReactElement => {
                  return (
                    <Stack tokens={{ childrenGap: 10 }}>
                      <CompanyPicker
                        id="companyCode"
                        value={value}
                        defaultValue={value}
                        onChange={(companyCodeTags: ICompany[]) => {
                          setValue('companyCodes', companyCodeTags);
                        }}
                        required
                        errorMessage={errors?.companyCodes?.message}
                        label="Default company code(s)"
                        placeholder="Select one or more company code(s)"
                        disabled={allActiveChecked}
                      />
                      <AllActiveCompanyCodes
                        checked={allActiveChecked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAllActiveChecked(e.target.checked);
                          if (!allActiveChecked) {
                            setValue('companyCodes', [
                              { name: 'All Active', companyCode: 0, countryCode: 'US' },
                            ]);
                          } else {
                            setValue('companyCodes', []);
                          }
                        }}
                      />
                    </Stack>
                  );
                }}
                rules={{
                  validate: { arrayMustNotBeEmpty },
                }}
              />

              <Controller
                id="deadline"
                name="deadline"
                control={control}
                required
                defaultValue={data?.deadline}
                render={({ value }) => {
                  return (
                    <MaskedTextField
                      value={value}
                      label="Deadline"
                      required
                      mask="99:99"
                      suffix="PST HH:MM"
                      errorMessage={errors?.deadline?.message}
                      onChange={(e, newValue) => {
                        setValue('deadline', newValue);
                      }}
                    />
                  );
                }}
                rules={{
                  required: 'Please provide deadline',
                  validate: {
                    timeIn24HourFormat,
                  },
                  // add custom validation
                }}
              />
              <Controller
                name="isActive"
                control={control}
                defaultValue={data?.isActive}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="isActive"
                      label="Is active"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.isActive ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('isActive', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="autoPublish"
                control={control}
                defaultValue={data?.autoPublish}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="autoPublish"
                      label="Auto publish"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.autoPublish ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('autoPublish', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
              <Stack>
                <Label>Checklist management</Label>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Controller
                  name="checklistOwners"
                  valueKey="checklistOwners"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.checklistOwners)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="checklistOwners"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove checklist owners"
                        controlName="checklistOwners"
                        onChange={(users: IAadUser[]): void => {
                          setValue('checklistOwners', users);
                        }}
                        errorMessage={errors?.checklistOwners?.message}
                        defaultValue={value}
                        placeholder="Type one or more Checklist owner(s)"
                        isMultiUserAddable
                        label="Checklist owner(s)"
                        width="50%"
                        isRequired
                      />
                    );
                  }}
                  rules={{
                    validate: { arrayMustNotBeEmpty },
                  }}
                />
                <Controller
                  name="backupTaskPerformers"
                  valueKey="backupTaskPerformers"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.backupTaskPerformers)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="backupTaskPerformers"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove backup task performer"
                        controlName="backupTaskPerformers"
                        onChange={(users: IAadUser[]): void => {
                          setValue('backupTaskPerformers', users);
                        }}
                        errorMessage={errors?.backupTaskPerformers?.message}
                        defaultValue={value}
                        placeholder="Type one or more Backup task performer(s)"
                        isMultiUserAddable
                        label="Backup task performer(s)"
                        width="50%"
                        isRequired
                      />
                    );
                  }}
                  rules={{
                    validate: { arrayMustNotBeEmpty },
                  }}
                />
              </Stack>
              <Stack>
                <Label>Journal entry user lists</Label>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Controller
                  name="backupJePosters"
                  valueKey="backupJePosters"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.backupJePosters)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="backupJePoster"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove backup JE poster"
                        controlName="backupJePosters"
                        onChange={(users: IAadUser[]): void => {
                          setValue('backupJePosters', users);
                        }}
                        errorMessage={errors?.backupJePosters?.message}
                        defaultValue={value}
                        placeholder="Type one or more Backup JE poster(s)"
                        isMultiUserAddable
                        label="Backup JE poster(s)"
                        width="50%"
                      />
                    );
                  }}
                />
                <Controller
                  name="backupJeReviewers"
                  valueKey="backupJeReviewers"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.backupJeReviewers)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="backupJeReviewer"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove backup JE reviewer"
                        controlName="backupJeReviewers"
                        onChange={(users: IAadUser[]): void => {
                          setValue('backupJeReviewers', users);
                        }}
                        errorMessage={errors?.backupJeReviewers?.message}
                        defaultValue={value}
                        placeholder="Type one or more Backup JE reviewer(s)"
                        isMultiUserAddable
                        label="Backup JE reviewer(s)"
                        width="50%"
                      />
                    );
                  }}
                />
              </Stack>

              <Stack>
                <Label>Reconciler user lists</Label>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Controller
                  name="backupRiReconcilers"
                  valueKey="backupRiReconcilers"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.backupRiReconcilers)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="backupRiReconciler"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove backup reconciler"
                        controlName="backupRiReconcilers"
                        onChange={(users: IAadUser[]): void => {
                          setValue('backupRiReconcilers', users);
                        }}
                        errorMessage={errors?.backupRiReconcilers?.message}
                        defaultValue={value}
                        placeholder="Type one or more Backup reconciler(s)"
                        isMultiUserAddable
                        label="Backup reconciler(s)"
                        width="50%"
                      />
                    );
                  }}
                />
                <Controller
                  name="backupRiReviewers"
                  valueKey="backupRiReviewers"
                  control={control}
                  defaultValue={mapUsersNoTypename(data?.backupRiReviewers)}
                  render={({ value }) => {
                    return (
                      <GraphUsersPeoplePicker
                        id="backupRiReviewer"
                        itemLimit={PEOPLE_PICKER_LIMIT}
                        removeButtonAriaLabel="Remove backup reviewer"
                        controlName="backupRiReviewers"
                        onChange={(users: IAadUser[]): void => {
                          setValue('backupRiReviewers', users);
                        }}
                        errorMessage={errors?.backupRiReviewers?.message}
                        defaultValue={value}
                        placeholder="Type one or more Backup reviewer(s)"
                        isMultiUserAddable
                        label="Backup reviewer(s)"
                        width="50%"
                      />
                    );
                  }}
                />
              </Stack>
              <Controller
                name="backupRiApprovers"
                valueKey="backupRiApprovers"
                control={control}
                defaultValue={mapUsersNoTypename(data?.backupRiApprovers)}
                render={({ value }) => {
                  return (
                    <GraphUsersPeoplePicker
                      id="backupRiApprover"
                      itemLimit={PEOPLE_PICKER_LIMIT}
                      removeButtonAriaLabel="Remove backup approver"
                      controlName="backupRiApprovers"
                      onChange={(users: IAadUser[]): void => {
                        setValue('backupRiApprovers', users);
                      }}
                      errorMessage={errors?.backupRiApprovers?.message}
                      defaultValue={value}
                      placeholder="Type one or more backupRiApprovers"
                      mustBeFte
                      isMultiUserAddable
                      label="Backup approver(s) - FTEs only"
                      width="50%"
                    />
                  );
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default OpsOrgDetailPanel;
