import React from 'react';
import IntegratedClientApp from '../IntegratedClientApp';
import config from '../../../utils/ConfigLoader';

const StatIntegratedExperience = (): JSX.Element => {
  const microUiSettings = config?.settings?.statConfig;

  return (
    <IntegratedClientApp
      scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.integratedFilePath}`}
      appName="StatSharedComponents"
      data={{
        baseUrl: microUiSettings?.siteUrl,
        baseName: 'stat',
      }}
      supportLink="https://stat.microsoft.com/" // TODO Need to update this link
    />
  );
};

export default StatIntegratedExperience;
