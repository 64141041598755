import { useContext } from 'react';
import AppConfigValueContext from '../../utils/appConfigValues/AppConfigValuesContext';
import AppConfigValues from '../../utils/appConfigValues/AppConfigValues';

interface IFeatureFlaggedProps {
  flagName: string;
  children: JSX.Element;
}

const FeatureFlagged = ({ flagName, children }: IFeatureFlaggedProps): JSX.Element => {
  const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);

  const { flags } = appConfigValues;
  const isActive = flags ? flags[flagName] : false;

  return isActive ? children : null;
};

export default FeatureFlagged;
