import React, { useState } from 'react';
import { Stack, IconButton } from '@fluentui/react';
import { IProjectNileWorkflow } from '../../utils/types/IProjectNileWorkflow';
import NileWorkflowTagPicker from './NileWorkflowTagPicker';
import NileWorkflowsPanel from '../../components/tasks/edit/NileWorkflowsPanel';

export interface INileWorkflowSelectorProps {
  defaultValue: IProjectNileWorkflow[];
  onChange: (nileWorkflows: IProjectNileWorkflow[]) => void;
}

const NileWorkflowSelector: React.FunctionComponent<INileWorkflowSelectorProps> = ({
  defaultValue,
  onChange,
}: INileWorkflowSelectorProps): JSX.Element => {
  const [nileWorkflowsPanelVisible, setNileWorkflowsPanelVisible] = useState(false);

  return (
    <Stack>
      <Stack horizontal>
        <NileWorkflowTagPicker
          label="Workflow"
          selectedWorkflows={defaultValue}
          placeholder="Select a workflow"
          onInputClicked={() => setNileWorkflowsPanelVisible(true)}
        />
        <IconButton
          title="Select a Workflow"
          ariaLabel="Select a Workflow"
          iconProps={{
            iconName: 'TriggerAuto',
          }}
          onClick={() => setNileWorkflowsPanelVisible(true)}
        />
        {nileWorkflowsPanelVisible && (
          <NileWorkflowsPanel
            selectedWorkflows={defaultValue}
            onSave={onChange}
            closePanel={() => {
              setNileWorkflowsPanelVisible(false);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default NileWorkflowSelector;
