import React from 'react';
import { Icon, Link, Stack, Text } from '@fluentui/react';

const helpSectionHeaderStyles = {
  root: { fontWeight: '600', paddingBottom: 10 },
};
interface IHelpSectionLink {
  href: string;
  name: string;
}
const resourcesConfig = [
  {
    href: 'https://onefinance.microsoftcrmportals.com/finsuphome/',
    name: 'Contact Finsup',
  },
] as IHelpSectionLink[];

const getExternalLink = (link: IHelpSectionLink) => {
  return (
    <Link key={link.name} target="_blank" rel="noopener noreferrer" href={link.href}>
      {link.name} <Icon iconName="NavigateExternalInline" />
    </Link>
  );
};
const JemHelpPanel = (): JSX.Element => {
  return (
    <Stack>
      <Text variant="mediumPlus" styles={helpSectionHeaderStyles}>
        Help
      </Text>
      <Stack tokens={{ childrenGap: 5 }}>
        {resourcesConfig.map((link) => {
          return getExternalLink(link);
        })}
      </Stack>
    </Stack>
  );
};

export default JemHelpPanel;
