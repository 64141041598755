import { IColumn, ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import { fiscalPeriodToMonth } from '../../utils/formatters/DateFormatters';
import enumFormatter from '../../utils/formatters/EnumFormatter';
import { deepCopy } from '../../utils/Helpers';
import { IFiscalYearPeriod } from '../../utils/types/ICalendarDate';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import DownloadButton from '../buttons/DownloadButton';
import { mapPublishedTaskFilterTagsToIds } from './list/BaseTaskListfilters.config';
import ProgressItem from '../clientApps/commonComponents/ProgressItem';

const getFiscalYearPeriodListColumns = (
  taskTypeUrlPath: string,
  filters: IGenericFilterTag[],
): IColumn[] => {
  return [
    {
      key: 'fiscalYear',
      fieldName: 'fiscalYear',
      name: 'Fiscal year period',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      onRender: (item: IFiscalYearPeriod) => {
        const fiscalMonth = fiscalPeriodToMonth(item.period);
        return (
          (item?.fiscalYear && (
            <Link to={`/${taskTypeUrlPath}/${item.fiscalYear}/${item.period}`}>
              {`${fiscalMonth} (P${item.period} FY${item.fiscalYear.toString().slice(2)})`}
            </Link>
          )) || <></>
        );
      },
    },
    {
      key: 'taskCount',
      fieldName: 'taskCount',
      name: 'Task Count',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'tasksNotStarted',
      fieldName: 'tasksNotStarted',
      name: 'Not Started',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'tasksStarted',
      fieldName: 'tasksStarted',
      name: 'Started',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'tasksNotPerformed',
      fieldName: 'tasksNotPerformed',
      name: 'Not Performed',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'tasksCompleted',
      fieldName: 'tasksCompleted',
      name: 'Completed',
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'tasksCompletePercentage',
      fieldName: 'tasksCompletePercentage',
      name: 'Done %',
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: IFiscalYearPeriod) => {
        return <ProgressItem percentComplete={item?.tasksCompletePercentage || 0} />;
      },
    },
    {
      key: 'export',
      fieldName: 'id',
      name: 'Export',
      minWidth: 207,
      maxWidth: 207,
      onRender: (item: IFiscalYearPeriod) => {
        const filteredParams = deepCopy(filters);
        filteredParams.push({
          dataKey: 'fiscalYear',
          title: 'fiscalYear',
          values: [{ name: 'fiscalYear', key: item.fiscalYear }],
        });
        filteredParams.push({
          dataKey: 'period',
          title: 'period',
          values: [{ name: 'period', key: item.period }],
        });

        const queryParams = mapPublishedTaskFilterTagsToIds(filteredParams);
        queryParams.period = item.period;
        queryParams.fiscalYear = item.fiscalYear;

        queryParams.publishType = queryParams.publishType?.map((publishType: string) => {
          return enumFormatter(publishType).replace(/ /g, '');
        });
        return (
          <DownloadButton
            key={`Export${item.period}${item.fiscalYear}`}
            text="Export tasks"
            fileEndpoint="Tasks?status=Published"
            fileName="Tasks"
            postBody={JSON.stringify(queryParams)}
            hideMenuItemAriaRole={false}
            buttonStyles={{ root: { height: 44 } }}
          />
        );
      },
    },
  ];
};
export const getDraftFiscalYearPeriodListColumns = (taskTypeUrlPath: string): IColumn[] => {
  return [
    {
      key: 'fiscalYear',
      fieldName: 'fiscalYear',
      name: 'Fiscal year period',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      onRender: (item: IFiscalYearPeriod) => {
        const fiscalMonth = fiscalPeriodToMonth(item.period);
        return (
          (item?.fiscalYear && (
            <Link to={`/${taskTypeUrlPath}/${item.fiscalYear}/${item.period}`}>
              {`${fiscalMonth} (P${item.period} FY${item.fiscalYear.toString().slice(2)})`}
            </Link>
          )) || <></>
        );
      },
    },
    {
      key: 'taskCount',
      fieldName: 'taskCount',
      name: 'Task Count',
      minWidth: 100,
      maxWidth: 100,
    },
  ];
};

export interface IDraftTaskMenuProps {
  itemsSelected: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onBulkDeleteClick?: () => void;
}

export const getDraftTasksMenu = (props: IDraftTaskMenuProps): ICommandBarItemProps[] => {
  const { itemsSelected, itemsSelectedCount, totalItemsCount } = props;
  const listCommandBarItems: ICommandBarItemProps[] = [];

  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'deleteMain',
      text: 'Delete Draft Tasks',
      cacheKey: 'deleteBulk',
      iconProps: { iconName: 'Delete' },
      subMenuProps: {
        items: [
          {
            key: 'delete',
            text: `Delete selected (${itemsSelectedCount})`,
            cacheKey: 'deleteBulk',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return null;
            },
          },
          {
            key: 'deleteAll',
            text: `Delete all filtered (${totalItemsCount})`,
            cacheKey: 'deleteAll',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return null;
            },
          },
        ],
      },
    },
  ];

  return itemsSelected ? selectedCommandBarItems : listCommandBarItems;
};

export default getFiscalYearPeriodListColumns;
