import { useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import {
  REMOVE_SHARED_FILTER_MUTATION,
  SAVED_FILTER_LIST_QUERY,
} from '../../../utils/api/SavedFilters';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

interface ISavedFilterDeleteProps {
  selectedFilterId?: number;
  showDeleteModal: boolean;
  hideSavedFilterDeleteModal: () => void;
}

const SharedFilterRemovePanel = (props: ISavedFilterDeleteProps): JSX.Element => {
  const { selectedFilterId, showDeleteModal, hideSavedFilterDeleteModal } = props;

  const [deleteFilter, { loading, error }] = useMutation(REMOVE_SHARED_FILTER_MUTATION, {
    onCompleted: () => {
      hideSavedFilterDeleteModal();
    },
    refetchQueries: [SAVED_FILTER_LIST_QUERY],
  });

  const onDeleteClick = () => {
    deleteFilter({
      variables: {
        savedFilterId: selectedFilterId,
      },
    });
  };

  const onRenderFooterContent = (): JSX.Element => {
    return (
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <PrimaryButton
          text="Remove"
          onClick={onDeleteClick}
          ariaLabel="Remove"
          disabled={loading}
        />
        <DefaultButton
          text="Cancel"
          onClick={hideSavedFilterDeleteModal}
          ariaLabel="Cancel"
          disabled={loading}
        />
      </Stack>
    );
  };

  return (
    <CoherenceModal
      isOpen={showDeleteModal}
      modalWidth="small"
      height="responsive"
      title="Remove shared filter"
      onDismiss={hideSavedFilterDeleteModal}
      onRenderFooter={onRenderFooterContent}
    >
      <div>
        <LoadingErrorMessage loading={loading} error={error} label="Removing shared filter" />
        <MessageBar messageBarType={MessageBarType.warning}>
          <Stack tokens={{ childrenGap: 10 }}>
            <p>
              Clicking Remove will <strong>permanently remove</strong> this shared filter from your
              list. It will need to be re-shared if needed later.
            </p>
          </Stack>
        </MessageBar>
      </div>
    </CoherenceModal>
  );
};

export default SharedFilterRemovePanel;
