import React, { useEffect, useState } from 'react';
import { QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, Stack } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import { IStagedTask } from '../../utils/types/ITask';
import { GetStagedTasksSearch } from '../../utils/api/StagedTaskApi';
import paginatedResultFormatter from '../../utils/formatters/PaginatedResultFormatter';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import { getPickerStyles } from '../styles/CommonStyleObjects';

export interface IStagedTaskPickerProps {
  defaultValue: IStagedTask[];
  onChange: (stagedTasks: IStagedTask[]) => void;
  itemLimit?: number;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  id?: string;
  placeholder?: string;
  isActive?: boolean;
  value?: IStagedTask[];
  disabled?: boolean;
}

export const mapStagedTaskNoTypename = (stagedTasks: IStagedTask[]): IStagedTask[] => {
  return stagedTasks?.map((st) => {
    return {
      id: st.id,
      name: st.name,
      description: st.description,
      taskTags: st.taskTags,
    };
  });
};

export const mapStagedTaskToTag = (stagedTask: IStagedTask[]): ITag[] => {
  return stagedTask?.map((st) => {
    return {
      key: st.id,
      name: `${st.id} - ${st.name}`,
      stagedTaskName: st.name,
    };
  });
};
export const mapTagToStagedTask = (tags: ITag[]): IStagedTask[] => {
  return tags?.map((t) => {
    const id = t.key;
    const name = t.name.split(' - ')[1];
    return { id, name } as IStagedTask;
  });
};
const StagedTaskPicker: React.FunctionComponent<IStagedTaskPickerProps> = ({
  defaultValue,
  itemLimit,
  required,
  errorMessage,
  label,
  id,
  onChange,
  placeholder,
  isActive = true,
  value,
  disabled,
}: IStagedTaskPickerProps): JSX.Element => {
  const [loadStagedTasks, { error }] = useLazyQuery(GetStagedTasksSearch);
  const [selectedStagedTasks, setSelectedStagedTasks] = useState<ITag[]>(
    mapStagedTaskToTag(defaultValue),
  );
  const onResolveSuggestions = async (filterText: string): Promise<ITag[]> => {
    return loadStagedTasks({
      variables: {
        keyword: filterText,
      },
    }).then((result: QueryResult) => {
      const stagedTasks = paginatedResultFormatter<IStagedTask>(result?.data?.stagedTasks);
      return mapStagedTaskToTag(stagedTasks.data).filter((stagedTaskTag) => {
        const duplicate = selectedStagedTasks?.find((sc) => sc.key === stagedTaskTag.key);
        return !duplicate;
      });
    });
  };

  useEffect(() => {
    if (value) {
      setSelectedStagedTasks(mapStagedTaskToTag(value));
    }
  }, [value]);
  return (
    <Stack>
      {label && (
        <Label htmlFor="StagedTaskPicker" required={required} disabled={disabled}>
          {label}
        </Label>
      )}
      <TagPicker
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Staged Task"
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
          suggestionsHeaderText: 'Suggested tasks',
        }}
        inputProps={{
          id: `StagedTaskPicker${id}`,
          'aria-label': label || 'Value',
          placeholder: placeholder || 'Search staged tasks',
        }}
        itemLimit={itemLimit}
        resolveDelay={THROTTLE_SEARCH_TIMEOUT}
        selectedItems={selectedStagedTasks}
        onChange={(items?: ITag[]): void => {
          setSelectedStagedTasks(items);
          onChange(mapTagToStagedTask(items));
        }}
        styles={getPickerStyles(errorMessage)}
        disabled={disabled}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage || error?.message} />
    </Stack>
  );
};

export default StagedTaskPicker;
