import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { IGroup, Stack } from '@fluentui/react';
import ISalesLocation from '../../utils/types/ISalesLocation';
import GenericGroupedChecklist from './GenericGroupedChecklist';
import GetSalesLocationsByFilter from '../../utils/api/GeographyApi';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';

export interface ISalesLocationGroupedChecklistProps {
  selections?: IGroupedChecklistItem[];
  onCheckChanged?: (items: ISalesLocation[]) => void;
  onClearAllSelections?: () => void;
}

const SalesLocationGroupedChecklist = (props: ISalesLocationGroupedChecklistProps): JSX.Element => {
  const { onCheckChanged, selections, onClearAllSelections } = props;
  const [salesLocations, setSalesLocations] = useState<ISalesLocation[]>([]);
  const [selectedSalesLocations, setSelectedSalesLocations] = useState<ISalesLocation[]>([]);
  const { data, loading, error } = useQuery(GetSalesLocationsByFilter, {
    fetchPolicy: 'network-only',
    variables: {
      keyword: '',
    },
  });

  useEffect(() => {
    if (data?.salesLocations && !error && !loading) {
      const salesLocationsData: ISalesLocation[] = data?.salesLocations?.map(
        (salesLocation: ISalesLocation) => ({
          ...salesLocation,
          key: salesLocation.code,
          selected: selections?.some((selection) => selection.key === salesLocation.code) ?? false,
        }),
      );
      setSalesLocations(salesLocationsData);
    }
  }, [data, selections]);

  // Convert the selected IGroupedChecklistItems to a list of ISalesLocation objects
  // and call check changed with new list of sales location objects
  const handleCheckChanged = (currentSelections: IGroupedChecklistItem[]): void => {
    const items: ISalesLocation[] = currentSelections?.map((selection) => {
      // Find the associated Sales Location for the IGroupedChecklistItem
      const associatedSalesLocation = data?.salesLocations?.find(
        (salesLocation: ISalesLocation) => salesLocation?.code === selection?.key,
      );
      if (associatedSalesLocation) {
        return {
          key: associatedSalesLocation.code,
          name: `${associatedSalesLocation.name} (${associatedSalesLocation.area})`,
          code: associatedSalesLocation.code, // Identifier required
          area: associatedSalesLocation.area,
          region: associatedSalesLocation.region,
          subRegion: associatedSalesLocation.subRegion,
          selected: selection.selected,
        };
      }
      return selection;
    });
    onCheckChanged(items);
  };

  // ----- Convert Tags to Sales Locations ------
  // Once the sales locations are retrieved...
  // convert the selected location tags to a
  // list of ISalesLocation objects
  // for parsing by the grouped checklist
  useMemo(() => {
    const updatedSelectedSalesLocations = selections?.map((selection) => {
      // Find the salesLocation where the code is equal to the tag's key
      const associatedSalesLocation = data?.salesLocations?.find(
        (salesLocation: ISalesLocation) => salesLocation?.code === selection?.key,
      );
      // If the associatedSalesLocation exists, return it with the necessary properties set
      if (associatedSalesLocation) {
        return {
          key: associatedSalesLocation.code,
          name: associatedSalesLocation.name,
          code: associatedSalesLocation.code,
          area: associatedSalesLocation.area,
          region: associatedSalesLocation.region,
          subRegion: associatedSalesLocation.subRegion,
          selected: true, // Selected true
        };
      }
      return selection;
    });

    setSelectedSalesLocations(updatedSelectedSalesLocations);
  }, [salesLocations]);

  let salesLocationGroups: IGroup[] = [];
  salesLocationGroups = salesLocations?.reduce((areaGroups, location, index) => {
    // For each location in the salesLocations list, find the matching areaGroup for the location's area value
    // If the areaGroup group does not exist, add it.
    let areaGroup = areaGroups.find((group) => group.key === `sl-area-${location.area}`);
    if (!areaGroup) {
      areaGroup = {
        key: `sl-area-${location.area}`,
        name: location.area,
        isCollapsed: location.selected,
        data: { containsSelectedItem: false },
        startIndex: 0,
        count: 0,
        level: 0,
        children: [],
      };
      areaGroups.push(areaGroup);
    }
    areaGroup.count += 1;

    // For each location's areaGroup, find the matching regionGroup for the location's region value
    // If the regionGroup does not exist, add it.
    let regionGroup = areaGroup.children.find(
      (group) => group.key === `sl-region-${location.region}`,
    );
    if (!regionGroup) {
      regionGroup = {
        key: `sl-region-${location.region}`,
        name: location.region,
        isCollapsed: location.selected,
        data: { containsSelectedItem: false },
        startIndex: 0,
        count: 0,
        level: 1,
        children: [],
      };
      areaGroup.children.push(regionGroup);
    }
    regionGroup.count += 1;

    // For each location's areaGroup's regionGroup, find the matching subRegionGroup for the location's subRegion value
    // If the subRegionGroup does not exist, add it.
    let subRegionGroup = regionGroup.children.find(
      (group) => group.key === `sl-subRegion-${location.subRegion}`,
    );
    if (!subRegionGroup) {
      subRegionGroup = {
        key: `sl-subRegion-${location.subRegion}`,
        name: location.subRegion,
        isCollapsed: location.selected,
        data: { containsSelectedItem: false },
        startIndex: 0,
        count: 0,
        level: 2,
      };
      regionGroup.children.push(subRegionGroup);
    }
    subRegionGroup.count += 1;

    return areaGroups;
  }, [] as IGroup[]);

  // - Set each group's "startIndex" (and each child group's) to the end index of the previous group. 0 if the group is the first one.
  // - Set the groups's "isCollapsed" property based on whether the group contains any selected itemss.
  // - Set the group's "containsSelectedItem" property based on whether the group contains any selected items.
  let startIndex = 0;
  salesLocationGroups?.forEach((areaGroup) => {
    // Check if any items in "selectedSalesLocations" belong to the current areaGroup
    const areaHasSelectedLocation = selectedSalesLocations?.some(
      (selectedSalesLocation) => selectedSalesLocation.area === areaGroup.name,
    );

    // Area Group
    areaGroup.startIndex = startIndex;
    areaGroup.isCollapsed = !areaHasSelectedLocation;
    areaGroup.data.containsSelectedItem = areaHasSelectedLocation;

    // Region Group
    areaGroup.children.forEach((regionGroup) => {
      // Check if any items in "selectedSalesLocations" belong to the current regionGroup
      const regionHasSelectedLocation = selectedSalesLocations?.some(
        (selectedSalesLocation) => selectedSalesLocation.region === regionGroup.name,
      );

      regionGroup.startIndex = startIndex;
      regionGroup.isCollapsed = !regionHasSelectedLocation;
      regionGroup.data.containsSelectedItem = regionHasSelectedLocation;

      // Sub-Region Group
      regionGroup.children.forEach((subRegionGroup) => {
        subRegionGroup.startIndex = startIndex;

        // Check if any items in "selectedSalesLocations" belong to the current subRegionGroup
        const subRegionHasSelectedLocation = selectedSalesLocations?.some(
          (selectedSalesLocation) => selectedSalesLocation.subRegion === subRegionGroup.name,
        );

        subRegionGroup.isCollapsed = !subRegionHasSelectedLocation;
        subRegionGroup.data.containsSelectedItem = subRegionHasSelectedLocation;

        // Increment the start index at lowest level
        startIndex += subRegionGroup.count;
      });
    });
  });

  return (
    <Stack verticalAlign="start" verticalFill>
      <LoadingErrorMessage error={error} loading={loading} />
      <GenericGroupedChecklist
        itemGroups={salesLocationGroups}
        items={salesLocations}
        onCheckChanged={handleCheckChanged}
        onClearAllSelections={onClearAllSelections}
      />
    </Stack>
  );
};
export default SalesLocationGroupedChecklist;
