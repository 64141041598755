import { IconButton, Label, MaskedTextField, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import { IStagedTask } from '../../../utils/types/ITask';
import FormGridCard from '../../../common/formFields/FormGridCard';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import FrequencyPanel from '../edit/FrequencyPanel';
import AssignedDateField from '../../../common/formFields/AssignedDateField';
import { timeIn24HourFormat } from '../../../utils/validation/CustomValidations';
import AutoPublishDateField from '../../../common/formFields/AutoPublishDateField';

interface IStagedtaskDetailScheduleCardProps {
  form: UseFormMethods<IStagedTask>;
}

const StagedtaskDetailScheduleCard = (props: IStagedtaskDetailScheduleCardProps): JSX.Element => {
  const { form } = props;
  const { control, getValues, setValue, errors } = form;
  const [frequencyPanelVisible, setFrequencyPanelVisible] = useState(false);
  const { frequencyCode } = getValues();

  return (
    <>
      <FormGridCard cardLabel="Schedule">
        <Stack horizontal wrap styles={formGridRowStyles}>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <AssignedDateField frequencyCode={frequencyCode} />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <>
              <Label>Frequency</Label>
              <Stack horizontal>
                <Controller
                  as={TextField}
                  name="frequencyCode"
                  placeholder="Enter or set code"
                  control={control}
                  defaultValue={frequencyCode}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter a frequency code',
                    },
                  }}
                  errorMessage={errors?.frequencyCode?.message}
                  ariaLabel="Frequency"
                />
                <IconButton
                  title="Set frequency code"
                  ariaLabel="Set frequency code"
                  iconProps={{
                    iconName: 'CalendarSettings',
                  }}
                  onClick={() => setFrequencyPanelVisible(true)}
                />
              </Stack>
            </>
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              id="dueTime"
              name="dueTime"
              control={control}
              defaultValue="17:00"
              render={({ value }) => {
                return (
                  <MaskedTextField
                    value={value}
                    label="Due time"
                    required
                    mask="99:99"
                    suffix="HH:MM"
                    errorMessage={errors?.dueTime?.message}
                    onChange={(e, newValue) => {
                      setValue('dueTime', newValue);
                    }}
                  />
                );
              }}
              rules={{
                required: 'Please provide due time',
                validate: {
                  timeIn24HourFormat,
                },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <AutoPublishDateField />
          </FormGridColumn>
        </Stack>
      </FormGridCard>
      {frequencyPanelVisible && (
        <FrequencyPanel
          closePanel={() => {
            setFrequencyPanelVisible(false);
          }}
          onSave={(code: string) => {
            setValue('frequencyCode', code);
          }}
          existingFrequencyCode={frequencyCode}
        />
      )}
    </>
  );
};
export default StagedtaskDetailScheduleCard;
