import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DetailsListLayoutMode,
  IObjectWithKey,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';

import { bodyContentContainer } from '../../../common/styles/CommonStyleObjects';
import {
  EditDraftTaskBulk,
  EditDraftTaskRemoveBulk,
  GetDraftTasks,
} from '../../../utils/api/DraftTaskApi';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import paginatedResultFormatter from '../../../utils/formatters/PaginatedResultFormatter';
import { PaginatedData, PaginationQueryVariables } from '../../../utils/types/PaginationTypes';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../common/constants/SiteConstants';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../common/lists/ColumnConfigHelper';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import ColumnConfigPanel from './ColumnConfigPanel';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import {
  IDraftTask,
  IDraftTaskFilterIds,
  IPublishedTask,
  ITaskRouteParams,
} from '../../../utils/types/ITask';
import { IBaseTaskListColumns } from '../BaseTasks.config';
import { fiscalPeriodToMonth } from '../../../utils/formatters/DateFormatters';
import BackButton from '../../../common/buttons/BackButton';
import {
  getFormInputConfig,
  getRemoveTaskMapper,
  getUpdateTaskMapper,
} from '../edit/DraftTaskBulkEdit.config';
import getDraftTasksMenu, { getDraftTaskListColumns } from '../DraftTasks.config';
import PublishDraftTasks, { PublishDraftTasksMode } from '../generators/PublishDraftTasks';
import DraftTaskDeleteModal from './DraftTaskDeleteModal';
import { mapDraftTaskFilterTagsToIds, TaskType } from './BaseTaskListfilters.config';
import { convertDotPathToNestedObject } from '../../../utils/Helpers';
import PaginationWrapperManaged from '../../../common/lists/PaginationWrapperManaged';
import BulkEditMode from '../../../utils/types/IBulkEdit';
import { IQuerySortDefinition, SortDirection } from '../../../utils/types/IListSortDefinition';
import { IEntityListState, IListQueryVariables } from '../../../utils/types/IList';
import DraftTaskListPageFilters from './DraftTaskListPageFilters';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../utils/listHelpers';
import { sharedEditButton } from '../../../common/lists/SharedDataGridColumns';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import DraftTaskEditPanel from '../edit/DraftTaskEditPanel';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../common/lists/GenericFilterTagHandler';
import BulkEdit, { BulkEditType } from '../../../common/bulkEdit/BulkEdit';
import addFiltersForPeriodAndYear from '../../../utils/formatters/AddFiscalYearPeriod';

interface IDraftTaskListState extends IEntityListState {
  publishMode: PublishDraftTasksMode;
  showDeleteModal: boolean;
  editDraftTask: IPublishedTask;
}

const DraftTaskList = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.draftTaskListColumns,
    [],
  );

  const { fiscalYear, period } = useParams<ITaskRouteParams>();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [dataResult, setDataResult] = useState<PaginatedData<IDraftTask>>();
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'dueDate',
  };

  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.draftTaskQueryState}${fiscalYear}${period}`,
    { ...defaultPaginationProps, ...defaultFiltersAndSort } as IListQueryVariables,
  );
  taskQueryCache.filterTags = addFiltersForPeriodAndYear(
    taskQueryCache.filterTags,
    fiscalYear,
    period,
  );
  const [pageState, setPagesState] = useState<IDraftTaskListState>({
    ...getDefaultStateProps(taskQueryCache),
    publishMode: null,
    showDeleteModal: false,
    editDraftTask: null,
  });

  const [getTasks, { data, loading, error }] = useLazyQuery(GetDraftTasks, {
    variables: {
      keyword: null,
      filters: mapDraftTaskFilterTagsToIds(taskQueryCache.filterTags),
      status: 0,
      ...taskQueryCache?.pageInfo,
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  let filterTimeout: string | number | NodeJS.Timeout;
  const renderHeader = (): JSX.Element => {
    const fiscalMonth = fiscalPeriodToMonth(parseInt(period, 10));
    return (
      <Stack horizontal>
        <BackButton ariaLabel="Back to draft tasks list" backDepth={2}>
          <h1>
            Draft tasks for {`${fiscalMonth} (P${period} FY${fiscalYear.toString().slice(2)})`}
          </h1>
        </BackButton>
      </Stack>
    );
  };

  const itemsSelected = selectedTasks?.length > 0;

  const onBulkEditClick = (editMode: BulkEditMode) => {
    setPagesState({
      ...pageState,
      bulkEditMode: editMode,
    });
  };
  const onPublishClick = (editMode: PublishDraftTasksMode) => {
    setPagesState({
      ...pageState,
      publishMode: editMode,
    });
  };
  const onBulkDeleteClick = (deleteMode: BulkEditMode) => {
    setPagesState({ ...pageState, showDeleteModal: true, bulkEditMode: deleteMode });
  };

  const onRefreshTasksClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };

  const changeCasingOnEnums = (
    filters: IGenericFilterTag[],
    exportAll: boolean,
  ): IDraftTaskFilterIds => {
    if (exportAll) {
      const nonBarFilters = filters.filter((filter) => filter.hideFromBar === true);
      return mapDraftTaskFilterTagsToIds(nonBarFilters);
    }

    const queryParams = mapDraftTaskFilterTagsToIds(filters);
    queryParams.publishType = queryParams.publishType?.map((publishType: string) => {
      return enumFormatter(publishType).replace(/ /g, '');
    });

    return queryParams;
  };

  const commandBarItems = React.useMemo(() => {
    const { keyword, filterTags } = pageState;

    const downloadFilteredButtonProps: IDownloadButtonProps = {
      key: 'DownloadDraftTasksAll',
      fileEndpoint: 'Tasks?status=Draft',
      fileName: 'DraftTasks',
      text: 'Export All',
      postBody: JSON.stringify(changeCasingOnEnums(filterTags, true)),
      commandBarButtonAs: DownloadButton,
      cacheKey: 'TasksAllDownload',
      iconProps: { iconName: 'ExcelLogo' },
    };

    const downloadAllButtonProps: IDownloadButtonProps = {
      key: 'DownloadDraftTasksFiltered',
      fileEndpoint: `Tasks?status=Draft${keyword != null ? `&keyword=${keyword}` : ''}`,
      fileName: 'DraftTasks',
      text: `Export Filtered (${dataResult?.totalCount})`,
      postBody: JSON.stringify(changeCasingOnEnums(filterTags, false)),
      commandBarButtonAs: DownloadButton,
      cacheKey: 'TasksFilteredDownload',
      iconProps: { iconName: 'ExcelLogo' },
    };

    return getDraftTasksMenu({
      itemsSelected,
      itemsSelectedCount: selectedTasks?.length,
      totalItemsCount: dataResult?.totalCount,
      onBulkEditClick,
      onPublishClick,
      onBulkDeleteClick,
      downloadAllButtonProps,
      downloadFilteredButtonProps,
      onRefreshTasksClick,
    });
  }, [
    itemsSelected,
    selectedTasks?.length,
    pageState.keyword,
    pageState.filterTags,
    dataResult?.totalCount,
  ]);
  const columnConfig: IBaseTaskListColumns = {
    taskType: TaskType.DraftTasks,
  };
  const cachedListColumns = getColumnConfigByKey(
    getDraftTaskListColumns(columnConfig, fiscalYear, period),
    listColumnsCache,
  );

  const listColumns = cachedListColumns.filter((col) => {
    return (col as ILocalStorageColumnConfig).active;
  });
  listColumns.unshift(
    sharedEditButton<IBaseTask>(
      (selectedItem: IDraftTask) => {
        const task = selectedItem as IPublishedTask;
        task.fiscalPeriod = parseInt(period, 10);
        task.fiscalYear = parseInt(fiscalYear, 10);
        setPagesState({
          ...pageState,
          editDraftTask: task,
        });
      },
      selectedTasks?.length > 1,
      'name',
    ),
  );

  const closeEditPanel = (): void => {
    const stateCopy = structuredClone(pageState);
    setPagesState({
      ...stateCopy,
      editDraftTask: null,
    });
  };

  const detailsListConfig: IGeneralEntityListProps<IDraftTask> = {
    data: dataResult?.data || [],
    listColumns,
    commandBarItems,
    onSearchBoxChange: (ev, searchTerm: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
          ...getResetPaginationProps(taskQueryCache?.paginationSize),
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    loading,
    layoutMode: DetailsListLayoutMode.fixedColumns,
    error,
    lockActionsColumn: true,
    onChangeSelectedItems: (selection: IObjectWithKey[]) => {
      setSelectedTasks(selection);
    },
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
    frozenColumnsFromStart: 1,
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <DraftTaskListPageFilters />
        </GenericFilterTagHandler>
      );
    },
  };
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = mapDraftTaskFilterTagsToIds(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    filters.fiscalYear = parseInt(fiscalYear, 10);
    filters.period = parseInt(period, 10);

    getTasks({
      variables: {
        keyword,
        status: 0,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };

  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);

  useEffect(() => {
    // i had to do this because selected items were breaking if i referenced formatted data directly
    const formattedData = paginatedResultFormatter<IDraftTask>(data?.draftTasks);
    setDataResult(formattedData);
    setSelectedTasks([]);
  }, [loading, data]);

  const selectedTaskList = selectedTasks?.map((task: IDraftTask) => {
    return task?.id;
  });

  const selectedInActiveStagedTaskList = selectedTasks
    ?.filter((task: IDraftTask) => !task?.stagedTask?.isActive)
    .map((task: IDraftTask) => task?.id);

  return (
    <>
      <FullWidthHeader title={renderHeader} />

      <div className={`${bodyContentContainer}`}>
        <PaginationWrapperManaged<IDraftTask>
          dataResult={dataResult}
          selectedPage={pageState.selectedPaginationPage}
          loadingData={loading}
          onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
            setPagesState({
              ...pageState,
              selectedPaginationPage: value,
              pageInfo: {
                ...variables,
              },
            });
          }}
          paginationSize={pageState.paginationSize}
          onPageSizeChange={(newPageSize: number) => {
            setPagesState({
              ...pageState,
              ...getResetPaginationProps(newPageSize),
            });
          }}
        >
          <>
            <MessageBar messageBarType={MessageBarType.warning}>
              <Stack tokens={{ childrenGap: 10 }}>
                <span>
                  Draft Tasks are not yet published to a period until the publish button is pressed
                </span>
              </Stack>
            </MessageBar>
            {GeneralEntityList(detailsListConfig)}
          </>
        </PaginationWrapperManaged>
      </div>

      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
      <BulkEdit
        type={BulkEditType.tasks}
        getFormInputs={getFormInputConfig}
        bulkEditMode={pageState?.showDeleteModal ? null : pageState?.bulkEditMode}
        hideBulkEditModal={() => {
          const stateCopy = structuredClone(pageState);
          setPagesState({
            ...stateCopy,
            bulkEditMode: null,
          });
        }}
        selectedItemsCount={
          pageState.bulkEditMode === BulkEditMode.BulkEditSelected
            ? selectedTasks.length
            : dataResult?.totalCount
        }
        selectedItems={selectedTaskList}
        keyword={pageState.keyword}
        updateMutation={useMutation(EditDraftTaskBulk)}
        removeMutation={useMutation(EditDraftTaskRemoveBulk)}
        getUpdateMapper={getUpdateTaskMapper}
        getRemoveMapper={getRemoveTaskMapper}
        filters={mapDraftTaskFilterTagsToIds(pageState.filterTags)}
      />
      <PublishDraftTasks
        publishMode={pageState?.publishMode}
        fiscalYear={parseInt(fiscalYear, 10)}
        period={parseInt(period, 10)}
        hideModal={() => {
          const stateCopy = structuredClone(pageState);
          setPagesState({
            ...stateCopy,
            publishMode: null,
          });
        }}
        selectedTasksCount={
          pageState.publishMode === PublishDraftTasksMode.PublishDraftsSelected
            ? selectedTasks.length
            : dataResult?.totalCount
        }
        selectedTasks={selectedTaskList}
        selectedInActiveStagedTasksCount={selectedInActiveStagedTaskList}
        keyword={pageState.keyword}
        filters={mapDraftTaskFilterTagsToIds(pageState.filterTags)}
        refetchPage={refreshPage}
      />
      {pageState?.showDeleteModal === true && (
        <DraftTaskDeleteModal
          selectedTasks={selectedTaskList}
          selectedTaskCount={
            pageState.bulkEditMode === BulkEditMode.BulkEditSelected
              ? selectedTasks.length
              : dataResult?.totalCount
          }
          showDeleteModal={pageState.showDeleteModal}
          keyword={pageState.keyword}
          hideBulkDeleteModal={(shouldRefetch: boolean) => {
            const stateCopy = structuredClone(pageState);
            setPagesState({ ...stateCopy, showDeleteModal: false, bulkEditMode: null });
            if (shouldRefetch) refreshPage();
          }}
          bulkDeleteMode={pageState?.bulkEditMode}
          filters={mapDraftTaskFilterTagsToIds(pageState.filterTags)}
        />
      )}
      {pageState.editDraftTask !== null && (
        <DraftTaskEditPanel task={pageState?.editDraftTask} closePanel={closeEditPanel} />
      )}
    </>
  );
};

export default DraftTaskList;
