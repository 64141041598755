import { Stack, Text } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

interface IProgressItemProp {
  percentComplete: number;
}

function getColor(percentage: number) {
  const decimal = percentage / 100;
  const hue = decimal * 120;
  return `hsl(${hue}, 100%, 50%)`;
}

const ProgressItem: FunctionComponent<IProgressItemProp> = ({ percentComplete }) => {
  const barStyle = {
    width: '1.3em',
    height: '0.75em',
    background: '#C4C4C4',
    marginLeft: '4px',
    outline: '0.5px solid #C4C4C4',
  };
  return (
    <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
      <Text
        variant="small"
        block
        style={{
          color: '#666666',
          fontWeight: 500,
        }}
      >
        {percentComplete}%
      </Text>
      <div style={barStyle}>
        <div
          style={{
            // Show at least 1% of the bar as red so it's not blank.
            width: `${percentComplete || 1}%`,
            height: '100%',
            background: getColor(percentComplete),
          }}
        />
      </div>
    </Stack>
  );
};

export default ProgressItem;
