import { useLazyQuery, useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import {
  DefaultButton,
  HoverCard,
  HoverCardType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import FiscalPeriodDropdown, {
  IFiscalPeriodDropdownOption,
} from '../../../common/formFields/FiscalPeriodDropdown';
import DRAFT_TASK_PREVIEW_QUERY, {
  GenerateDraftTasksMutation,
} from '../../../utils/api/DraftTaskApi';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import { RepeatValues } from '../../../utils/types/IFrequencyChoices';
import { IDraftTaskFilterIds } from '../../../utils/types/ITask';

export enum GenerateDraftsMode {
  GenerateDraftsSelected,
  GenerateDraftsAllFiltered,
  GenerateDraftsAll,
}

interface IGenerateDraftTasksProps {
  selectedTasks: number[];
  keyword: string;
  filters: unknown;
  generateMode: GenerateDraftsMode;
  selectedTasksCount: number;
  hideModal: () => void;
}
const GenerateDraftTasks = (props: IGenerateDraftTasksProps): JSX.Element => {
  const { selectedTasksCount, selectedTasks, keyword, filters, generateMode, hideModal } = props;
  const history = useHistory();
  const [pageState, setPageState] = useState({
    enableSave: false,
    fiscalPeriod: null,
    fiscalYear: null,
  });
  const [generateDraftTasks, { loading, error }] = useMutation(GenerateDraftTasksMutation, {
    onCompleted: () => {
      history.push(`/draftTasks/${pageState.fiscalYear}/${pageState.fiscalPeriod}/`);
    },
  });

  const resetComponent = () => {
    hideModal();
  };
  const getModeVariables = (): {
    selectedItems: number[];
    keyword: string;
    filters: Partial<IDraftTaskFilterIds>;
  } => {
    return {
      selectedItems:
        generateMode === GenerateDraftsMode.GenerateDraftsSelected ? selectedTasks : null,
      keyword: generateMode === GenerateDraftsMode.GenerateDraftsAllFiltered ? keyword : null,
      filters:
        generateMode === GenerateDraftsMode.GenerateDraftsAllFiltered
          ? filters
          : { period: pageState.fiscalPeriod, fiscalYear: pageState.fiscalYear },
    };
  };

  const [getDraftTaskCounts, { data, loading: countLoading, error: countError }] = useLazyQuery(
    DRAFT_TASK_PREVIEW_QUERY,
    {
      variables: {
        draftTaskGeneratorDto: getModeVariables(),
      },
      fetchPolicy: 'network-only',
    },
  );

  const submitForm = () => {
    generateDraftTasks({
      variables: {
        generateDraftTasksDto: {
          ...getModeVariables(),
        },
      },
    }).then(() => {
      resetComponent();
    });
  };
  const countData = data?.draftTaskPreviewCounts?.publishTypeCounts;
  const inActiveStagedTasksCount = data?.draftTaskPreviewCounts?.inActiveStagedTaskIds?.length;
  const draftedTasksInFiscalYearPeriod = data?.draftTaskPreviewCounts?.tasksInPublishedState;
  const notDraftedCounts = data?.draftTaskPreviewCounts?.notDraftedCounts.filter(
    (notDraftedItem: { repititionValue: string; taskCount: number }) => {
      return notDraftedItem.taskCount && notDraftedItem.taskCount > 0;
    },
  );

  const inactiveTenStagedTaskIds = data?.draftTaskPreviewCounts?.inActiveStagedTaskIds
    ?.slice(0, 10)
    .join(', ');
  const draftedTasksIdsInFiscalYearPeriodString = draftedTasksInFiscalYearPeriod?.join(', ');
  const inactiveStagedTaskIds = data?.draftTaskPreviewCounts?.inActiveStagedTaskIds?.join(', ');
  const cardStyles = {
    padding: '20px',
    width: '200px',
    display: 'flex',
  };

  const getInactiveStagedTasksHoverCard = () => {
    return (
      <div>
        <HoverCard
          type={HoverCardType.plain}
          plainCardProps={{
            onRenderPlainCard: () => <div style={cardStyles}>{inactiveStagedTaskIds}</div>,
          }}
        >
          <div>
            One or more of the staged tasks selected are Inactive and cannot be drafted. Please
            remove inactive staged tasks from the selection and try again. Inactive staged tasks are{' '}
            <strong>{inactiveTenStagedTaskIds}</strong>
            {inActiveStagedTasksCount > 10 && <span>...</span>}
          </div>
        </HoverCard>
      </div>
    );
  };

  const getDraftedTasksInFiscalYearPeriodHoverCard = () => (
    <div>
      <HoverCard
        type={HoverCardType.plain}
        plainCardProps={{
          onRenderPlainCard: () => (
            <div style={cardStyles}>{draftedTasksIdsInFiscalYearPeriodString}</div>
          ),
        }}
      >
        One or more of the selected staged tasks are already drafted for the selected fiscal year
        and period and cannot be drafted. Please remove staged tasks from the selection and try
        again. Staged tasks are <strong>{draftedTasksIdsInFiscalYearPeriodString}</strong>
        {draftedTasksInFiscalYearPeriod?.length > 10 && <span>...</span>}
      </HoverCard>
    </div>
  );

  const getMessage = () => {
    let totalCount = 0;

    return (
      <>
        <div>
          {inActiveStagedTasksCount > 0 && (
            <MessageBar messageBarType={MessageBarType.error}>
              {getInactiveStagedTasksHoverCard()}
            </MessageBar>
          )}
          {draftedTasksInFiscalYearPeriod?.length > 0 && (
            <MessageBar messageBarType={MessageBarType.error}>
              {getDraftedTasksInFiscalYearPeriodHoverCard()}
            </MessageBar>
          )}
        </div>
        <div>
          {inActiveStagedTasksCount <= 0 && draftedTasksInFiscalYearPeriod?.length <= 0 && (
            <MessageBar messageBarType={MessageBarType.warning}>
              <span>
                Click Generate draft tasks button to create:
                <ul>
                  {countData?.map((countItem: { publishType: string; taskCount: number }) => {
                    totalCount += countItem.taskCount;
                    return (
                      <li key={countItem.publishType}>
                        <strong>{enumFormatter(countItem.publishType)}: </strong>
                        {countItem.taskCount}
                      </li>
                    );
                  })}
                  <li key="total">
                    <strong>Total: </strong>
                    {totalCount}
                  </li>
                </ul>
                from <strong> {selectedTasksCount} </strong> staged tasks.
                {notDraftedCounts?.map(
                  (notDraftedItem: { repetitionValue: string; taskCount: number }) => (
                    <div>
                      <strong>
                        {RepeatValues[notDraftedItem.repetitionValue as keyof typeof RepeatValues]}
                        tasks NOT drafted:{' '}
                      </strong>
                      {notDraftedItem.taskCount}
                    </div>
                  ),
                )}
              </span>
            </MessageBar>
          )}
        </div>
      </>
    );
  };
  return (
    <CoherenceModal
      isOpen={generateMode !== null}
      modalWidth="small"
      height="responsive"
      title="Generate draft tasks"
      onDismiss={() => {
        hideModal();
      }}
    >
      <Stack styles={{ root: { marginTop: 10 } }}>
        <Stack tokens={{ childrenGap: 10 }} grow>
          <FiscalPeriodDropdown
            required
            id="fiscalPeriodKey"
            valueKey="fiscalPeriodKey"
            width="100%"
            handleChange={(newValue: IFiscalPeriodDropdownOption, valueKey: string): void => {
              setPageState({
                fiscalPeriod: newValue.fiscalPeriod,
                fiscalYear: newValue.fiscalYear,
                enableSave: true,
              });

              const dto = getModeVariables();
              // if we dont have filters in dto this will crap out
              dto.filters.period = newValue.fiscalPeriod;
              dto.filters.fiscalYear = newValue.fiscalYear;
              getDraftTaskCounts({
                variables: {
                  draftTaskGeneratorDto: dto,
                },
              });
            }}
          />
          <Stack tokens={{ childrenGap: 10 }}>
            <LoadingErrorMessage
              loading={countLoading}
              error={countError}
              label="Loading task counts"
            />
            <span>{getMessage()}</span>
          </Stack>
        </Stack>
        <LoadingErrorMessage loading={loading} error={error} label="Saving tasks" />
        <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Generate draft tasks"
            onClick={submitForm}
            ariaLabel="Generate draft tasks"
            disabled={
              loading ||
              !pageState.enableSave ||
              countError?.message?.length > 0 ||
              inActiveStagedTasksCount > 0 ||
              draftedTasksInFiscalYearPeriod?.length > 0 ||
              countLoading ||
              countData?.length === 0 ||
              !data?.draftTaskPreviewCounts
            }
          />
          <DefaultButton
            disabled={loading}
            text="Cancel"
            onClick={() => {
              resetComponent();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};
export default GenerateDraftTasks;
