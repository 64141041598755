import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { IGroup, Stack } from '@fluentui/react';
import { IProjectNileWorkflow } from '../../utils/types/IProjectNileWorkflow';
import GenericGroupedChecklist from './GenericGroupedChecklist';
import GetProjectNileWorkflows from '../../utils/api/ProjectNileWorkflowsApi';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';

export interface INileWorkflowGroupedChecklistProps {
  selections: IProjectNileWorkflow[];
  onCheckChanged?: (items: IProjectNileWorkflow[]) => void;
  onClearAllSelections: () => void;
}

const NileWorkflowGroupedChecklist = (props: INileWorkflowGroupedChecklistProps): JSX.Element => {
  const { onCheckChanged, selections, onClearAllSelections } = props;
  const [projectNileWorkflows, setProjectNileWorkflows] = useState<IProjectNileWorkflow[]>([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState<IProjectNileWorkflow[]>([]);
  const { data, loading, error } = useQuery(GetProjectNileWorkflows, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.projectNileWorkflows && !error && !loading) {
      const projectNileWorkflowsData: IProjectNileWorkflow[] = data?.projectNileWorkflows?.map(
        (workflow: IProjectNileWorkflow) => ({
          ...workflow,
          key: workflow.id,
          selected: selections?.some((selection) => selection.key === workflow.id) ?? false,
        }),
      );
      setProjectNileWorkflows(projectNileWorkflowsData);
    }
  }, [data, selections]);

  // Convert the selected IGroupedChecklistItems to a list of IProjectNileWorkflow objects
  // and call check changed with new list of workflow objects
  const handleCheckChanged = (currentSelections: IGroupedChecklistItem[]): void => {
    const items: IProjectNileWorkflow[] = currentSelections?.map((selection) => {
      // Find the associated Workflow for the IGroupedChecklistItem
      const associatedWorkflow: IProjectNileWorkflow = data?.projectNileWorkflows?.find(
        (workflow: IProjectNileWorkflow) => workflow?.id === selection?.key,
      );
      if (associatedWorkflow) {
        return {
          key: associatedWorkflow.id,
          name: associatedWorkflow.name,
          id: associatedWorkflow.id, // Identifier required
          selected: selection.selected,
        };
      }
      return selection;
    });
    onCheckChanged(items);
  };

  // ----- Convert Tags to Workflows ------
  // Once the workflows are retrieved...
  // convert the selected workflow tags to a
  // list of IProjectNileWorkflow objects
  // for parsing by the grouped checklist
  useMemo(() => {
    const updatedSelectedNileWorkflows = selections?.map((selection) => {
      // Find the workflow where the id is equal to the tag's key
      const associatedWorkflow = data?.projectNileWorkflows?.find(
        (workflow: IProjectNileWorkflow) => workflow?.id === selection?.key,
      );
      // If the associatedWorkflow exists, return it with the necessary properties set
      if (associatedWorkflow) {
        return {
          key: associatedWorkflow.id,
          name: associatedWorkflow.name,
          id: associatedWorkflow.id,
          selected: true, // Selected true
        };
      }
      return selection;
    });

    setSelectedWorkflows(updatedSelectedNileWorkflows);
  }, [projectNileWorkflows]);

  let nileWorkflowGroups: IGroup[] = []; // The groups that will be used in the checklist
  nileWorkflowGroups = projectNileWorkflows?.reduce((alphabeticalGroups, workflow, index) => {
    // For each workflow in the workflows list, find the matching alphabetical group for the workflow's name value
    // If the alphabetical group does not exist, add it.
    let alphabeticalGroup = alphabeticalGroups.find(
      (group) => group.key === `nw-${workflow.name[0]?.toLowerCase()}`,
    );
    if (!alphabeticalGroup) {
      alphabeticalGroup = {
        key: `nw-${workflow.name[0]?.toLowerCase()}`, // Make the key lowercase since workflow names may be a mix of upper and lower case
        name: workflow.name[0]?.toUpperCase(), // Capitalize the group title
        isCollapsed: false,
        data: { containsSelectedItem: false },
        startIndex: 0,
        count: 0,
        level: 0,
        children: [],
      };
      alphabeticalGroups.push(alphabeticalGroup);
    }
    alphabeticalGroup.count += 1;
    return alphabeticalGroups;
  }, [] as IGroup[]);

  // - Set each group's "startIndex" (and each child group's) to the end index of the previous group. 0 if the group is the first one.
  // - Set the groups's "isCollapsed" property based on whether the group contains any selected items.
  // - Set the group's "containsSelectedItem" property based on whether the group contains any selected items.
  let startIndex = 0;
  nileWorkflowGroups?.forEach((alphabeticalGroup) => {
    // Check if any items in "selectedWorkflows" belong to the current alphabeticalGroup
    const typeHasSelectedWorkflow = selectedWorkflows?.some(
      (workflow) => workflow.name[0] === alphabeticalGroup.name,
    );

    alphabeticalGroup.startIndex = startIndex;
    alphabeticalGroup.isCollapsed = !typeHasSelectedWorkflow;
    alphabeticalGroup.data.containsSelectedItem = typeHasSelectedWorkflow;

    // Increment the start index at lowest level
    startIndex += alphabeticalGroup.count;
  });

  return (
    <Stack verticalAlign="start" verticalFill>
      <LoadingErrorMessage error={error} loading={loading} />
      <GenericGroupedChecklist
        itemGroups={nileWorkflowGroups}
        items={projectNileWorkflows}
        onCheckChanged={handleCheckChanged}
        onClearAllSelections={onClearAllSelections}
        multiSelect={false}
      />
    </Stack>
  );
};
export default NileWorkflowGroupedChecklist;
