import { ITag } from '@fluentui/react';
import React from 'react';
import GetCompanyTagsByFilter from '../../utils/api/CompaniesApi';
import ICompany from '../../utils/types/ICompany';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

export interface ICompanyChecklistProps extends IChecklistImplementationProps {
  isActive: boolean;
}

const CompanyChecklist = (props: ICompanyChecklistProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, isActive, disabledValues } = props;

  const mapToTag = (item: ICompany): ITag => {
    return {
      key: item.companyCode,
      name: `${item.companyCode.toString()} - ${item.name}`,
    };
  };

  const mapData = (data: { companyTags: ICompany[] }): ITag[] => {
    return data?.companyTags?.map((os: ICompany) => {
      return mapToTag(os);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text="Co. code">
      <GenericChecklist
        query={GetCompanyTagsByFilter}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        isActiveQueryParam={isActive}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default CompanyChecklist;
