import { useQuery } from '@apollo/client';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { Checkbox, Dropdown, IconButton, IDropdownOption, Label, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ActionButtons from '../../../common/buttons/ActionButtons';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import AssignedDateField from '../../../common/formFields/AssignedDateField';
import CalendarCodePicker from '../../../common/formFields/CalendarCodePicker';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import { GetFrequencyCode } from '../../../utils/api/CalendarApi';
import generateFrequencyCode from '../../../utils/formatters/FrequencyCodeFormatter';
import CalendarCodeTypes from '../../../utils/types/ICalendarCodeTypes';
import IFrequencyChoices, { RepeatValues } from '../../../utils/types/IFrequencyChoices';
import { daysOfWeek, repeatChoices, startPeriodChoices } from './FrequencyPanel.config';

interface IFrequencyPanelProps {
  closePanel: () => void;
  onSave: (frequencyCode: string) => void;
  existingFrequencyCode: string;
}

const loadingMessageStyles = { root: { justifyContent: 'start', margin: '10px 15px' } };

const FrequencyPanel = (props: IFrequencyPanelProps): JSX.Element => {
  const { closePanel, onSave, existingFrequencyCode } = props;

  const form = useForm<IFrequencyChoices>();
  const { watch, control, setValue, reset, handleSubmit } = form;

  const watchAllFields = watch();

  const [frequencyCode, setFrequencyCode] = useState(existingFrequencyCode);

  const { data, loading, error, refetch } = useQuery(GetFrequencyCode, {
    variables: { frequencyCodeString: existingFrequencyCode },
  });

  const handleOnSave = () => {
    refetch({
      frequencyCodeString: frequencyCode,
    }).then(() => {
      onSave(frequencyCode);
      closePanel();
    });
  };

  const repeatGreaterThanWeekly = [RepeatValues.M, RepeatValues.Q, RepeatValues.H, RepeatValues.Y];
  const repeatGreaterThanMonthly = [RepeatValues.Q, RepeatValues.H, RepeatValues.Y];

  React.useEffect(() => {
    setFrequencyCode(generateFrequencyCode(watchAllFields));
  }, [watchAllFields]);

  React.useEffect(() => {
    const defaultFormObject = {} as IFrequencyChoices;
    if (data?.frequencyCode?.repetitionValue) {
      const repetitionValue: string = Object.entries(RepeatValues).find(
        ([key, value]) => key === data?.frequencyCode?.repetitionValue,
      )?.[1];
      defaultFormObject.repeat = repetitionValue as string;
    }
    if (data?.frequencyCode?.startPeriod && data?.frequencyCode?.startYear) {
      defaultFormObject.startPeriodSelection = {
        startPeriod: data?.frequencyCode?.startPeriod,
        startYear: data?.frequencyCode?.startYear,
      };
    }
    if (
      repeatGreaterThanWeekly.includes(
        RepeatValues[data?.frequencyCode?.repetitionValue as keyof typeof RepeatValues],
      )
    ) {
      defaultFormObject.rasCloseDaySelection = data?.frequencyCode?.dateCodes.filter(
        (code: string) => code.startsWith('RCD'),
      );
      if (defaultFormObject.rasCloseDaySelection.length > 0) {
        const additionalRas = data?.frequencyCode?.dateCodes.filter((code: string) =>
          code.startsWith('CD'),
        );
        defaultFormObject.rasCloseDaySelection = additionalRas.concat(
          defaultFormObject.rasCloseDaySelection,
        );
      } else {
        defaultFormObject.closeDaySelection = data?.frequencyCode?.dateCodes.filter(
          (code: string) => code.startsWith('CD'),
        );
      }

      defaultFormObject.calendarDaySelection = data?.frequencyCode?.dateCodes.filter(
        (code: string) => code.startsWith('CA'),
      );
      defaultFormObject.workingDaySelection = data?.frequencyCode?.dateCodes.filter(
        (code: string) => code.startsWith('WD'),
      );
      defaultFormObject.variableDaySelection = data?.frequencyCode?.dateCodes.filter(
        (code: string) =>
          !code.startsWith('RCD') &&
          !code.startsWith('CD') &&
          !code.startsWith('CA') &&
          !code.startsWith('WD'),
      );
    }
    if (data?.frequencyCode?.repetitionValue === 'W') {
      defaultFormObject.weeklyCadenceDayMonChecked = data?.frequencyCode?.dateCodes.includes('MO');
      defaultFormObject.weeklyCadenceDayTueChecked = data?.frequencyCode?.dateCodes.includes('TU');
      defaultFormObject.weeklyCadenceDayWedChecked = data?.frequencyCode?.dateCodes.includes('WE');
      defaultFormObject.weeklyCadenceDayThuChecked = data?.frequencyCode?.dateCodes.includes('TH');
      defaultFormObject.weeklyCadenceDayFriChecked = data?.frequencyCode?.dateCodes.includes('FR');
      defaultFormObject.weeklyCadenceDaySatChecked = data?.frequencyCode?.dateCodes.includes('SA');
      defaultFormObject.weeklyCadenceDaySunChecked = data?.frequencyCode?.dateCodes.includes('SU');
    }
    reset({ ...defaultFormObject });
  }, [data]);

  const setRepeatValue = (repeatValue: string) => {
    const newForm = watchAllFields;

    if (repeatGreaterThanWeekly.includes(repeatValue as RepeatValues)) {
      newForm.weeklyCadenceDayMonChecked = false;
      newForm.weeklyCadenceDayTueChecked = false;
      newForm.weeklyCadenceDayWedChecked = false;
      newForm.weeklyCadenceDayThuChecked = false;
      newForm.weeklyCadenceDayFriChecked = false;
      newForm.weeklyCadenceDaySatChecked = false;
      newForm.weeklyCadenceDaySunChecked = false;
    } else {
      newForm.closeDaySelection = [];
      newForm.rasCloseDaySelection = [];
      newForm.calendarDaySelection = [];
      newForm.workingDaySelection = [];
      newForm.variableDaySelection = [];
    }
    newForm.repeat = repeatValue;

    reset({ ...newForm });
  };

  const renderDayOfMonth = () => {
    return (
      <>
        <Label>Day of month</Label>
        <Controller
          name="closeDaySelection"
          control={control}
          render={() => (
            <CalendarCodePicker
              defaultValues={watchAllFields.closeDaySelection}
              calendarCodeType={CalendarCodeTypes.CloseDay}
              onSelectedItems={(dateCodes: string[]) => {
                setValue('closeDaySelection', dateCodes);
              }}
              label="Close day"
            />
          )}
        />
        <Controller
          name="calendarDaySelection"
          control={control}
          render={() => (
            <CalendarCodePicker
              calendarCodeType={CalendarCodeTypes.CalendarDay}
              defaultValues={watchAllFields.calendarDaySelection}
              onSelectedItems={(dateCodes: string[]) => {
                setValue('calendarDaySelection', dateCodes);
              }}
              label="Calendar day"
            />
          )}
        />
        <Controller
          name="workingDaySelection"
          control={control}
          render={() => (
            <CalendarCodePicker
              calendarCodeType={CalendarCodeTypes.WorkingDay}
              defaultValues={watchAllFields.workingDaySelection}
              onSelectedItems={(dateCodes: string[]) => {
                setValue('workingDaySelection', dateCodes);
              }}
              label="Working day"
            />
          )}
        />
        <Controller
          name="variableDaySelection"
          control={control}
          render={() => (
            <CalendarCodePicker
              calendarCodeType={CalendarCodeTypes.VariableDay}
              defaultValues={watchAllFields.variableDaySelection}
              onSelectedItems={(dateCodes: string[]) => {
                setValue('variableDaySelection', dateCodes);
              }}
              label="Variable day"
            />
          )}
        />
        {/** RAS close days will only have CD positives and rcd minus */}
        <Controller
          name="rasCloseDaySelection"
          control={control}
          render={() => (
            <CalendarCodePicker
              defaultValues={watchAllFields.rasCloseDaySelection}
              calendarCodeType={CalendarCodeTypes.RASCloseDay}
              onSelectedItems={(dateCodes: string[]) => {
                setValue('rasCloseDaySelection', dateCodes);
              }}
              label="RAS Close day"
            />
          )}
        />
      </>
    );
  };

  const renderDayOfWeek = () => {
    return (
      <>
        {daysOfWeek.map((day) => (
          <Controller
            name={`weeklyCadenceDay${day}Checked`}
            control={control}
            render={() => {
              return (
                <Checkbox
                  defaultChecked={
                    !!watchAllFields?.[`weeklyCadenceDay${day}Checked` as keyof IFrequencyChoices]
                  }
                  id={day}
                  label={day}
                  onChange={(e, checked: boolean): void => {
                    setValue(`weeklyCadenceDay${day}Checked`, checked);
                  }}
                />
              );
            }}
          />
        ))}
      </>
    );
  };

  const renderPeriodSelector = () => {
    return (
      <Controller
        name="startPeriodSelection"
        control={control}
        render={() => {
          return (
            <Dropdown
              id="startPeriodSelection"
              label="Start period"
              required
              placeholder="Select One"
              defaultSelectedKey={`${watchAllFields?.startPeriodSelection?.startPeriod}:${watchAllFields?.startPeriodSelection?.startYear}`}
              options={startPeriodChoices}
              onChange={(e, newValue: IDropdownOption): void => {
                const [period, year] = newValue.key.toString().split(':');
                setValue('startPeriodSelection', {
                  startPeriod: parseInt(period, 10),
                  startYear: parseInt(year, 10),
                });
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Set Frequency"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Set Frequency"
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            mutationLoading={loading}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSave)}
            saveLabel="Save"
            saveTitle="Save"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
          />
        </Stack>
      )}
    >
      <Stack verticalAlign="space-between" verticalFill>
        <form onSubmit={handleSubmit(handleOnSave)}>
          {(loading || error) && (
            <LoadingErrorMessage styles={loadingMessageStyles} loading={loading} error={error} />
          )}
          {!loading && (
            <Stack tokens={{ childrenGap: 20 }}>
              <Controller
                name="repeat"
                control={control}
                defaultValue={RepeatValues.M}
                render={() => {
                  return (
                    <Dropdown
                      id="Repeat"
                      label="Repeat"
                      placeholder="Select One"
                      options={repeatChoices}
                      required
                      defaultSelectedKey={data?.frequencyCode?.repetitionValue}
                      onChange={(e, newValue: IDropdownOption): void => {
                        setRepeatValue(newValue.text);
                      }}
                    />
                  );
                }}
              />
              {repeatGreaterThanMonthly.includes(watchAllFields?.repeat as RepeatValues) &&
                renderPeriodSelector()}
              {repeatGreaterThanWeekly.includes(watchAllFields?.repeat as RepeatValues) &&
                renderDayOfMonth()}
              {(watchAllFields?.repeat as RepeatValues) === RepeatValues.W && renderDayOfWeek()}
            </Stack>
          )}
        </form>
        {frequencyCode && (
          <Stack.Item>
            <AssignedDateField
              frequencyCode={frequencyCode}
              period={watchAllFields?.startPeriodSelection?.startPeriod}
              fiscalYear={watchAllFields?.startPeriodSelection?.startYear}
            />
            <Stack horizontal horizontalAlign="space-between">
              <Label>Frequency Code:</Label>
              <IconButton
                title="Copy Frequency Code"
                iconProps={{ iconName: 'Copy' }}
                onClick={() => navigator.clipboard.writeText(frequencyCode)}
              />
            </Stack>
            <Label styles={{ root: { wordBreak: 'break-word' } }}>{frequencyCode}</Label>
          </Stack.Item>
        )}
      </Stack>
    </CoherencePanel>
  );
};
export default FrequencyPanel;
