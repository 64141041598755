import {
  ActionButton,
  CommandBarButton,
  IContextualMenuProps,
  ITag,
  IconButton,
  NeutralColors,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import SavedFilterContext from '../../components/savedFilters/SavedFilterContext';
import AppHeaderProfileSelector from '../AppHeaderProfileSelector';
import GenericFilterTagItems from './GenericFilterTagItems';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../constants/SiteConstants';
import SavedFilterPanel from '../../components/savedFilters/panels/SavedFilterPanel';
import {
  CREATE_SAVED_FILTER_MUTATION,
  EDIT_SAVED_FILTER_MUTATION,
} from '../../utils/api/SavedFilters';
import { arraysEqual } from '../../utils/formatters/ArrayFormatters';
import { FilterType } from '../../utils/types/SavedFilters';
import { consolidateFilterList } from '../../utils/formatters/FilterConverter';

interface IGenericFilterTagHandlerProps {
  onChange: (fitlerTags: IGenericFilterTag[]) => void;
  allFilterTags: IGenericFilterTag[];
  children?: JSX.Element;
  hideSavedFilter?: boolean;
}

export const filterOutSavedTags = (filterTags: IGenericFilterTag[]) => {
  let tags = filterTags.map((f: IGenericFilterTag) => {
    return { ...f, values: f.values.filter((v) => !v?.isSavedFilter) };
  });

  tags = tags.filter((f) => f.values.length > 0);

  return tags;
};

const filterValueLength = (filterTags: IGenericFilterTag[]) => {
  return filterTags?.map((f) => f.values.length).reduce((a, b) => a + b, 0);
};

const GenericFilterTagHandler = (props: IGenericFilterTagHandlerProps): JSX.Element => {
  const { onChange, allFilterTags, children, hideSavedFilter } = props;
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const pageFilterTags = filterOutSavedTags(allFilterTags);

  const [panelState, setPanelState] = useState({
    isAddPanelOpen: false,
    isEditPanelOpen: false,
  });

  const filterWrapperStyles = {
    root: {
      padding: ' 0 5px 0 9px',
      background: filterValueLength(allFilterTags) > 0 ? NeutralColors.gray20 : 'inherit',
      outerWidth: '100%',
      [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
        alignItems: 'flex-start',
        flexFlow: 'column',
      },
    },
  };

  const commandBarButtonStyles = {
    root: {
      backgroundColor: NeutralColors.gray20,
      padding: '12px 0 12px 0',
    },
  };

  const { savedFilter } = React.useContext(SavedFilterContext);

  const deleteEmptyArrays = (filterTags: IGenericFilterTag[]) => {
    return filterTags?.filter((f) => f.values.length > 0);
  };

  const getChildFilters = (parentDataKey: string): IGenericFilterTag[] => {
    return allFilterTags?.filter((filter) => filter?.parentDataKey === parentDataKey);
  };

  const getUpdatedFilters = (filterValues: IGenericFilterTag[]): IGenericFilterTag[] => {
    let filters: IGenericFilterTag[] = structuredClone(allFilterTags);

    const updateFilters = (filterValue: IGenericFilterTag) => {
      // Get any associated child filters for this filter
      const childFilters = getChildFilters(filterValue?.dataKey);

      // Recursively update any child filters
      childFilters?.forEach((childFilter) => {
        childFilter.values.length = 0;
        updateFilters(childFilter);
      });

      const indexOfValue = filters?.findIndex((item) => item.dataKey === filterValue.dataKey);
      if (indexOfValue === -1) {
        // Add new filter
        filters = filters.concat([filterValue]);
      } else {
        // Update previous filter
        filters.splice(indexOfValue, 1, {
          dataKey: filters[indexOfValue].dataKey,
          title: filters[indexOfValue].title,
          hideFromBar: filters[indexOfValue].hideFromBar,
          parentDataKey: filters[indexOfValue].parentDataKey,
          values: filterValue.values,
        });
      }
    };

    // For each flter tag update it and append it to the list of tags
    filterValues.forEach((filterValue) => {
      updateFilters(filterValue);
    });
    return filters;
  };

  const onFilterItemUpdate = (filterValue: IGenericFilterTag) => {
    // Get list of updated filters and update state all at once
    const updatedFilters = getUpdatedFilters([filterValue]);
    onChange(updatedFilters);
  };

  const onFilterTagsChange = (filterTagKey: string, filterTagItems: ITag[]): void => {
    const tag: IGenericFilterTag = {
      title: filterTagKey,
      dataKey: filterTagKey,
      values: filterTagItems,
    };
    onFilterItemUpdate(tag);
  };

  const onClearAllFilters = () => {
    let newFilterTags = structuredClone(allFilterTags);
    newFilterTags = newFilterTags.filter((f) => f.dataKey === 'forMeOnly');
    onChange(newFilterTags || []);
  };

  const onFilterPanelUpdate = (newFilterTags: IGenericFilterTag[]) => {
    const cleanedFilterTags = deleteEmptyArrays(newFilterTags);
    onChange(cleanedFilterTags);
    setShowFilterPanel(false);
  };

  const onResetFilters = () => {
    onChange(structuredClone(savedFilter?.filters || []));
  };

  const closePanels = (): void => {
    setPanelState({
      isAddPanelOpen: false,
      isEditPanelOpen: false,
    });
  };

  React.useEffect(() => {
    const cleanedFilterTags = deleteEmptyArrays(savedFilter?.filters);
    onChange(consolidateFilterList([...(cleanedFilterTags || []), ...(pageFilterTags || [])]));
  }, [savedFilter]);

  const onSaveFilters = () => {
    if (savedFilter?.id) {
      setPanelState({ ...panelState, isEditPanelOpen: true });
    } else {
      setPanelState({ ...panelState, isAddPanelOpen: true });
    }
  };

  const onSaveAsFilters = () => {
    setPanelState({ ...panelState, isAddPanelOpen: true });
  };

  const altButtons: IContextualMenuProps = {
    items: [
      {
        key: 'saveCurrent',
        text: 'Save',
        ariaLabel: 'Save',
        iconProps: { iconName: 'Save' },
        onClick: () => {
          onSaveFilters();
        },
      },
      {
        key: 'saveAs',
        text: 'Save As',
        ariaLabel: 'Save as',
        iconProps: { iconName: 'Save' },
        onClick: () => {
          onSaveAsFilters();
        },
      },
    ],
  };

  const savedFilterEdited = !arraysEqual(
    allFilterTags.filter((item) => !item.hideFromBar),
    savedFilter?.filters,
  );

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 20 }}
        styles={filterWrapperStyles}
      >
        <Stack horizontal wrap verticalAlign="center" tokens={{ childrenGap: 10 }}>
          {!hideSavedFilter && (
            <>
              <AppHeaderProfileSelector isEdited={savedFilterEdited} />
              {savedFilterEdited && filterValueLength(allFilterTags) > 0 && (
                <CommandBarButton
                  text="Save"
                  iconProps={{ iconName: 'Save' }}
                  ariaLabel="See save options"
                  menuProps={altButtons}
                  styles={commandBarButtonStyles}
                />
              )}
              {savedFilterEdited && <ActionButton text="Reset Filter" onClick={onResetFilters} />}
            </>
          )}
          {allFilterTags?.length > 0 && (
            <GenericFilterTagItems
              filterTagItems={allFilterTags}
              onFilterTagsChange={onFilterTagsChange}
              onClearAllFilters={onClearAllFilters}
            />
          )}
        </Stack>
        <IconButton
          iconProps={{
            iconName: 'Filter',
          }}
          title="Edit Filters"
          ariaLabel="Edit Filters"
          styles={{ root: { margin: '4px 0px' } }}
          onClick={() => setShowFilterPanel(true)}
        />
      </Stack>
      {showFilterPanel &&
        React.cloneElement(children, {
          currentFilterValues: allFilterTags,
          onFilterUpdate: onFilterPanelUpdate,
          onClosePanel: () => setShowFilterPanel(false),
        })}
      {panelState.isAddPanelOpen && (
        <SavedFilterPanel
          closePanel={closePanels}
          mutation={CREATE_SAVED_FILTER_MUTATION}
          savedFilter={{
            id: -1,
            name: '',
            filterType: FilterType.PERSONAL,
            filters: allFilterTags,
          }}
          mode="Add"
          isOnSaveAs
        />
      )}
      {panelState.isEditPanelOpen && (
        <SavedFilterPanel
          closePanel={closePanels}
          savedFilter={{ ...structuredClone(savedFilter), filters: allFilterTags }}
          mutation={EDIT_SAVED_FILTER_MUTATION}
          mode="Edit"
        />
      )}
    </>
  );
};
export default GenericFilterTagHandler;
