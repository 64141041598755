import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DocumentNode } from 'graphql';
import { useMutation } from '@apollo/client';
import { IPublishedTask } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import { GetPublishedTask } from '../../../utils/api/PublishedTaskApi';
import { TaskType } from '../list/BaseTaskListfilters.config';
import TaskDetailForm from './TaskDetailForm';
import { retrieveUrl } from '../../../utils/Helpers';
import { mapFunctionsSummaryNoTypename } from '../../../common/formFields/FunctionsSummaryPicker';

interface IPublishedTasksDetailFormProps {
  publishedTask: IPublishedTask;
  mutation: DocumentNode;
  panelMode: PanelTypes;
  fiscalYear?: number;
  fiscalPeriod?: number;
}
const PublishedTaskDetailForm = (props: IPublishedTasksDetailFormProps): JSX.Element => {
  const { publishedTask, mutation, panelMode, fiscalYear, fiscalPeriod } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const parentRouteDepth = panelMode === PanelTypes.Add ? 1 : 2;
  const [updatePublishedTask, { loading, error }] = useMutation(mutation, {
    refetchQueries: [{ query: GetPublishedTask, variables: { publishedTaskId: publishedTask.id } }],
    onCompleted: (data) => {
      if (panelMode !== PanelTypes.Copy) {
        history.push(retrieveUrl(null, parentRouteDepth, match));
      } else if (panelMode === PanelTypes.Copy) {
        history.push(
          `/tasks/${fiscalYear}/${fiscalPeriod}/details/${data.addPublishedTaskCopy.id}`,
        );
      }
    },
  });

  const submitForm = (publishedTaskInputData: IPublishedTask) => {
    if (panelMode === PanelTypes.Edit) {
      updatePublishedTask({
        variables: {
          publishedTaskDto: {
            id: publishedTask?.id,
            dueTime: publishedTaskInputData.dueTime,
            dueDate: publishedTaskInputData.dueDate,
            primaries: publishedTaskInputData.primaries,
            backupTaskPerformers: publishedTaskInputData.backupTaskPerformers,
            stakeholders: publishedTaskInputData.stakeholders,
            publishedTaskNotes: publishedTaskInputData.publishedTaskNotes,
          },
        },
      });
    }
    if (panelMode === PanelTypes.Add || panelMode === PanelTypes.Copy) {
      updatePublishedTask({
        variables: {
          publishedTaskInputDto: {
            id: publishedTask.id,
            stagedTaskId: panelMode === PanelTypes.Copy ? publishedTask.stagedTaskId : null,
            dueTime: publishedTaskInputData.dueTime,
            dueDate: publishedTaskInputData.dueDate,
            primaries: publishedTaskInputData.primaries,
            backupTaskPerformers: publishedTaskInputData.backupTaskPerformers,
            stakeholders: publishedTaskInputData.stakeholders,
            publishedTaskNotes: publishedTaskInputData.publishedTaskNotes,
            status: 'PUBLISHED',
            name: publishedTaskInputData.name,
            description: publishedTaskInputData.description,
            operationsDetailId: publishedTaskInputData.operationsDetailId,
            typeId: publishedTaskInputData.typeId,
            levelId: publishedTaskInputData.levelId,
            scopeOfActivityId: publishedTaskInputData.scopeOfActivityId,
            estimatedTime: parseInt(publishedTaskInputData.estimatedTime?.toString(), 10),
            publishType: publishedTaskInputData.publishType,
            supplierId: publishedTaskInputData.supplierId,
            companies: publishedTaskInputData.companies.map((company) => company.companyCode),
            dtpUpdateDate: publishedTaskInputData.dtpUpdateDate,
            dtpDocLink: publishedTaskInputData.dtpDocLink,
            permanentNotes: publishedTaskInputData.permanentNotes,
            dataSources: publishedTaskInputData.dataSources,
            dependencyOnStagedTasks:
              publishedTaskInputData.dependencyOnStagedTasks?.map((task) => task.id) || [],
            dependencyForStagedTasks:
              publishedTaskInputData.dependencyForStagedTasks?.map((task) => task.id) || [],
            functionsSummaries: mapFunctionsSummaryNoTypename(
              publishedTaskInputData.functionsSummaries,
            ),
            timeZoneId: publishedTaskInputData.timeZoneId,
            bcpCategoryId: publishedTaskInputData.bcpCategoryId,
            bcpPriorityPeak: publishedTaskInputData.bcpPriorityPeak,
            bcpPriorityNonPeak: publishedTaskInputData.bcpPriorityNonPeak,
            taskTags: publishedTaskInputData.taskTags || [],
            fiscalYear,
            fiscalPeriod,
            reportTypeId: publishedTaskInputData.reportTypeId,
            analysisTypeId: publishedTaskInputData.analysisTypeId,
            outputId: publishedTaskInputData.outputId,
            salesLocations: publishedTaskInputData.salesLocations?.map(
              (salesLocation) => salesLocation.code,
            ),
          },
        },
      });
    }
  };

  return (
    <TaskDetailForm
      task={publishedTask}
      taskType={TaskType.PublishedTasks}
      publishTypes={publishedTask.publishType}
      panelMode={panelMode}
      loading={loading}
      error={error}
      updateTask={submitForm}
      loadingStart={false}
      errorStart={null}
      getOverrideDefaults={() => {
        return {} as Partial<IPublishedTask>;
      }}
    />
  );
};

export default PublishedTaskDetailForm;
