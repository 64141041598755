import React from 'react';

const linkFormatter = (link: string): JSX.Element => {
  if (link && link !== '') {
    return (
      <a target="_blank" rel="noreferrer" href={link}>
        DTP Doc Link
      </a>
    );
  }

  return <span>--</span>;
};

export default linkFormatter;
