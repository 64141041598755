import React, { useState } from 'react';
import { SpinButton, Stack } from '@fluentui/react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from '../formFields/Collapsible';

interface IEstimatedTimeRangePickerProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag[];
  dataKeys?: string[];
  wrapperLabel?: string | null;
  labels?: string[];
}

const EstimatedTimeRangePicker = (props: IEstimatedTimeRangePickerProps): JSX.Element => {
  const { dataKeys, wrapperLabel, labels, onSelectedItems, initialValues } = props;

  if (labels.length !== 2) {
    return (
      <span>
        Estimated time range picker requires an array with two label values, one for &quot;at
        least&quot; and one for &quot;at most.&quot;
      </span>
    );
  }
  if (dataKeys.length !== 2) {
    return (
      <span>
        Estimated time range picker requires an array with two data keys, one for &quot;at
        least&quot; and one for &quot;at most.&quot;
      </span>
    );
  }

  const startLabel = labels[0];
  const endLabel = labels[1];
  const startKey = dataKeys[0];
  const endKey = dataKeys[1];

  const fromTimeObject = initialValues?.find((x) => x.dataKey === startKey);
  const toTimeObject = initialValues?.find((x) => x.dataKey === endKey);
  const [selectedFromTime, setSelectedFromTime] = useState(
    fromTimeObject?.values && fromTimeObject.values[0]?.name ? fromTimeObject.values[0].name : '0',
  );
  const [selectedToTime, setSelectedToTime] = useState(
    toTimeObject?.values && toTimeObject.values[0]?.name ? toTimeObject.values[0].name : '10000',
  );

  const handleValueChange = (
    time: string,
    dataKey: string,
    title: string,
    setFunction: (value: React.SetStateAction<string>) => void,
  ) => {
    setFunction(time);
    onSelectedItems({ dataKey, title, values: [{ key: time, name: time }] });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Collapsible text={wrapperLabel}>
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <SpinButton
            value={selectedFromTime}
            label={startLabel}
            min={0}
            max={10000}
            onChange={(ev, newValue) => {
              handleValueChange(newValue, startKey, startLabel, setSelectedFromTime);
            }}
          />
          <SpinButton
            value={selectedToTime}
            label={endLabel}
            min={0}
            max={10000}
            onChange={(ev, newValue) => {
              handleValueChange(newValue, endKey, endLabel, setSelectedToTime);
            }}
          />
        </Stack>
      </Collapsible>
    </Stack>
  );
};

export default EstimatedTimeRangePicker;
