import React from 'react';
import { alternateBackgroundColor } from '../styles/CommonStyleObjects';
import { pageHeaderFullWidth } from './Headers.styles';

interface IPageHeaderProps {
  title: () => JSX.Element;
  subtitle?: () => JSX.Element;
  alternateBackground?: boolean;
}

const FullWidthHeader: React.FunctionComponent<IPageHeaderProps> = (props: IPageHeaderProps) => {
  const { title, subtitle, alternateBackground } = props;
  const Styles = pageHeaderFullWidth();
  return (
    <div
      className={`${Styles.pageHeaderWrapper}`}
      style={alternateBackground ? alternateBackgroundColor : {}}
    >
      <div className={Styles.pageHeaderTitle}>{title()}</div>
      {subtitle && <div className={Styles.pageSubheaderWrapper}>{subtitle()}</div>}
    </div>
  );
};

export default FullWidthHeader;
