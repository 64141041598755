import React from 'react';
import { DatePicker, defaultDatePickerStrings, ITextFieldProps } from '@fluentui/react';
import moment from 'moment';

export interface IUtcDatePickerProps {
  id?: string;
  label?: string;
  ariaLabel?: string;
  className?: string;
  textField?: ITextFieldProps;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  allowTextInput?: boolean;
  isRequired?: boolean;
  disableAutoFocus?: boolean;
  value?: string | Date;
  onChange?: (utcDateString: string) => void;
  isRequiredErrorMessage?: string;
}

const UtcDatePicker: React.FunctionComponent<IUtcDatePickerProps> = ({
  value,
  onChange,
  ...props
}: IUtcDatePickerProps): JSX.Element => {
  const utcDateValue = value ? new Date(moment(value.toString()).format('L')) : undefined;
  const handleSelectDate = (selectedDate: Date): void => {
    if (typeof selectedDate === 'undefined' || selectedDate === null) {
      return;
    }
    const momentDate = moment()
      .utc(true)
      .year(selectedDate.getFullYear())
      .month(selectedDate.getMonth())
      .date(selectedDate.getDate())
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    const selectedDateString = momentDate.utc().format('YYYY-MM-DD');
    onChange(selectedDateString);
  };

  const {
    id,
    ariaLabel,
    label,
    className,
    textField,
    disabled,
    minDate,
    maxDate,
    allowTextInput,
    isRequired,
    disableAutoFocus,
    isRequiredErrorMessage,
  } = props;
  const utcDateStrings = defaultDatePickerStrings;
  if (isRequiredErrorMessage) {
    utcDateStrings.isRequiredErrorMessage = isRequiredErrorMessage;
  }
  return (
    <DatePicker
      id={id}
      ariaLabel={ariaLabel}
      label={label}
      className={className}
      textField={textField}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      allowTextInput={allowTextInput}
      isRequired={isRequired}
      value={utcDateValue}
      onSelectDate={handleSelectDate}
      disableAutoFocus={disableAutoFocus}
      strings={utcDateStrings}
      initialPickerDate={utcDateValue || new Date(Date.now())}
    />
  );
};

export default UtcDatePicker;
