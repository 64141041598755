import { Icon, IconButton, mergeStyles, NeutralColors } from '@fluentui/react';
import React from 'react';

const tagStyles = mergeStyles({
  backgroundColor: NeutralColors.gray30,
  padding: '3px 8px 3px 8px',
});

const whiteTagStyles = mergeStyles({
  backgroundColor: NeutralColors.white,
});

interface IValueTagProps {
  tagString: string | number;
  useWhiteBackground?: boolean;
  iconName?: string;
  onClick?: (itemName: string) => void;
}

const iconStyles = { marginRight: '8px' };
const cancelIconProps = {
  iconName: 'Cancel',
  styles: {
    root: { fontSize: '12px', lineHeight: '16px', margin: '0px 4px', color: NeutralColors.gray150 },
  },
};
const ValueTag = (props: IValueTagProps): JSX.Element => {
  const { tagString, useWhiteBackground, iconName, onClick } = props;

  const tagClass = useWhiteBackground ? `${tagStyles} ${whiteTagStyles}` : tagStyles;

  return (
    <div className={tagClass}>
      {!!iconName && <Icon style={iconStyles} iconName={iconName} aria-hidden="true" />}
      {tagString}
      {onClick && (
        <IconButton iconProps={cancelIconProps} onClick={() => onClick(tagString.toString())} />
      )}
    </div>
  );
};

export default ValueTag;
