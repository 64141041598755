import { useQuery } from '@apollo/client';
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles, Label } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { GetFiscalCalendarDateCodes } from '../../utils/api/CalendarApi';
import CalendarCodeTypes from '../../utils/types/ICalendarCodeTypes';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

export interface ICalendarCodePickerProps {
  onSelectedItems: (selectedItems: string[]) => void;
  label?: string;
  calendarCodeType: CalendarCodeTypes;
  defaultValues: string[];
}

const CalendarCodePicker = (props: ICalendarCodePickerProps): JSX.Element => {
  const { onSelectedItems, label, calendarCodeType, defaultValues } = props;

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    optionsContainerWrapper: {
      height: '300px',
      maxWidth: '300px',
    },
  };
  const loadingMessageStyles = { root: { justifyContent: 'start', margin: '10px 15px' } };

  const { data, loading, error } = useQuery(GetFiscalCalendarDateCodes, {
    variables: { dateCodeType: calendarCodeType },
  });
  const [options, setOptions] = useState([] as IComboBoxOption[]);
  const [selectedItems, setSelectedItems] = useState(defaultValues || ([] as string[]));

  useEffect(() => {
    if (data?.fiscalCalendarDateCodes && !error && !loading) {
      let dateCodes = [...data.fiscalCalendarDateCodes];
      if (calendarCodeType !== CalendarCodeTypes.VariableDay) {
        dateCodes.sort(
          (d1: string, d2: string) => parseInt(d1.substring(2), 10) - parseInt(d2.substring(2), 10),
        );
      }
      dateCodes = dateCodes.map((d: string) => {
        return { key: d, text: d } as IComboBoxOption;
      });
      setOptions(dateCodes);
    }
  }, [data]);

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ) => {
    if (option?.selected) setSelectedItems([...selectedItems, option.text]);
    else setSelectedItems(selectedItems.filter((opt) => opt !== option.text));
  };

  useEffect(() => {
    onSelectedItems(selectedItems);
  }, [selectedItems]);

  return (
    <>
      <Label>{label}</Label>
      <LoadingErrorMessage styles={loadingMessageStyles} loading={loading} error={error} />
      {!loading && !error && (
        <ComboBox
          options={options}
          ariaLabel="Select a date code"
          multiSelect
          required
          placeholder="Select one or many"
          selectedKey={selectedItems}
          styles={comboBoxStyles}
          useComboBoxAsMenuWidth
          onChange={onChange}
        />
      )}
    </>
  );
};
export default CalendarCodePicker;
