import IAadUser from './IAadUser';
import { IGenericFilterTag } from './IGenericFilterTag';

export interface IPersonSharedWith {
  id: number;
  personId: string;
  person: IAadUser;
}

export interface ISavedFilter {
  id: number;
  name: string;
  filterType: FilterType;
  filters: IGenericFilterTag[];
  peopleSharedWith?: IPersonSharedWith[];
}

export enum FilterType {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED',
}
