import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { IPublishedTask, ITaskRouteParams } from '../../../utils/types/ITask';
import { AddPublishedTaskCopy, GetPublishedTask } from '../../../utils/api/PublishedTaskApi';
import PublishedTaskDetailForm from './PublishedTaskDetailForm';
import PanelTypes from '../../../utils/types/PanelTypes';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';

const PublishedTaskNewFromCopy = (): JSX.Element => {
  const { fiscalYear, period, publishedTaskId } = useParams<ITaskRouteParams>();
  const { data, loading, error } = useQuery<{ publishedTask: IPublishedTask }>(GetPublishedTask, {
    variables: { publishedTaskId: parseInt(publishedTaskId, 10) },
  });

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {data?.publishedTask && (
        <PublishedTaskDetailForm
          panelMode={PanelTypes.Copy}
          publishedTask={data?.publishedTask}
          mutation={AddPublishedTaskCopy}
          fiscalYear={parseInt(fiscalYear, 10)}
          fiscalPeriod={parseInt(period, 10)}
        />
      )}
    </>
  );
};

export default PublishedTaskNewFromCopy;
