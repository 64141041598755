import { gql } from '@apollo/client';

export const GetCompanyTagsByFilter = gql`
  query CompanyTags($keyword: String!, $isActive: Boolean!) {
    companyTags(keyword: $keyword, isActive: $isActive) {
      companyCode
      countryCode
      name
      companyCodeName
    }
  }
`;
export const GetCountryTagsByFilter = gql`
  query CountryTags($keyword: String!, $isActive: Boolean!) {
    countryTags(keyword: $keyword, isActive: $isActive) {
      countryCode
    }
  }
`;
export const GetDefaultCompany = gql`
  query DefaultCompany {
    defaultCompany {
      companyCode
      countryCode
      name
    }
  }
`;

export default GetCompanyTagsByFilter;
