import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import { getInitialPageSize } from '../../../common/lists/SharedPagination';
import { GetVariableDates } from '../../../utils/api/CalendarApi';
import { IFiscalCalendar } from '../../../utils/types/ICalendarDate';
import PanelTypes from '../../../utils/types/PanelTypes';
import CalendarListPanel from './CalendarListPanel';

interface ICalendarListEditProps {
  variableDate: IFiscalCalendar;
  closePanel: (shouldRefresh: boolean) => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const CalendarListEdit = (props: ICalendarListEditProps): JSX.Element => {
  const { mutation, variableDate, closePanel, panelMode } = props;
  const [updateVariableDateValue, { loading, error }] = useMutation(mutation, {
    onCompleted: () => {
      closePanel(true);
    },
    refetchQueries: [
      {
        query: GetVariableDates,
        variables: {
          first: getInitialPageSize(),
          after: null,
          last: null,
          before: null,
          order: [{ taskDateCode: 'ASC' }],
        },
      },
    ],
  });

  const submitForm = (variableDateDtoData: IFiscalCalendar) => {
    updateVariableDateValue({
      variables: {
        variableDateDto: {
          fiscalYear: variableDateDtoData.fiscalYear,
          fiscalPeriod: [
            { period: 1, assignedDate: variableDateDtoData.assignedDateP1 },
            { period: 2, assignedDate: variableDateDtoData.assignedDateP2 },
            { period: 3, assignedDate: variableDateDtoData.assignedDateP3 },
            { period: 4, assignedDate: variableDateDtoData.assignedDateP4 },
            { period: 5, assignedDate: variableDateDtoData.assignedDateP5 },
            { period: 6, assignedDate: variableDateDtoData.assignedDateP6 },
            { period: 7, assignedDate: variableDateDtoData.assignedDateP7 },
            { period: 8, assignedDate: variableDateDtoData.assignedDateP8 },
            { period: 9, assignedDate: variableDateDtoData.assignedDateP9 },
            { period: 10, assignedDate: variableDateDtoData.assignedDateP10 },
            { period: 11, assignedDate: variableDateDtoData.assignedDateP11 },
            { period: 12, assignedDate: variableDateDtoData.assignedDateP12 },
          ],
          description: variableDateDtoData.description,
          taskDateCode: variableDateDtoData.taskDateCode,
        },
      },
    });
  };

  return (
    <CalendarListPanel
      data={variableDate}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default CalendarListEdit;
