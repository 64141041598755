import { DocumentNode, useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import GraphUsersPeoplePicker from '../../common/formFields/GraphUsersPeoplePicker';
import IAadUser, { IInactiveUser, IReplaceUser } from '../../utils/types/IAadUser';
import { GetInactiveUserList } from '../../utils/api/ToolsApi';
import { PEOPLE_PICKER_LIMIT } from '../../common/constants/SiteConstants';

interface IInactiveUserReplaceProps {
  selectedItems?: IInactiveUser[];
  selectedUsersCount?: number;
  showReplaceModal: boolean;
  hideReplaceModal: (shouldRefetch: boolean) => void;
  mutation: DocumentNode;
}

const ReplaceModal = (props: IInactiveUserReplaceProps): JSX.Element => {
  const { selectedItems, selectedUsersCount, mutation, showReplaceModal, hideReplaceModal } = props;

  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const [replaceInactiveUser, { loading, error }] = useMutation(mutation, {
    refetchQueries: [{ query: GetInactiveUserList }],
    onCompleted: () => {
      hideReplaceModal(true);
    },
  });

  const submitForm = (replaceInactiveUserDtoData: IReplaceUser): void => {
    replaceInactiveUser({
      variables: {
        replaceInactiveUserDto: {
          selectedUsers: selectedItems,
          replaceWithUser: replaceInactiveUserDtoData.replaceWithUser,
        },
      },
    });
  };
  const handleOnSaveClick = (formData: IReplaceUser): void => {
    submitForm(formData);
  };
  const getMessage = () => {
    return (
      <>
        Click save to replace <strong> {selectedUsersCount} </strong> inactive user with selected
        users.
      </>
    );
  };

  return (
    <CoherenceModal
      isOpen={showReplaceModal}
      modalWidth="small"
      height="responsive"
      title="Replace Inactive Users"
      styles={{ root: { padding: '20px 0' } }}
      onDismiss={() => {
        hideReplaceModal(false);
      }}
    >
      <LoadingErrorMessage loading={loading} error={error} label="Replacing Inactive Users" />
      <Stack styles={{ root: { paddingTop: '30px' } }}>
        <Controller
          name="replaceWithUser"
          valueKey="replaceWithUser"
          control={control}
          render={({ value }) => {
            return (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <GraphUsersPeoplePicker
                  id="replaceWithUser"
                  itemLimit={PEOPLE_PICKER_LIMIT}
                  removeButtonAriaLabel="Remove person replaced with"
                  controlName="replaceWithUser"
                  onChange={(users: IAadUser[]): void => {
                    setValue('replaceWithUser', users);
                  }}
                  errorMessage={errors?.replaceWithUser?.message}
                  defaultValue={value}
                  placeholder="Type one or more alias"
                  isMultiUserAddable
                  label="Replace"
                  width="225px"
                />
              </Stack>
            );
          }}
        />
      </Stack>
      <Stack>
        <MessageBar messageBarType={MessageBarType.warning}>
          <Stack tokens={{ childrenGap: 10 }}>
            <span>{getMessage()}</span>
          </Stack>
        </MessageBar>
        <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 20 }}>
          <PrimaryButton
            text="Save"
            onClick={handleSubmit(handleOnSaveClick)}
            ariaLabel="Save"
            disabled={loading}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              hideReplaceModal(false);
            }}
            ariaLabel="Cancel"
            disabled={loading}
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export default ReplaceModal;
