import { ITag } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';
import { GetDataSourceApplicationsByKeyword } from '../../utils/api/DataSourcesApi';
import IDataSourceApplication from '../../utils/types/IDataSourceApplication';

const DataSourceApplicationsChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;

  const mapToTag = (item: IDataSourceApplication): ITag => {
    return {
      key: item.id,
      name: item.name,
    };
  };

  const mapData = (data: { dataSourceApplications: IDataSourceApplication[] }): ITag[] => {
    return data?.dataSourceApplications?.map((application: IDataSourceApplication) => {
      return mapToTag(application);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text="Applications">
      <GenericChecklist
        query={GetDataSourceApplicationsByKeyword}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default DataSourceApplicationsChecklist;
