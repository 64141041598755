import { IconButton, Label } from '@fluentui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { errorLabelStyles } from '../styles/CommonStyleObjects';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import { IOpsDetailFormInputChoice } from '../../components/hierarchies/edit/operations/OpsOrgDetailBulkEdit.config';
import { ITaskFormInputChoice } from '../../components/tasks/edit/StagedTaskBulkEdit.config';

export interface IFormEditInputRow {
  selectedInputKey: string;
}

const BulkEditRow = (props: {
  inputRowProps: IFormEditInputRow;
  formInputs: IOpsDetailFormInputChoice[] | ITaskFormInputChoice[];
  activeFormInputs: string[];
  control: Control<FieldValues>;
  bulkEditState: IFormEditInputRow[];
  setBulkEditState: Dispatch<SetStateAction<IFormEditInputRow[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: any;
  errors?: DeepMap<FieldValues, FieldError>;
  showSingleValueOptions: boolean;
}) => {
  const {
    inputRowProps,
    formInputs,
    activeFormInputs,
    control,
    bulkEditState,
    setBulkEditState,
    getValues,
    errors,
    showSingleValueOptions,
  } = props;

  const { selectedInputKey } = inputRowProps;
  const getFilteredFormInputs = (currentKey: string) => {
    return formInputs.filter((input) => {
      const unusedInput =
        input.key.toString() === currentKey || !activeFormInputs.includes(input.key.toString());
      const showSingle = showSingleValueOptions ? true : input.isMultiValue;
      return unusedInput && showSingle;
    });
  };

  const getIndividualEditFormComponent = (formInputKey: string) => {
    const inputType: IOpsDetailFormInputChoice | ITaskFormInputChoice = formInputs.find((input) => {
      return input.key === formInputKey;
    });
    const componentProps = inputType?.props !== undefined ? Object.assign(inputType?.props()) : {};
    return (
      <>
        <Controller
          control={control}
          as={inputType?.component}
          name={inputType?.key?.toString()}
          value={getValues(selectedInputKey)}
          defaultValue={getValues(selectedInputKey)}
          ariaLabel="Value"
          {...componentProps}
          rules={{
            required: 'Required field',
          }}
        />
        {errors[inputType?.key?.toString()] && (
          <Label styles={errorLabelStyles} required>
            {errors[inputType?.key?.toString()].message}
          </Label>
        )}
      </>
    );
  };
  return (
    <>
      <div style={{ gridColumn: '1' }}>
        <QueryBasedDropdown
          value={selectedInputKey}
          getFocusOnRender
          ariaLabel="Field"
          dropdownOptionsData={getFilteredFormInputs(selectedInputKey)}
          handleChange={(
            newValue: IOpsDetailFormInputChoice | ITaskFormInputChoice,
            changeInputKey: string,
          ): void => {
            const currentState = bulkEditState;
            const editedItem = currentState.findIndex((item: IFormEditInputRow) => {
              return item.selectedInputKey === changeInputKey;
            });
            if (currentState[editedItem]) {
              currentState[editedItem].selectedInputKey = newValue.key.toString();
              setBulkEditState([...currentState]);
            }
          }}
          valueKey={selectedInputKey}
          width="100%"
        />
      </div>
      <div style={{ gridColumn: '2', textAlign: 'center' }}>
        <Label>=</Label>
      </div>
      <div style={{ gridColumn: '3', maxWidth: '300px' }}>
        {getIndividualEditFormComponent(selectedInputKey)}
      </div>
      <div style={{ gridColumn: '4' }}>
        <IconButton
          ariaLabel="Delete the field row"
          disabled={activeFormInputs.length <= 1}
          iconProps={{ iconName: 'Delete' }}
          onClick={() => {
            const currentState = bulkEditState;
            const filteredItems = currentState.filter((item: IFormEditInputRow) => {
              return item.selectedInputKey !== selectedInputKey;
            });
            setBulkEditState([...filteredItems]);
          }}
        />
      </div>
    </>
  );
};

export default BulkEditRow;
