import { CommandBar, ICommandBarItemProps, Pivot, PivotItem } from '@fluentui/react';
import React, { useState } from 'react';
import PageContainer from '../../common/page/PageContainer';
import { CREATE_SAVED_FILTER_MUTATION } from '../../utils/api/SavedFilters';
import { FilterType } from '../../utils/types/SavedFilters';
import SavedFilterPanel from './panels/SavedFilterPanel';
import MySavedFiltersTab from './SavedFiltersTab';
import { flexWrapper, pivotWrapperStyles } from '../../common/styles/CommonStyleObjects';

const SavedFiltersManagementPage = (): JSX.Element => {
  const [panelState, setPanelState] = useState({ isAddPanelOpen: false });

  const openNewSavedFilterPanel = (): void => {
    setPanelState({ isAddPanelOpen: true });
  };

  const closeNewSavedFilterPanel = (): void => {
    setPanelState({ isAddPanelOpen: false });
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newFilter',
      text: 'New Saved Filter',
      cacheKey: 'newFilter',
      iconProps: { iconName: 'Add' },
      onClick: openNewSavedFilterPanel,
    },
  ];

  return (
    <PageContainer title="Saved Filters">
      <CommandBar items={commandBarItems} />
      <Pivot style={flexWrapper} styles={pivotWrapperStyles} overflowBehavior="menu">
        <PivotItem style={flexWrapper} headerText="My Saved Filters">
          <br />
          <MySavedFiltersTab filterType={FilterType.PERSONAL} />
        </PivotItem>
        <PivotItem style={flexWrapper} headerText="Saved Filters Shared With Me">
          <br />
          <MySavedFiltersTab filterType={FilterType.SHARED} />
        </PivotItem>
      </Pivot>
      {panelState.isAddPanelOpen && (
        <SavedFilterPanel
          closePanel={closeNewSavedFilterPanel}
          mutation={CREATE_SAVED_FILTER_MUTATION}
          savedFilter={{ id: -1, name: '', filterType: FilterType.PERSONAL, filters: [] }}
          mode="Add"
        />
      )}
    </PageContainer>
  );
};

export default SavedFiltersManagementPage;
