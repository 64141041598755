import { useQuery } from '@apollo/client';
import { FontSizes, FontWeights, ITag, SharedColors, TagPicker, Label } from '@fluentui/react';
import React, { useState } from 'react';
import { IDataSourceOption } from '../../utils/types/ITask';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import { RequiredMarker } from '../labels/RequiredMarker';
import { GetDataSourceOptionsByKeyword } from '../../utils/api/DataSourcesApi';

const pickerId = 'DATA_SOURCES_PICKER';
const labelStyles = {
  fontWeight: FontWeights.semibold,
  fontSize: FontSizes.size14,
  paddingTop: '5px',
  paddingBottom: '5px',
};

const pickerErrorStyles = {
  text: {
    borderColor: SharedColors.red20,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '2px',
    selectors: {
      '&:hover': {
        borderColor: SharedColors.red20,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '2px',
      },
      '::after': {
        borderColor: SharedColors.red20,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '2px',
        inset: '-1px',
      },
    },
  },
};

export interface IDataSourcesPickerProps {
  label?: string;
  defaultValue?: IDataSourceOption[];
  onChange?: (dataSourceTags: IDataSourceOption[]) => void;
  errorMessage?: string;
  disabled?: boolean;
}
interface ITagWithDataSource extends ITag {
  applicationId: number;
  perspectiveId: number;
  isEditable: boolean;
}
export const mapDataSourceToTag = (dataSources: IDataSourceOption[]): ITagWithDataSource[] => {
  return dataSources?.map((dataSource) => {
    return {
      key: dataSource.id,
      name: dataSource.name,
      applicationId: dataSource.applicationId,
      perspectiveId: dataSource.perspectiveId,
      isEditable: dataSource.isEditable,
    };
  });
};
export const mapTagToDataSource = (tags: ITagWithDataSource[]): IDataSourceOption[] => {
  return tags?.map((dataSourceTag) => {
    return {
      id: dataSourceTag.key,
      name: dataSourceTag.name,
      applicationId: dataSourceTag.applicationId,
      perspectiveId: dataSourceTag.perspectiveId,
      isEditable: dataSourceTag.isEditable,
    } as IDataSourceOption;
  });
};

const DataSourcesPicker = (props: IDataSourcesPickerProps): JSX.Element => {
  const { label, defaultValue, onChange, errorMessage, disabled } = props;
  const [selectedDataSourceTags, setSelectedDataSourceTags] = useState<ITag[]>(
    mapDataSourceToTag(defaultValue),
  );
  const { refetch } = useQuery(GetDataSourceOptionsByKeyword, {
    skip: true,
    variables: { keyword: '' },
  });

  const duplicateCheck = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }

    return tagList.some((item) => item.key === tag.key);
  };

  const searchDataSources = (keyword: string, tagList: ITag[]): ITag[] | Promise<ITag[]> => {
    if (keyword) {
      return refetch({
        keyword,
      }).then((result): ITag[] => {
        return result.data.dataSourceOptions
          .filter((item: ITag) => {
            return !duplicateCheck(item, tagList);
          })
          .map((dataSource: IDataSourceOption): ITagWithDataSource => {
            return {
              key: dataSource.id,
              name: dataSource.name,
              applicationId: dataSource.applicationId,
              perspectiveId: dataSource.perspectiveId,
              isEditable: dataSource.isEditable,
            };
          });
      });
    }

    return [];
  };

  const styles = errorMessage ? pickerErrorStyles : {};

  return (
    <>
      {label && (
        <Label style={labelStyles} disabled={disabled}>
          {label}
          <RequiredMarker />
        </Label>
      )}
      <TagPicker
        aria-label={label}
        removeButtonAriaLabel="Remove"
        selectionAriaLabel="Selected Data Sources"
        onResolveSuggestions={searchDataSources}
        resolveDelay={THROTTLE_SEARCH_TIMEOUT}
        inputProps={{
          id: pickerId,
          'aria-label': label || 'Value',
          placeholder: 'Type one or more Data Sources',
          required: true,
        }}
        styles={styles}
        defaultSelectedItems={selectedDataSourceTags}
        onChange={(items?: ITagWithDataSource[]): void => {
          setSelectedDataSourceTags(items);
          onChange(mapTagToDataSource(items));
        }}
        disabled={disabled}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export default DataSourcesPicker;
