import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import ProtectedRoute from '../../components/authentication/ProtectedRoute';

interface IPageProps extends RouteProps {
  title: string;
  requestedPermission?: string;
}

const Page: React.FunctionComponent<IPageProps> = (props) => {
  const { title, requestedPermission, ...rest } = props;

  const pageTitle = title?.length ? `FCCW | ${title}` : 'FCCW';

  useEffect(() => {
    document.title = pageTitle;
  });

  if (requestedPermission) {
    return <ProtectedRoute {...rest} requestedPermission={requestedPermission} />;
  }
  return <Route {...rest} />;
};

export default Page;
