import { useLazyQuery } from '@apollo/client';
import { HoverCard, HoverCardType, Label, NeutralColors, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { GetFiscalCalendarDates } from '../../utils/api/CalendarApi';
import { monthToFiscalPeriod, yearToFiscalYear } from '../../utils/formatters/DateFormatters';
import { deconstructFrequencyCode } from '../../utils/formatters/FrequencyCodeFormatter';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

export interface IAssignedDateFieldProps {
  frequencyCode?: string;
  period?: number;
  fiscalYear?: number;
}

interface FiscalCalendarDates {
  assignedDate: string;
}

const AssignedDateField = (props: IAssignedDateFieldProps): JSX.Element => {
  const { frequencyCode, period, fiscalYear } = props;

  const [assignedDates, setAssignedDates] = useState('');

  const currentDate = new Date();
  const assignedPeriod = period ?? monthToFiscalPeriod(currentDate.getMonth());
  const assignedYear =
    fiscalYear ?? yearToFiscalYear(currentDate.getFullYear(), currentDate.getMonth());
  const loadingMessageStyles = { root: { justifyContent: 'start', margin: '10px 15px' } };
  const spanStyles = { root: { color: NeutralColors.gray180, fontStyle: 'italic' } };
  const cardStyles = {
    padding: '20px',
    width: '200px',
    display: 'flex',
  };

  const [loadAssignedDates, { loading, error }] = useLazyQuery(GetFiscalCalendarDates, {
    variables: {
      dateCodes: [],
      period: assignedPeriod,
      fiscalYear: assignedYear,
    },
  });

  useEffect(() => {
    if (frequencyCode) {
      const dateCodes = deconstructFrequencyCode(frequencyCode);
      if (dateCodes) {
        loadAssignedDates({
          variables: { dateCodes, period: assignedPeriod, fiscalYear: assignedYear },
        }).then((result) => {
          setAssignedDates(
            result?.data?.fiscalCalendarDates
              ?.map((date: FiscalCalendarDates) => date?.assignedDate)
              .join(', '),
          );
        });
      } else {
        setAssignedDates('');
      }
    }
  }, [frequencyCode]);

  const renderDates = () => {
    if (!assignedDates?.length) return <Stack styles={spanStyles}>To be system-generated</Stack>;
    if (assignedDates?.length < 100) return <>{assignedDates}</>;
    return (
      <HoverCard
        type={HoverCardType.plain}
        plainCardProps={{
          onRenderPlainCard: () => <div style={cardStyles}> {assignedDates}</div>,
        }}
      >
        <div>{assignedDates.substring(0, 99)}...</div>
      </HoverCard>
    );
  };

  return (
    <>
      <Label>
        Assigned dates for P{assignedPeriod} FY{assignedYear}
      </Label>
      <LoadingErrorMessage styles={loadingMessageStyles} loading={loading} error={error} />
      {!loading && !error && renderDates()}
    </>
  );
};
export default AssignedDateField;
