import { Card, CardStandardHeader } from '@coherence-design-system/controls';
import { ITag, Persona, PersonaSize, Stack } from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import SpanWithLabel from '../../../common/cardContents/SpanWithLabel';
import StackColumn from '../../../common/cardContents/StackColumn';
import ValueTag from '../../../common/cards/ValueTag';
import RemainingTime from '../../../common/dates/RemainingTime';
import { DateConstants } from '../../../utils/formatters/DateConstants';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import linkFormatter from '../../../utils/formatters/LinkFormatter';
import IAadUser from '../../../utils/types/IAadUser';
import ICompany from '../../../utils/types/ICompany';
import IFunctionsSummary from '../../../utils/types/IFunctionsSummary';
import { IDataSourceOption, IPublishedTask, IStagedTask } from '../../../utils/types/ITask';
import PublishedTaskActions from './PublishedTaskActions';
import { getCountriesLabel } from './TaskDetailLocationCard';
import FeatureFlagged from '../../featureFlags/FeatureFlagged';
import ISalesLocation from '../../../utils/types/ISalesLocation';

const cardStyles = {
  root: {
    height: 'auto',
    width: 'auto',
    maxWidth: '97%',
    marginLeft: '10px',
  },
};

const personaStyles = {
  root: { marginLeft: '5px' },
};

const personaStackStyles = {
  root: { minHeight: '50px', paddingTop: '5px' },
};

interface ITaskDetailCardsProps {
  task: IPublishedTask;
}

const TaskDetailCards = ({ task }: ITaskDetailCardsProps): JSX.Element => {
  const countryName = getCountriesLabel(task?.companies);

  return (
    <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
      <StackColumn>
        <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Location & team" />}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel
                labelText="Ops summary / org"
                value={
                  task?.operationsDetail
                    ? `${task?.operationsDetail?.opsOrg?.opsSummary?.name} / ${task?.operationsDetail?.opsOrg?.name}`
                    : null
                }
              />
              <SpanWithLabel labelText="Ops detail" value={task?.operationsDetail?.name} />
              <SpanWithLabel labelText="Company code(s)">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.companies?.map((co: ICompany) => {
                    return (
                      <ValueTag
                        key={`co${co.companyCode}`}
                        tagString={`${co.companyCode} - ${co.name}`}
                      />
                    );
                  })}
                </Stack>
              </SpanWithLabel>
              <SpanWithLabel labelText="Country" value={countryName} />
              <FeatureFlagged flagName="SalesLocationOnTasks">
                <SpanWithLabel labelText="Sales Location(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                    {task?.salesLocations?.map((s: ISalesLocation) => {
                      return <ValueTag key={`sl${s.code}`} tagString={s.name} />;
                    })}
                  </Stack>
                </SpanWithLabel>
              </FeatureFlagged>
              <SpanWithLabel labelText="Work timezone" value={task?.timeZone?.name} />
              <SpanWithLabel labelText="Analysis type" value={task?.analysisType?.name} />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Supplier" value={task?.supplier?.name} />
              <SpanWithLabel labelText="Function summary(ies)">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.functionsSummaries?.map((fs: IFunctionsSummary) => {
                    return <ValueTag key={`fs${fs.id}`} tagString={fs.name} />;
                  })}
                </Stack>
              </SpanWithLabel>
              <SpanWithLabel labelText="DTP doc link">
                {linkFormatter(task?.dtpDocLink)}
              </SpanWithLabel>
              <SpanWithLabel labelText="DTP update date">
                <span>
                  {task?.dtpUpdateDate
                    ? moment(task?.dtpUpdateDate).format(DateConstants.DayBreakout)
                    : '--'}
                </span>
              </SpanWithLabel>
              <SpanWithLabel labelText="Report type" value={task?.reportType?.name} />
              <SpanWithLabel labelText="Output" value={task?.output?.name} />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Assignee(s)" />}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Primaries">
                <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                  {task?.primaries?.map((p: IAadUser) => {
                    return (
                      <Persona
                        styles={personaStyles}
                        key={p.graphGuid}
                        text={p.displayName}
                        size={PersonaSize.size24}
                      />
                    );
                  })}
                </Stack>
              </SpanWithLabel>
              <SpanWithLabel labelText="Stakeholders">
                <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                  {task?.stakeholders?.map((p: IAadUser) => {
                    return (
                      <Persona
                        styles={personaStyles}
                        key={p.graphGuid}
                        text={p.displayName}
                        size={PersonaSize.size24}
                      />
                    );
                  })}
                </Stack>
              </SpanWithLabel>
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Backup(s)">
                <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                  {task?.backupTaskPerformers?.map((p: IAadUser) => {
                    return (
                      <Persona
                        styles={personaStyles}
                        key={p.graphGuid}
                        text={p.displayName}
                        size={PersonaSize.size24}
                      />
                    );
                  })}
                </Stack>
              </SpanWithLabel>
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Schedule" />}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Due date">
                <span>
                  {task?.dueDate ? moment(task?.dueDate).format(DateConstants.DayBreakout) : '--'}
                </span>
              </SpanWithLabel>
              <SpanWithLabel labelText="Remaining time">
                <RemainingTime publishedTask={task} />
              </SpanWithLabel>
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Due time (PST)" value={task?.dueTime} />
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card
          styles={cardStyles}
          header={<CardStandardHeader cardTitle={`Task details - ID ${task?.id}`} />}
        >
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Task name" value={task?.name} />
              <SpanWithLabel labelText="Task level" value={task?.level?.name} />
              <SpanWithLabel labelText="Estimated time (in minutes)" value={task?.estimatedTime} />
              <SpanWithLabel labelText="Scope of activity" value={task?.scopeOfActivity?.name} />
              <SpanWithLabel labelText="BCP category" value={task?.bcpCategory?.name} />
              <SpanWithLabel labelText="BCP peak" value={enumFormatter(task?.bcpPriorityPeak)} />
              <SpanWithLabel
                labelText="BCP non-peak"
                value={enumFormatter(task?.bcpPriorityNonPeak)}
              />

              <SpanWithLabel labelText="Tags">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.taskTags?.map((tag: ITag) => {
                    return <ValueTag key={`tag${tag.key}`} tagString={tag.name} />;
                  })}
                </Stack>
              </SpanWithLabel>
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Task description" value={task?.description} />
              <SpanWithLabel labelText="Task type" value={task?.type?.name} />
              <SpanWithLabel labelText="Publish type" value={enumFormatter(task?.publishType)} />
              <SpanWithLabel labelText="Data sources">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.dataSources?.map((ds: IDataSourceOption) => {
                    return <ValueTag key={`ds${ds.id}`} tagString={ds.name} />;
                  })}
                </Stack>
              </SpanWithLabel>
              <SpanWithLabel labelText="Dependency on task ID(s)">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.dependencyOnStagedTasks?.map((dependencyOn: IStagedTask) => {
                    return <ValueTag key={`do${dependencyOn.id}`} tagString={dependencyOn.id} />;
                  })}
                </Stack>
              </SpanWithLabel>
              <SpanWithLabel labelText="Dependency for task ID(s)">
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                  {task?.dependencyForStagedTasks?.map((dependencyFor: IStagedTask) => {
                    return <ValueTag key={`df${dependencyFor.id}`} tagString={dependencyFor.id} />;
                  })}
                </Stack>
              </SpanWithLabel>
              <FeatureFlagged flagName="ProjectNileWorkflows">
                <SpanWithLabel labelText="Workflow" value={task?.workflowId} />
              </FeatureFlagged>
            </StackColumn>
          </Stack>
        </Card>
        <br />
        <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Notes" />}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <StackColumn>
              <SpanWithLabel labelText="Peramanent notes" value={task?.permanentNotes} />
            </StackColumn>
            <StackColumn>
              <SpanWithLabel labelText="Published task notes" value={task?.publishedTaskNotes} />
            </StackColumn>
          </Stack>
        </Card>
      </StackColumn>
      <StackColumn maxWidth="300px">
        <h2>Timeline</h2>
        {task?.id && <PublishedTaskActions publishedTaskId={task?.id} />}
      </StackColumn>
    </Stack>
  );
};

export default TaskDetailCards;
