import { useQuery } from '@apollo/client';
import React from 'react';
import AddStagedTask from '../../../utils/api/StagedTaskApi';
import { GetDefaultTimeZone } from '../../../utils/api/TaskPropertiesApi';
import { ITaskPropertyValue } from '../../../utils/types/ITaskProperty';
import PanelTypes from '../../../utils/types/PanelTypes';
import StagedTasksDetailForm from './StagedTasksDetailForm';

const StagedTasksNew = (): JSX.Element => {
  const { data: defaultTimeZoneData } = useQuery<{ defaultTimeZone: ITaskPropertyValue }>(
    GetDefaultTimeZone,
    {
      fetchPolicy: 'cache-only',
    },
  );
  return (
    <StagedTasksDetailForm
      panelMode={PanelTypes.Add}
      stagedTask={{
        id: -1,
        name: '',
        description: '',
        opsSummaryAndOrgName: '',
        taskTags: null,
        timeZone: defaultTimeZoneData?.defaultTimeZone
          ? defaultTimeZoneData?.defaultTimeZone
          : null,
      }}
      mutation={AddStagedTask}
    />
  );
};

export default StagedTasksNew;
