import React, { useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadTheme } from '@fluentui/react';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { CoherenceTheme } from '@coherence-design-system/styles';
import App from './App';
import AppHeader from './common/AppHeader';
import config, {
  loadConfiguration,
  setDefaultCompany,
  setDefaultTimeZone,
} from './utils/ConfigLoader';
import AuthCallback from './components/authentication/AuthCallback';
import graphApiRequest, {
  setAuthInstance,
  getClient,
  getAuthInstance,
} from './utils/api/ApiProvider';
import { graphConfig } from './utils/authentication/AuthConfig';
import { AuthModule } from './utils/authentication/AuthModule';
import AuthorizationChecker from './components/authentication/AuthorizationChecker';
import { NotificationProvider, Toast } from './common/notifications/NotificationsContext';
import { bustCacheIfNeeded } from './utils/Helpers';
import { SavedFilterProvider } from './components/savedFilters/SavedFilterContext';
import AuthErrorPage from './components/error/AuthErrorPage';

const AppLoader = (): JSX.Element => {
  const [client, setClient] = useState<ApolloClient<unknown>>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [graphProfileData, setGraphProfileData] = useState({
    userImageUrl: null,
    graphProfile: null,
  });
  const [toasts, setToasts] = useState<Toast[]>([]);
  useEffect(() => {
    loadConfiguration().then(async () => {
      const auth = new AuthModule();
      await auth.loadAuthModule(setIsLoggedIn);
      setAuthInstance(auth);

      const clientConfiguration = getClient();
      setClient(clientConfiguration);
      setDefaultCompany(clientConfiguration);
      setDefaultTimeZone(clientConfiguration);
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: config?.settings?.appInsightsInstrumentationKey,
          enableAutoRouteTracking: true,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: [
            new URL(config?.settings?.statConfig?.microUiUrl)?.hostname,
            new URL(config?.settings?.jemConfig?.microUiUrl).hostname,
            new URL(config?.settings?.jemConfig?.siteUrl).hostname,
            new URL(config?.settings?.reconIntelligenceConfig?.microUiUrl).hostname,
          ],
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

      if (auth.getAuthError()) {
        appInsights.trackTrace(
          { message: 'Authentication error', severityLevel: 3 },
          { error: auth.getAuthError() },
        );
      }
    });

    loadTheme(CoherenceTheme);
  }, []);

  useEffect(() => {
    if (isLoggedIn && client) {
      graphApiRequest(graphConfig.graphMePhotoEndpoint, false)
        .then((graphPhotoResponse) => {
          return graphPhotoResponse.blob();
        })
        .then((photoBlob) => {
          return photoBlob ? URL.createObjectURL(photoBlob) : null;
        })
        .then((imageUrlFromBlob) => {
          return graphApiRequest(graphConfig.graphMeEndpoint).then((profileResponse) => {
            setGraphProfileData({ userImageUrl: imageUrlFromBlob, graphProfile: profileResponse });
          });
        });
      bustCacheIfNeeded();
    }
  }, [isLoggedIn, client]);

  const onLogOut = (): void => {
    getAuthInstance()?.logout();
  };

  const msalProvider = {
    getInstance: () => {
      return getAuthInstance();
    },
  };

  const removeTopToast = (): void => {
    setToasts((prevToasts) => prevToasts.slice(1));
  };

  const addToast = (toast: Toast): void => {
    const newToasts = [...toasts, toast];
    setToasts(newToasts);

    setTimeout(removeTopToast, 7000);
  };

  const [savedFilter, setSavedFilter] = React.useState(null);
  return (
    <>
      {client && (
        <ApolloProvider client={client}>
          <NotificationProvider
            value={{
              toasts,
              addToast,
            }}
          >
            <Router>
              <SavedFilterProvider value={{ savedFilter, setSavedFilter }}>
                <AppHeader
                  onLogOut={onLogOut}
                  graphProfileData={graphProfileData?.graphProfile}
                  imageUrl={graphProfileData?.userImageUrl}
                />
                <Switch>
                  <Route path="/authcallback" component={AuthCallback} />
                  <Route path="/autherror" component={AuthErrorPage} />
                  <Route
                    path="/"
                    render={() => {
                      return (
                        <AuthorizationChecker graphId={graphProfileData?.graphProfile?.id}>
                          <Context.Provider
                            value={{
                              msalProvider,
                            }}
                          >
                            <App />
                          </Context.Provider>
                        </AuthorizationChecker>
                      );
                    }}
                  />
                </Switch>
              </SavedFilterProvider>
            </Router>
          </NotificationProvider>
        </ApolloProvider>
      )}
    </>
  );
};

export default AppLoader;
