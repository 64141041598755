import React from 'react';
import { Label, MaskedTextField, Stack, TextField } from '@fluentui/react';
import { Controller, FieldError, FieldValues, UseFormMethods } from 'react-hook-form';
import GraphUsersPeoplePicker, {
  mapUsersNoTypename,
} from '../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../utils/types/IAadUser';
import arrayMustNotBeEmpty, {
  timeIn24HourFormat,
} from '../../../utils/validation/CustomValidations';
import { IPublishedTask } from '../../../utils/types/ITask';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';
import { PEOPLE_PICKER_LIMIT } from '../../../common/constants/SiteConstants';

interface ISmallTaskEditFormProps {
  form: UseFormMethods<FieldValues>;
  publishedTask: IPublishedTask;
}

const SmallTaskEditForm: React.FunctionComponent<ISmallTaskEditFormProps> = ({
  form,
  publishedTask,
}: ISmallTaskEditFormProps): JSX.Element => {
  const { control, errors, setValue } = form;

  return (
    <div>
      <Controller
        label="Primaries"
        name="primaries"
        control={control}
        defaultValue={mapUsersNoTypename(publishedTask?.primaries)}
        value={mapUsersNoTypename(publishedTask?.primaries)}
        render={({ value }) => {
          return (
            <Stack tokens={{ childrenGap: 5 }}>
              <GraphUsersPeoplePicker
                id="primaries"
                label="Primaries"
                itemLimit={PEOPLE_PICKER_LIMIT}
                removeButtonAriaLabel="Remove primary"
                controlName="primaries"
                onChange={(users: IAadUser[]): void => {
                  setValue('primaries', users);
                }}
                errorMessage={(errors?.primaries as unknown as FieldError)?.message}
                value={value}
                defaultValue={value}
                placeholder="Type one or more Primary"
                isRequired
                isMultiUserAddable
              />
            </Stack>
          );
        }}
        rules={{
          validate: { arrayMustNotBeEmpty },
        }}
      />
      <Controller
        label="Backups"
        name="backupTaskPerformers"
        control={control}
        defaultValue={mapUsersNoTypename(publishedTask?.backupTaskPerformers)}
        value={mapUsersNoTypename(publishedTask?.backupTaskPerformers)}
        render={({ value }) => {
          return (
            <Stack tokens={{ childrenGap: 5 }}>
              <GraphUsersPeoplePicker
                id="backups"
                label="Backups"
                removeButtonAriaLabel="Remove backup"
                controlName="backupTaskPerformers"
                onChange={(users: IAadUser[]): void => {
                  setValue('backupTaskPerformers', users);
                }}
                errorMessage={(errors?.backupTaskPerformers as unknown as FieldError)?.message}
                value={value}
                defaultValue={value}
                placeholder="Type one or more Backup"
                isRequired
                isMultiUserAddable
              />
            </Stack>
          );
        }}
        rules={{
          validate: { arrayMustNotBeEmpty },
        }}
      />
      <Controller
        label="Stakeholders"
        name="stakeholders"
        control={control}
        defaultValue={mapUsersNoTypename(publishedTask?.stakeholders)}
        value={mapUsersNoTypename(publishedTask?.stakeholders)}
        render={({ value }) => {
          return (
            <Stack tokens={{ childrenGap: 5 }}>
              <GraphUsersPeoplePicker
                id="stakeholders"
                label="Stakeholders"
                itemLimit={PEOPLE_PICKER_LIMIT}
                removeButtonAriaLabel="Remove stakeholder"
                controlName="stakeholders"
                onChange={(users: IAadUser[]): void => {
                  setValue('stakeholders', users);
                }}
                errorMessage={(errors?.stakeholders as unknown as FieldError)?.message}
                value={value}
                defaultValue={value}
                placeholder="Type one or more Stakeholder"
                isMultiUserAddable
              />
            </Stack>
          );
        }}
      />
      <Label>Due Date</Label>
      <Controller
        id="dueDate"
        name="dueDate"
        control={control}
        defaultValue={publishedTask?.dueDate}
        render={({ value }) => {
          return (
            <UtcDatePicker
              value={value}
              allowTextInput
              isRequired={!!errors?.dueDate?.message}
              isRequiredErrorMessage={errors?.dueDate?.message}
              textField={{
                name: 'dueDate',
              }}
              onChange={(dateString: string): void => {
                setValue('dueDate', dateString);
              }}
              ariaLabel="Due date"
            />
          );
        }}
        rules={{
          required: 'Please provide due date',
        }}
      />
      <Controller
        id="dueTime"
        name="dueTime"
        control={control}
        defaultValue={publishedTask?.dueTime}
        render={({ value }) => {
          return (
            <MaskedTextField
              value={value}
              label="Due time"
              required
              mask="99:99"
              suffix="HH:MM"
              errorMessage={errors?.dueTime?.message}
              onChange={(e, newValue) => {
                setValue('dueTime', newValue);
              }}
            />
          );
        }}
        rules={{
          required: 'Please provide due time',
          validate: {
            timeIn24HourFormat,
          },
        }}
      />
      <Controller
        as={TextField}
        label="Published task notes"
        id="publishedTaskNotes"
        name="publishedTaskNotes"
        multiline
        control={control}
        defaultValue={publishedTask.publishedTaskNotes ?? ''}
      />
    </div>
  );
};

export default SmallTaskEditForm;
