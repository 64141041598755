import { CommandBarButton, IButtonStyles, IContextualMenuProps } from '@fluentui/react';
import React from 'react';
import IAadUser from '../../utils/types/IAadUser';

interface ICopyButtonProps {
  data: IAadUser[];
}

const commandBarButtonStyles: IButtonStyles = {
  menuIcon: {
    padding: '8px 0px',
  },
};

const CopyButton = (props: ICopyButtonProps): JSX.Element => {
  const { data } = props;

  const altButtons: IContextualMenuProps = {
    items: [
      {
        key: 'commaSeparatedEmails',
        text: 'Comma Separated Emails',
        ariaLabel: 'Copy Comma Separated Emails',
        iconProps: { iconName: 'Mail' },
        onClick: () => {
          navigator.clipboard.writeText(data?.map((user) => user.emailAddress).join(','));
        },
      },
      {
        key: 'semicolonSeparatedEmails',
        text: 'Semicolon Separated Emails',
        ariaLabel: 'Copy Semicolon Separated Emails',
        iconProps: { iconName: 'Mail' },
        onClick: () => {
          navigator.clipboard.writeText(data?.map((user) => user.emailAddress).join(';'));
        },
      },
      {
        key: 'commaSeparatedAlias',
        text: 'Comma Separated Aliases',
        ariaLabel: 'Copy Comma Separated Aliases',
        iconProps: { iconName: 'Contact' },
        onClick: () => {
          navigator.clipboard.writeText(data?.map((user) => user.alias).join(','));
        },
      },
      {
        key: 'semicolonSeparatedAliases',
        text: 'Semicolon Separated Aliases',
        ariaLabel: 'Copy Semicolon Separated Aliases',
        iconProps: { iconName: 'Contact' },
        onClick: () => {
          navigator.clipboard.writeText(data?.map((user) => user.alias).join(';'));
        },
      },
    ],
  };

  return (
    <CommandBarButton
      iconProps={{ iconName: 'Copy' }}
      ariaLabel="See 4 copy options"
      styles={commandBarButtonStyles}
      menuProps={altButtons}
    />
  );
};
export default CopyButton;
