import { IPersonaProps } from '@fluentui/react';
import graphApiRequest from './ApiProvider';
import IAadUser from '../types/IAadUser';

interface IGraphResult {
  userPrincipalName: string;
  displayName: string;
  mail: string;
  id: string;
  givenName: string;
  surname: string;
  onPremisesExtensionAttributes: {
    extensionAttribute2: string; // used to determine if FTE,  FTE value is 50 ¯\_(ツ)_/¯
  };
}

function formatGraphFilterText(filterText: string) {
  filterText = filterText.replace(/'/g, "''");
  return filterText;
}

export default function searchForUsers(
  filterText: string,
  mustBeFte = false,
): IPersonaProps[] | Promise<IPersonaProps[]> {
  filterText = formatGraphFilterText(filterText);
  const requestBaseUrl = 'https://graph.microsoft.com/v1.0/users';
  const filterByName = `userType eq 'Member' and (startsWith(userPrincipalName, '${filterText}') or startsWith(displayName, '${filterText}'))`;
  const properties =
    'id,displayName,mail,givenName,surName,userPrincipalName,onPremisesExtensionAttributes';
  const url = `${requestBaseUrl}?$top=15&$count=true&$filter=${filterByName}&$select=${properties}`;
  return graphApiRequest(url, false)
    .then((results) => {
      return results.json();
    })
    .then((json) => {
      return json.value;
    })
    .then((array) => {
      let filteredResults = array;
      if (mustBeFte) {
        filteredResults = array.filter(
          (person: IGraphResult) =>
            person.onPremisesExtensionAttributes.extensionAttribute2 === '50',
        );
      }

      return filteredResults?.map((person: IGraphResult) => {
        const userPrincipalNameSplit = person.userPrincipalName.split('@');
        return {
          text: person.displayName,
          secondaryText: person.mail,
          id: person.id,
          alias: userPrincipalNameSplit[0],
          emailAddress: person.userPrincipalName,
        };
      });
    });
}

export async function getSingleGraphUser(filterText: string): Promise<IAadUser> {
  const requestBaseUrl = 'https://graph.microsoft.com/v1.0/users';
  const filterByName = `userType eq 'Member' and (startsWith(userPrincipalName, '${filterText}'))`;
  const properties =
    'id,displayName,mail,givenName,surName,userPrincipalName,onPremisesExtensionAttributes';
  const url = `${requestBaseUrl}?$top=1&$count=true&$filter=${filterByName}&$select=${properties}`;
  const results = await graphApiRequest(url, false);
  const json = await results.json();
  const person = await json.value;
  const userPrincipalNameSplit = person[0]?.userPrincipalName?.split('@');
  return {
    graphGuid: person[0]?.id,
    displayName: person[0]?.displayName,
    alias: userPrincipalNameSplit?.length ? userPrincipalNameSplit[0] : '',
    emailAddress: person[0]?.userPrincipalName,
  };
}
