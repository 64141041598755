import React from 'react';
import { ApolloError } from '@apollo/client';
import { ChoiceGroup, Stack, TextField } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  CoherencePanelStyles,
  formChoiceGroup,
} from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import { IOpsOrg } from '../../../../utils/types/IHierarchy';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import PanelTypes from '../../../../utils/types/PanelTypes';
import { IOpsOrgDropdownReference } from '../../../../common/formFields/OpsOrgDropdown';
import ModifiedDetails from '../../../../common/auditedEntity/ModifiedDetails';
import OpsSummaryDropDown from '../../../../common/formFields/OpsSummaryDropDown';
import yesNo from '../../../../common/formFields/YesNoOptions';

interface IOpsOrgPanelProps {
  data: IOpsOrg;
  mutation: (opsOrgInputData: IOpsOrg) => void;
  closePanel: (shouldRefresh: boolean) => void;
  mutationError?: ApolloError;
  mutationLoading?: boolean;
  panelMode: PanelTypes;
}
const OpsOrgPanel: React.FunctionComponent<IOpsOrgPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: IOpsOrgPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const handleOnSaveClick = (formData: IOpsOrg): void => {
    mutation(formData);
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelMode} Ops Org`}
      isOpen
      onDismiss={() => {
        closePanel(false);
      }}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close OpsOrg"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={() => {
            closePanel(false);
          }}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <Stack tokens={{ childrenGap: 20 }}>
          {panelMode === PanelTypes.Edit && (
            <ModifiedDetails
              modifiedOn={data?.modifiedOn}
              modifiedBy={data?.modifiedBy?.displayName}
            />
          )}
          <span>
            <RequiredMarker /> Required
          </span>
          <LoadingErrorMessage
            loading={mutationLoading}
            error={mutationError}
            actionName="Saving the Ops Org"
            label="saving Ops Org"
          />
          {data && (
            <>
              <Controller
                as={TextField}
                label="Ops org"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: 'Please provide an ops org',
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                label="Ops summary"
                id="opsSummaryId"
                name="opsSummaryId"
                required
                control={control}
                resizable={false}
                defaultValue={data?.opsSummary?.id}
                render={(fieldProps) => (
                  <OpsSummaryDropDown
                    label="Summary Name"
                    valueKey="opsSummaryId"
                    required
                    value={fieldProps.value}
                    handleChange={(newValue: IOpsOrgDropdownReference, valueKey: string): void => {
                      setValue('opsSummaryId', newValue.key);
                    }}
                    errors={errors}
                  />
                )}
                rules={{
                  required: 'Please provide an ops summary',
                }}
              />
              <Controller
                name="isActive"
                control={control}
                defaultValue={data?.isActive}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="isActive"
                      label="Is active"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.isActive ? 'true' : 'false'}
                      required
                      onChange={(e, newValue): void => {
                        setValue('isActive', newValue.key === 'true');
                      }}
                    />
                  );
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default OpsOrgPanel;
