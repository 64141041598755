import { useQuery } from '@apollo/client';
import { LoadingErrorMessage } from 'fcw-dashboard-components';
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import { GetAllOpsOrgDetails } from '../../utils/api/HierarchiesApi';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { IOpsOrgDetail } from '../../utils/types/IHierarchy';

export interface IOpsDetailDropdownReference extends IDropdownOption {
  opsDetail: IOpsOrgDetail;
}

export const mapEntityToDropdown = (
  OpsOrgDetails: IOpsOrgDetail[],
): IOpsDetailDropdownReference[] => {
  const onlyActive = (value: IOpsOrgDetail) => value.isActive;
  let opsDetails = [];
  opsDetails = OpsOrgDetails?.filter(onlyActive)?.map(
    (opsDetail: IOpsOrgDetail): IOpsDetailDropdownReference => {
      return {
        key: opsDetail.id,
        text: opsDetail.name,
        opsDetail,
      };
    },

    opsDetails.unshift({
      key: 'FilterHeader',
      itemType: DropdownMenuItemType.Header,
      text: '',
    }),
  );
  return opsDetails;
};

const OpsDetailDropdown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(GetAllOpsOrgDetails, {
    fetchPolicy: 'network-only',
    variables: {
      order: [{ name: 'ASC' }],
    },
  });
  const { opsOrgDetailsList } = !dropdownLoading && dropdownData ? dropdownData : [];

  return (
    <>
      {(dropdownLoading || dropdownErrors) && (
        <LoadingErrorMessage loading={dropdownLoading} errorMessage={dropdownErrors?.message} />
      )}
      {dropdownData && (
        <QueryBasedDropdown
          required={required}
          value={value}
          label={label}
          ariaLabel={label}
          outputKey={outputKey}
          dropdownOptionsData={mapEntityToDropdown(opsOrgDetailsList)}
          errors={dropdownErrors}
          handleChange={handleChange}
          valueKey={valueKey}
          width={width}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default OpsDetailDropdown;
