import React from 'react';
import CompanyChecklist from '../../../../common/formFields/CompanyChecklist';
import GraphUsersFilter from '../../../../common/formFields/GraphUsersFilter';
import InvalidUsersChecklist from '../../../../common/formFields/InvalidUsersChecklist';
import YesNoChecklist from '../../../../common/formFields/YesNoChecklist';
import ListFilters from '../../../../common/lists/ListFilters';
import {
  GetOpsOrgDetailListSearch,
  GetOpsOrgListSearch,
  GetOpsSummaryListSearch,
} from '../../../../utils/api/HierarchiesApi';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import CollapsibleGenericChecklist from '../../../../common/formFields/CollapsibleGenericChecklist';

/** move to central place */
export interface IListFiltersState {
  filterTags: IGenericFilterTag[];
  filterPanelVisible: boolean;
}
export interface IListPageFiltersProps {
  onClosePanel?: () => void;
  currentFilterValues?: IGenericFilterTag[];
  onFilterUpdate?: (formData: IGenericFilterTag[]) => void;
}
const OpsOrgDetailFilters = (props: IListPageFiltersProps): JSX.Element => {
  const { onClosePanel, currentFilterValues, onFilterUpdate } = props;
  return (
    <ListFilters
      onClosePanel={onClosePanel}
      onFilterUpdate={onFilterUpdate}
      currentFilterValues={currentFilterValues}
    >
      <CollapsibleGenericChecklist
        dataKey="opsSummaries"
        label="Ops summaries"
        query={GetOpsSummaryListSearch}
        queryKey="opsSummaryNames"
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgs"
        label="Ops org"
        query={GetOpsOrgListSearch}
        queryKey="opsOrgNames"
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgDetails"
        label="Ops org detail"
        query={GetOpsOrgDetailListSearch}
        queryKey="opsDetailNames"
      />
      <CompanyChecklist dataKey="companies" label="Co. code" isActive={false} />
      <YesNoChecklist dataKey="isActive" label="Is active" />
      <YesNoChecklist dataKey="autoPublish" label="Auto publish" />
      <GraphUsersFilter
        dataKey="checklistOwner"
        label="Checklist owner(s)"
        removeButtonAriaLabel="Remove owner"
        controlName="checklistOwner"
        placeholder="Type one or more Checklist owner(s)"
      />
      <GraphUsersFilter
        dataKey="backupTaskPerformer"
        label="Backup task performer(s)"
        removeButtonAriaLabel="Remove backup"
        controlName="backupTaskPerformer"
        placeholder="Type one or more Backup task performer(s)"
      />
      <GraphUsersFilter
        dataKey="backupJeReviewer"
        label="Backup JE reviewer(s)"
        removeButtonAriaLabel="Remove reviewer"
        controlName="backupJeReviewer"
        placeholder="Type one or more Backup JE reviewer(s)"
      />
      <GraphUsersFilter
        dataKey="backupJePoster"
        label="Backup JE poster(s)"
        removeButtonAriaLabel="Remove poster"
        controlName="backupJePoster"
        placeholder="Type one or more Backup JE poster(s)"
      />
      <GraphUsersFilter
        dataKey="backupRiReconciler"
        label="Backup reconciler(s)"
        removeButtonAriaLabel="Remove reconciler"
        controlName="backupRiReconciler"
        placeholder="Type one or more Backup reconciler(s)"
      />
      <GraphUsersFilter
        dataKey="backupRiReviewer"
        label="Backup reviewer(s)"
        removeButtonAriaLabel="Remove reviewer"
        controlName="backupRiReviewer"
        placeholder="Type one or more Backup reviewer(s)"
      />
      <GraphUsersFilter
        dataKey="backupRiApprover"
        label="Backup approver(s) - FTEs only"
        removeButtonAriaLabel="Remove approver"
        controlName="backupRiApprover"
        placeholder="Type one or more Backup approver(s) - FTEs only"
      />
      <InvalidUsersChecklist dataKey="invalidUserType" label="Invalid users" />
    </ListFilters>
  );
};
export default OpsOrgDetailFilters;
