import { ITag } from '@fluentui/react';
import React from 'react';
import { IChecklistImplementationProps } from './YesNoChecklist';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';

const BcpPriorityChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;

  const priorities = [
    { key: 'Low', name: 'Low' },
    { key: 'Medium', name: 'Medium' },
    { key: 'High', name: 'High' },
  ];

  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({
      dataKey,
      title: label,
      values: items,
    });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        items={priorities}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};

export default BcpPriorityChecklist;
