import { Facepile, IFacepilePersona, OverflowButtonType, PersonaSize } from '@fluentui/react';
import React from 'react';
import IAadUser from '../../utils/types/IAadUser';

export interface IFacepileWrapperProps {
  users: IAadUser[];
  ariaLabel: string;
}

const FacepileWrapper = (props: IFacepileWrapperProps): JSX.Element => {
  const { users, ariaLabel } = props;

  const facepilePersonas = users?.map((user): IFacepilePersona => {
    return {
      personaName: user.isInvalidUser ? `(Invalid User) ${user.displayName}` : user.displayName,
    };
  });

  const overflowButtonProps = {
    ariaLabel: 'More users',
    onClick: (ev: React.MouseEvent<HTMLButtonElement>) => {},
  };

  return (
    <Facepile
      personas={facepilePersonas}
      personaSize={PersonaSize.size24}
      maxDisplayablePersonas={3}
      overflowButtonType={OverflowButtonType.descriptive}
      overflowButtonProps={overflowButtonProps}
      ariaDescription="To move through the items use left and right arrow keys."
      ariaLabel={ariaLabel}
    />
  );
};

export default FacepileWrapper;
