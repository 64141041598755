import moment from 'moment';
import { useState, Dispatch, SetStateAction } from 'react';

export enum LocalStorageKeys {
  stagedTaskListColumns = 'stagedTaskListColumns',
  draftTaskListColumns = 'draftTaskListColumns',
  publishedTaskListColumns = 'publishedTaskListColumns',
  opsSummaryListColumns = 'opsSummaryListColumns',
  opsOrgListColumns = 'opsOrgListColumns',
  opsOrgDetailListColumns = 'opsOrgDetailListColumns',
  fiscalCalendarListColumns = 'fiscalCalendarListColumns',
  variableCalendarListColumns = 'variableCalendarListColumns',
  companyCodeAssignmentColumns = 'companyCodeColumns',
  opsOrgDetailFullListColumns = 'opsOrgDetailFullListColumns',
  inactveUserListColumns = 'inactveUserListColumns',
  publishedTaskQueryState = 'publishedTaskQueryState',
  allPublishedTaskQueryState = 'allPublishedTaskQueryState',
  level3PageCache = 'level3PageCache',
  draftTaskQueryState = 'draftTaskQueryState',
  stagedTaskQueryState = 'stagedTaskQueryState',
  opsSummaryQueryState = 'opsSummaryQueryState',
  opsOrgQueryState = 'opsOrgQueryState',
  opsOrgDetailQueryState = 'opsOrgDetailQueryState',
  fiscalCalendarQueryState = 'fiscalCalendarQueryState',
  variableCalendarQueryState = 'variableCalendarQueryState',
  companyCodeAssignmentQueryState = 'companyCodeQueryState',
  opsOrgDetailFullQueryState = 'opsOrgDetailFullQueryState',
  inactiveUserQueryState = 'inactiveUserQueryState',
  homeQueryState = 'homeQueryState',
  savedFilterId = 'savedFilterId',
  localStorageVersion = 'localStorageVersion',
  dataSourceListColumns = 'dataSourceListColumns',
  dataSourceQueryState = 'dataSourceQueryState',
  dataSourceApplicationListColumns = 'dataSourceApplicationListColumns',
  dataSourceApplicationQueryState = 'dataSourceApplicationQueryState',
  dataSourcePerspectiveListColumns = 'dataSourcePerspectiveListColumns',
  dataSourcePerspectiveQueryState = 'dataSourcePerspectiveQueryState',
}

export default function useLocalStorage<S>(
  key: string,
  initialValue: S | (() => S),
  reviveDateTime?: boolean,
): [S, Dispatch<SetStateAction<S>>] {
  const reviveDateTimeFunction = (myKey: string, value: unknown): unknown => {
    if (reviveDateTime && typeof value === 'string') {
      const isDate = moment(value, moment.ISO_8601, true).isValid();
      if (isDate) {
        return new Date(value);
      }
    }
    return value;
  };

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item, reviveDateTimeFunction) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: S | ((prop: S) => S)): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // Need to log to telemetry
    }
  };

  return [storedValue, setValue];
}
