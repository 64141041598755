import { TooltipHost } from '@fluentui/react';
import React from 'react';
import moment from 'moment-timezone';
import { DateConstants } from '../../utils/formatters/DateConstants';
import { IPublishedTask } from '../../utils/types/ITask';

interface IRemainingTimeProps {
  publishedTask: IPublishedTask;
}

const RemainingTime = ({ publishedTask }: IRemainingTimeProps): JSX.Element => {
  if (!publishedTask) {
    return <span>--</span>;
  }

  const momentDueDate = moment.tz(
    `${publishedTask.dueDate} ${publishedTask.dueTime}`,
    'America/Los_Angeles',
  );
  const diffCalc = momentDueDate.fromNow();

  return (
    <TooltipHost
      content={`${momentDueDate.local().format(DateConstants.DayBreakoutWithTime)} Local`}
      id={`remainingTime${publishedTask.id}`}
    >
      <span>{diffCalc}</span>
    </TooltipHost>
  );
};

export default RemainingTime;
