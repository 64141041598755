import { ITag } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

const PublishTypeChecklist = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;
  const PublishTypes = [
    { key: 'SINGLE', name: 'Single' },
    { key: 'GLOBAL', name: 'Global' },
    { key: 'MULTI_CC', name: 'Multi Cc' },
    { key: 'JEM_CHECKLIST', name: 'Jem Checklist' },
  ];

  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        items={PublishTypes}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default PublishTypeChecklist;
