import moment from 'moment';
import React from 'react';
import { DateConstants } from '../../utils/formatters/DateConstants';

interface IModifiedDetails {
  modifiedOn: Date;
  modifiedBy: string;
}

const ModifiedDetails = ({ modifiedOn, modifiedBy }: IModifiedDetails): JSX.Element => {
  return (
    <span>
      Last modified {moment(modifiedOn).format(DateConstants.DayBreakout)} by {modifiedBy}
    </span>
  );
};

export default ModifiedDetails;
