import React from 'react';
import { Switch } from 'react-router-dom';
import Page from '../../common/page/Page';
import StagedTasksEdit from './details/StagedTasksEdit';
import StagedTasksNew from './details/StagedTasksNew';
import StagedTasksList from './list/StagedTasksListPage';

const StagedTasksRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Page
          exact
          path="/stagedTasks"
          component={StagedTasksList}
          title="Staged Task List"
          requestedPermission="ViewStagedTask"
        />
        <Page
          exact
          path="/stagedTasks/new"
          component={StagedTasksNew}
          title="New Staged Task"
          requestedPermission="AddStagedTask"
        />
        <Page
          exact
          path="/stagedTasks/edit/:stagedTaskId"
          component={StagedTasksEdit}
          title="Edit Staged Task"
          requestedPermission="EditStagedTask"
        />
      </Switch>
    </>
  );
};

export default StagedTasksRoutes;
