import { Label, MaskedTextField, Stack } from '@fluentui/react';
import React, { useMemo } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import { IDraftTask } from '../../../utils/types/ITask';
import FormGridCard from '../../../common/formFields/FormGridCard';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import { timeIn24HourFormat } from '../../../utils/validation/CustomValidations';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';
import SystemGeneratedField from '../../../common/formFields/SystemGeneratedField';
import { TaskChangeStateOption } from '../../../utils/types/ITaskChangeStateOption';
import { dateTimeToMoment } from '../../../utils/formatters/DateFormatters';

interface IPublishedTaskDetailScheduleCardProps {
  form: UseFormMethods<IDraftTask>;
  taskState: TaskChangeStateOption;
}

const DraftTaskDetailScheduleCard = (props: IPublishedTaskDetailScheduleCardProps): JSX.Element => {
  const { form, taskState } = props;
  const { control, getValues, setValue, errors, watch } = form;

  const { dueDate, dueTime } = getValues();
  const watchDueDateAndTime = watch(['dueDate', 'dueTime']);

  const remainingTime = useMemo(() => {
    if (!watchDueDateAndTime.dueDate || !watchDueDateAndTime.dueTime) return '';
    const dueDateMoment = dateTimeToMoment(
      watchDueDateAndTime.dueDate,
      watchDueDateAndTime.dueTime,
    );

    const hoursRemaining = dueDateMoment.fromNow();

    return hoursRemaining;
  }, [watchDueDateAndTime]);

  const remainingTimeText =
    taskState === TaskChangeStateOption.NOT_STARTED || taskState === TaskChangeStateOption.STARTED
      ? remainingTime
      : 'Task completed';

  return (
    <>
      <FormGridCard cardLabel="Schedule">
        <Stack horizontal wrap styles={formGridRowStyles}>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              id="dueTime"
              name="dueTime"
              control={control}
              defaultValue={dueTime || '17:00'}
              value={dueTime}
              render={({ value }) => {
                return (
                  <MaskedTextField
                    value={value}
                    label="Due time"
                    required
                    mask="99:99"
                    suffix="HH:MM"
                    errorMessage={errors?.dueTime?.message}
                    onChange={(e, newValue) => {
                      setValue('dueTime', newValue);
                    }}
                  />
                );
              }}
              rules={{
                required: 'Please provide due time',
                validate: {
                  timeIn24HourFormat,
                },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <>
              <Label>Due Date</Label>
              <Controller
                id="dueDate"
                name="dueDate"
                control={control}
                value={dueDate}
                defaultValue={dueDate}
                render={({ value }) => {
                  return (
                    <UtcDatePicker
                      value={value}
                      allowTextInput
                      isRequired={!!errors?.dueDate?.message}
                      isRequiredErrorMessage={errors?.dueDate?.message}
                      textField={{
                        name: 'dueDate',
                      }}
                      onChange={(dateString: string): void => {
                        setValue('dueDate', dateString);
                      }}
                    />
                  );
                }}
                rules={{
                  required: 'Please provide due date',
                }}
              />
            </>
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <SystemGeneratedField label="Time remaining" value={remainingTimeText || ''} />
          </FormGridColumn>
        </Stack>
      </FormGridCard>
    </>
  );
};
export default DraftTaskDetailScheduleCard;
