import { CoherencePanel } from '@coherence-design-system/controls';
import { IColumn, IObjectWithKey, Selection } from '@fluentui/react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import ActionButtons from '../../../common/buttons/ActionButtons';
import { ILocalStorageColumnConfig } from '../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import { CoherencePanelStyles, rowClasses } from '../../../common/styles/CommonStyleObjects';

interface IColumnConfigPanelProps {
  closePanel: () => void;
  columns: ILocalStorageColumnConfig[];
  setColumns: Dispatch<SetStateAction<ILocalStorageColumnConfig[]>>;
}

const ColumnConfigPanel = (props: IColumnConfigPanelProps): JSX.Element => {
  const { closePanel, columns, setColumns } = props;
  const [selectedColumns, setSelectedColumns] = useState<IObjectWithKey[]>(columns);
  const listColumns = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Column name',
      maxWidth: 60,
      minWidth: 60,
      className: rowClasses.medFont,
    },
  ] as IColumn[];

  const detailsListConfig: IGeneralEntityListProps<ILocalStorageColumnConfig> = {
    data: columns,
    listColumns,
    listWrapperStyles: { root: { position: 'relative' } },
    onChangeSelectedItems: (selection) => {
      setSelectedColumns(selection);
    },
    hideSelectedCount: true,
    setInitialSelection: (data: ILocalStorageColumnConfig[], selection: Selection) => {
      data?.forEach((item: ILocalStorageColumnConfig) => {
        if (item.active) {
          selection.setKeySelected(item.key, true, true);
        }
      });
    },
  };
  const handleOnSaveClick = () => {
    if (selectedColumns) {
      setColumns(
        selectedColumns.map((sc: IObjectWithKey, index: number) => {
          return {
            key: sc.key,
            active: true,
            order: index,
          } as ILocalStorageColumnConfig;
        }),
      );
    }
    closePanel();
  };
  return (
    <CoherencePanel
      customWidth="350px"
      titleText="Change columns"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close change columns"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={closePanel}
          handleSubmit={handleOnSaveClick}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleOnSaveClick}>{GeneralEntityList(detailsListConfig)}</form>
    </CoherencePanel>
  );
};
export default ColumnConfigPanel;
