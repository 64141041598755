import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { IBaseTaskFilterIds } from '../../../utils/types/IBaseTask';
import {
  IStagedTaskFilterIds,
  IDraftTaskFilterIds,
  IPublishedTaskFilterIds,
} from '../../../utils/types/ITask';

export enum TaskType {
  StagedTasks = 'staged tasks',
  DraftTasks = 'draft tasks',
  PublishedTasks = 'published tasks',
}

export const getTagValues = (tagName: string, tags: IGenericFilterTag[]): (string | number)[] => {
  const ids = tags
    ?.find((tag) => tag.dataKey === tagName)
    ?.values?.map((tag) => tag.key ?? tag.name);
  return ids;
};
export const getTagValue = (
  tagName: string,
  tags: IGenericFilterTag[],
  type?: string,
): string | number | boolean => {
  const value = tags?.find((tag) => tag.dataKey === tagName)?.values[0]?.key;
  if (type === 'number') {
    return parseInt(value as string, 10);
  }
  if (type === 'boolean') {
    return value === 1 || value === 'true';
  }
  return value;
};
export const mapFilterTagsToIds = (
  filters: IGenericFilterTag[],
  taskKey: string,
): IBaseTaskFilterIds => {
  const baseFilters = {
    companyCodes: getTagValues('companies', filters),
    taskIds: getTagValues(taskKey, filters),
    levelIds: getTagValues('levels', filters),
    typeIds: getTagValues('types', filters),
    scopeOfActivityIds: getTagValues('scopeOfActivities', filters),
    supplierIds: getTagValues('suppliers', filters),
    opsSummaryIds: getTagValues('opsSummaries', filters),
    opsOrgIds: getTagValues('opsOrgs', filters),
    opsOrgDetailIds: getTagValues('opsOrgDetails', filters),
    primaryGuids: getTagValues('primaries', filters),
    backupGuids: getTagValues('backups', filters),
    stakeholderGuids: getTagValues('stakeholders', filters),
    tagIds: getTagValues('tags', filters),
    publishType: getTagValues('publishType', filters),
    invalidUserType: getTagValues('invalidUserType', filters),
    modifiedOnDateRangeStart: getTagValue('modifiedOnDateRangeStart', filters),
    modifiedOnDateRangeEnd: getTagValue('modifiedOnDateRangeEnd', filters),
    isActive: getTagValues('isActive', filters),
    dueTimeRangeStart: getTagValue('dueTimeRangeStart', filters),
    dueTimeRangeEnd: getTagValue('dueTimeRangeEnd', filters),
    dtpDocDateRangeStart: getTagValue('dtpDocDateRangeStart', filters),
    dtpDocDateRangeEnd: getTagValue('dtpDocDateRangeEnd', filters),
    permanentNotes: getTagValue('permanentNotes', filters),
    frequencyCode: getTagValue('frequencyCode', filters),
    workTimezone: getTagValues('workTimezone', filters),
    dataSources: getTagValues('dataSourceOptions', filters),
    dependencyOn: getTagValues('dependencyOn', filters),
    dependencyFor: getTagValues('dependencyFor', filters),
    functionSummaries: getTagValues('functionSummaries', filters),
    bcpCategories: getTagValues('bcpCategories', filters),
    bcpPeakPriority: getTagValues('bcpPeakPriority', filters),
    bcpNonPeakPriority: getTagValues('bcpNonPeakPriority', filters),
    estimatedTimeRangeStart: getTagValue('estimatedTimeRangeStart', filters, 'number'),
    estimatedTimeRangeEnd: getTagValue('estimatedTimeRangeEnd', filters, 'number'),
    modifiedByUsers: getTagValues('modifiedByUsers', filters),
    countries: getTagValues('countries', filters),
    stateChangedBy: getTagValues('stateChangedBy', filters),
    stateChangedRangeStart: getTagValue('stateChangedRangeStart', filters),
    stateChangedRangeEnd: getTagValue('stateChangedRangeEnd', filters),
    publishedTaskNotes: getTagValue('publishedTaskNotes', filters),
    reportTypeIds: getTagValues('reportTypes', filters),
    analysisTypeIds: getTagValues('analysisTypes', filters),
    outputIds: getTagValues('outputs', filters),
    salesLocations: getTagValues('salesLocations', filters),
  };

  return baseFilters;
};

export const mapStagedTaskFilterTagsToIds = (
  filters: IGenericFilterTag[],
): IStagedTaskFilterIds => {
  const filterIds = mapFilterTagsToIds(filters, 'stagedTasks') as IStagedTaskFilterIds;
  filterIds.isActive = getTagValues('isActive', filters) as string[];
  return filterIds;
};

export const mapPublishedTaskFilterTagsToIds = (
  filters: IGenericFilterTag[],
): IPublishedTaskFilterIds => {
  const filterIds = mapFilterTagsToIds(filters, 'tasks') as IPublishedTaskFilterIds;
  const dueDate = getTagValue('dueDate', filters);
  filterIds.stageTaskIds = getTagValues('stagedTasks', filters);
  filterIds.dueDate = dueDate?.toString();
  filterIds.dueDateRangeStart = getTagValue('dueDateRangeStart', filters)?.toString();
  filterIds.dueDateRangeEnd = getTagValue('dueDateRangeEnd', filters)?.toString();
  filterIds.fiscalYear = getTagValue('fiscalYear', filters, 'number') as number;
  filterIds.period = getTagValue('period', filters, 'number') as number;
  filterIds.taskState = getTagValues('taskState', filters) as string[];
  filterIds.forMeOnly = getTagValue('forMeOnly', filters, 'boolean') as boolean;
  filterIds.fiscalPeriodRangeStart = getTagValue('fiscalPeriodRangeStart', filters) as number;
  filterIds.fiscalPeriodRangeEnd = getTagValue('fiscalPeriodRangeEnd', filters) as number;
  filterIds.fiscalYearRangeStart = getTagValue('fiscalYearRangeStart', filters) as number;
  filterIds.fiscalYearRangeEnd = getTagValue('fiscalYearRangeEnd', filters) as number;
  filterIds.fiscalPeriodYearRangeStart = getTagValue(
    'fiscalPeriodYearRangeStart',
    filters,
  ) as string;
  filterIds.fiscalPeriodYearRangeEnd = getTagValue('fiscalPeriodYearRangeEnd', filters) as string;
  filterIds.stateNotes = getTagValue('stateNotes', filters) as string;
  return filterIds;
};

export const mapDraftTaskFilterTagsToIds = (filters: IGenericFilterTag[]): IDraftTaskFilterIds => {
  const filterIds = mapFilterTagsToIds(filters, 'tasks') as IDraftTaskFilterIds;
  const dueDate = getTagValue('dueDate', filters);
  filterIds.stageTaskIds = getTagValues('stagedTasks', filters);
  filterIds.dueDate = dueDate?.toString();
  filterIds.dueDateRangeStart = getTagValue('dueDateRangeStart', filters)?.toString();
  filterIds.dueDateRangeEnd = getTagValue('dueDateRangeEnd', filters)?.toString();
  filterIds.fiscalYear = getTagValue('fiscalYear', filters, 'number') as number;
  filterIds.period = getTagValue('period', filters, 'number') as number;

  return filterIds;
};
