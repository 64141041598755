import { useLazyQuery } from '@apollo/client';
import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import FullWidthHeader from '../../../common/headers/FullWidthHeader';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import { bodyContentContainer } from '../../../common/styles/CommonStyleObjects';
import { GetTaskFiscalYearPeriods } from '../../../utils/api/CalendarApi';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import {
  defaultPaginationProps,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../utils/listHelpers';
import { IFiscalYearPeriod } from '../../../utils/types/ICalendarDate';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { IEntityListState, IListQueryVariables } from '../../../utils/types/IList';
import { IPublishedTaskFilterIds } from '../../../utils/types/ITask';
import getFiscalYearPeriodListColumns from '../TaskFiscalYear.config';
import { mapPublishedTaskFilterTagsToIds } from './BaseTaskListfilters.config';
import PublishedTaskListPageFilters from './PublishedTaskListPageFilters';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../common/lists/GenericFilterTagHandler';

const PublishedTaskFiscalYearListPage = (): JSX.Element => {
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    taskStateFilter: ['NOT_STARTED'],
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.publishedTaskQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IListQueryVariables,
  );

  const [pageState, setPagesState] = useState<IEntityListState>({
    ...getDefaultStateProps(taskQueryCache),
  });
  // change to point to published tasks periods
  const [getTasks, { data, loading, error }] = useLazyQuery(GetTaskFiscalYearPeriods, {
    variables: {
      filters: {} as Partial<IPublishedTaskFilterIds>,
      status: 'PUBLISHED', // Published  or draft
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal>
        <h1>Tasks</h1>
      </Stack>
    );
  };

  const detailsListConfig: IGeneralEntityListProps<IFiscalYearPeriod> = {
    data: data?.taskFiscalYearPeriods || [],
    listColumns: getFiscalYearPeriodListColumns('tasks', pageState.filterTags),
    onSearchBoxChange: (ev, searchTerm: string) => {
      setPagesState({
        ...pageState,
        keyword: searchTerm,
      });
    },
    disableSelect: true,
    loading,
    error,
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <PublishedTaskListPageFilters />
        </GenericFilterTagHandler>
      );
    },
  };
  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const { filterTags, pageInfo, selectedPaginationPage, paginationSize } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
    });
  };
  const refreshPage = () => {
    const { filterTags } = pageState;
    const filters = mapPublishedTaskFilterTagsToIds(filterTags);

    getTasks({
      variables: {
        status: 'PUBLISHED',
        ...pageState.pageInfo,
        filters,
      },
    });
  };
  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
  ]);
  return (
    <>
      <FullWidthHeader title={renderHeader} />
      <div className={`${bodyContentContainer}`}>{GeneralEntityList(detailsListConfig)}</div>
    </>
  );
};
export default PublishedTaskFiscalYearListPage;
