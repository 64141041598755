import React from 'react';
import { IntegratedExperienceHeader } from 'fcw-dashboard-components';
import { useLocation } from 'react-router-dom';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import { defaultPaginationProps } from '../../../utils/listHelpers';
import { DateRangeType, IHomeQueryVariables } from '../../home/Home';
import { mapPublishedTaskFilterTagsToIds } from '../../tasks/list/BaseTaskListfilters.config';
import JemIntegratedExperience, { IJemDashboardFilters } from './JemIntegratedExperience';
import JemHelpPanel from './JemHelpPanel';

const JemIntegratedExperienceWrapper = (): JSX.Element => {
  const queryString = new URLSearchParams(useLocation().search);

  const jeType = queryString.get('jeType');
  const jeStatus = queryString.get('jeStatus');
  const moduleType = queryString.get('moduleType');
  /** Query state  cache */

  const homeLocalStorage = window.localStorage.getItem(LocalStorageKeys.homeQueryState);
  const homeLocalStorageParsed = homeLocalStorage ? JSON.parse(homeLocalStorage) : {};
  const [taskQueryCache] = useLocalStorage<IHomeQueryVariables>(
    `${LocalStorageKeys.level3PageCache}`,
    {
      ...homeLocalStorageParsed,
      ...defaultPaginationProps,
      taskStateFilter: null,
    } as IHomeQueryVariables,
  );

  // Todo: Make this more generic for ri team to use from their implementation
  // When Jem team takes over the level 1 and level 2 microfrontend, have them implement this code as well
  // so that we can get rid of this wrapper and just send props for homeQueryState
  const mapFilters = (taskQuery: IHomeQueryVariables) => {
    const filterIds = mapPublishedTaskFilterTagsToIds(
      taskQuery.filterTags,
    ) as unknown as IJemDashboardFilters;

    filterIds.filterByPeriod = taskQuery.dateRangeType === DateRangeType.rangePeriod;
    filterIds.fiscalPeriod = taskQuery.fiscalPeriod;
    filterIds.fiscalYear = taskQuery.fiscalYear;
    filterIds.dueDateRangeStart = taskQuery.dueDateRangeStart;
    filterIds.dueDateRangeEnd = taskQuery.dueDateRangeEnd;
    filterIds.forMeOnly = taskQuery.forMeOnly;
    filterIds.companyCodes = filterIds.companyCodes || [];
    filterIds.opsOrgDetailIds = filterIds.opsOrgDetailIds || [];
    filterIds.forMeOnly = taskQuery.forMeOnly;

    filterIds.jeType = jeType?.toLowerCase() || null;
    filterIds.jeStatus = jeStatus?.toLowerCase() || null;
    filterIds.moduleType = moduleType?.toLowerCase() || null;

    // a way to remove null and undefined values from an object
    const onlyFilterIdsWithValues = Object.keys(filterIds).reduce(
      (accumulator: { [key: string]: unknown }, key) => {
        if (filterIds[key] != null && key !== 'period') {
          accumulator[key] = filterIds[key];
        }
        return accumulator;
      },
      {},
    );
    return onlyFilterIdsWithValues as IJemDashboardFilters;
  };

  const jemFilters = mapFilters(taskQueryCache as IHomeQueryVariables);

  return (
    <>
      <IntegratedExperienceHeader
        appName="JEM"
        supportPanelElement={<JemHelpPanel />}
        additionalMessage=""
      />
      <JemIntegratedExperience filters={jemFilters} />
    </>
  );
};
export default JemIntegratedExperienceWrapper;
