import React from 'react';
import { useParams } from 'react-router-dom';
import { AddPublishedTask } from '../../../utils/api/PublishedTaskApi';
import { ITaskRouteParams } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import PublishedTaskDetailForm from './PublishedTaskDetailForm';

const PublishedTaskNew = (): JSX.Element => {
  const { fiscalYear, period } = useParams<ITaskRouteParams>();

  return (
    <PublishedTaskDetailForm
      panelMode={PanelTypes.Add}
      publishedTask={{
        id: -1,
        dueDate: null,
        dueTime: '',
        stagedTaskId: -1,
        publishedTaskNotes: '',
        publishedStatus: '',
        name: '',
        description: '',
        taskTags: null,
      }}
      fiscalYear={parseInt(fiscalYear, 10)}
      fiscalPeriod={parseInt(period, 10)}
      mutation={AddPublishedTask}
    />
  );
};

export default PublishedTaskNew;
