interface IFrequencyChoices {
  repeat?: string;
  closeDaySelection?: string[];
  rasCloseDaySelection?: string[];
  calendarDaySelection?: string[];
  workingDaySelection?: string[];
  variableDaySelection?: string[];
  weeklyCadence?: string;
  weeklyCadenceDayMonChecked?: boolean;
  weeklyCadenceDayTueChecked?: boolean;
  weeklyCadenceDayWedChecked?: boolean;
  weeklyCadenceDayThuChecked?: boolean;
  weeklyCadenceDayFriChecked?: boolean;
  weeklyCadenceDaySatChecked?: boolean;
  weeklyCadenceDaySunChecked?: boolean;
  startPeriodSelection?: {
    startPeriod?: number;
    startYear?: number;
  };
}

export enum RepeatValues {
  O = 'One time - does not repeat',
  W = 'Weekly',
  M = 'Monthly',
  Q = 'Quarterly',
  H = 'Half yearly',
  Y = 'Yearly',
}
export const CalendarFrequencyValues = [
  { key: 'Monthly', text: 'Monthly' },
  { key: 'Quarterly', text: 'Quarterly', disabled: true },
  { key: 'Half yearly', text: 'Half yearly', disabled: true },
];

export const FiscalPeriodValues = [
  { key: 1, text: 'July (P1)' },
  { key: 2, text: 'August (P2)' },
  { key: 3, text: 'September (P3)' },
  { key: 4, text: 'October (P4)' },
  { key: 5, text: 'November (P5)' },
  { key: 6, text: 'December (P6)' },
  { key: 7, text: 'January (P7)' },
  { key: 8, text: 'February (P8)' },
  { key: 9, text: 'March (P9)' },
  { key: 10, text: 'April (P10)' },
  { key: 11, text: 'May (P11)' },
  { key: 12, text: 'June (P12)' },
];

export const FiscalPeriodMonths: string[] = [
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
];

export default IFrequencyChoices;
