export const MOBILE_NAV_MAX_WIDTH = 1023;
export const MOBILE_NAV_MAX_WIDTH_BREAKPOINT = `@media (max-width: ${MOBILE_NAV_MAX_WIDTH}px)`;
export const DEFAULT_NAV_MIN_WIDTH_BREAKPOINT = `@media (min-width: ${MOBILE_NAV_MAX_WIDTH + 1}px)`;

export const XXL_BREAK_POINT = '@media (min-width:1366px) and (max-width: 1919px)';
export const XL_BREAK_POINT = '@media (min-width:1024px) and (max-width: 1365px)';
export const LG_BREAK_POINT = '@media (min-width:640px) and (max-width: 1023px)';
export const MD_BREAK_POINT = '@media (min-width:480px) and (max-width: 639px)';
export const SM_BREAK_POINT = '@media (min-width: 321px) and (max-width: 479px)';
export const XSM_BREAK_POINT = '@media (max-width: 320px)';
export const FORM_GRID_MIN_WIDTH = XXL_BREAK_POINT;
export const FORM_GRID_THIRDS_MIN_WIDTH = XL_BREAK_POINT;
export const FORM_GRID_HALF_MIN_WIDTH = LG_BREAK_POINT;
export const FORM_GRID_FULL_MIN_WIDTH = `@media (max-width: 639px)`;

export const VIEWPORT_HEIGHT = 'calc(100vh - 48px)';
export const NAV_HEIGHT = 'calc(100vh - 48px)';

export const FOOTER_SMALL_BREAKPOINT = '@media (max-width: 485px)';
export const FOOTER_X_SMALL_BREAKPOINT = '@media (max-width: 345px)';

export const THROTTLE_SEARCH_TIMEOUT = 500;
export const MIN_CHARACTERS_TO_SEARCH = 3;
export const PEOPLE_PICKER_LIMIT = 75;
