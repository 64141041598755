import { useQuery } from '@apollo/client';
import {
  DefaultButton,
  DetailsRow,
  GroupedList,
  IGroup,
  IGroupedList,
  SelectionMode,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { GetOpsOrgDetailList } from '../../../../utils/api/HierarchiesApi';
import { IOpsOrg, IOpsOrgDetail, IOpsSummary } from '../../../../utils/types/IHierarchy';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import { expandButtonStyle } from '../../../../common/styles/CommonStyleObjects';

const FullView = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const componentRef = useRef<IGroupedList>(null);

  const { data, loading, error } = useQuery(GetOpsOrgDetailList, {
    variables: {
      order: [
        {
          opsOrg: {
            opsSummary: {
              name: 'ASC',
            },
          },
        },
        {
          opsOrg: {
            name: 'ASC',
          },
        },
      ],
      first: 1000,
    },
  });

  // create groups of nested ops summaries -> ops orgs -> ops org details
  const getGroups = (): IGroup[] => {
    const operations = data?.opsOrgDetails?.edges;
    const opsSummaries: IGroup[] = [];

    if (!operations || operations?.length === 0) {
      return opsSummaries;
    }

    let currentOpsSummary: IOpsSummary = { id: 0, isActive: false, name: '' };
    let currentOpsSummaryIndex = 0;
    let currentOpsOrg: IOpsOrg = {
      id: 0,
      isActive: false,
      name: '',
      opsSummary: { id: 0, isActive: false, name: '' },
    };
    let currentOpsOrgIndex = 0;

    operations.forEach((operation: { node: IOpsOrgDetail }, index: number) => {
      const opsDetail: IOpsOrgDetail = operation?.node;
      if (currentOpsSummary?.id !== opsDetail.opsOrg.opsSummary.id) {
        currentOpsSummary = opsDetail.opsOrg.opsSummary;
        opsSummaries.push({
          key: `OpsSummaryGroup-${opsDetail.opsOrg.opsSummary.id.toString()}`,
          name: opsDetail.opsOrg.opsSummary.name,
          startIndex: index,
          count: 0,
          isCollapsed: false,
          data: opsDetail.opsOrg.opsSummary,
          children: [],
          level: 0,
        });
        currentOpsSummaryIndex = opsSummaries.length - 1;
      }

      if (currentOpsOrg?.id !== opsDetail.opsOrg.id) {
        currentOpsOrg = opsDetail.opsOrg;
        opsSummaries[currentOpsSummaryIndex].children.push({
          key: `OpsOrgGroup-${opsDetail.opsOrg.id.toString()}`,
          name: opsDetail.opsOrg.name,
          startIndex: index,
          count: 0,
          isCollapsed: false,
          data: opsDetail.opsOrg,
          children: [],
          level: 1,
        });
        currentOpsOrgIndex = opsSummaries[currentOpsSummaryIndex].children.length - 1;
      }

      opsSummaries[currentOpsSummaryIndex].children[currentOpsOrgIndex].count += 1;
      opsSummaries[currentOpsSummaryIndex].count += 1;
    });

    return opsSummaries;
  };

  const onRenderCell = (
    nestingDepth?: number,
    item?: IOpsOrgDetail,
    itemIndex?: number,
    group?: IGroup,
  ): React.ReactNode => {
    return item && typeof itemIndex === 'number' && itemIndex > -1 ? (
      <DetailsRow
        columns={[
          {
            key: 'opsName',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            onRender: (detail: IOpsOrgDetail) => {
              return (
                <Link to={`/hierarchies/operations/details/${detail?.id}`}>{detail?.name}</Link>
              );
            },
          },
        ]}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selectionMode={SelectionMode.none}
        group={group}
      />
    ) : null;
  };

  const toggleCollapse = (): void => {
    const currentIsCollapsed = isCollapsed;

    setIsCollapsed(!currentIsCollapsed);
    if (componentRef.current) {
      componentRef.current.toggleCollapseAll(!currentIsCollapsed);
    }
  };

  const itemsWithoutNode = data?.opsOrgDetails?.edges?.map(
    (item: { node: IOpsOrgDetail }) => item.node,
  );

  const items = React.useMemo(() => itemsWithoutNode, [itemsWithoutNode]);
  const groups = React.useMemo(() => getGroups(), [data]);

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      {!loading && !error && (
        <>
          <DefaultButton
            onClick={toggleCollapse}
            text={isCollapsed ? 'Expand All' : 'Collapse All'}
            className={expandButtonStyle}
          />
          <GroupedList
            componentRef={componentRef}
            items={items}
            selectionMode={SelectionMode.none}
            onRenderCell={onRenderCell}
            groups={groups}
            onShouldVirtualize={() => false}
          />
        </>
      )}
    </>
  );
};
export default FullView;
