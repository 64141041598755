import React, { useEffect, useState } from 'react';
import {
  DetailsListLayoutMode,
  ICommandBarItemProps,
  IObjectWithKey,
  Stack,
} from '@fluentui/react';
import { useLazyQuery } from '@apollo/client';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import { bodyContentContainer } from '../../common/styles/CommonStyleObjects';
import PaginationWrapperManaged from '../../common/lists/PaginationWrapperManaged';
import { PaginatedData, PaginationQueryVariables } from '../../utils/types/PaginationTypes';
import GeneralEntityList, { IGeneralEntityListProps } from '../../common/lists/GeneralEntityList';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../utils/listHelpers';
import IAadUser, { IInactiveUser } from '../../utils/types/IAadUser';
import {
  ILocalStorageColumnConfig,
  getColumnConfigByKey,
} from '../../common/lists/ColumnConfigHelper';
import useLocalStorage, { LocalStorageKeys } from '../../utils/hooks/useLocalStorage';
import { IQuerySortDefinition, SortDirection } from '../../utils/types/IListSortDefinition';
import { IEntityListState, IListQueryVariables } from '../../utils/types/IList';

import paginatedResultFormatter, {
  getEmptyResults,
} from '../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../utils/Helpers';
import getInactiveUsersMenu, {
  getInactiveUserListColumns,
  IInactiveUsersMenuProps,
} from './InactiveUsers.config';
import { GetInactiveUserList, ReplaceInactiveUser } from '../../utils/api/ToolsApi';
import ReplaceModal from './ReplaceModal';

interface IInactiveUserState extends IEntityListState {
  showReplaceModal: boolean;
}
const InactiveUser = (): JSX.Element => {
  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal>
        <h1>Inactive Users</h1>
      </Stack>
    );
  };
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dataResult, setDataResult] = useState<PaginatedData<IAadUser>>();
  const itemsSelected = selectedUsers?.length > 0;
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.inactveUserListColumns,
    [],
  );
  const defaultSort = {
    keyword: '',
    sortDir: SortDirection.ASC,
    sortKey: 'alias',
  };
  const [InactiveuserQueryCache, setInactiveuserQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.inactiveUserQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultSort,
    } as IListQueryVariables,
  );

  const [pageState, setPagesState] = useState<IInactiveUserState>({
    ...getDefaultStateProps(InactiveuserQueryCache),
    showReplaceModal: false,
  });
  const onReplaceClick = () => {
    setPagesState({ ...pageState, showReplaceModal: true });
  };
  const gridMenuItems = React.useMemo((): ICommandBarItemProps[] => {
    const commandBarItemConfig: IInactiveUsersMenuProps = {
      itemsSelected,
      itemsSelectedCount: selectedUsers?.length,
      totalItemsCount: dataResult?.totalCount,
      onReplaceClick,
    };
    return getInactiveUsersMenu(commandBarItemConfig);
  }, [itemsSelected, selectedUsers?.length, dataResult?.totalCount]);
  const cachedListColumns = getColumnConfigByKey(getInactiveUserListColumns(), listColumnsCache);

  const [getData, { data, loading, error }] = useLazyQuery(GetInactiveUserList, {
    variables: {
      ...InactiveuserQueryCache?.pageInfo,
      order: [
        {
          [InactiveuserQueryCache.sortKey]: InactiveuserQueryCache.sortDir,
        } as IQuerySortDefinition,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const selectedUserList = selectedUsers?.map((user: IInactiveUser) => {
    return {
      graphGuid: user.graphGuid,
      displayName: user.displayName,
      alias: user.alias,
      emailAddress: user.emailAddress,
      opsDetailCount: user.opsDetailCount,
      primaryTaskCount: user.primaryTaskCount,
      backupTaskCount: user.backupTaskCount,
      stakeholderTaskCount: user.stakeholderTaskCount,
    };
  });
  const detailsListConfig: IGeneralEntityListProps<IAadUser> = {
    data: dataResult?.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems: gridMenuItems,
    loading,
    layoutMode: DetailsListLayoutMode.fixedColumns,
    error,
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    lockActionsColumn: false,
    onChangeSelectedItems: (selection: IObjectWithKey[]) => {
      setSelectedUsers(selection);
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
  };
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    setInactiveuserQueryCache({
      ...InactiveuserQueryCache,
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { sortDir, sortKey } = pageState;
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    getData({
      variables: {
        ...pageState.pageInfo,
        order: sortArray,
      },
    });
  };
  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  useEffect(() => {
    const dataLoaded = !loading && !error;
    const formattedData =
      dataLoaded && data
        ? paginatedResultFormatter<IAadUser>(data?.inactiveUserList)
        : getEmptyResults<IAadUser>();
    setDataResult(formattedData);
    setSelectedUsers([]);
  }, [loading, data]);
  return (
    <>
      <FullWidthHeader title={renderHeader} />

      <div className={`${bodyContentContainer}`}>
        <PaginationWrapperManaged<IAadUser>
          dataResult={dataResult}
          selectedPage={pageState.selectedPaginationPage}
          loadingData={loading}
          onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
            setPagesState({
              ...pageState,
              selectedPaginationPage: value,
              pageInfo: {
                ...variables,
              },
            });
          }}
          paginationSize={pageState.paginationSize}
          onPageSizeChange={(newPageSize: number) => {
            setPagesState({
              ...pageState,
              ...getResetPaginationProps(newPageSize),
            });
          }}
        >
          {GeneralEntityList(detailsListConfig)}
        </PaginationWrapperManaged>
      </div>
      {pageState?.showReplaceModal === true && (
        <ReplaceModal
          mutation={ReplaceInactiveUser}
          selectedItems={selectedUserList}
          selectedUsersCount={selectedUsers?.length}
          showReplaceModal={pageState.showReplaceModal}
          hideReplaceModal={(shouldRefetch: boolean) => {
            setPagesState({ ...pageState, showReplaceModal: false });
            if (shouldRefetch) refreshPage();
          }}
        />
      )}
    </>
  );
};

export default InactiveUser;
