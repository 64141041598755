import React from 'react';
import { ApolloError } from '@apollo/client';
import { ChoiceGroup, IChoiceGroupOption, Stack, TextField } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  CoherencePanelStyles,
  formChoiceGroup,
} from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import { IOpsSummary } from '../../../../utils/types/IHierarchy';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import yesNo from '../../../../common/formFields/YesNoOptions';
import PanelTypes from '../../../../utils/types/PanelTypes';
import ModifiedDetails from '../../../../common/auditedEntity/ModifiedDetails';

export interface IOpsSummaryPanelProps {
  data: IOpsSummary;
  closePanel: (shouldRefresh: boolean) => void;
  mutation: (opsSummaryInputData: IOpsSummary) => void;
  mutationError?: ApolloError;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const OpsSummaryPanel: React.FunctionComponent<IOpsSummaryPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: IOpsSummaryPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const handleOnSaveClick = (formData: IOpsSummary): void => {
    mutation(formData);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelMode} Ops Summary`}
      isOpen
      onDismiss={() => {
        closePanel(false);
      }}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Ops Summary"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={() => {
            closePanel(false);
          }}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="saving Ops Summary"
          actionName="Saving the Ops Summary"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          {panelMode === PanelTypes.Edit && (
            <ModifiedDetails
              modifiedOn={data?.modifiedOn}
              modifiedBy={data?.modifiedBy?.displayName}
            />
          )}
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                as={TextField}
                label="Ops summary"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: 'Please provide an ops summary',
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                name="isActive"
                control={control}
                defaultValue={data?.isActive}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="isActive"
                      label="Is active"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.isActive ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('isActive', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default OpsSummaryPanel;
