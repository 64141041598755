import React, { useState } from 'react';
import { Stack, IconButton } from '@fluentui/react';
import ISalesLocation from '../../utils/types/ISalesLocation';
import SalesLocationPanel from '../../components/tasks/edit/SalesLocationPanel';
import SalesLocationTagPicker from './SalesLocationTagPicker';

export interface ISalesLocationSelectorProps {
  defaultValue: ISalesLocation[];
  onChange: (salesLocations: ISalesLocation[]) => void;
}

const SalesLocationSelector: React.FunctionComponent<ISalesLocationSelectorProps> = ({
  defaultValue,
  onChange,
}: ISalesLocationSelectorProps): JSX.Element => {
  const [salesLocationPanelVisible, setSalesLocationPanelVisible] = useState(false);

  return (
    <Stack>
      <Stack horizontal>
        <SalesLocationTagPicker
          label="Sales Locations"
          selectedSalesLocations={defaultValue}
          placeholder="Select one or more Sales Location"
          onInputClicked={() => setSalesLocationPanelVisible(true)}
        />
        <IconButton
          title="Select Sales Location"
          ariaLabel="Select Sales Location"
          iconProps={{
            iconName: 'MapPin',
          }}
          onClick={() => setSalesLocationPanelVisible(true)}
        />
        {salesLocationPanelVisible && (
          <SalesLocationPanel
            selectedSalesLocations={defaultValue}
            onSave={onChange}
            closePanel={() => {
              setSalesLocationPanelVisible(false);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default SalesLocationSelector;
