import { gql } from '@apollo/client';

export const INACTIVE_USER_FRAGMENT = gql`
  fragment InactiveUser on InactiveUserDto {
    graphGuid
    displayName
    emailAddress
    alias
    opsDetailCount
    primaryTaskCount
    backupTaskCount
    stakeholderTaskCount
  }
`;

export const GetInactiveUserList = gql`
  query InactiveUserList(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [InactiveUserDtoSortInput!]
  ) {
    inactiveUserList(first: $first, after: $after, last: $last, before: $before, order: $order) {
      edges {
        node {
          ...InactiveUser
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${INACTIVE_USER_FRAGMENT}
`;
export const ReplaceInactiveUser = gql`
  mutation replaceInactiveUser($replaceInactiveUserDto: ReplaceInactiveUserInputDtoInput!) {
    replaceInactiveUser(replaceInactiveUserDto: $replaceInactiveUserDto) {
      ...InactiveUser
    }
  }
  ${INACTIVE_USER_FRAGMENT}
`;
