import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionButton, Persona, PersonaSize, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { Card, CardStandardHeader } from '@coherence-design-system/controls';
import BackButton from '../../../../common/buttons/BackButton';
import ModifiedDetails from '../../../../common/auditedEntity/ModifiedDetails';
import { IOpsOrgDetail } from '../../../../utils/types/IHierarchy';
import { EditOpsOrgDetailValue, GetOpsOrgDetail } from '../../../../utils/api/HierarchiesApi';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import { cardBackground } from '../../../../common/styles/CommonStyleObjects';
import StackColumn from '../../../../common/cardContents/StackColumn';
import SpanWithLabel from '../../../../common/cardContents/SpanWithLabel';
import IAadUser from '../../../../utils/types/IAadUser';
import ValueTag from '../../../../common/cards/ValueTag';
import ICompany from '../../../../utils/types/ICompany';
import OpsOrgDetailEdit from './OpsOrgDetailEdit';
import PanelTypes from '../../../../utils/types/PanelTypes';
import PageContainer from '../../../../common/page/PageContainer';
import AutoPublishDateField from '../../../../common/formFields/AutoPublishDateField';

const cardStyles = {
  root: {
    height: 'auto',
    width: 'auto',
    maxWidth: '97%',
    marginLeft: '10px',
  },
};

const personaStyles = {
  root: { marginLeft: '5px' },
};

const personaStackStyles = {
  root: { minHeight: '50px', paddingTop: '5px' },
};

export interface IHiearchyRouteParams {
  hierarchyViewId: string;
}

const OpsOrgDetailPage: React.FunctionComponent = () => {
  const { hierarchyViewId } = useParams<IHiearchyRouteParams>();

  const { data, loading, error } = useQuery<{ opsOrgDetailById: IOpsOrgDetail }>(GetOpsOrgDetail, {
    variables: { opsOrgDetailId: parseInt(hierarchyViewId, 10) },
  });

  const opsOrgDetail = data?.opsOrgDetailById;

  const [pageState, setPageState] = useState({
    editPanelOpen: false,
  });

  const onEditClick = (): void => {
    setPageState({ editPanelOpen: true });
  };

  const closeEditPanel = (): void => {
    setPageState({ editPanelOpen: false });
  };

  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
          <BackButton ariaLabel="Back to ops detail list" backDepth={2}>
            <h1>Ops detail</h1>
          </BackButton>
          <ModifiedDetails
            modifiedOn={opsOrgDetail?.modifiedOn}
            modifiedBy={opsOrgDetail?.modifiedBy?.displayName}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <PageContainer title={renderHeader} alternateBackground>
      <LoadingErrorMessage error={error} loading={loading} />
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
        styles={{ root: { marginLeft: '10px' } }}
      >
        <Stack.Item styles={cardBackground}>
          <ActionButton
            iconProps={{ iconName: 'Edit' }}
            title="Edit ops detail"
            onClick={onEditClick}
            text="Edit ops detail"
          />
        </Stack.Item>
      </Stack>
      <br />
      <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
        <StackColumn>
          <Card styles={cardStyles} header={<CardStandardHeader cardTitle="Detail" />}>
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel labelText="Ops detail" value={opsOrgDetail?.name} />
                <SpanWithLabel
                  labelText="Ops summary / org"
                  value={`${opsOrgDetail?.opsOrg?.opsSummary?.name} / ${opsOrgDetail?.opsOrg?.name}`}
                />
                <SpanWithLabel labelText="Default company code(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                    {opsOrgDetail?.companyCodes?.map((co: ICompany) => {
                      return (
                        <ValueTag
                          key={`co${co.companyCode}`}
                          tagString={`${co.companyCode} - ${co.name}`}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
                <AutoPublishDateField />
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Deadline" value={opsOrgDetail?.deadline} />
                <SpanWithLabel labelText="Is active" value={opsOrgDetail?.isActive} />
                <SpanWithLabel labelText="Auto publish" value={opsOrgDetail?.autoPublish} />
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            styles={cardStyles}
            header={<CardStandardHeader cardTitle="Checklist management" />}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel labelText="Checklist owner(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.checklistOwners?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Backup task performer(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupTaskPerformers?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            styles={cardStyles}
            header={<CardStandardHeader cardTitle="Journal entry user lists" />}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel labelText="Backup JE poster(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupJePosters?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Backup JE reviewer(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupJeReviewers?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
            </Stack>
          </Card>
          <br />
          <Card
            styles={cardStyles}
            header={<CardStandardHeader cardTitle="Reconciler user lists" />}
          >
            <Stack horizontal wrap horizontalAlign="space-between">
              <StackColumn>
                <SpanWithLabel labelText="Backup Reconciler(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupRiReconcilers?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
                <SpanWithLabel labelText="Backup Approver(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupRiApprovers?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
              <StackColumn>
                <SpanWithLabel labelText="Backup Reviewer(s)">
                  <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={personaStackStyles}>
                    {opsOrgDetail?.backupRiReviewers?.map((p: IAadUser) => {
                      return (
                        <Persona
                          styles={personaStyles}
                          key={p.graphGuid}
                          text={p.displayName}
                          size={PersonaSize.size24}
                        />
                      );
                    })}
                  </Stack>
                </SpanWithLabel>
              </StackColumn>
            </Stack>
          </Card>
        </StackColumn>
      </Stack>
      {pageState.editPanelOpen && (
        <OpsOrgDetailEdit
          opsOrgDetail={opsOrgDetail}
          closePanel={closeEditPanel}
          mutation={EditOpsOrgDetailValue}
          panelMode={PanelTypes.Edit}
        />
      )}
    </PageContainer>
  );
};

export default OpsOrgDetailPage;
