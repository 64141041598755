import React from 'react';
import { IDashboardFilters } from 'fcw-dashboard-components';
import { useQuery } from '@apollo/client';

import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import TileClientApp from '../TileClientApp';
import config from '../../../utils/ConfigLoader';
import GetJemUserBasedFilter from '../../../utils/api/JemUserBasedFilteringApi';

const JemAtAGlanceCards = (props: { filters: IDashboardFilters }): JSX.Element => {
  const { filters } = props;
  const microUiSettings = config?.settings?.jemConfig;

  const {
    data: fcwFilteredFilters,
    loading,
    error: filterError,
  } = useQuery<{
    companyCodes?: string[];
    opsOrgDetailIds?: string[];
  }>(GetJemUserBasedFilter, {
    variables: {
      filters: {
        companyCodes: filters.companyCodes,
        opsOrgDetailIds: filters.opsOrgDetailIds,
        dueDateRangeStart: filters.dueDateRangeStart,
        dueDateRangeEnd: filters.dueDateRangeEnd,
        forMeOnly: filters.forMeOnly,
        fiscalPeriod: filters.fiscalPeriod,
        fiscalYear: filters.fiscalYear,
        filterByPeriod: filters.filterByPeriod,
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <LoadingErrorMessage loading={loading} error={filterError} />
      <>
        {!filterError && !loading && fcwFilteredFilters && (
          <>
            <TileClientApp
              scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
              appName="JEMAAG"
              data={{
                filters: {
                  ...filters,
                  companyCodes: fcwFilteredFilters?.companyCodes,
                  opsOrgDetailIds: fcwFilteredFilters?.opsOrgDetailIds,
                },
                baseUrl: microUiSettings?.microUiUrl,
                baseName: 'jemAtAGlanceCard',
                moduleType: 'Posting',
              }}
              supportLink={`${microUiSettings?.siteUrl}`}
              friendlyAppName="Journal Entry Management Posting"
            />
            <TileClientApp
              scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
              appName="JEMAAG"
              data={{
                filters: {
                  ...filters,
                  companyCodes: fcwFilteredFilters?.companyCodes,
                  opsOrgDetailIds: fcwFilteredFilters?.opsOrgDetailIds,
                },
                baseUrl: microUiSettings?.microUiUrl,
                baseName: 'jemAtAGlanceCard',
                moduleType: 'Review',
              }}
              supportLink={`${microUiSettings?.siteUrl}`}
              friendlyAppName="Journal Entry Management Review"
            />
          </>
        )}
      </>
    </>
  );
};

export default JemAtAGlanceCards;
