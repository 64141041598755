enum DateConstants {
  Default = 'MM/D/YYYY', // 10/2/2019
  TwoDigitDate = 'MM/DD/YYYY', // 10/02/2019
  TimeZone = 'MM/D/YYYY ZZ', // 10/2/2019
  DaySimple = 'MMM D, YYYY',
  DaySimpleNoComma = 'MMM D (YYYY)',
  DaySimpleNoYear = 'MMM D', // Jun 30
  DayBreakout = 'MMM D, YYYY (ddd)', // Aug 31, 2019 (Sat)
  NoYear = 'MMM D',
  DayBreakoutWithTime = 'MMM D, YYYY (ddd), h:mm:ss a', // Aug 23, 2023 (Wed), 6:47:35 am
  DayBreakoutWithShortTimeAndTimezone = 'MMM D, YYYY (ddd), h:mm A z', // Aug 23, 2023 (Wed), 6:47 AM PDT
}

enum TimezoneConstants {
  PST = 'America/Los_Angeles',
}

export { DateConstants, TimezoneConstants };
