import React, { useEffect, useState } from 'react';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Label, TagPicker, ITag, Stack } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import GetCompanyTagsByFilter from '../../utils/api/CompaniesApi';
import ICompany from '../../utils/types/ICompany';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import { getPickerStyles } from '../styles/CommonStyleObjects';

export interface ICompanyPickerProps {
  id: string;
  defaultValue: ICompany[];
  onChange: (companies: ICompany[]) => void;
  itemLimit?: number;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  isActive?: boolean;
  value?: ICompany[];
  disabled?: boolean;
}
interface ITagWithCountry extends ITag {
  countryCode: string;
}

export const mapCompanyNoTypename = (companies: ICompany[]): ICompany[] => {
  return companies?.map((co) => {
    return {
      companyCode: co.companyCode,
      name: co.name,
      countryCode: co.countryCode,
    };
  });
};

export const mapCompanyToTag = (companies: ICompany[]): ITagWithCountry[] => {
  return companies?.map((cc) => {
    return {
      key: cc.companyCode,
      name: `${cc.companyCode} - ${cc.name}`,
      countryCode: cc.countryCode,
    };
  });
};
export const mapTagToCompany = (tags: ITagWithCountry[]): ICompany[] => {
  return tags?.map((cc) => {
    const { countryCode } = cc;
    const companyCode = cc.key;
    const name = cc.name.split(' - ')[1];
    return { companyCode, name, countryCode } as ICompany;
  });
};
const CompanyPicker: React.FunctionComponent<ICompanyPickerProps> = ({
  id,
  defaultValue,
  itemLimit,
  required,
  errorMessage,
  label,
  onChange,
  placeholder,
  isActive = true,
  value,
  disabled = false,
}: ICompanyPickerProps): JSX.Element => {
  const [loadCompanyTags, { error }] = useLazyQuery(GetCompanyTagsByFilter);
  const [selectedCompanyTags, setSelectedCompanyTags] = useState<ITagWithCountry[]>(
    mapCompanyToTag(defaultValue),
  );
  const onResolveSuggestions = async (filterText: string): Promise<ITagWithCountry[]> => {
    return loadCompanyTags({
      variables: {
        keyword: filterText,
        isActive,
      },
    }).then((result: QueryResult<{ companyTags: ICompany[] }, OperationVariables>) => {
      const companyTags: ICompany[] = result?.data?.companyTags;
      return mapCompanyToTag(companyTags).filter((companyTag) => {
        const duplicate = selectedCompanyTags?.find((sc) => sc.key === companyTag.key);
        return !duplicate;
      });
    });
  };

  useEffect(() => {
    if (value) {
      setSelectedCompanyTags(mapCompanyToTag(value));
    }
  }, [value]);

  return (
    <Stack>
      {label && (
        <Label htmlFor="companyPicker" required={required}>
          {label}
        </Label>
      )}
      <TagPicker
        onResolveSuggestions={onResolveSuggestions}
        removeButtonAriaLabel="Remove Company"
        pickerSuggestionsProps={{
          noResultsFoundText: 'No results found',
        }}
        inputProps={{
          id: `companyPicker${id}`, // What was the intent here? This results in ids like "companyPickercompanyCodePicker"
          'aria-label': label || 'Value',
          placeholder: placeholder || 'Search companies',
          required: true,
        }}
        itemLimit={itemLimit}
        resolveDelay={THROTTLE_SEARCH_TIMEOUT}
        selectedItems={selectedCompanyTags}
        onChange={(items?: ITagWithCountry[]): void => {
          setSelectedCompanyTags(items);
          onChange(mapTagToCompany(items));
        }}
        styles={getPickerStyles(errorMessage)}
        disabled={disabled}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage || error?.message} />
    </Stack>
  );
};

export default CompanyPicker;
