import { ITag } from '@fluentui/react';
import { DEFAULT_RECORDS_PER_PAGE } from '../common/lists/SharedPagination';
import { IGenericFilterTag } from './types/IGenericFilterTag';
import { IListQueryVariables, IEntityListState } from './types/IList';
import { IListSortDefinition, SortDirection } from './types/IListSortDefinition';

export const toggleColSortDir = (sortDir: SortDirection): SortDirection => {
  return sortDir === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
};

export const getResetPaginationProps = (value: number): Partial<IEntityListState> => {
  return {
    selectedPaginationPage: 1,
    paginationSize: value,
    pageInfo: {
      first: value,
      after: null,
      last: null,
      before: null,
    },
  };
};
export const defaultPaginationProps: Partial<IListQueryVariables> = {
  paginationSize: DEFAULT_RECORDS_PER_PAGE,
  pageInfo: {
    first: DEFAULT_RECORDS_PER_PAGE,
    after: null,
    last: null,
    before: null,
  },
  selectedPaginationPage: 1,
};
export const getDefaultStateProps = (taskQueryCache: IListQueryVariables): IEntityListState => {
  return {
    showColumnConfigPanel: false,
    bulkEditMode: null,
    filterTags: taskQueryCache.filterTags,
    keyword: taskQueryCache.keyword,
    selectedPaginationPage: taskQueryCache.selectedPaginationPage,
    paginationSize: taskQueryCache.paginationSize,
    pageInfo: {
      ...taskQueryCache?.pageInfo,
    },
    sortKey: taskQueryCache.sortKey,
    sortDir: taskQueryCache.sortDir,
  };
};

export const getCurrentFilterTags = (
  filterTags: IGenericFilterTag[],
  filterTagKey: string,
  filterTagItems: ITag[],
): IGenericFilterTag[] => {
  if (filterTagItems?.length === 0) {
    const tagToRemove = filterTags.find((fTag) => {
      return fTag.dataKey === filterTagKey;
    });

    if (tagToRemove) {
      filterTags.splice(filterTags.indexOf(tagToRemove), 1);
      return filterTags;
    }
  }

  return filterTags.map((tag) => {
    if (tag.dataKey === filterTagKey) {
      tag.values = filterTagItems;
    }
    return tag;
  });
};

export const getCurrentSortingDefinition = (
  sortDefinition: IListSortDefinition,
  colKey: string,
): IListSortDefinition => {
  let currentSortingDir = SortDirection.ASC;
  if (colKey === sortDefinition.sortKey) {
    currentSortingDir = toggleColSortDir(sortDefinition.sortDir);
  }
  return { sortKey: colKey, sortDir: currentSortingDir };
};
