import React from 'react';
import Collapsible from './Collapsible';
import { IChecklistImplementationProps } from './YesNoChecklist';
import FeatureFlagged from '../../components/featureFlags/FeatureFlagged';
import SalesLocationGroupedChecklist from './SalesLocationGroupedChecklist';
import ISalesLocation from '../../utils/types/ISalesLocation';

const SalesLocationGroupedChecklistFilter = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey } = props;

  const onCheckChanged = (items: ISalesLocation[]) => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <FeatureFlagged flagName="SalesLocationOnTasks">
      <Collapsible text="Sales locations">
        <SalesLocationGroupedChecklist
          selections={initialValues?.values}
          onCheckChanged={onCheckChanged}
        />
      </Collapsible>
    </FeatureFlagged>
  );
};
export default SalesLocationGroupedChecklistFilter;
