import { ITag } from '@fluentui/react';
import { DocumentNode } from 'graphql';
import React from 'react';
import { INamable } from '../../utils/types/IHierarchy';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

interface ICollapsibleGenericChecklistProps extends IChecklistImplementationProps {
  query?: DocumentNode;
  queryKey?: string;
}

const CollapsibleGenericChecklist = (props: ICollapsibleGenericChecklistProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, query, queryKey, disabledValues } = props;

  const mapToTag = (item: INamable): ITag => {
    return { key: item.id, name: item.name };
  };

  const mapData = (data: { [x: string]: INamable[] }): ITag[] => {
    if (!data) {
      return [];
    }
    const propertyName = queryKey ?? dataKey;

    return data[propertyName].map((tpv: INamable) => {
      return mapToTag(tpv);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        query={query}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default CollapsibleGenericChecklist;
