import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import GetCompanyCodeList from '../../../../utils/api/CompanyCodeAssignmentApi';
import ICompanyCodeAssignment from '../../../../utils/types/ICompanyCodeAssignment';
import PanelTypes from '../../../../utils/types/PanelTypes';
import CompanyCodeAssignmentPanel from './CompanyCodeAssignmentPanel';

interface ICompanyCodeAssignmentEditProps {
  companyCodeAssignment: ICompanyCodeAssignment;
  closePanel: (shouldRefetch?: boolean) => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const CompanyCodeAssignmentEdit = (props: ICompanyCodeAssignmentEditProps): JSX.Element => {
  const { mutation, companyCodeAssignment, closePanel, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetCompanyCodeList }];
  const [updateCompanyCodeAssignmentValue, { loading, error }] = useMutation(mutation, {
    onCompleted: () => {
      closePanel(true);
    },
    refetchQueries,
  });
  const submitForm = (companyCodeAssignmentDtoData: ICompanyCodeAssignment) => {
    updateCompanyCodeAssignmentValue({
      variables: {
        companyCodeAssignmentDto: {
          name: companyCodeAssignmentDtoData.name,
          companyCode: companyCodeAssignmentDtoData.companyCode,
          countryCode: companyCodeAssignmentDtoData.countryCode,
          isActive: companyCodeAssignmentDtoData.isActive,
          inGlobalChecklist: companyCodeAssignmentDtoData.inGlobalChecklist,
          inLocalChecklist: companyCodeAssignmentDtoData.inLocalChecklist,
          owners: companyCodeAssignmentDtoData.owners,
        },
      },
    });
  };

  return (
    <CompanyCodeAssignmentPanel
      data={companyCodeAssignment}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default CompanyCodeAssignmentEdit;
