import React from 'react';
import { ApolloError } from '@apollo/client';
import { ChoiceGroup, IChoiceGroupOption, Label, Stack } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  CoherencePanelStyles,
  formChoiceGroup,
} from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import GraphUsersPeoplePicker, {
  mapUsersNoTypename,
} from '../../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../../utils/types/IAadUser';
import arrayMustNotBeEmpty from '../../../../utils/validation/CustomValidations';
import PanelTypes from '../../../../utils/types/PanelTypes';
import ICompanyCodeAssignment from '../../../../utils/types/ICompanyCodeAssignment';
import SpanWithLabel from '../../../../common/cardContents/SpanWithLabel';

export interface ICompanyCodeAssignmentPanelProps {
  data: ICompanyCodeAssignment;
  mutation: (companyCodeAssignmentDtoData: ICompanyCodeAssignment) => void;
  closePanel: () => void;
  mutationError: ApolloError | undefined;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const CompanyCodeAssignmentPanel: React.FunctionComponent<ICompanyCodeAssignmentPanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: ICompanyCodeAssignmentPanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const handleOnSaveClick = (formData: ICompanyCodeAssignment): void => {
    mutation(formData);
  };
  const yesNo: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' },
  ];

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelMode} assignment`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close assignment"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="Saving assignment"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                name="companyCode"
                control={control}
                defaultValue={data?.companyCode}
                render={() => {
                  return (
                    <SpanWithLabel labelText="Company code">
                      <>{data?.companyCode}</>
                    </SpanWithLabel>
                  );
                }}
              />
              <SpanWithLabel labelText="Company name">
                <>{data?.name}</>
              </SpanWithLabel>
              <SpanWithLabel labelText="Country">
                <>{data?.countryCode}</>
              </SpanWithLabel>
              <Controller
                name="isActive"
                control={control}
                defaultValue={data?.isActive}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="isActive"
                      label="Is active"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.isActive ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('isActive', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="inGlobalChecklist"
                control={control}
                defaultValue={data?.inGlobalChecklist}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="inGlobalChecklist"
                      label="In global checklist"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.inGlobalChecklist ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('inGlobalChecklist', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="inLocalChecklist"
                control={control}
                defaultValue={data?.inLocalChecklist}
                render={() => {
                  return (
                    <ChoiceGroup
                      id="inLocalChecklist"
                      label="In local checklist"
                      styles={formChoiceGroup}
                      options={yesNo}
                      defaultSelectedKey={data?.inLocalChecklist ? 'true' : 'false'}
                      required
                      onChange={(e, newValue?: IChoiceGroupOption): void => {
                        setValue('inLocalChecklist', newValue?.key === 'true');
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="owners"
                valueKey="owners"
                control={control}
                defaultValue={mapUsersNoTypename(data?.owners)}
                render={({ value }) => {
                  return (
                    <Stack tokens={{ childrenGap: 5 }}>
                      <Label required>Owner(s)</Label>
                      <GraphUsersPeoplePicker
                        id="owners"
                        itemLimit={10}
                        removeButtonAriaLabel="Remove owners"
                        controlName="owners"
                        onChange={(users: IAadUser[]): void => {
                          setValue('owners', users);
                        }}
                        errorMessage={errors?.owners?.message}
                        defaultValue={value}
                        placeholder="Type one or more owners"
                      />
                    </Stack>
                  );
                }}
                rules={{
                  validate: { arrayMustNotBeEmpty },
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default CompanyCodeAssignmentPanel;
