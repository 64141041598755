import React from 'react';
import { Label, NormalPeoplePicker, IPeoplePickerProps, getTheme } from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';

interface IErrorHandlingPeoplePickerProps extends IPeoplePickerProps {
  controlName: string;
  labelText?: string;
  errorMessage?: string;
  required?: boolean;
  setErrorMessage?: (stateKey: string, errorMessage: string) => void;
}

const errorStyles = {
  text: {
    borderColor: getTheme().semanticColors.errorIcon,
  },
};

const ErrorHandlingPeoplePicker = (props: IErrorHandlingPeoplePickerProps): JSX.Element => {
  const {
    controlName,
    labelText,
    errorMessage,
    required: isRequired,
    setErrorMessage,
    selectedItems,
  } = props;

  const pickerErrorStyles = errorMessage ? errorStyles : {};

  const validate = (): void => {
    if (setErrorMessage) {
      if (isRequired && (!selectedItems || selectedItems.length < 1)) {
        setErrorMessage(controlName, `You must add at least one ${labelText}`);
      } else {
        setErrorMessage(controlName, '');
      }
    }
  };
  return (
    <>
      {labelText && (
        <Label htmlFor={`${controlName}Picker`} required={isRequired}>
          {labelText}
        </Label>
      )}
      <NormalPeoplePicker
        inputProps={{
          title: labelText,
          'aria-label': `${labelText} People Picker`,
          name: controlName,
          id: `${controlName}Picker`,
          required: isRequired,
        }}
        styles={pickerErrorStyles}
        {...props}
        onBlur={validate}
      />
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export default ErrorHandlingPeoplePicker;
