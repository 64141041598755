import React from 'react';
import { ApolloError } from '@apollo/client';
import { Stack, TextField } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { CoherencePanelStyles } from '../../../../common/styles/CommonStyleObjects';
import ActionButtons from '../../../../common/buttons/ActionButtons';
import { RequiredMarker } from '../../../../common/labels/RequiredMarker';
import LoadingErrorMessage from '../../../../common/errorContent/LoadingErrorMessage';
import PanelTypes from '../../../../utils/types/PanelTypes';
import IDataSource from '../../../../utils/types/IDataSource';
import DataSourceApplicationDropdown, {
  IDataSourceApplicationDropdownReference,
} from '../../../../common/formFields/DataSourceApplicationDropdown';
import DataSourcePerspectiveDropdown, {
  IDataSourcePerspectiveDropdownReference,
} from '../../../../common/formFields/DataSourcePerspectiveDropdown';

export interface IDataSourcePanelProps {
  data: IDataSource;
  mutation: (dataSourceDtoData: IDataSource) => void;
  closePanel: () => void;
  mutationError: ApolloError | undefined;
  mutationLoading: boolean;
  panelMode: PanelTypes;
}
const DataSourcePanel: React.FunctionComponent<IDataSourcePanelProps> = ({
  mutation,
  closePanel,
  data,
  mutationError,
  mutationLoading,
  panelMode,
}: IDataSourcePanelProps): JSX.Element => {
  const form = useForm();
  const { handleSubmit, control, errors, setValue } = form;
  const handleOnSaveClick = (formData: IDataSource): void => {
    mutation(formData);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${panelMode} data source`}
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close data source"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={mutationLoading}
          closePanel={closePanel}
          handleSubmit={handleSubmit(handleOnSaveClick)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <form onSubmit={handleSubmit(handleOnSaveClick)}>
        <LoadingErrorMessage
          loading={mutationLoading}
          error={mutationError}
          label="Saving data source"
        />
        <Stack tokens={{ childrenGap: 20 }}>
          <span>
            <RequiredMarker /> Required
          </span>
          {data && (
            <>
              <Controller
                as={TextField}
                label="Name"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={data?.name}
                value={data?.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a name',
                  },
                  maxLength: {
                    value: 75,
                    message: 'Name cannot have more than 75 characters',
                  },
                }}
              />
              <Controller
                label="Application"
                id="applicationId"
                required
                name="applicationId"
                control={control}
                resizable={false}
                defaultValue={data?.application?.id}
                render={(fieldProps) => (
                  <DataSourceApplicationDropdown
                    label="Application"
                    valueKey="applicationId"
                    required
                    value={fieldProps.value}
                    handleChange={(
                      newValue: IDataSourceApplicationDropdownReference,
                      valueKey: string,
                    ): void => {
                      setValue('applicationId', newValue.key);
                    }}
                    errors={errors}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide an application',
                  },
                }}
              />
              <Controller
                label="Perspective"
                id="perspectiveId"
                required
                name="perspectiveId"
                control={control}
                resizable={false}
                defaultValue={data?.perspective?.id}
                render={(fieldProps) => (
                  <DataSourcePerspectiveDropdown
                    label="Perspective"
                    valueKey="perspectiveId"
                    required
                    value={fieldProps.value}
                    handleChange={(
                      newValue: IDataSourcePerspectiveDropdownReference,
                      valueKey: string,
                    ): void => {
                      setValue('perspectiveId', newValue.key);
                    }}
                    errors={errors}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide a perspective',
                  },
                }}
              />
            </>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default DataSourcePanel;
