import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DetailsListLayoutMode,
  ICommandBarItemProps,
  IObjectWithKey,
  Stack,
  ITag,
} from '@fluentui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import getStagedTasksMenu, {
  getStagedTaskListColumns,
  IStagedTaskMenuProps,
} from '../StagedTasks.config';

import FullWidthHeader from '../../../common/headers/FullWidthHeader';

import { bodyContentContainer } from '../../../common/styles/CommonStyleObjects';
import {
  EditStagedTaskBulk,
  EditStagedTaskRemoveBulk,
  GetStagedTasks,
} from '../../../utils/api/StagedTaskApi';

import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import paginatedResultFormatter from '../../../utils/formatters/PaginatedResultFormatter';
import { PaginatedData, PaginationQueryVariables } from '../../../utils/types/PaginationTypes';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../common/constants/SiteConstants';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../common/lists/ColumnConfigHelper';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import ColumnConfigPanel from './ColumnConfigPanel';
import GenerateDraftTasks, { GenerateDraftsMode } from '../generators/GenerateDraftTasks';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import { IStagedTask } from '../../../utils/types/ITask';
import { mapStagedTaskFilterTagsToIds, TaskType } from './BaseTaskListfilters.config';
import { IBaseTaskListColumns } from '../BaseTasks.config';
import {
  getFormInputConfig,
  getRemoveTaskMapper,
  getUpdateTaskMapper,
} from '../edit/StagedTaskBulkEdit.config';
import StagedTaskDeleteModal from './StagedTaskDeleteModal';
import BulkEditMode from '../../../utils/types/IBulkEdit';
import { IListQueryVariables, IEntityListState } from '../../../utils/types/IList';
import { IQuerySortDefinition, SortDirection } from '../../../utils/types/IListSortDefinition';
import { convertDotPathToNestedObject } from '../../../utils/Helpers';
import PaginationWrapperManaged from '../../../common/lists/PaginationWrapperManaged';
import StagedTaskListPageFilters from './StagedTaskListPageFilters';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../utils/listHelpers';
import { sharedEditButton } from '../../../common/lists/SharedDataGridColumns';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';
import enumFormatter from '../../../utils/formatters/EnumFormatter';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../common/lists/GenericFilterTagHandler';
import StagedTaskActivationModal from './StagedTaskActivationModal';
import TasksImportPanel, { ImportTaskType } from './TasksImportPanel';
import BulkEdit, { BulkEditType } from '../../../common/bulkEdit/BulkEdit';

interface IStageTaskListState extends IEntityListState {
  showImportPanel: boolean;
  showColumnConfigPanel: boolean;
  generateDraftsMode: GenerateDraftsMode;
  showDeleteModal: boolean;
  showActivationModal: boolean;
}
const StagedTasksList = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.stagedTaskListColumns,
    [],
  );

  const yesTag: ITag[] = [{ key: 'Yes', name: 'Yes' }];
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [
      { title: 'Is active', dataKey: 'isActive', values: yesTag },
    ] as IGenericFilterTag[],
    sortDir: SortDirection.DESC,
    sortKey: 'id',
  };

  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    LocalStorageKeys.stagedTaskQueryState,
    { ...defaultPaginationProps, ...defaultFiltersAndSort } as IListQueryVariables,
  );
  const [pageState, setPagesState] = useState<IStageTaskListState>({
    ...getDefaultStateProps(taskQueryCache),
    showImportPanel: false,
    generateDraftsMode: null,
    showDeleteModal: false,
    showActivationModal: false,
  });

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [dataResult, setDataResult] = useState<PaginatedData<IStagedTask>>();
  const [getTasks, { data, loading, error }] = useLazyQuery(GetStagedTasks, {
    variables: {
      keyword: null,
      filters: {},
      ...taskQueryCache?.pageInfo,
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  let filterTimeout: string | number | NodeJS.Timeout;
  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal>
        <h1>Staged tasks</h1>
      </Stack>
    );
  };
  const onNewTaskClick = () => {
    history.push('/stagedTasks/new');
  };
  const itemsSelected = selectedTasks?.length > 0;

  const onShowImportPanel = () => {
    setPagesState({
      ...pageState,
      showImportPanel: true,
    });
  };

  const onBulkEditClick = (editMode: BulkEditMode) => {
    setPagesState({
      ...pageState,
      bulkEditMode: editMode,
    });
  };

  const onGenerateDraftTasks = (generateMode: GenerateDraftsMode) => {
    setPagesState({
      ...pageState,
      generateDraftsMode: generateMode,
    });
  };

  const onBulkDeleteClick = (deleteMode: BulkEditMode) => {
    setPagesState({ ...pageState, showDeleteModal: true, bulkEditMode: deleteMode });
  };

  const onRefreshTasksClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };

  const onActivateTasksClick = (activationMode: BulkEditMode) => {
    setPagesState({ ...pageState, showActivationModal: true, bulkEditMode: activationMode });
  };

  const commandBarItems = React.useMemo((): ICommandBarItemProps[] => {
    const { keyword, filterTags } = pageState;
    const downloadTemplateProps: IDownloadButtonProps = {
      key: 'StagedTasksTemplate',
      fileEndpoint: 'StagedTasksTemplate',
      fileName: 'StagedTasksImportTemplate',
      cacheKey: 'TasksAllDownload',
      iconProps: { iconName: 'ExcelLogo' },
      commandBarButtonAs: DownloadButton,
    };

    const filtersMap = mapStagedTaskFilterTagsToIds(filterTags);
    filtersMap.publishType = filtersMap.publishType?.map((publishType: string) => {
      return enumFormatter(publishType).replace(/ /g, '');
    });

    const exportStagedTasksProps: IDownloadButtonProps = {
      key: 'StagedTasksFiltered',
      fileEndpoint: keyword == null ? 'StagedTasks' : `StagedTasks?keyword=${keyword}`,
      fileName: 'StagedTasks',
      text: `Export Filtered (${dataResult?.totalCount})`,
      cacheKey: 'TasksFilteredDownload',
      iconProps: { iconName: 'ExcelLogo' },
      postBody: JSON.stringify(filtersMap),
      commandBarButtonAs: DownloadButton,
    };

    const exportAllStagedTasksProps: IDownloadButtonProps = {
      ...exportStagedTasksProps,
      postBody: '',
      text: 'Export All',
      key: 'StagedTaskAllDownload',
    };

    const commandBarItemConfig: IStagedTaskMenuProps = {
      itemsSelected,
      itemsSelectedCount: selectedTasks?.length,
      totalItemsCount: dataResult?.totalCount,
      onNewTaskClick,
      onShowImportPanel,
      onBulkEditClick,
      onGenerateDraftTasks,
      onBulkDeleteClick,
      downloadTemplateProps,
      exportStagedTasksProps,
      exportAllStagedTasksProps,
      onRefreshTasksClick,
      onActivateTasksClick,
    };

    return getStagedTasksMenu(commandBarItemConfig);
  }, [
    itemsSelected,
    selectedTasks?.length,
    pageState.keyword,
    pageState.filterTags,
    dataResult?.totalCount,
  ]);

  const columnConfig: IBaseTaskListColumns = {
    taskType: TaskType.StagedTasks,
  };

  const cachedListColumns = getColumnConfigByKey(
    getStagedTaskListColumns(columnConfig),
    listColumnsCache,
  );

  const listColumns = cachedListColumns.filter((col) => {
    return (col as ILocalStorageColumnConfig).active;
  });
  listColumns.unshift(
    sharedEditButton<IBaseTask>(
      (selectedItem: IStagedTask) => {
        history.push(`/stagedTasks/edit/${selectedItem.id}`);
      },
      selectedTasks?.length > 1,
      'name',
    ),
  );

  const detailsListConfig: IGeneralEntityListProps<IStagedTask> = {
    data: dataResult?.data || [],
    listColumns,
    commandBarItems,
    onSearchBoxChange: (ev, searchTerm: string) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
          ...getResetPaginationProps(taskQueryCache?.paginationSize),
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    loading,
    layoutMode: DetailsListLayoutMode.fixedColumns,
    error,
    lockActionsColumn: true,
    onChangeSelectedItems: (selection: IObjectWithKey[]) => {
      setSelectedTasks(selection);
    },
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
    frozenColumnsFromStart: 1,
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <StagedTaskListPageFilters />
        </GenericFilterTagHandler>
      );
    },
  };
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = mapStagedTaskFilterTagsToIds(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    getTasks({
      variables: {
        keyword,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
    });
  };

  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);

  useEffect(() => {
    // i had to do this because selected items were breaking if i referenced formatted data directly
    const formattedData = paginatedResultFormatter<IStagedTask>(data?.stagedTasks);
    setDataResult(formattedData);
    setSelectedTasks([]);
  }, [loading, data]);

  const selectedTaskList = selectedTasks?.map((task: IStagedTask) => {
    return task?.id;
  });

  return (
    <>
      <FullWidthHeader title={renderHeader} />

      <div className={`${bodyContentContainer}`}>
        <PaginationWrapperManaged<IStagedTask>
          dataResult={dataResult}
          selectedPage={pageState.selectedPaginationPage}
          loadingData={loading}
          onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
            setPagesState({
              ...pageState,
              selectedPaginationPage: value,
              pageInfo: {
                ...variables,
              },
            });
          }}
          paginationSize={pageState.paginationSize}
          onPageSizeChange={(newPageSize: number) => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              ...getResetPaginationProps(newPageSize),
            });
          }}
        >
          {GeneralEntityList(detailsListConfig)}
        </PaginationWrapperManaged>
      </div>
      {pageState?.showImportPanel && (
        <TasksImportPanel
          closePanel={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showImportPanel: false,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            });
          }}
          importTaskType={ImportTaskType.StagedTasks}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
      <BulkEdit
        type={BulkEditType.tasks}
        getFormInputs={getFormInputConfig}
        bulkEditMode={
          pageState?.showDeleteModal || pageState?.showActivationModal
            ? null
            : pageState?.bulkEditMode
        }
        hideBulkEditModal={() => {
          const stateCopy = structuredClone(pageState);
          setPagesState({
            ...stateCopy,
            bulkEditMode: null,
          });
        }}
        selectedItemsCount={
          pageState.bulkEditMode === BulkEditMode.BulkEditSelected
            ? selectedTasks.length
            : dataResult?.totalCount
        }
        selectedItems={selectedTaskList}
        keyword={pageState.keyword}
        filters={mapStagedTaskFilterTagsToIds(pageState.filterTags)}
        updateMutation={useMutation(EditStagedTaskBulk)}
        removeMutation={useMutation(EditStagedTaskRemoveBulk)}
        getUpdateMapper={getUpdateTaskMapper}
        getRemoveMapper={getRemoveTaskMapper}
      />
      {pageState?.generateDraftsMode !== null && (
        <GenerateDraftTasks
          generateMode={pageState?.generateDraftsMode}
          selectedTasksCount={
            pageState.generateDraftsMode === GenerateDraftsMode.GenerateDraftsSelected
              ? selectedTasks.length
              : dataResult?.totalCount
          }
          selectedTasks={selectedTaskList}
          hideModal={() => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              generateDraftsMode: null,
            });
          }}
          keyword={pageState.keyword}
          filters={mapStagedTaskFilterTagsToIds(pageState.filterTags)}
        />
      )}
      {pageState?.showDeleteModal === true && (
        <StagedTaskDeleteModal
          selectedTasks={selectedTaskList}
          selectedTaskCount={
            pageState.bulkEditMode === BulkEditMode.BulkEditSelected
              ? selectedTasks.length
              : dataResult?.totalCount
          }
          showDeleteModal={pageState.showDeleteModal}
          keyword={pageState.keyword}
          hideBulkDeleteModal={(shouldRefetch: boolean) => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showDeleteModal: false,
              bulkEditMode: null,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            });

            if (shouldRefetch) {
              refreshPage();
            }
          }}
          bulkDeleteMode={pageState?.bulkEditMode}
          filters={mapStagedTaskFilterTagsToIds(pageState.filterTags)}
        />
      )}
      {pageState?.showActivationModal === true && (
        <StagedTaskActivationModal
          selectedTasks={selectedTaskList}
          selectedTaskCount={
            pageState.bulkEditMode === BulkEditMode.BulkEditSelected
              ? selectedTasks.length
              : dataResult?.totalCount
          }
          showActivationModal={pageState?.showActivationModal}
          hideActivationModal={(shouldRefetch: boolean) => {
            const stateCopy = structuredClone(pageState);
            setPagesState({
              ...stateCopy,
              showActivationModal: false,
              bulkEditMode: null,
            });

            if (shouldRefetch) {
              refreshPage();
            }
          }}
          bulkActivationMode={pageState?.bulkEditMode}
          filters={mapStagedTaskFilterTagsToIds(pageState?.filterTags)}
          keyword={pageState?.keyword}
        />
      )}
    </>
  );
};

export default StagedTasksList;
