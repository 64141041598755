import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from '../formFields/Collapsible';
import FiscalPeriodDropdown, {
  IFiscalPeriodDropdownOption,
  formatFiscalInfoString,
} from '../formFields/FiscalPeriodDropdown';
import FeatureFlagged from '../../components/featureFlags/FeatureFlagged';

interface IPageState {
  fiscalPeriodYearRangeStart?: string;
  fiscalPeriodYearRangeEnd?: string;
}
interface IFiscalPeriodRangePickerProps {
  onMultipleFiltersUpdate?: (filters: IGenericFilterTag[]) => void;
  getFilterByKey?: (filterKey: string) => IGenericFilterTag;
  dataKeys?: string[];
  wrapperLabel?: string | null;
  labels?: string[];
}

const FiscalPeriodRangePicker = (props: IFiscalPeriodRangePickerProps): JSX.Element => {
  const { wrapperLabel, labels, dataKeys, onMultipleFiltersUpdate, getFilterByKey } = props;
  if (labels.length !== 2) {
    return (
      <>
        The Fiscal Year Range Picker requires an array with two values, one for start and one for
        end
      </>
    );
  }
  if (dataKeys.length !== 6) {
    return <>The Fiscal Period Range Picker requires an array with 6 data keys</>;
  }

  const periodStartKey = dataKeys[0]; // fiscalPeriodRangeStart
  const periodEndKey = dataKeys[1]; // fiscalPeriodRangeEnd
  const yearStartKey = dataKeys[2]; // fiscalYearRangeStart
  const yearEndKey = dataKeys[3]; // fiscalYearRangeEnd
  const periodStartTextKey = dataKeys[4]; // fiscalPeriodYearRangeStart
  const periodEndTextKey = dataKeys[5]; // fiscalPeriodYearRangeEnd

  const fromPeriodText = getFilterByKey(periodStartTextKey)?.values[0]?.key?.toString() ?? null;
  const toPeriodText = getFilterByKey(periodEndTextKey)?.values[0]?.key?.toString() ?? null;

  const [pageState, setPageState] = useState<IPageState>({
    fiscalPeriodYearRangeStart: fromPeriodText,
    fiscalPeriodYearRangeEnd: toPeriodText,
  });

  const updatePageState = (newPageState: IPageState) => {
    setPageState((state: IPageState) => {
      return { ...state, ...newPageState };
    });
  };

  const handleStartPeriodChange = (
    startPeriod: string,
    startYear: string,
    startPeriodString: string,
  ) => {
    updatePageState({ fiscalPeriodYearRangeStart: startPeriodString });
    onMultipleFiltersUpdate([
      {
        dataKey: periodStartTextKey,
        title: 'Period start',
        hideFromBar: false,
        values: [{ name: startPeriodString, key: startPeriodString }],
      },
      {
        dataKey: periodStartKey,
        title: periodStartKey,
        hideFromBar: true,
        parentDataKey: periodStartTextKey,
        values: [{ name: periodStartKey, key: Number(startPeriod) }],
      },
      {
        dataKey: yearStartKey,
        title: yearStartKey,
        hideFromBar: true,
        parentDataKey: periodStartTextKey,
        values: [{ name: yearStartKey, key: Number(startYear) }],
      },
    ]);
  };
  const handleEndPeriodChange = (endPeriod: string, endYear: string, endPeriodString: string) => {
    updatePageState({ fiscalPeriodYearRangeEnd: endPeriodString });
    onMultipleFiltersUpdate([
      {
        dataKey: periodEndTextKey,
        title: 'Period end',
        hideFromBar: false,
        values: [{ name: endPeriodString, key: endPeriodString }],
      },
      {
        dataKey: periodEndKey,
        title: periodEndKey,
        hideFromBar: true,
        parentDataKey: periodEndTextKey,
        values: [{ name: periodEndKey, key: Number(endPeriod) }],
      },
      {
        dataKey: yearEndKey,
        title: yearEndKey,
        hideFromBar: true,
        parentDataKey: periodEndTextKey,
        values: [{ name: yearEndKey, key: Number(endYear) }],
      },
    ]);
  };

  return (
    <FeatureFlagged flagName="FiscalYearPeriodFilters">
      <Stack tokens={{ childrenGap: 10 }}>
        <Collapsible text={wrapperLabel}>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <FiscalPeriodDropdown
              id={periodStartTextKey}
              value={pageState?.fiscalPeriodYearRangeStart}
              label={labels[0]}
              width={250}
              handleChange={(newValue: IFiscalPeriodDropdownOption, valueKey: string): void => {
                const selectedFromPeriodString = formatFiscalInfoString(
                  newValue?.fiscalPeriod,
                  newValue?.fiscalYear,
                );
                handleStartPeriodChange(
                  newValue?.fiscalPeriod.toString(),
                  newValue?.fiscalYear.toString(),
                  selectedFromPeriodString,
                );
              }}
            />
            <FiscalPeriodDropdown
              id={periodEndTextKey}
              value={pageState?.fiscalPeriodYearRangeEnd}
              label={labels[1]}
              width={250}
              handleChange={(newValue: IFiscalPeriodDropdownOption, valueKey: string): void => {
                const selectedToPeriodString = formatFiscalInfoString(
                  newValue?.fiscalPeriod,
                  newValue?.fiscalYear,
                );
                handleEndPeriodChange(
                  newValue?.fiscalPeriod.toString(),
                  newValue?.fiscalYear.toString(),
                  selectedToPeriodString,
                );
              }}
            />
          </Stack>
        </Collapsible>
      </Stack>
    </FeatureFlagged>
  );
};
export default FiscalPeriodRangePicker;
