import { Label, NeutralColors, Stack } from '@fluentui/react';
import React from 'react';

interface ISystemGeneratedFieldProps {
  label: string;
  value: string;
  disabled?: boolean;
}
const SystemGeneratedField = (props: ISystemGeneratedFieldProps): JSX.Element => {
  const { label, value, disabled } = props;
  const spanStyles = { root: { color: NeutralColors.gray180, fontStyle: 'italic' } };
  const systemMessage = 'To be system-generated';
  return (
    <Stack>
      <Label disabled={disabled}>{label}</Label>
      <Stack verticalAlign="center" styles={spanStyles}>
        {!value || value === '' ? systemMessage : value}
      </Stack>
    </Stack>
  );
};
export default SystemGeneratedField;
