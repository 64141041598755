import { ITag } from '@fluentui/react';
import { IGenericFilterTag, ITypedTag } from '../types/IGenericFilterTag';

const getCurrentFilterValues = (
  dataKey: string,
  currentFilterValues: IGenericFilterTag[],
): IGenericFilterTag => {
  const current = currentFilterValues?.find((value: IGenericFilterTag) => {
    return value.dataKey === dataKey;
  });

  if (current && current.dataType === 'number') {
    current.values = current?.values?.map((tag: ITag) => {
      return {
        name: tag.name,
        key: parseInt(tag.key.toString(), 10),
      };
    });
  }

  return current;
};

export const getTypedFilters = (filters: IGenericFilterTag[], isSavedFilter?: boolean) => {
  return filters.map((filter) => {
    let { values } = filter;
    values = values.map((value: ITypedTag) => {
      if (filter.dataType === 'number') {
        return {
          name: value.name,
          key: typeof value.key === 'string' ? parseInt(value.key, 10) : value.key,
          isSavedFilter,
        } as ITypedTag;
      }
      return { ...value, isSavedFilter };
    });

    return { ...filter, values };
  });
};

interface IFilterDictionary {
  [key: string]: IGenericFilterTag;
}

export const consolidateFilterList = (filters: IGenericFilterTag[]) => {
  const newFilterList = {} as IFilterDictionary;
  filters.forEach((filter) => {
    const key = filter.dataKey as keyof IFilterDictionary;

    if (key in newFilterList) {
      const uniqueValues = filter.values.filter(
        (v) => newFilterList[key].values.findIndex((v2) => v2.key === v.key) === -1,
      );
      newFilterList[key].values = [...newFilterList[key].values, ...uniqueValues];
    } else {
      // Deep copy
      newFilterList[key] = structuredClone(filter);
    }
  });
  return Object.values(newFilterList);
};

export default getCurrentFilterValues;
