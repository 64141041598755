import { PaginatedQueryResults, PaginatedData, IPageInfo } from '../types/PaginationTypes';

export const getEmptyResults = <O>() => {
  return {
    totalCount: 1,
    pageInfo: {} as IPageInfo,
    data: [] as O[],
  };
};

const paginatedResultFormatter = <O>(queryResults: PaginatedQueryResults<O>): PaginatedData<O> => {
  if (!queryResults) {
    return getEmptyResults<O>();
  }
  const { edges, pageInfo, totalCount } = queryResults;
  const itemsList: O[] = edges.map(({ node }) => {
    return { ...node };
  });
  return {
    data: itemsList,
    pageInfo,
    totalCount,
  } as PaginatedData<O>;
};
export default paginatedResultFormatter;
