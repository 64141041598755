import React from 'react';
import { Label } from '@fluentui/react';
import {
  monthToFiscalPeriod,
  yearToFiscalYear,
  getAutoPublishDate,
} from '../../utils/formatters/DateFormatters';

export interface IAutoPublishDateFieldProps {
  period?: number;
  fiscalYear?: number;
}

const AutoPublishDateField = (props: IAutoPublishDateFieldProps): JSX.Element => {
  const { period, fiscalYear } = props;
  const currentDate = new Date();
  const assignedPeriod = period ?? monthToFiscalPeriod(currentDate.getMonth());
  const assignedYear =
    fiscalYear ?? yearToFiscalYear(currentDate.getFullYear(), currentDate.getMonth());

  return (
    <>
      <Label>
        Auto Publish date for P{assignedPeriod} FY{assignedYear}
      </Label>
      {getAutoPublishDate()}
    </>
  );
};
export default AutoPublishDateField;
