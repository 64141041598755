import { gql } from '@apollo/client';

export const ProjectNileWorkflowsEndpoint = '/listBlobsInContainer';

const GetProjectNileWorkflows = gql`
  query projectNileWorkflows {
    projectNileWorkflows {
      id
      name
    }
  }
`;
export default GetProjectNileWorkflows;
