import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import PanelTypes from '../../../../utils/types/PanelTypes';
import { GetDataSourcesList } from '../../../../utils/api/DataSourcesApi';
import DataSourcePerspectivePanel from './DataSourcePerspectivePanel';
import IDataSourcePerspective from '../../../../utils/types/IDataSourcePerspective';

interface IDataSourcePerspectiveEditProps {
  dataSourcePerspective: IDataSourcePerspective;
  closePanel: () => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const DataSourcePerspectiveEdit = (props: IDataSourcePerspectiveEditProps): JSX.Element => {
  const { mutation, dataSourcePerspective, closePanel, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetDataSourcesList }];
  const [updateDataSourcePerspectiveValue, { loading, error }] = useMutation(mutation, {
    onCompleted: closePanel,
    refetchQueries,
  });
  const submitForm = (dataSourcePerspectiveData: IDataSourcePerspective) => {
    updateDataSourcePerspectiveValue({
      variables: {
        dataSourcePerspectiveDto: {
          id: dataSourcePerspective.id,
          name: dataSourcePerspectiveData.name,
        },
      },
    });
  };

  return (
    <DataSourcePerspectivePanel
      data={dataSourcePerspective}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default DataSourcePerspectiveEdit;
