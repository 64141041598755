import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react';
import React from 'react';

export const businessContinuityPlanPriorityOptions: IDropdownOption[] = [
  { key: 'LOW', text: 'Low' },
  { key: 'MEDIUM', text: 'Medium' },
  { key: 'HIGH', text: 'High ' },
];

interface bcpPriorityDropdownProps extends Omit<IDropdownProps, 'options'> {
  handleChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<string>,
    index?: number,
  ) => void;
}
const BcpPriorityPeakDropdown = (dropdownProps: bcpPriorityDropdownProps): JSX.Element => {
  const { handleChange } = dropdownProps;
  return (
    <Dropdown
      {...dropdownProps}
      onChange={(event, option, index) => {
        handleChange(event, option, index);
      }}
      options={businessContinuityPlanPriorityOptions}
    />
  );
};

export default BcpPriorityPeakDropdown;
