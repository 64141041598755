import { gql } from '@apollo/client';

export const GetDataSourceOptionsByKeyword = gql`
  query DataSourceOptions($keyword: String) {
    dataSourceOptions(keyword: $keyword) {
      id
      name
      perspectiveId
      applicationId
      isEditable
    }
  }
`;

export const GetDataSourceApplicationsByKeyword = gql`
  query DataSourceApplications($keyword: String) {
    dataSourceApplications(keyword: $keyword) {
      id
      name
    }
  }
`;

export const GetDataSourcePerspectivesByKeyword = gql`
  query DataSourcePerspectives($keyword: String) {
    dataSourcePerspectives(keyword: $keyword) {
      id
      name
    }
  }
`;

export const GetDataSourceById = gql`
  query DataSource($dataSourceId: Int!) {
    dataSource(dataSourceId: $dataSourceId) {
      id
      name
      perspective {
        id
        name
      }
      application {
        id
        name
      }
      isEditable
    }
  }
`;

export const CreateDataSource = gql`
  mutation CreateDataSource($dataSourceDto: DataSourceInputDtoInput!) {
    createDataSource(dataSourceDto: $dataSourceDto) {
      id
      name
      perspective {
        id
        name
      }
      application {
        id
        name
      }
      isEditable
    }
  }
`;

export const CreateDataSourceApplication = gql`
  mutation CreateDataSourceApplication(
    $dataSourceApplicationDto: DataSourceApplicationInputDtoInput!
  ) {
    createDataSourceApplication(dataSourceApplicationInputDto: $dataSourceApplicationDto) {
      id
      name
    }
  }
`;

export const EditDataSourceApplication = gql`
  mutation EditDataSourceApplication(
    $dataSourceApplicationDto: DataSourceApplicationInputDtoInput!
  ) {
    editDataSourceApplication(dataSourceApplicationInputDto: $dataSourceApplicationDto) {
      id
      name
    }
  }
`;

export const CreateDataSourcePerspective = gql`
  mutation CreateDataSourcePerspective(
    $dataSourcePerspectiveDto: DataSourcePerspectiveInputDtoInput!
  ) {
    createDataSourcePerspective(dataSourcePerspectiveInputDto: $dataSourcePerspectiveDto) {
      id
      name
    }
  }
`;

export const EditDataSourcePerspective = gql`
  mutation EditDataSourcePerspective(
    $dataSourcePerspectiveDto: DataSourcePerspectiveInputDtoInput!
  ) {
    editDataSourcePerspective(dataSourcePerspectiveInputDto: $dataSourcePerspectiveDto) {
      id
      name
    }
  }
`;

export const GetDataSourcesList = gql`
  query DataSources(
    $filters: DataSourceFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [DataSourceDtoSortInput!]
    $keyword: String
  ) {
    dataSources(
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
      keyword: $keyword
    ) {
      edges {
        node {
          id
          name
          perspective {
            id
            name
          }
          application {
            id
            name
          }
          isEditable
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetDataSourceApplicationsList = gql`
  query dataSourceApplicationsList(
    $filters: DataSourceApplicationFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [DataSourceApplicationDtoSortInput!]
    $keyword: String
  ) {
    dataSourceApplicationsList(
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
      keyword: $keyword
    ) {
      edges {
        node {
          id
          name
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetDataSourcePerspectivesList = gql`
  query dataSourcePerspectivesList(
    $filters: DataSourcePerspectiveFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [DataSourcePerspectiveDtoSortInput!]
    $keyword: String
  ) {
    dataSourcePerspectivesList(
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
      keyword: $keyword
    ) {
      edges {
        node {
          id
          name
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const EditDataSource = gql`
  mutation EditDataSource($dataSourceDto: DataSourceInputDtoInput!) {
    editDataSource(dataSourceDto: $dataSourceDto) {
      id
      name
      perspective {
        id
        name
      }
      application {
        id
        name
      }
      isEditable
    }
  }
`;
