import { gql } from '@apollo/client';

const GET_APP_CONFIG_VALUES = gql`
  query GetAppConfigValues {
    appConfigValues {
      flags
      outageBannerMessage
      currentUiVersion
      clientAppFCWBaseUrl
      clientAppFCWChecklistFilename
      clientAppStatBaseUrl
      clientAppStatCardFilename
      clientAppStatIntegratedFilename
      clientAppRIBaseUrl
      clientAppRICardFilename
      clientAppRIIntegratedFilename
      clientAppJemBaseUrl
      clientAppJemIntegratedFilename
    }
  }
`;
export default GET_APP_CONFIG_VALUES;
