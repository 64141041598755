import React, { useContext } from 'react';
import { CoherenceNav } from '@coherence-design-system/controls';
import { INavLink } from '@m365-admin/nav';
import { useLocation, useHistory } from 'react-router-dom';
import navLinkGroups from './Navigation.config';
import UserContext from '../utils/authorization/UserContext';

interface INavigationProps {
  navIsHidden: boolean;
  setNavIsHidden: (navIsHidden: boolean) => void;
}

const Navigation = ({ navIsHidden, setNavIsHidden }: INavigationProps): JSX.Element => {
  const history = useHistory();

  const location = useLocation();
  const policies = useContext(UserContext);

  const handleLinkClick = (ev: React.MouseEvent<HTMLElement>, item: INavLink): void => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(item.url || item.href);
  };

  return (
    <CoherenceNav
      styles={{
        root: { position: 'relative', zIndex: 2, top: 0 },
        outerNavWrapper: { display: 'flex' },
        mobileNavWrapper: { position: 'absolute' },
      }}
      groups={navLinkGroups(handleLinkClick, location.pathname, policies)}
      appName="Finance Close & Compliance Workspace"
    />
  );
};

export default Navigation;
