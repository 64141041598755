import { ITag } from '@fluentui/react';
import React from 'react';
import Collapsible from './Collapsible';
import TaskTagPicker from './TaskTagPicker';
import { IChecklistImplementationProps } from './YesNoChecklist';

const TaskTagFilter = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey } = props;

  const onChange = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text="Tags">
      <TaskTagPicker
        pickerId="taskTags"
        label="Tags"
        defaultSelectedKey={initialValues?.values ?? []}
        onChange={onChange}
        enableNew={false}
      />
    </Collapsible>
  );
};

export default TaskTagFilter;
