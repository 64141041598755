import { gql } from '@apollo/client';

export const GetTaskTags = gql`
  query TaskTags($keyword: String) {
    taskTags(keyword: $keyword) {
      key
      name
    }
  }
`;

export default GetTaskTags;
