import { gql } from '@apollo/client';

const COMPANY_CODE_ASSIGNMENT_FULL_FRAGMENT = gql`
  fragment CompanyCodeAssignmentFull on CompanyDto {
    name
    companyCode
    countryCode
    isActive
    inGlobalChecklist
    inLocalChecklist
    owners {
      graphGuid
      displayName
      alias
      emailAddress
      isInvalidUser
    }
  }
`;
export const GetCompanyCodeList = gql`
  query CompanyCodeAssignments(
    $keyword: String
    $filters: CompanyCodeAssignmentFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [CompanyDtoSortInput!]
  ) {
    companyCodeAssignments(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...CompanyCodeAssignmentFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COMPANY_CODE_ASSIGNMENT_FULL_FRAGMENT}
`;

export const EditCompanyCodeValue = gql`
  mutation editCompanyCodeAssignmentValue(
    $companyCodeAssignmentDto: CompanyCodeAssignmentInputDtoInput!
  ) {
    editCompanyCodeAssignmentValue(companyCodeAssignmentDto: $companyCodeAssignmentDto) {
      ...CompanyCodeAssignmentFull
    }
  }
  ${COMPANY_CODE_ASSIGNMENT_FULL_FRAGMENT}
`;
export default GetCompanyCodeList;
