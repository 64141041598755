import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

const styles = mergeStyleSets({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 500,
  },
  centerTextDiv: { textAlign: 'center' },
});

const AuthErrorPage: React.FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.centerTextDiv}>
        <h1>Authentication Error</h1>
        <p>There was an error with the authentication process.</p>
      </div>
    </div>
  );
};

export default AuthErrorPage;
