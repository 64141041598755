import React from 'react';
import { Stack } from '@fluentui/react';

interface IStackColumnProps {
  minWidth?: string;
  maxWidth?: string;
  children: React.ReactNode;
}

const StackColumn = ({ children, minWidth, maxWidth }: IStackColumnProps): JSX.Element => {
  const stackColumnStyles = { root: { flexBasis: 0, minWidth: minWidth || '250px', maxWidth } };

  return (
    <Stack grow={1} tokens={{ childrenGap: 15 }} styles={stackColumnStyles}>
      {children}
    </Stack>
  );
};

export default StackColumn;
