import { Stack, TextField } from '@fluentui/react';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import FormGridCard from '../../../common/formFields/FormGridCard';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import { TaskType } from '../list/BaseTaskListfilters.config';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import PanelTypes from '../../../utils/types/PanelTypes';

interface IDraftTaskDetailNotesCardProps {
  form: UseFormMethods<IBaseTask>;
  taskType: TaskType;
  panelMode: PanelTypes;
}
const DraftTaskDetailNotesCard = (props: IDraftTaskDetailNotesCardProps): JSX.Element => {
  const { form, taskType, panelMode } = props;
  const { control, getValues } = form;
  // Could not figure out how to make the below types work
  // ran outta time ⏰
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { permanentNotes, publishedTaskNotes } = getValues() as any;

  const editablePanelTypes = [PanelTypes.Add, PanelTypes.Copy];

  return (
    <FormGridCard cardLabel="Notes">
      <>
        <Stack horizontal wrap styles={formGridRowStyles}>
          <FormGridColumn columnWidth={FormGridColumnWidth.Triple}>
            <Controller
              as={TextField}
              label="Permanent notes"
              id="permanentNotes"
              name="permanentNotes"
              multiline
              control={control}
              defaultValue={permanentNotes}
              value={permanentNotes}
              disabled={
                !(
                  taskType === TaskType.StagedTasks ||
                  (taskType === TaskType.PublishedTasks &&
                    editablePanelTypes.some((x) => x === panelMode))
                )
              }
            />
          </FormGridColumn>
          {taskType !== TaskType.StagedTasks && (
            <FormGridColumn columnWidth={FormGridColumnWidth.Triple}>
              <Controller
                as={TextField}
                label="Published task notes"
                id="publishedTaskNotes"
                name="publishedTaskNotes"
                multiline
                control={control}
                defaultValue={publishedTaskNotes}
                value={publishedTaskNotes}
              />
            </FormGridColumn>
          )}
        </Stack>
      </>
    </FormGridCard>
  );
};
export default DraftTaskDetailNotesCard;
