import { ISpinButtonProps, SpinButton } from '@fluentui/react';
import React from 'react';

export interface IEstimatedTimeSpinButtonProps extends ISpinButtonProps {
  handleChange: (event: React.FormEvent<HTMLDivElement>, newValue?: string) => void;
}
const EstimatedTimeSpinButton = (props: IEstimatedTimeSpinButtonProps): JSX.Element => {
  const { handleChange, disabled, value, id, label } = props;
  return (
    <SpinButton
      disabled={disabled}
      id={id}
      defaultValue={value}
      max={10000}
      min={0}
      ariaDescribedBy={`${id}Label`}
      aria-labelledby={`${id}Label`}
      decrementButtonAriaLabel={`Decrement ${label}`}
      incrementButtonAriaLabel={`Increment ${label}`}
      inputProps={{ 'aria-labelledby': `${id}Label` }}
      onChange={(event: React.FormEvent<HTMLDivElement>, newValue?: string): void => {
        handleChange(event, newValue);
      }}
    />
  );
};
export default EstimatedTimeSpinButton;
