import { IIconStyles, Label, TooltipHost, IconButton, Stack } from '@fluentui/react';
import React from 'react';

export interface ILabelTooltipProps {
  id: string;
  label: string;
  tooltipMessage: string;
  required?: boolean;
}
export const LabelTooltip = (props: ILabelTooltipProps): JSX.Element => {
  const { id, label, required, tooltipMessage } = props;
  const offsetDateIconStyles: Partial<IIconStyles> = {
    root: { marginLeft: 5 },
  };
  return (
    <>
      <Label id={`${id}Label`} required={required} htmlFor="timeTaken">
        {label}
      </Label>
      <TooltipHost content={tooltipMessage} id={id}>
        <IconButton
          aria-describedby={id}
          ariaLabel="Button for showing helpful information"
          iconProps={{ iconName: 'Info' }}
          styles={offsetDateIconStyles}
        />
      </TooltipHost>
    </>
  );
};

export const LabelTooltipHorizontal = (props: ILabelTooltipProps): JSX.Element => {
  const { id, label, required, tooltipMessage } = props;
  return (
    <Stack horizontal horizontalAlign="space-between">
      <LabelTooltip id={id} label={label} required={required} tooltipMessage={tooltipMessage} />
    </Stack>
  );
};
