import { useQuery } from '@apollo/client';
import { IDropdownOption } from '@fluentui/react';
import React, { useEffect } from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { ITaskPropertyValue } from '../../utils/types/ITaskProperty';
import { GetTaskPropertyByName } from '../../utils/api/TaskPropertiesApi';

export interface ITaskPropertyDropdownProps extends IFieldsetProps {
  taskPropertyName: string;
  firstAsDefault?: boolean;
}
export const mapValuesToOptions = (
  values: ITaskPropertyValue[],
  firstAsDefault?: boolean,
): IDropdownOption[] => {
  const onlyActive = (value: ITaskPropertyValue) => value.isActive;
  return values?.filter(onlyActive)?.map((value: ITaskPropertyValue, index): IDropdownOption => {
    return {
      key: value.id,
      text: value.name,
      selected: firstAsDefault && index === 0,
    };
  });
};

const TaskPropertyDropdown = (dropdownProps: ITaskPropertyDropdownProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    taskPropertyName,
    width,
    firstAsDefault,
    disabled,
    ariaLabel,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(GetTaskPropertyByName, {
    variables: {
      taskPropertyName,
    },
  });
  const { taskPropertyByName } = !dropdownLoading && dropdownData ? dropdownData : [];

  useEffect(() => {
    if (!value && firstAsDefault && taskPropertyByName) {
      handleChange({ key: taskPropertyByName?.values[0]?.id }, valueKey);
    }
  }, [taskPropertyByName]);

  return (
    <QueryBasedDropdown
      required={required}
      value={value}
      label={label}
      ariaLabel={ariaLabel}
      outputKey={outputKey}
      dropdownOptionsData={mapValuesToOptions(taskPropertyByName?.values, firstAsDefault) || []}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default TaskPropertyDropdown;
