import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import {
  bodyContentContainer,
  flexWrapper,
  pivotWrapperStyles,
} from '../../../common/styles/CommonStyleObjects';
import { HierarchiesPivotKeys } from './HierarchiesList.config';
import DataSourceListPage from './datasources/DataSourceListPage';
import DataSourceApplicationsListPage from './datasources/DataSourceApplicationsListPage';
import DataSourcePerspectivesListPage from './datasources/DataSourcePerspectivesListPage';

const HierarchiesListDataSourcesPage = () => {
  return (
    <div className={`${bodyContentContainer}`}>
      <Pivot style={flexWrapper} styles={pivotWrapperStyles} overflowBehavior="menu">
        <PivotItem
          style={flexWrapper}
          headerText="Applications"
          itemKey={HierarchiesPivotKeys.application}
        >
          <DataSourceApplicationsListPage />
        </PivotItem>
        <PivotItem
          style={flexWrapper}
          headerText="Perspectives"
          itemKey={HierarchiesPivotKeys.perspective}
        >
          <DataSourcePerspectivesListPage />
        </PivotItem>
        <PivotItem
          style={flexWrapper}
          headerText="Data Sources"
          itemKey={HierarchiesPivotKeys.dataSource}
        >
          <DataSourceListPage />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default HierarchiesListDataSourcesPage;
