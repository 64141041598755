import { useQuery } from '@apollo/client';
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { GetFiscalPeriods } from '../../utils/api/CalendarApi';
import { fiscalPeriodToMonth, getFiscalInfoFromDate } from '../../utils/formatters/DateFormatters';
import { ICalendarDate } from '../../utils/types/ICalendarDate';

export interface IFiscalPeriodDropdownOption extends IDropdownOption {
  fiscalPeriod: number;
  fiscalYear: number;
}

export const formatFiscalInfoString = (
  fiscalPeriod: number,
  fiscalYear: number,
  isTwoDigitYear = false,
): string => {
  const fiscalMonth = fiscalPeriodToMonth(fiscalPeriod);
  const fiscalYearString = isTwoDigitYear ? fiscalYear.toString().slice(2) : fiscalYear.toString();
  return `${fiscalMonth} (P${fiscalPeriod.toString()} FY${fiscalYearString})`;
};

export const mapValuesToOptions = (values: ICalendarDate[]): IFiscalPeriodDropdownOption[] => {
  const today = new Date();
  const fiscalInfo = getFiscalInfoFromDate(today);
  const currentFiscalPeriod = formatFiscalInfoString(
    fiscalInfo.fiscalPeriod,
    fiscalInfo.fiscalYear,
  );
  const dropdownOptions: IFiscalPeriodDropdownOption[] = [];
  dropdownOptions.push({
    key: 'CurrentPeriodDivider',
    text: 'CurrentPeriodDivider',
    fiscalPeriod: fiscalInfo.fiscalPeriod,
    fiscalYear: fiscalInfo.fiscalYear,
    itemType: DropdownMenuItemType.Divider,
  });
  values?.forEach((value, index) => {
    const optionFiscalPeriod = formatFiscalInfoString(value.fiscalPeriod, value.fiscalYear);
    let dropdownOptionText = optionFiscalPeriod;
    if (optionFiscalPeriod === currentFiscalPeriod) {
      dropdownOptionText = `${dropdownOptionText} - Current`;
    }
    dropdownOptions.push({
      key: optionFiscalPeriod,
      text: dropdownOptionText,
      fiscalPeriod: value.fiscalPeriod,
      fiscalYear: value.fiscalYear,
    });
  });
  return dropdownOptions;
};

const FiscalPeriodDropdown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    label,
    errors: dropdownErrors,
    handleChange,
    value,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
  } = dropdownProps;
  const { loading, data } = useQuery(GetFiscalPeriods);
  const { fiscalPeriods } = !loading && data ? data : [];

  return (
    <QueryBasedDropdown
      ariaLabel="Please select fiscal period"
      required={required}
      value={value}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={mapValuesToOptions(fiscalPeriods) || []}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default FiscalPeriodDropdown;
