import { IPersonaProps, ITag, mergeStyles } from '@fluentui/react';
import React from 'react';
import searchForUsers from '../../utils/api/GraphUserApi';
import IAadUser from '../../utils/types/IAadUser';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from './Collapsible';
import ErrorHandlingPeoplePicker from './ErrorHandlingPeoplePicker';
import { IChecklistImplementationProps } from './YesNoChecklist';

export interface IGraphUsersPeoplePicker extends IChecklistImplementationProps {
  controlName?: string;
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  itemLimit?: number;
  removeButtonAriaLabel?: string;
  placeholder?: string;
}

export const mapUsersNoTypename = (users: IAadUser[]): IAadUser[] => {
  return users?.map((user) => {
    return {
      graphGuid: user.graphGuid,
      displayName: user.displayName,
      alias: user.alias,
      emailAddress: user.emailAddress,
    };
  });
};

const GraphUsersFilter = ({
  onSelectedItems,
  label,
  controlName,
  itemLimit,
  removeButtonAriaLabel,
  placeholder,
  dataKey,
  initialValues,
}: IGraphUsersPeoplePicker): JSX.Element => {
  const PICKER_RESOLVE_DELAY = 450;

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText);
    }
    return [];
  };

  const getPersona = (users: ITag[]): IPersonaProps[] => {
    if (users) {
      const results = users.map((user) => {
        return {
          id: user.key.toString(),
          text: user.name,
          graphId: user.key,
          alias: '',
          emailAddress: '',
        };
      });

      return results;
    }
    return [];
  };
  /** We need this style because the people picker has an absolutely positioned div that overflows panels */
  const peoplePickerWrapperStyle = mergeStyles({ position: 'relative' });

  return (
    <Collapsible text={label}>
      <div role="form" className={peoplePickerWrapperStyle}>
        <ErrorHandlingPeoplePicker
          removeButtonAriaLabel={removeButtonAriaLabel}
          controlName={controlName}
          labelText={label}
          inputProps={{
            id: `graphUsersPickers${controlName}`,
            'aria-label': label || 'Value',
            placeholder: placeholder || 'Search users',
          }}
          onChange={(items?: IAadUser[]): void => {
            const results = items.map((item) => {
              return {
                key: item.id,
                name: item.text,
              };
            });
            onSelectedItems({ dataKey, title: label, values: results });
          }}
          selectedItems={getPersona(initialValues?.values)}
          onResolveSuggestions={onResolveSuggestions}
          resolveDelay={PICKER_RESOLVE_DELAY}
          itemLimit={itemLimit}
        />
      </div>
    </Collapsible>
  );
};

export default GraphUsersFilter;
