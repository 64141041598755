import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from '../formFields/Collapsible';
import UtcDatePicker from '../formFields/UTCDatePicker';

interface IDateRangePickerProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag[];
  dataKeys?: string[];
  wrapperLabel?: string | null;
  labels?: string[];
}
const DateRangePicker = (props: IDateRangePickerProps): JSX.Element => {
  const { dataKeys, wrapperLabel, labels, onSelectedItems, initialValues } = props;
  if (labels.length !== 2) {
    return <>Date Range Picker requires an array with two values, one for start and one for end</>;
  }
  if (dataKeys.length !== 2) {
    return (
      <>Date Range Picker requires an array with two data keys, one for start and one for end</>
    );
  }

  const startLabel = labels[0];
  const endLabel = labels[1];
  const startKey = dataKeys[0];
  const endKey = dataKeys[1];

  const fromDateObject = initialValues?.find((x) => x.dataKey === startKey);
  const toDateObject = initialValues?.find((x) => x.dataKey === endKey);
  const [selectedFromDate, setSelectedFromDate] = useState(
    fromDateObject?.values ? fromDateObject.values[0]?.name : null,
  );
  const [selectedToDate, setSelectedToDate] = useState(
    toDateObject?.values ? toDateObject.values[0]?.name : null,
  );
  const handleDateChange = (
    date: string,
    dataKey: string,
    title: string,
    setFunction: (value: React.SetStateAction<string>) => void,
  ) => {
    setFunction(date);
    onSelectedItems({ dataKey, title, values: [{ key: date, name: date }] });
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Collapsible text={wrapperLabel}>
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <UtcDatePicker
            label="Due date from"
            value={selectedFromDate}
            onChange={(date) => {
              handleDateChange(date, startKey, startLabel, setSelectedFromDate);
            }}
            allowTextInput
          />
          <UtcDatePicker
            label="Due date to"
            value={selectedToDate}
            onChange={(date) => {
              handleDateChange(date, endKey, endLabel, setSelectedToDate);
            }}
            allowTextInput
          />
        </Stack>
      </Collapsible>
    </Stack>
  );
};
export default DateRangePicker;
