import { Label, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react';
import { VIEWPORT_HEIGHT } from './constants/SiteConstants';
import { verticalCenterStack } from './styles/CommonStyleObjects';

const LoadingPageContent = (): JSX.Element => {
  return (
    <Stack
      className={verticalCenterStack}
      horizontalAlign="center"
      style={{ height: VIEWPORT_HEIGHT }}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Label>Loading...</Label>
        <Spinner size={SpinnerSize.large} />
      </Stack>
    </Stack>
  );
};

export default LoadingPageContent;
