import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import {
  IStackTokens,
  mergeStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
} from '@fluentui/react';
import React, { useRef, useState } from 'react';
import Collapsible from '../../../common/formFields/Collapsible';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import { restApiDocumentUploadRequest } from '../../../utils/api/ApiProvider';

export enum ImportTaskType {
  StagedTasks = 'stagedtasks',
  PublishedTasks = 'publishedtasks',
}
interface ITasksImportPanelProps {
  closePanel: () => void;
  importTaskType: string;
}

interface ValidationResult {
  propertyName?: string;
  errorMessage?: string;
  invalidValues?: [];
}

const TasksImportPanel = (props: ITasksImportPanelProps): JSX.Element => {
  const { closePanel, importTaskType } = props;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [pageState, setPageState] = useState({ loading: false, importResult: null });
  const showFileDialog = (): void => inputFileRef.current && inputFileRef.current.click();

  const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = event.target;
    const formData = new FormData();
    const fileToUpload = files[0];

    if (fileToUpload.name.match(/[~#%&={}+'|]|\\.\\.|^\\.|\\.$/)) {
      setPageState({
        loading: false,
        importResult: {
          data: '',
          errors: [
            {
              propertyName: 'File name',
              errorMessage:
                "File name cannot contain any of the following characters: ' = ~ # % & { } + | . .. ^ . .",
            },
          ],
        },
      });

      return;
    }

    formData.append('formFile', fileToUpload);

    setPageState({ loading: true, importResult: null });
    const endpoint = `dataimport/import${importTaskType}`;
    restApiDocumentUploadRequest(endpoint, formData, true).then((response) => {
      inputFileRef.current.value = null;
      if (response === null || response === undefined) {
        setPageState({
          loading: false,
          importResult: {
            data: '',
            errors: [
              {
                propertyName: 'Server Error',
                errorMessage:
                  'The server encountered an error while processing the request, or was blocked by a security firewall.',
              },
            ],
          },
        });
        return;
      }
      setPageState({ loading: false, importResult: response });
    });
  };

  const fileInputStyle = mergeStyles({ display: 'none' });

  const stackToken: IStackTokens = {
    childrenGap: 2,
  };

  const { loading, importResult } = pageState;

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText="Import tasks"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close import tasks"
    >
      <Stack tokens={stackToken}>
        <input
          className={fileInputStyle}
          ref={inputFileRef}
          type="file"
          placeholder="Import tasks"
          onChange={onFileInputChange}
        />
        <MessageBar messageBarType={MessageBarType.info}>
          <Stack tokens={{ childrenGap: 10 }}>
            <p>
              Upload a XLSX spreadsheet to import tasks. Imports are limited to no more than 2,000
              rows at a time to prevent performance issues. Files with sensitivity labels are not
              supported and will fail to import. Remove the labels before importing.
            </p>
            <p>
              Keep the import panel open until the import is complete. When processing is finished a
              status message will indicate the result.
            </p>
          </Stack>
        </MessageBar>
        <PrimaryButton onClick={(): void => showFileDialog()} disabled={loading}>
          Click to import spreadsheet &nbsp; {loading && <Spinner />}
        </PrimaryButton>
        {importResult !== null && importResult?.data !== '' && (
          <MessageBar messageBarType={MessageBarType.success}>
            <Stack tokens={{ childrenGap: 10 }}>{importResult?.data}</Stack>
          </MessageBar>
        )}
        {importResult !== null && importResult?.errors.length !== 0 && (
          <MessageBar messageBarType={MessageBarType.error}>
            <Stack tokens={{ childrenGap: 10 }}>
              Validation errors need to be resolved before this file can be imported. Expand the
              items below to view errors.
            </Stack>
          </MessageBar>
        )}
        {importResult?.errors !== null &&
          importResult?.errors?.map((error: ValidationResult) => (
            <Collapsible key={error?.propertyName} text={error?.propertyName}>
              <p>{error?.errorMessage}</p>
              {error?.invalidValues !== null && (
                <ul>
                  {error?.invalidValues?.map((invalidValue: string) => (
                    <li key={invalidValue}>{invalidValue}</li>
                  ))}
                </ul>
              )}
            </Collapsible>
          ))}
      </Stack>
    </CoherencePanel>
  );
};
export default TasksImportPanel;
