import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import React from 'react';
import { getCurrentAppVersion } from '../../utils/api/ApiProvider';
import { AppConfigValueConsumer } from '../../utils/appConfigValues/AppConfigValuesContext';

const OutdatedUiBanner = (): JSX.Element => {
  const currentAppVersion = React.useMemo(() => {
    return getCurrentAppVersion();
  }, []);

  const refreshPage = (): void => {
    window.location.reload();
  };

  return (
    <AppConfigValueConsumer>
      {({ currentUiVersion }) => (
        <>
          {currentAppVersion !== currentUiVersion &&
            currentUiVersion?.length > 0 &&
            currentAppVersion !== 'DevBuild' && (
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                actions={
                  <div>
                    <MessageBarButton onClick={refreshPage}>Refresh</MessageBarButton>
                  </div>
                }
              >
                You are using an outdated version of this web page. Click the refresh button to
                reload the page.
              </MessageBar>
            )}
        </>
      )}
    </AppConfigValueConsumer>
  );
};

export default OutdatedUiBanner;
