import { useQuery } from '@apollo/client';
import moment from 'moment';
import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import { GetFiscalYears } from '../../utils/api/CalendarApi';
import IFieldsetProps from '../../utils/types/IFieldsetProps';

const FiscalYearDropDown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
  } = dropdownProps;
  const { loading, data } = useQuery(GetFiscalYears);
  const { fiscalYears } = !loading && data ? data : [];

  const currentYearPlusOne = moment().utc().year() + 1;

  const options = fiscalYears?.map((fiscalYear: IDropdownOption) => {
    return {
      key: fiscalYear.key.toString(),
      text: fiscalYear.text.toString(),
    };
  });

  if (
    options?.find((option: IDropdownOption) => option.key === currentYearPlusOne.toString()) ===
    undefined
  ) {
    options?.push({
      key: currentYearPlusOne.toString(),
      text: currentYearPlusOne.toString(),
    });
  }

  return (
    <QueryBasedDropdown
      ariaLabel="Please select fiscal year"
      required={required}
      value={value}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={options || []}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default FiscalYearDropDown;
