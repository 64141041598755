import { useQuery } from '@apollo/client';
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { GetDataSourceApplicationsByKeyword } from '../../utils/api/DataSourcesApi';
import IDataSourceApplication from '../../utils/types/IDataSourceApplication';

export interface IDataSourceApplicationDropdownReference extends IDropdownOption {
  dataSourceApplication: IDataSourceApplication;
}

export const mapEntityToDropdown = (DataSourceApplications: IDataSourceApplication[]) => {
  let applications = [];
  applications = DataSourceApplications?.map(
    (application: IDataSourceApplication): IDataSourceApplicationDropdownReference => {
      return {
        key: application.id,
        text: application.name,
        dataSourceApplication: application,
      };
    },

    applications.unshift({
      key: 'FilterHeader',
      itemType: DropdownMenuItemType.Header,
      text: '',
    }),
  );
  return applications;
};

const DataSourceApplicationDropdown = (dropdownProps: IFieldsetProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(
    GetDataSourceApplicationsByKeyword,
    {
      fetchPolicy: 'network-only',
      variables: {
        order: [{ name: 'ASC' }],
      },
    },
  );
  const { dataSourceApplications } = !dropdownLoading && dropdownData ? dropdownData : [];

  return (
    <QueryBasedDropdown
      required={required}
      value={value}
      label={label}
      ariaLabel={label}
      outputKey={outputKey}
      dropdownOptionsData={mapEntityToDropdown(dataSourceApplications)}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default DataSourceApplicationDropdown;
