import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { TaskType } from '../../components/tasks/list/BaseTaskListfilters.config';

const publishTypeOptions: IDropdownOption[] = [
  { key: 'SINGLE', text: 'Single' },
  { key: 'GLOBAL', text: 'Global' },
  { key: 'MULTI_CC', text: 'Multi-CC' },
];

interface IPublishTypeDropdownProps extends IFieldsetProps {
  taskType?: TaskType;
}

const PublishTypeDropdown = (props: IPublishTypeDropdownProps): JSX.Element => {
  const { label, required, disabled, value, handleChange, valueKey, errorMessage, taskType } =
    props;

  const options =
    taskType === TaskType.StagedTasks
      ? [...publishTypeOptions, { key: 'JEM_CHECKLIST', text: 'JEM-Checklist' }]
      : publishTypeOptions;

  return (
    <Dropdown
      errorMessage={errorMessage}
      required={required}
      label={label}
      ariaLabel={label}
      options={options}
      disabled={disabled}
      selectedKey={value}
      placeholder="Select publish type"
      onChange={(event, item): void => {
        handleChange(item, valueKey);
      }}
    />
  );
};

export default PublishTypeDropdown;
