import { deepCopy } from '../Helpers';
import { IGenericFilterTag } from '../types/IGenericFilterTag';

const addFiltersForPeriodAndYear = (
  filters: IGenericFilterTag[],
  fiscalYear: string,
  period: string,
): IGenericFilterTag[] => {
  const filteredParams = deepCopy(filters);

  const fiscalYearIndex = filteredParams.findIndex((param) => param.dataKey === 'fiscalYear');
  const periodIndex = filteredParams.findIndex((param) => param.dataKey === 'period');
  if (fiscalYearIndex !== -1) {
    filteredParams[fiscalYearIndex].values = [
      { name: 'fiscalYear', key: parseInt(fiscalYear, 10) },
    ];
  } else {
    filteredParams.push({
      dataKey: 'fiscalYear',
      title: 'fiscalYear',
      hideFromBar: true,
      values: [{ name: 'fiscalYear', key: parseInt(fiscalYear, 10) }],
    });
  }
  if (periodIndex !== -1) {
    filteredParams[periodIndex].values = [{ name: 'period', key: parseInt(period, 10) }];
  } else {
    filteredParams.push({
      dataKey: 'period',
      title: 'period',
      hideFromBar: true,
      values: [{ name: 'period', key: parseInt(period, 10) }],
    });
  }
  return filteredParams;
};

export default addFiltersForPeriodAndYear;
