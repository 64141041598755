import {
  IProcessedStyleSet,
  mergeStyleSets,
  FontWeights,
  IStyle,
  FontSizes,
  IChoiceGroupOptionStyles,
  IChoiceGroupStyles,
} from '@fluentui/react';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../constants/SiteConstants';

interface IPageHeaderFullWidthStyles {
  pageHeaderWrapper: IStyle;
  pageSubheaderWrapper: IStyle;
  pageHeaderTitle: IStyle;
  pageHeaderSubtitle: IStyle;
}
export const pageHeaderFullWidth = (): IProcessedStyleSet<IPageHeaderFullWidthStyles> => {
  return mergeStyleSets({
    pageHeaderWrapper: {
      padding: '25px 40px 10px 40px',
      selectors: {
        [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
          padding: 0,
          margin: '5px 20px 0 20px',
        },
      },
    },
    pageHeaderSubtitle: {
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.regular,
    },

    pageHeaderTitle: {
      selectors: {
        h1: {
          margin: 0,
          fontSize: FontSizes.size28,
          fontWeight: FontWeights.semibold,
          selectors: {
            [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
              fontSize: FontSizes.size16,
              padding: '5px 0',
            },
          },
        },
      },
    },
    pageSubheaderWrapper: {
      marginTop: '10px',
    },
  });
};

export const headerChoiceGroupOptionStyles: IChoiceGroupOptionStyles = {
  root: {
    margin: '0 0 0 20px',
  },
};
export const headerChoiceGroupStyles: IChoiceGroupStyles = {
  root: {
    padding: '0 30px',
    display: 'flex !important',
    alignItems: 'center',
  },
};
