import React from 'react';
import { IColumn, ICommandBarItemProps, IContextualMenuItem } from '@fluentui/react';
import { sharedEditButton, sharedLastModified } from '../../../common/lists/SharedDataGridColumns';
import { rowClasses } from '../../../common/styles/CommonStyleObjects';
import { fiscalPeriodToMonth } from '../../../utils/formatters/DateFormatters';
import { IFiscalCalendar, ICalendarDate } from '../../../utils/types/ICalendarDate';
import DownloadButton, { IDownloadButtonProps } from '../../buttons/DownloadButton';

export const getCalendarDateColumnList = (): IColumn[] => {
  return [
    {
      key: 'fiscalYear',
      name: 'Fiscal year',
      fieldName: 'fiscalYear',
      maxWidth: 110,
      minWidth: 110,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'fiscalPeriod',
      name: 'Period',
      fieldName: 'fiscalPeriod',
      maxWidth: 80,
      minWidth: 80,
      className: rowClasses.medFont,
      isResizable: true,
      onRender: (item: ICalendarDate) => {
        return `${fiscalPeriodToMonth(item.fiscalPeriod)} (P${item.fiscalPeriod})`;
      },
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'taskDateCode',
      name: 'Task date code',
      fieldName: 'taskDateCode',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'assignedDate',
      name: 'Assigned date',
      fieldName: 'assignedDate',
      maxWidth: 135,
      minWidth: 135,
      className: rowClasses.medFont,
      isResizable: true,
    },
    ...sharedLastModified<{ [key: string]: Date }>('modifiedOn', 'modifiedBy.displayName'),
  ];
};
export const getVariableDateColumnList = (
  onEditClick: (item: IFiscalCalendar) => void,
  canEditVariableDates?: boolean,
): IColumn[] => {
  const columns: IColumn[] = [];

  if (canEditVariableDates) {
    columns.push(sharedEditButton<IFiscalCalendar>(onEditClick, false, 'fiscalPeriod'));
  }

  columns.push(
    {
      key: 'taskDateCode',
      name: 'Task date code',
      fieldName: 'taskDateCode',
      maxWidth: 150,
      minWidth: 150,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'fiscalYear',
      name: 'Fiscal year',
      fieldName: 'fiscalYear',
      maxWidth: 110,
      minWidth: 110,
      className: rowClasses.medFont,
      isResizable: true,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      maxWidth: 200,
      minWidth: 200,
      className: rowClasses.medFont,
      isResizable: true,
    },
  );

  return columns;
};
export enum CalendarPivotKeys {
  fiscalCalendar = 'fiscalCalendar',
  variableDateManagement = 'variableDateManagement',
}
export const getFiscalCalendarListMenu = (
  onRefreshClick: () => void,
  exportButtonProps: IDownloadButtonProps,
) => {
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefreshClick,
    },
    {
      key: 'exportToExcel',
      text: 'Export to Excel',
      cacheKey: 'export', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => (
        <DownloadButton
          text="Export to Excel"
          renderedInOverflow={item.renderedInOverflow}
          {...exportButtonProps}
        />
      ),
      fileEndpoint: exportButtonProps.fileEndpoint,
      fileName: exportButtonProps.fileName,
      postBody: exportButtonProps.postBody,
    },
  ];
  return listCommandBarItems;
};

export const getCalendarListMenu = (onAddItem: () => void, onRefetch: () => void) => {
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New Item',
      cacheKey: 'newItem', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      onClick: onAddItem,
    },
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefetch,
    },
  ];
  return listCommandBarItems;
};
