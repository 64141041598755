import React, { useState } from 'react';
import { MaskedTextField, Stack } from '@fluentui/react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from '../formFields/Collapsible';

interface ITimeRangePickerProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag[];
  dataKeys?: string[];
  wrapperLabel?: string | null;
  labels?: string[];
}

const TimeRangePicker = (props: ITimeRangePickerProps): JSX.Element => {
  const { dataKeys, wrapperLabel, labels, onSelectedItems, initialValues } = props;

  if (labels.length !== 2) {
    return (
      <>Time Range Picker requires an array with two label values, one for start and one for end</>
    );
  }
  if (dataKeys.length !== 2) {
    return (
      <>Time Range Picker requires an array with two data keys, one for start and one for end</>
    );
  }

  const startLabel = labels[0];
  const endLabel = labels[1];
  const startKey = dataKeys[0];
  const endKey = dataKeys[1];

  const fromTimeObject = initialValues?.find((x) => x.dataKey === startKey);
  const toTimeObject = initialValues?.find((x) => x.dataKey === endKey);
  const [selectedFromTime, setSelectedFromTime] = useState(
    fromTimeObject?.values ? fromTimeObject?.values[0]?.name : '00:00',
  );
  const [selectedToTime, setSelectedToTime] = useState(
    toTimeObject?.values ? toTimeObject?.values[0]?.name : '23:59',
  );

  const validTimeRegex = /^([0-1]?\d|2[0-3]):[0-5]\d$/;

  const handleTimeChange = (
    time: string,
    dataKey: string,
    title: string,
    setFunction: (value: React.SetStateAction<string>) => void,
  ) => {
    if (validTimeRegex.test(time)) {
      setFunction(time);
      onSelectedItems({ dataKey, title, values: [{ key: time, name: time }] });
    }
  };

  const getErrorMessage = (value: string): string => {
    return validTimeRegex.test(value) ? '' : 'Input value must be a valid time';
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Collapsible text={wrapperLabel}>
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <MaskedTextField
            value={selectedFromTime}
            label={startLabel}
            mask="99:99"
            suffix="PST HH:MM"
            onChange={(_, newTime) => {
              handleTimeChange(newTime, startKey, startLabel, setSelectedFromTime);
            }}
            onGetErrorMessage={getErrorMessage}
            validateOnFocusOut
          />

          <MaskedTextField
            value={selectedToTime}
            label={endLabel}
            mask="99:99"
            suffix="PST HH:MM"
            onChange={(_, newTime) => {
              handleTimeChange(newTime, endKey, endLabel, setSelectedToTime);
            }}
            onGetErrorMessage={getErrorMessage}
            validateOnFocusOut
          />
        </Stack>
      </Collapsible>
    </Stack>
  );
};

export default TimeRangePicker;
