import { getTheme } from '@fluentui/react';
import React, { CSSProperties } from 'react';

interface IInlineFormInputErrorMessageProps {
  errorMessage?: string;
  errorWrapperStyle?: CSSProperties;
}

const errorStyles = {
  color: getTheme().semanticColors.errorIcon,
  fontWeight: 400,
  fontSize: '12px',
  margin: '5px 0 0 0',
};

const InlineFormInputErrorMessage = ({
  errorMessage,
  errorWrapperStyle,
}: IInlineFormInputErrorMessageProps): JSX.Element => {
  return (
    <>
      {errorMessage && (
        <div role="alert" style={errorWrapperStyle}>
          <p style={errorStyles}>
            <span>{errorMessage}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default InlineFormInputErrorMessage;
