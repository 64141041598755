import { IDropdownOption } from '@fluentui/react';
import { RepeatValues } from '../../../utils/types/IFrequencyChoices';

const closeDaySpread = 21;
const calendarMax = 31;
const weeklyMax = 5;
const futureMonthsMax = 20;
const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const repeatChoices: IDropdownOption[] = Object.entries(RepeatValues).map(([key, value]) => ({
  key,
  text: value,
}));

const closeDayChoices: IDropdownOption[] = [];
for (let i = -closeDaySpread; i <= closeDaySpread; i += 1) {
  closeDayChoices.push({ key: i, text: i.toString() });
}

const calendarDayChoices: IDropdownOption[] = [];
for (let i = 1; i <= calendarMax; i += 1) {
  calendarDayChoices.push({ key: i, text: i.toString() });
}

const ordinalSuffixOf = (i: number) => {
  if (i === 1) {
    return `${i}st`;
  }
  if (i === 2) {
    return `${i}nd`;
  }
  if (i === 3) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const variableDayChoices: IDropdownOption[] = [];
for (let i = 1; i <= weeklyMax; i += 1) {
  variableDayChoices.push({ key: i, text: ordinalSuffixOf(i) });
}

const variableWeekdayChoices: IDropdownOption[] = daysOfWeek.map((day) => ({
  key: day,
  text: day,
}));

const weeklyCadenceChoices: IDropdownOption[] = [];
for (let i = 1; i <= weeklyMax; i += 1) {
  weeklyCadenceChoices.push({ key: i, text: `${i} week${i > 1 ? 's' : ''}` });
}

const startPeriodChoices: IDropdownOption[] = [];
let dateIterator = new Date();
dateIterator.setDate(15);
let fiscalDate = new Date();
fiscalDate.setDate(15);
fiscalDate.setMonth(dateIterator.getMonth() + 6);
for (let i = 1; i <= futureMonthsMax; i += 1) {
  const monthNameShort = dateIterator.toLocaleString('en-US', { month: 'short' });
  startPeriodChoices.push({
    key: `${fiscalDate.getMonth() + 1}:${fiscalDate.getFullYear().toString()}`,
    text: `${monthNameShort} (P${fiscalDate.getMonth() + 1} FY${fiscalDate
      .getFullYear()
      .toString()
      .substring(2)})`,
  });
  dateIterator = new Date(dateIterator.setMonth(dateIterator.getMonth() + 1));
  fiscalDate = new Date(fiscalDate.setMonth(fiscalDate.getMonth() + 1));
}

export {
  repeatChoices,
  closeDayChoices,
  calendarDayChoices,
  variableDayChoices,
  variableWeekdayChoices,
  daysOfWeek,
  weeklyCadenceChoices,
  startPeriodChoices,
};
