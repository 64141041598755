import { DocumentNode } from '@apollo/client';
import { ITag } from '@fluentui/react';
import React from 'react';
import { INamable } from '../../utils/types/IHierarchy';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';
import { IChecklistImplementationProps } from './YesNoChecklist';

interface IPaginatedChecklistProps extends IChecklistImplementationProps {
  query?: DocumentNode;
  queryKey?: string;
}

const TaskIdNameChecklist = (props: IPaginatedChecklistProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, query, queryKey, disabledValues } = props;

  const mapToTag = (item: { node: INamable }): ITag => {
    return { key: item.node.id, name: `${item.node.id.toString()} - ${item.node.name}` };
  };

  const mapData = (data: { [x: string]: { edges: { node: INamable }[] } }): ITag[] => {
    if (!data) {
      return [];
    }
    const propertyName = queryKey ?? dataKey;

    return data[propertyName].edges?.map((os: { node: INamable }) => {
      return mapToTag(os);
    });
  };
  const onCheckChanged = (items: ITag[]): void => {
    const valueMap = items.map((item: ITag): ITag => {
      return { key: item.key, name: item.key.toString() };
    });

    onSelectedItems({ dataKey, title: label, values: valueMap });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        query={query}
        mapData={mapData}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default TaskIdNameChecklist;
