import { useQuery } from '@apollo/client';
import React from 'react';
import { ThemeProvider } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { Route, Switch } from 'react-router-dom';
import { IDashboardFilters } from 'fcw-dashboard-components';
import Navigation from './common/Navigation';
import {
  applicationWrapper,
  mainContentContainer,
  mainContentContainerExtended,
} from './common/styles/CommonStyleObjects';
import FeatureFlagged from './components/featureFlags/FeatureFlagged';
import HierarchiesRoutes from './components/hierarchies/HierarchiesRoutes';
import Home from './components/home/Home';
import GET_APP_CONFIG_VALUES from './utils/api/FeatureFlagApi';
import { AppConfigValueProvider } from './utils/appConfigValues/AppConfigValuesContext';
import AppConfigValues from './utils/appConfigValues/AppConfigValues';
import StagedTasksRoutes from './components/tasks/StagedTasksRoutes';
import PublishedTaskRoutes from './components/tasks/PublishedTaskRoutes';
import DraftTaskRoutes from './components/tasks/DraftTaskRoutes';
import CalendarListPage from './components/calendar/list/CalendarListPage';
import UserAccountPage from './components/users/UserAccountPage';
import OutageBanner from './common/headers/OutageBanner';
import OutdatedUiBanner from './common/headers/OutdatedUiBanner';
import Page from './common/page/Page';
import SavedFiltersManagementPage from './components/savedFilters/SavedFiltersManagementPage';
import OpsOrgDetailPage from './components/hierarchies/details/operations/OpsOrgDetailPage';
import InactiveUsers from './components/tools/InactiveUsers';
import StatIntegratedExperience from './components/clientApps/stat/StatIntegratedExperience';
import JemIntegratedExperienceWrapper from './components/clientApps/jem/JemIntegratedExperienceWrapper';
import RIIntegratedExperience from './components/clientApps/reconIntelligence/RIIntegratedExperience';
import GsrAtAGlanceCards from './components/clientApps/gsr/GsrAtAGlanceCard';

// Added this comment to Force CodeQL pipeline
const App = (): JSX.Element => {
  const navIsHidden = true;

  const { data: appConfigValueData } = useQuery(GET_APP_CONFIG_VALUES, {
    fetchPolicy: 'network-only',
    pollInterval: 300000, // 5 minutes
  });

  const coherenceThemeOverrides = CoherenceTheme;
  const globalTagPickerStyles = {
    itemsWrapper: {
      '.ms-TagItem': {
        height: 'auto',
      },
      '.ms-TagItem-text': {
        overflow: 'inherit',
        whiteSpace: 'wrap',
      },
    },
  };

  const globalDropdownStyles = {
    caretDownWrapper: {
      '.ms-Dropdown-caretDown': {
        color: '#5F5E5C',
      },
    },
  };
  const globalPeoplePickerStyles = {
    root: {
      '.ms-Persona': {
        height: 'auto',
      },
      '.ms-Persona-primaryText': {
        overflow: 'inherit',
        whiteSpace: 'wrap',
      },
    },
  };
  coherenceThemeOverrides.components = {
    TagPicker: {
      styles: globalTagPickerStyles,
    },
    NormalPeoplePicker: {
      styles: globalPeoplePickerStyles,
    },
    Dropdown: {
      styles: globalDropdownStyles,
    },
  };

  const appConfigValues: AppConfigValues = new AppConfigValues(appConfigValueData?.appConfigValues);
  return (
    <AppConfigValueProvider value={appConfigValues}>
      <ThemeProvider theme={coherenceThemeOverrides}>
        <div className={applicationWrapper}>
          <Navigation navIsHidden={navIsHidden} setNavIsHidden={() => {}} />
          <main
            tabIndex={-1}
            className={navIsHidden ? mainContentContainer : mainContentContainerExtended}
          >
            <OutageBanner />
            <OutdatedUiBanner />
            <Switch>
              <Route path="/stagedTasks" component={StagedTasksRoutes} />
              <Route path="/tasks" component={PublishedTaskRoutes} />
              <Route path="/draftTasks" component={DraftTaskRoutes} />
              <Page
                path="/hierarchies/operations/details/:hierarchyViewId"
                component={OpsOrgDetailPage}
                title="Task Detail"
              />
              <Page
                path="/hierarchies/:hierarchyViewId"
                component={HierarchiesRoutes}
                title="Hierarchies"
              />
              <Page
                path="/fiscalCalendar"
                component={CalendarListPage}
                title="Fiscal Calendar List"
              />

              <Route path="/stat" render={() => <StatIntegratedExperience />} />
              <Route
                path="/jem/dashboard"
                render={() => (
                  <FeatureFlagged flagName="JemIntegration">
                    <JemIntegratedExperienceWrapper />
                  </FeatureFlagged>
                )}
              />
              <Route
                path="/ri"
                render={() => (
                  <FeatureFlagged flagName="RIIntegration">
                    <RIIntegratedExperience />
                  </FeatureFlagged>
                )}
              />
              <Route
                path="/clientCardSmoketest"
                render={() => (
                  <GsrAtAGlanceCards
                    filters={
                      {
                        dueDateRangeEnd: '2024-03-01',
                        dueDateRangeStart: '2024-03-31',
                        filterByPeriod: true,
                        fiscalPeriod: 9,
                        fiscalYear: 2024,
                        forMeOnly: false,
                      } as IDashboardFilters
                    }
                  />
                )}
              />
              <Page
                path="/savedfilters"
                component={SavedFiltersManagementPage}
                title="Saved Filters"
              />
              <Page path="/useraccount" component={UserAccountPage} title="User Account Page" />
              <Page
                path="/inactiveusers"
                component={InactiveUsers}
                title="Inactive Users"
                requestedPermission="ReplaceUser"
              />
              <Page exact path="/" component={Home} title="Dashboard | Home" />
            </Switch>
          </main>
        </div>
      </ThemeProvider>
    </AppConfigValueProvider>
  );
};

export default App;
