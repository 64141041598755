import { Stack } from '@fluentui/react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FullWidthHeader from '../../common/headers/FullWidthHeader';
import { IHierarchiesRouteParams } from '../../utils/types/IHierarchy';
import CompanyCodeAssignmentListPage from './list/companycodeassignment/CompanyCodeAssignmentListPage';
import HierarchiesOperationsListPage from './list/HierarchiesListOperationsPage';
import HierarchiesListViewDropdown from './list/HierarchiesListViewDropdown';
import TaskPropertyListPage from './list/TaskPropertyListPage';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../../common/constants/SiteConstants';
import HierarchiesListDataSourcesPage from './list/HierarchiesListDataSourcesPage';

const HierarchiesRoutes = (): JSX.Element => {
  const { hierarchyViewId } = useParams<IHierarchiesRouteParams>();
  const history = useHistory();
  const renderHeader = (): JSX.Element => {
    return (
      <Stack
        horizontal
        verticalAlign="center"
        styles={{
          root: {
            [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
              flexDirection: 'column',
              alignItems: 'start',
            },
          },
        }}
      >
        <h1>Hierarchies</h1>
        <HierarchiesListViewDropdown
          activeViewKey={hierarchyViewId}
          onUpdateViewClick={(newViewKey: string) => {
            history.push(`/hierarchies/${newViewKey}`);
          }}
        />
      </Stack>
    );
  };
  const renderDimensionListPage = (): JSX.Element => {
    switch (hierarchyViewId) {
      case 'operations':
        return <HierarchiesOperationsListPage />;
      case 'companycodeassignment':
        return <CompanyCodeAssignmentListPage />;
      case 'datasource':
        return <HierarchiesListDataSourcesPage />;
      default:
        return <TaskPropertyListPage />;
    }
  };
  return (
    <>
      <FullWidthHeader title={renderHeader} />
      {renderDimensionListPage()}
    </>
  );
};

export default HierarchiesRoutes;
