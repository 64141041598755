import React from 'react';
import DateRangePicker from '../../../common/dateRangePicker/DateRangerPicker';
import FiscalPeriodRangePicker from '../../../common/dateRangePicker/FiscalPeriodRangePicker';
import CompanyChecklist from '../../../common/formFields/CompanyChecklist';
import GraphUsersFilter from '../../../common/formFields/GraphUsersFilter';
import InvalidUsersChecklist from '../../../common/formFields/InvalidUsersChecklist';
import PublishTypeChecklist from '../../../common/formFields/PublishTypeChecklist';
import TaskIdNameChecklist from '../../../common/formFields/TaskIdNameChecklist';
import TaskPropertyValuesChecklist from '../../../common/formFields/TaskPropertyValuesChecklist';
import {
  GetOpsOrgDetailListSearch,
  GetOpsOrgListSearch,
  GetOpsSummaryListSearch,
} from '../../../utils/api/HierarchiesApi';
import { GetPublishedTasksSearch, GetStagedTasksSearch } from '../../../utils/api/StagedTaskApi';
import getCurrentFilterValues from '../../../utils/formatters/FilterConverter';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import CollapsibleGenericChecklist from '../../../common/formFields/CollapsibleGenericChecklist';
import SalesLocationGroupedChecklistFilter from '../../../common/formFields/SalesLocationGroupedChecklistFilter';

interface ITaskFilterOptions {
  onFilterUpdate: (filterValues: IGenericFilterTag) => void;
  onFiltersUpdate?: (filterValues: IGenericFilterTag[]) => void;
  getFilterByKey?: (filterKey: string) => IGenericFilterTag;
  currentFilterValues?: IGenericFilterTag[];
}

const TaskFilterOptions = (props: ITaskFilterOptions): JSX.Element => {
  const { onFilterUpdate, onFiltersUpdate, getFilterByKey, currentFilterValues } = props;

  return (
    <>
      <TaskIdNameChecklist
        dataKey="tasks"
        label="Task Id or name"
        query={GetPublishedTasksSearch}
        queryKey="publishedTasks"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('tasks', currentFilterValues)}
      />
      <TaskIdNameChecklist
        dataKey="stagedTasks"
        label="Staged task Id or name"
        query={GetStagedTasksSearch}
        queryKey="stagedTasks"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('stagedTasks', currentFilterValues)}
      />
      <CollapsibleGenericChecklist
        dataKey="opsSummaries"
        label="Ops summaries"
        query={GetOpsSummaryListSearch}
        queryKey="opsSummaryNames"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('opsSummaries', currentFilterValues)}
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgs"
        label="Ops org"
        query={GetOpsOrgListSearch}
        queryKey="opsOrgNames"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('opsOrgs', currentFilterValues)}
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgDetails"
        label="Ops org detail"
        query={GetOpsOrgDetailListSearch}
        queryKey="opsDetailNames"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('opsOrgDetails', currentFilterValues)}
      />
      <CompanyChecklist
        dataKey="companies"
        label="Co. code"
        isActive={false}
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('companies', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="levels"
        label="Task level"
        taskPropertyName="Task level"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('levels', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="types"
        label="Task type"
        taskPropertyName="Task type"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('types', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="scopeOfActivities"
        label="Scope of activity"
        taskPropertyName="Scope of activity"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('scopeOfActivities', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="suppliers"
        label="Supplier"
        taskPropertyName="Supplier"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('suppliers', currentFilterValues)}
      />
      <GraphUsersFilter
        dataKey="primaries"
        label="Primary"
        removeButtonAriaLabel="Remove primary"
        controlName="primaries"
        placeholder="Type one or more Primaries"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('primaries', currentFilterValues)}
      />
      <GraphUsersFilter
        dataKey="backups"
        label="Backup"
        removeButtonAriaLabel="Remove backup"
        controlName="backups"
        placeholder="Type one or more Backups"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('backups', currentFilterValues)}
      />
      <GraphUsersFilter
        dataKey="stakeholders"
        label="Stakeholder"
        removeButtonAriaLabel="Remove stakeholder"
        controlName="stakeholders"
        placeholder="Type one or more Stakeholders"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('stakeholders', currentFilterValues)}
      />
      <PublishTypeChecklist
        dataKey="publishType"
        label="Publish type"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('publishType', currentFilterValues)}
      />
      <InvalidUsersChecklist
        dataKey="invalidUserType"
        label="Invalid users"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('invalidUserType', currentFilterValues)}
      />
      <DateRangePicker
        wrapperLabel="Due date between"
        labels={['Due date start', 'Due date end']}
        dataKeys={['dueDateRangeStart', 'dueDateRangeEnd']}
        onSelectedItems={onFilterUpdate}
        initialValues={currentFilterValues}
      />
      <DateRangePicker
        wrapperLabel="Last modified date between"
        labels={['Last modified date start', 'Last modified date end']}
        dataKeys={['modifiedOnDateRangeStart', 'modifiedOnDateRangeEnd']}
        onSelectedItems={onFilterUpdate}
        initialValues={currentFilterValues}
      />
      <FiscalPeriodRangePicker
        wrapperLabel="Fiscal period between"
        labels={['Period start', 'Period end']}
        dataKeys={[
          'fiscalPeriodRangeStart',
          'fiscalPeriodRangeEnd',
          'fiscalYearRangeStart',
          'fiscalYearRangeEnd',
          'fiscalPeriodYearRangeStart',
          'fiscalPeriodYearRangeEnd',
        ]}
        onMultipleFiltersUpdate={onFiltersUpdate}
        getFilterByKey={getFilterByKey}
      />
      <GraphUsersFilter
        dataKey="modifiedByUsers"
        label="Modified by"
        removeButtonAriaLabel="Remove modified by users"
        controlName="modifiedByUsers"
        placeholder="Type one or more users"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('modifiedByUsers', currentFilterValues)}
      />
      <GraphUsersFilter
        dataKey="stateChangedByUsers"
        label="State changed by"
        removeButtonAriaLabel="Remove state changed by"
        controlName="stateChangedByUsers"
        placeholder="Type one or more users"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('stateChangedByUsers', currentFilterValues)}
      />
      <DateRangePicker
        wrapperLabel="DTP doc update date between"
        labels={['DTP update start', 'DTP update end']}
        dataKeys={['dtpDocDateRangeStart', 'dtpDocDateRangeEnd']}
        onSelectedItems={onFilterUpdate}
        initialValues={currentFilterValues}
      />
      <DateRangePicker
        wrapperLabel="State changed date between"
        labels={['State changed start', 'State changed end']}
        dataKeys={['stateChangedRangeStart', 'stateChangedRangeEnd']}
        onSelectedItems={onFilterUpdate}
        initialValues={currentFilterValues}
      />
      <SalesLocationGroupedChecklistFilter
        dataKey="salesLocations"
        label="Sales Location"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('salesLocations', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="reportTypes"
        label="Report type"
        taskPropertyName="Report Type"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('reportTypes', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="analysisTypes"
        label="Analysis type"
        taskPropertyName="Analysis Type"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('analysisTypes', currentFilterValues)}
      />
      <TaskPropertyValuesChecklist
        dataKey="outputs"
        label="Output"
        taskPropertyName="Output"
        onSelectedItems={onFilterUpdate}
        initialValues={getCurrentFilterValues('outputs', currentFilterValues)}
      />
    </>
  );
};

export default TaskFilterOptions;
