import { Stack, IDropdownOption, TextField } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import CompanyPicker, { mapCompanyNoTypename } from '../../../common/formFields/CompanyPicker';
import FormGridCard from '../../../common/formFields/FormGridCard';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import FormGridShowMore from '../../../common/formFields/FormGridShowMore';
import GraphUsersPeoplePicker, {
  mapUsersNoTypename,
} from '../../../common/formFields/GraphUsersPeoplePicker';
import OpsDetailDropdown, {
  IOpsDetailDropdownReference,
} from '../../../common/formFields/OpsDetailDropdown';
import SystemGeneratedField from '../../../common/formFields/SystemGeneratedField';
import TaskPropertyDropdown from '../../../common/formFields/TaskPropertyValuesDropdown';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';
import { formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import IAadUser from '../../../utils/types/IAadUser';
import ICompany from '../../../utils/types/ICompany';
import arrayMustNotBeEmpty, { urlMustBeValid } from '../../../utils/validation/CustomValidations';
import FunctionsSummaryPicker from '../../../common/formFields/FunctionsSummaryPicker';
import IFunctionsSummary from '../../../utils/types/IFunctionsSummary';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import AllActiveCompanyCodes from '../../../common/formFields/AllActiveCompanyCodes';
import PanelTypes from '../../../utils/types/PanelTypes';
import { PEOPLE_PICKER_LIMIT } from '../../../common/constants/SiteConstants';
import FeatureFlagged from '../../featureFlags/FeatureFlagged';
import ISalesLocation from '../../../utils/types/ISalesLocation';
import SalesLocationSelector from '../../../common/formFields/SalesLocationSelector';
import { mapSalesLocationNoTypename } from '../../../common/formFields/SalesLocationPicker';
import NileWorkflowSelector from '../../../common/formFields/NileWorkflowSelector';
import {
  IProjectNileWorkflow,
  getWorkflowListFromId,
} from '../../../utils/types/IProjectNileWorkflow';

export const getCountriesLabel = (inputCompanies: ICompany[]) => {
  switch (inputCompanies?.length) {
    case null:
    case undefined:
    case 0:
      return null;
    case 1:
      return inputCompanies[0].countryCode;
    default:
      return 'Multiple companies';
  }
};

interface IDraftTaskDetailLocationCardProps {
  form: UseFormMethods<IBaseTask>;
  panelMode: PanelTypes;
  disableStagedTaskFields?: boolean;
}

const DraftTaskDetailLocationCard: React.FunctionComponent<IDraftTaskDetailLocationCardProps> = ({
  form,
  panelMode,
  disableStagedTaskFields,
}: IDraftTaskDetailLocationCardProps): JSX.Element => {
  const [showOptionalValues, setShowOptionalValues] = useState(false);
  const [allActiveChecked, setAllActiveChecked] = useState(false);
  const { control, errors, setValue, getValues, register } = form;
  const {
    operationsDetailId,
    countryName,
    companies,
    supplier,
    primaries,
    backupTaskPerformers,
    stakeholders,
    opsSummaryAndOrgName,
    dtpDocLink,
    dtpUpdateDate,
    functionsSummaries,
    timeZone,
    reportType,
    analysisType,
    output,
    salesLocations,
    workflowId,
  } = getValues();

  register('operationsDetailId');

  if (panelMode === PanelTypes.Edit || panelMode === PanelTypes.Copy) {
    useEffect(() => {
      const { companies: onMountedCompanies } = getValues();
      if (
        onMountedCompanies !== undefined &&
        onMountedCompanies.filter((company) => company.name === 'All Active').length > 0
      ) {
        setAllActiveChecked(true);
      }
    }, []);
  }

  return (
    <FormGridCard cardLabel="Location & team">
      <>
        <Stack horizontal wrap styles={formGridRowStyles}>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              disabled={disableStagedTaskFields}
              as={OpsDetailDropdown}
              label="Ops detail"
              id="operationsDetailId"
              name="operationsDetailId"
              valueKey="operationsDetailId"
              required
              control={control}
              defaultValue={operationsDetailId?.toString()}
              value={operationsDetailId?.toString()}
              width="100%"
              handleChange={(newValue: IOpsDetailDropdownReference, valueKey: string): void => {
                const parentsNames = `${newValue.opsDetail?.opsOrg?.opsSummary?.name} / ${newValue.opsDetail?.opsOrg?.name}`;
                const countriesLabel = getCountriesLabel(newValue.opsDetail?.companyCodes);
                setValue('opsSummaryAndOrgName', parentsNames);
                setValue('companies', mapCompanyNoTypename(newValue.opsDetail?.companyCodes));
                setValue('countryName', countriesLabel);
                setValue(
                  'backupTaskPerformers',
                  mapUsersNoTypename(newValue.opsDetail.backupTaskPerformers),
                );
                setValue('operationsDetailId', newValue.key);
                setAllActiveChecked(false);
              }}
              errors={errors}
              errorMessage={errors?.operationsDetailId?.message}
              rules={{
                required: 'Please provide a ops detail',
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              disabled={disableStagedTaskFields}
              name="opsSummaryAndOrgName"
              control={control}
              as={SystemGeneratedField}
              label="Ops summary / org"
              value={opsSummaryAndOrgName}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              name="companies"
              label="Company code"
              defaultValue={companies}
              value={companies}
              render={({ value }) => {
                return (
                  <Stack tokens={{ childrenGap: 10 }}>
                    <CompanyPicker
                      id="companyCodePicker"
                      label="Company code"
                      value={value}
                      defaultValue={value}
                      onChange={(companyCodeTags: ICompany[]) => {
                        const countriesLabel = getCountriesLabel(companyCodeTags);
                        setValue('companies', companyCodeTags.length > 0 ? companyCodeTags : null);
                        setValue('countryName', countriesLabel);
                      }}
                      required
                      errorMessage={(errors?.companies as unknown as FieldError)?.message}
                      placeholder="Type one or more company codes"
                      disabled={disableStagedTaskFields || allActiveChecked}
                    />
                    <AllActiveCompanyCodes
                      checked={allActiveChecked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAllActiveChecked(e.target.checked);
                        if (!allActiveChecked) {
                          setValue('companies', [
                            { name: 'All Active', companyCode: 0, countryCode: 'US' },
                          ]);
                        } else {
                          setValue('companies', []);
                        }
                      }}
                      disabled={disableStagedTaskFields}
                    />
                  </Stack>
                );
              }}
              control={control}
              rules={{
                required: 'Please provide a company',
                validate: { arrayMustNotBeEmpty },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              disabled={disableStagedTaskFields}
              name="countryName"
              control={control}
              as={SystemGeneratedField}
              label="Country"
              value={countryName}
            />
          </FormGridColumn>
          <FeatureFlagged flagName="SalesLocationOnTasks">
            <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
              <Controller
                name="salesLocations"
                label="Sales locations"
                defaultValue={salesLocations}
                value={salesLocations}
                render={({ onChange, value }) => {
                  return (
                    <SalesLocationSelector
                      defaultValue={value}
                      onChange={(newSalesLocations: ISalesLocation[]): void => {
                        onChange(mapSalesLocationNoTypename(newSalesLocations));
                      }}
                    />
                  );
                }}
                control={control}
              />
            </FormGridColumn>
          </FeatureFlagged>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              label="Primary"
              name="primaries"
              control={control}
              defaultValue={mapUsersNoTypename(primaries)}
              value={mapUsersNoTypename(primaries)}
              render={({ value }) => {
                return (
                  <Stack tokens={{ childrenGap: 5 }}>
                    <GraphUsersPeoplePicker
                      id="primary"
                      label="Primaries"
                      itemLimit={PEOPLE_PICKER_LIMIT}
                      removeButtonAriaLabel="Remove primary"
                      controlName="primaries"
                      onChange={(users: IAadUser[]): void => {
                        setValue('primaries', users);
                      }}
                      errorMessage={(errors?.primaries as unknown as FieldError)?.message}
                      value={value}
                      defaultValue={value}
                      placeholder="Type one or more Primary"
                      isRequired
                      isMultiUserAddable
                    />
                  </Stack>
                );
              }}
              rules={{
                validate: { arrayMustNotBeEmpty },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              label="Backups"
              name="backupTaskPerformers"
              control={control}
              defaultValue={mapUsersNoTypename(backupTaskPerformers)}
              value={mapUsersNoTypename(backupTaskPerformers)}
              render={({ value }) => {
                return (
                  <Stack tokens={{ childrenGap: 5 }}>
                    <GraphUsersPeoplePicker
                      id="backup"
                      label="Backups"
                      itemLimit={PEOPLE_PICKER_LIMIT}
                      removeButtonAriaLabel="Remove backup"
                      controlName="backupTaskPerformers"
                      onChange={(users: IAadUser[]): void => {
                        setValue('backupTaskPerformers', users);
                      }}
                      errorMessage={
                        (errors?.backupTaskPerformers as unknown as FieldError)?.message
                      }
                      value={value}
                      defaultValue={value}
                      placeholder="Type one or more Backup"
                      isRequired
                      isMultiUserAddable
                    />
                  </Stack>
                );
              }}
              rules={{
                validate: { arrayMustNotBeEmpty },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              label="Stakeholder"
              name="stakeholders"
              control={control}
              defaultValue={mapUsersNoTypename(stakeholders)}
              value={mapUsersNoTypename(stakeholders)}
              render={({ value }) => {
                return (
                  <Stack tokens={{ childrenGap: 5 }}>
                    <GraphUsersPeoplePicker
                      id="stakeholder"
                      label="Stakeholders"
                      itemLimit={PEOPLE_PICKER_LIMIT}
                      removeButtonAriaLabel="Remove stakeholder"
                      controlName="stakeholders"
                      onChange={(users: IAadUser[]): void => {
                        setValue('stakeholders', users);
                      }}
                      errorMessage={(errors?.stakeholders as unknown as FieldError)?.message}
                      value={value}
                      defaultValue={value}
                      placeholder="Type one or more Stakeholder"
                      isMultiUserAddable
                    />
                  </Stack>
                );
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
            <Controller
              name="functionsSummaries"
              label="Function summary"
              defaultValue={functionsSummaries}
              value={functionsSummaries}
              render={({ value }) => {
                return (
                  <FunctionsSummaryPicker
                    label="Function summary"
                    value={value}
                    defaultValue={value}
                    onChange={(companyCodeTags: IFunctionsSummary[]) => {
                      setValue(
                        'functionsSummaries',
                        companyCodeTags.length > 0 ? companyCodeTags : null,
                      );
                    }}
                    required
                    errorMessage={(errors?.functionsSummaries as unknown as FieldError)?.message}
                    placeholder="Type one or more Function summary"
                    disabled={disableStagedTaskFields}
                  />
                );
              }}
              control={control}
              rules={{
                required: 'Please provide function summaries',
                validate: { arrayMustNotBeEmpty },
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              disabled={disableStagedTaskFields}
              as={TaskPropertyDropdown}
              ariaLabel="Supplier"
              required
              label="Supplier"
              id="supplier"
              name="supplierId"
              valueKey="supplierId"
              taskPropertyName="Supplier"
              width="100%"
              handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                setValue(valueKey, newValue.key);
              }}
              errors={errors}
              control={control}
              defaultValue={supplier?.id.toString()}
              value={supplier?.id.toString()}
              errorMessage={errors?.supplierId?.message}
              rules={{
                required: 'Please provide a task type',
              }}
            />
          </FormGridColumn>
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <Controller
              disabled={disableStagedTaskFields}
              as={TaskPropertyDropdown}
              label="Work timezone"
              id="timeZoneId"
              name="timeZoneId"
              ariaLabel="Work timezone"
              valueKey="timeZoneId"
              taskPropertyName="Work TimeZone"
              required
              control={control}
              defaultValue={timeZone?.id.toString()}
              value={timeZone?.id.toString()}
              width="100%"
              handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                setValue(valueKey, newValue.key);
              }}
              errors={errors}
              errorMessage={errors?.timeZoneId?.message}
              rules={{
                required: 'Please provide a timezone',
              }}
            />
          </FormGridColumn>
          {/* Optional Values */}
          <Stack
            style={showOptionalValues ? { display: 'initial' } : { display: 'none' }}
            horizontal
            wrap
          >
            <FormGridColumn
              columnWidth={
                showOptionalValues ? FormGridColumnWidth.Single : FormGridColumnWidth.Hide
              }
            >
              <Controller
                label="DTP doc link"
                key="dtpDocLink"
                value={dtpDocLink}
                as={TextField}
                control={control}
                name="dtpDocLink"
                disabled={disableStagedTaskFields}
                rules={{
                  validate: (value: string) => {
                    const emptyValue = !value || value === '';
                    return showOptionalValues && !emptyValue ? urlMustBeValid(value) : true;
                  },
                }}
                errorMessage={errors?.dtpDocLink?.message}
              />
            </FormGridColumn>
            <FormGridColumn
              columnWidth={
                showOptionalValues ? FormGridColumnWidth.Single : FormGridColumnWidth.Hide
              }
            >
              <Controller
                disabled={disableStagedTaskFields}
                key="dtpUpdateDate"
                name="dtpUpdateDate"
                value={dtpUpdateDate}
                as={UtcDatePicker}
                control={control}
                label="DTP update date"
                allowTextInput
                textField={{
                  name: 'dtpUpdateDate',
                }}
                onChange={(dateString: string): void => {
                  setValue('dtpUpdateDate', dateString);
                }}
              />
            </FormGridColumn>
            <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
              <Controller
                disabled={disableStagedTaskFields}
                as={TaskPropertyDropdown}
                ariaLabel="Report type"
                label="Report type"
                id="reportType"
                name="reportTypeId"
                valueKey="reportTypeId"
                taskPropertyName="Report Type"
                width="100%"
                handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                  setValue(valueKey, newValue.key);
                }}
                errors={errors}
                control={control}
                defaultValue={reportType?.id.toString()}
                value={reportType?.id.toString()}
                errorMessage={errors?.reportTypeId?.message}
              />
            </FormGridColumn>
            <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
              <Controller
                disabled={disableStagedTaskFields}
                as={TaskPropertyDropdown}
                ariaLabel="Analysis type"
                label="Analysis type"
                id="analysisType"
                name="analysisTypeId"
                valueKey="analysisTypeId"
                taskPropertyName="Analysis Type"
                width="100%"
                handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                  setValue(valueKey, newValue.key);
                }}
                errors={errors}
                control={control}
                defaultValue={analysisType?.id.toString()}
                value={analysisType?.id.toString()}
                errorMessage={errors?.analysisTypeId?.message}
              />
            </FormGridColumn>
            <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
              <Controller
                disabled={disableStagedTaskFields}
                as={TaskPropertyDropdown}
                ariaLabel="Output"
                label="Output"
                id="output"
                name="outputId"
                valueKey="outputId"
                taskPropertyName="Output"
                width="100%"
                handleChange={(newValue: IDropdownOption, valueKey: string): void => {
                  setValue(valueKey, newValue.key);
                }}
                errors={errors}
                control={control}
                defaultValue={output?.id.toString()}
                value={output?.id.toString()}
                errorMessage={errors?.outputId?.message}
              />
            </FormGridColumn>
            <FeatureFlagged flagName="ProjectNileWorkflows">
              <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
                <Controller
                  name="workflowId"
                  label="Workflow"
                  defaultValue={workflowId}
                  value={workflowId}
                  render={({ onChange, value }) => {
                    return (
                      <NileWorkflowSelector
                        // Convert the single workflowId to a list of 1 workflow with that Id
                        defaultValue={value ? getWorkflowListFromId(value) : []}
                        onChange={(newNileWorkflows: IProjectNileWorkflow[]): void => {
                          setValue('workflowId', newNileWorkflows[0]?.id);
                        }}
                      />
                    );
                  }}
                  control={control}
                />
              </FormGridColumn>
            </FeatureFlagged>
          </Stack>
        </Stack>
        <FormGridShowMore
          expanded={showOptionalValues}
          onClick={() => {
            setShowOptionalValues(!showOptionalValues);
          }}
        />
      </>
    </FormGridCard>
  );
};
export default DraftTaskDetailLocationCard;
