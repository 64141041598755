import { gql } from '@apollo/client';
import { OPS_DETAIL_BASE_FRAGMENT } from './HierarchiesApi';

export const baseTaskProps = `id
name
description
dueTime
companies {
  name
  companyCode
  countryCode
}
primaries {
  graphGuid
  displayName
  alias
  emailAddress
  isInvalidUser
}
backupTaskPerformers {
  graphGuid
  displayName
  alias
  emailAddress
  isInvalidUser
}
stakeholders {
  graphGuid
  displayName
  alias
  emailAddress
  isInvalidUser
}
scopeOfActivity {
  id
  name
}
estimatedTime
publishType
level {
  id
  name
}
type {
  id
  name
}
supplier {
  id
  name
}
operationsDetail {
  ...OpsDetailBase
}
dtpDocLink
dtpUpdateDate
timeZone {
  id
  name
}
modifiedOn
modifiedBy {
  graphGuid
  displayName
  alias
  emailAddress
}
dataSources {
  id
  name
  perspectiveId
  applicationId
  isEditable
}
dependencyForStagedTasks {
  id
  name
}
dependencyOnStagedTasks {
  id
  name
}
functionsSummaries {
  id
  name
}
bcpCategory {
  id
  name
}
bcpPriorityPeak
bcpPriorityNonPeak
taskTags {
  key
  name
}
permanentNotes
reportType {
  id
  name
}
analysisType {
  id
  name
}
output {
  id
  name
}
salesLocations {
  code
  name
  area
  region
  subRegion
}
workflowId
`;
const STAGED_TASK_FRAGMENT = gql`
  fragment StagedTaskFull on StagedTaskDto {
    ${baseTaskProps}
    frequencyCode
    isActive
  }
  ${OPS_DETAIL_BASE_FRAGMENT}
`;

const AddStagedTask = gql`
  mutation addStagedTask($stagedTaskDto: StagedTaskInputDtoInput!) {
    addStagedTask(stagedTaskDto: $stagedTaskDto) {
      ...StagedTaskFull
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;
export const EditStagedTask = gql`
  mutation editStagedTask($stagedTaskDto: StagedTaskInputDtoInput!) {
    editStagedTask(stagedTaskDto: $stagedTaskDto) {
      ...StagedTaskFull
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;

export const EditStagedTaskBulk = gql`
  mutation editStagedTaskBulk($itemBulkDto: StagedTaskBulkInputDtoInput!) {
    editStagedTaskBulk(itemBulkDto: $itemBulkDto) {
      ...StagedTaskFull
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;
export const EditStagedTaskRemoveBulk = gql`
  mutation editStagedTaskRemoveBulk($itemBulkDto: StagedTaskBulkInputDtoInput!) {
    editStagedTaskRemoveBulk(itemBulkDto: $itemBulkDto) {
      ...StagedTaskFull
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;
export const GetStagedTasks = gql`
  query StagedTasks(
    $keyword: String
    $filters: TaskFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [StagedTaskDtoSortInput!]
  ) {
    stagedTasks(
      keyword: $keyword
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...StagedTaskFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;

export const GetDraftTasksSearch = gql`
  query DraftTasks($keyword: String!, $filters: TaskFilterDtoInput) {
    publishedTasks(keyword: $keyword, filters: $filters, status: DRAFT) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const GetPublishedTasksSearch = gql`
  query PublishedTasks($keyword: String!, $filters: TaskFilterDtoInput) {
    publishedTasks(keyword: $keyword, filters: $filters, status: PUBLISHED) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const GetStagedTasksSearch = gql`
  query StagedTasks($keyword: String!) {
    stagedTasks(keyword: $keyword) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GetStagedTask = gql`
  query StagedTask($stagedTaskId: Int!) {
    stagedTask(stagedTaskId: $stagedTaskId) {
      ...StagedTaskFull
    }
  }
  ${STAGED_TASK_FRAGMENT}
`;

export const GetStagedTaskDeletePreviewCounts = gql`
  query StagedTaskDeletePreviewCounts($taskBulkDto: StagedTaskBulkInputDtoInput!) {
    stagedTaskDeletePreviewCounts(taskBulkDto: $taskBulkDto) {
      unpublishedCount
      draftPublishedCount
    }
  }
`;

export const RemoveStagedTasksMutation = gql`
  mutation removeStagedTaskBulk($itemBulkDto: StagedTaskBulkInputDtoInput!) {
    removeStagedTaskBulk(itemBulkDto: $itemBulkDto)
  }
`;

export const ActivateStagedTasksMutation = gql`
  mutation activateStagedTaskBulk($itemBulkDto: StagedTaskBulkInputDtoInput!) {
    activateStagedTaskBulk(itemBulkDto: $itemBulkDto)
  }
`;

export default AddStagedTask;
