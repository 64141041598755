import { IDropdownOption, ITag, ITextFieldProps, TextField } from '@fluentui/react';
import { FormEvent, useContext } from 'react';
import { FieldValue } from 'react-hook-form';
import BcpPriorityDropdown from '../../../common/formFields/BcpPriorityPeakDropdown';
import CompanyPicker, { ICompanyPickerProps } from '../../../common/formFields/CompanyPicker';
import DataSourcesPicker, {
  IDataSourcesPickerProps,
} from '../../../common/formFields/DataSourcesPicker';
import EstimatedTimeSpinButton, {
  IEstimatedTimeSpinButtonProps,
} from '../../../common/formFields/EstimatedTimeSpinButton';
import FunctionsSummaryPicker, {
  IFSTagPickerProps,
} from '../../../common/formFields/FunctionsSummaryPicker';
import GraphUsersPeoplePicker, {
  IGraphUsersPeoplePicker,
} from '../../../common/formFields/GraphUsersPeoplePicker';
import OpsDetailDropdown, {
  IOpsDetailDropdownReference,
} from '../../../common/formFields/OpsDetailDropdown';
import PublishTypeDropdown from '../../../common/formFields/PublishTypeDropdown';
import StagedTaskPicker from '../../../common/formFields/StagedTaskPicker';

import TaskPropertyDropdown, {
  ITaskPropertyDropdownProps,
} from '../../../common/formFields/TaskPropertyValuesDropdown';
import TaskTagPicker, { ITaskTagPickerProps } from '../../../common/formFields/TaskTagPicker';
import UtcDatePicker from '../../../common/formFields/UTCDatePicker';
import IAadUser from '../../../utils/types/IAadUser';
import ICompany from '../../../utils/types/ICompany';
import IFieldsetProps from '../../../utils/types/IFieldsetProps';
import IFunctionsSummary from '../../../utils/types/IFunctionsSummary';
import { IDataSourceOption, IStagedTask } from '../../../utils/types/ITask';
import SalesLocationPicker, {
  ISalesLocationTagPickerProps,
} from '../../../common/formFields/SalesLocationPicker';
import AppConfigValues from '../../../utils/appConfigValues/AppConfigValues';
import AppConfigValueContext from '../../../utils/appConfigValues/AppConfigValuesContext';
import ISalesLocation from '../../../utils/types/ISalesLocation';

export interface ITaskFormInputChoice extends IDropdownOption {
  component: (props: unknown) => React.ReactElement;
  props?: () =>
    | IFieldsetProps
    | IGraphUsersPeoplePicker
    | Omit<ICompanyPickerProps, 'defaultValue'>
    | ITaskPropertyDropdownProps
    | ITextFieldProps
    | ITaskTagPickerProps
    | Partial<IDropdownOption>
    | Partial<IFSTagPickerProps>
    | Partial<IEstimatedTimeSpinButtonProps>
    | Partial<IDataSourcesPickerProps>;
  isMultiValue?: boolean;
}

export const getFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>,
  ) => void,
): ITaskFormInputChoice[] => {
  const getTaskPropertyProps = (
    taskPropertyName: string,
    handleChange: (newValue: IDropdownOption, valueKey: string) => void,
  ) => {
    return { taskPropertyName, handleChange, ariaLabel: `Please select a ${taskPropertyName}` };
  };

  const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);
  const { flags } = appConfigValues;

  return [
    {
      key: 'operationsDetailId',
      text: 'Ops detail',
      component: OpsDetailDropdown,
      props: () => {
        return {
          handleChange: (newValue: IOpsDetailDropdownReference) => {
            setValue('operationsDetailId', newValue.key);
          },
        } as IFieldsetProps;
      },
      isMultiValue: false,
    },
    {
      key: 'primaries',
      text: 'Primary',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'primary',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('primaries', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupTaskPerformers',
      text: 'Backup',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backup',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('backupTaskPerformers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'stakeholders',
      text: 'Stakeholder',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'stakeholder',
          width: '100%',
          isMultiUserAddable: true,
          onChange: (users: IAadUser[]): void => {
            setValue('stakeholders', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'companies',
      text: 'Co. code',
      component: CompanyPicker,
      props: () => {
        return {
          id: 'CompanyCode',
          width: '100%',
          onChange: (companyCodeTags: ICompany[]): void => {
            setValue('companies', companyCodeTags.length > 0 ? companyCodeTags : null);
          },
        } as Omit<ICompanyPickerProps, 'defaultValue'>;
      },
      isMultiValue: true,
    },
    {
      key: 'supplierId',
      text: 'Supplier',
      component: TaskPropertyDropdown,

      props: () => {
        return getTaskPropertyProps('Supplier', (newValue: IDropdownOption): void => {
          setValue('supplierId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'levelId',
      text: 'Task level',
      component: TaskPropertyDropdown,
      props: () => {
        return getTaskPropertyProps('Task level', (newValue: IDropdownOption): void => {
          setValue('levelId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'typeId',
      text: 'Task type',
      component: TaskPropertyDropdown,
      props: () => {
        return getTaskPropertyProps('Task type', (newValue: IDropdownOption): void => {
          setValue('typeId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'scopeOfActivityId',
      text: 'Scope of activity',
      component: TaskPropertyDropdown,
      props: () => {
        return getTaskPropertyProps('Scope of activity', (newValue: IDropdownOption): void => {
          setValue('scopeOfActivityId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'publishType',
      text: 'Publish type',
      component: PublishTypeDropdown,
      props: () => {
        return {
          handleChange: (newValue: IDropdownOption) => {
            setValue('publishType', newValue.key);
          },
        } as IFieldsetProps;
      },
      isMultiValue: false,
    },
    {
      key: 'frequencyCode',
      text: 'Frequency code',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('frequencyCode', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'taskTags',
      text: 'Task tags',
      component: TaskTagPicker,
      props: () => {
        return {
          onChange: (tags: ITag[]) => {
            setValue('taskTags', tags.length > 0 ? tags : null);
          },
          enableNew: true,
        } as ITaskTagPickerProps;
      },
      isMultiValue: true,
    },
    {
      key: 'bcpCategoryId',
      text: 'BCP category',
      component: TaskPropertyDropdown,
      props: () => {
        return getTaskPropertyProps('BCP Category', (newValue: IDropdownOption): void => {
          setValue('bcpCategoryId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'timeZoneId',
      text: 'Work timezone',
      component: TaskPropertyDropdown,
      props: () => {
        return getTaskPropertyProps('Work TimeZone', (newValue: IDropdownOption): void => {
          setValue('timeZoneId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'bcpPriorityPeak',
      text: 'BCP peak',
      component: BcpPriorityDropdown,
      props: () => {
        return {
          handleChange: (
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption,
            index?: number,
          ): void => {
            return setValue('bcpPriorityPeak', option.key);
          },
          placeholder: 'Select BCP peak',
        } as Partial<IDropdownOption>;
      },
      isMultiValue: false,
    },
    {
      key: 'bcpPriorityNonPeak',
      text: 'BCP non-peak',
      component: BcpPriorityDropdown,
      props: () => {
        return {
          handleChange: (
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption,
            index?: number,
          ): void => {
            return setValue('bcpPriorityNonPeak', option.key);
          },
          placeholder: 'Select BCP non-peak',
        } as Partial<IDropdownOption>;
      },
      isMultiValue: false,
    },
    {
      key: 'functionsSummaries',
      text: 'Function summary',
      component: FunctionsSummaryPicker,
      props: () => {
        return {
          id: 'FSPicker',
          onChange: (companyCodeTags: IFunctionsSummary[]): void => {
            return setValue(
              'functionsSummaries',
              companyCodeTags.length > 0 ? companyCodeTags : null,
            );
          },
          placeholder: 'Select Function summary',
        } as Partial<IFSTagPickerProps>;
      },
      isMultiValue: true,
    },

    {
      key: 'estimatedTime',
      text: 'Estimated time',
      component: EstimatedTimeSpinButton,
      props: () => {
        return {
          id: 'estimatedTime',
          label: 'Estimated Time',
          handleChange: (event: React.FormEvent<HTMLDivElement>, newValue?: string): void => {
            return setValue('estimatedTime', newValue);
          },
        } as Partial<IEstimatedTimeSpinButtonProps>;
      },
      isMultiValue: false,
    },
    {
      key: 'dataSources',
      text: 'Data sources',
      component: DataSourcesPicker,
      props: () => {
        return {
          onChange: (dataSourcesTags: IDataSourceOption[]) => {
            setValue('dataSources', dataSourcesTags.length > 0 ? dataSourcesTags : []);
          },
          placeholder: 'Select Data sources',
        } as Partial<IDataSourcesPickerProps>;
      },
      isMultiValue: true,
    },
    {
      key: 'name',
      text: 'Task name',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('name', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'description',
      text: 'Task description',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('description', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dueTime',
      text: 'Time due',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dueTime', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'permanentNotes',
      text: 'Permanent notes',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('permanentNotes', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dtpDocLink',
      text: 'DTP Doc Link',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dtpDocLink', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dtpUpdateDate',
      text: 'DTP Update Date',
      component: UtcDatePicker,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dtpUpdateDate', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dependencyOnStagedTasks',
      text: 'Dependency on staged tasks',
      component: StagedTaskPicker,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dependencyOnStagedTasks', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'dependencyForStagedTasks',
      text: 'Dependency for staged tasks',
      component: StagedTaskPicker,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('dependencyForStagedTasks', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'reportTypeId',
      text: 'Report type',
      component: TaskPropertyDropdown,

      props: () => {
        return getTaskPropertyProps('Report Type', (newValue: IDropdownOption): void => {
          setValue('reportTypeId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'analysisTypeId',
      text: 'Analysis Type',
      component: TaskPropertyDropdown,

      props: () => {
        return getTaskPropertyProps('Analysis Type', (newValue: IDropdownOption): void => {
          setValue('analysisTypeId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    {
      key: 'outputId',
      text: 'Output',
      component: TaskPropertyDropdown,

      props: () => {
        return getTaskPropertyProps('Output', (newValue: IDropdownOption): void => {
          setValue('outputId', newValue.key);
        }) as ITaskPropertyDropdownProps;
      },
      isMultiValue: false,
    },
    ...(flags.SalesLocationOnTasks
      ? [
          {
            key: 'salesLocations',
            text: 'Sales location',
            component: SalesLocationPicker,
            props: () => {
              return {
                id: 'SLPicker',
                onChange: (salesLocationTags: ISalesLocation[]): void => {
                  return setValue(
                    'salesLocations',
                    salesLocationTags.length > 0 ? salesLocationTags : null,
                  );
                },
                placeholder: 'Select Sales location',
              } as Partial<ISalesLocationTagPickerProps>;
            },
            isMultiValue: true,
          },
        ]
      : []),
  ];
};

export const getUpdateTaskMapper = (taskInputData: IStagedTask) => {
  return {
    operationsDetailId: taskInputData.operationsDetailId,
    typeId: taskInputData.typeId,
    levelId: taskInputData.levelId,
    scopeOfActivityId: taskInputData.scopeOfActivityId,
    publishType: taskInputData.publishType,
    supplierId: taskInputData.supplierId,
    timeZoneId: taskInputData.timeZoneId,
    bcpCategoryId: taskInputData.bcpCategoryId,
    primaries: taskInputData.primaries,
    backupTaskPerformers: taskInputData.backupTaskPerformers,
    stakeholders: taskInputData.stakeholders,
    companies: taskInputData.companies?.map((company) => company.companyCode),
    frequencyCode: taskInputData.frequencyCode,
    taskTags: taskInputData.taskTags,
    bcpPriorityPeak: taskInputData.bcpPriorityPeak,
    bcpPriorityNonPeak: taskInputData.bcpPriorityNonPeak,
    functionsSummaries: taskInputData.functionsSummaries,
    estimatedTime: Number(taskInputData.estimatedTime),
    dataSources: taskInputData.dataSources,
    name: taskInputData.name,
    description: taskInputData.description,
    permanentNotes: taskInputData.permanentNotes,
    dueTime: taskInputData.dueTime,
    dtpDocLink: taskInputData.dtpDocLink,
    dtpUpdateDate: taskInputData.dtpUpdateDate,
    dependencyOnStagedTasks: taskInputData.dependencyOnStagedTasks?.map((task) => task.id) || [],
    dependencyForStagedTasks: taskInputData.dependencyForStagedTasks?.map((task) => task.id) || [],
    reportTypeId: taskInputData.reportTypeId,
    analysisTypeId: taskInputData.analysisTypeId,
    outputId: taskInputData.outputId,
    salesLocations: taskInputData.salesLocations?.map((salesLocation) => salesLocation.code),
  };
};

export const getRemoveTaskMapper = (taskInputData: IStagedTask) => {
  return {
    primaries: taskInputData.primaries,
    backupTaskPerformers: taskInputData.backupTaskPerformers,
    stakeholders: taskInputData.stakeholders,
    companies: taskInputData.companies?.map((company) => company.companyCode),
    taskTags: taskInputData.taskTags,
    functionsSummaries: taskInputData.functionsSummaries,
    dataSources: taskInputData.dataSources,
    salesLocations: taskInputData.salesLocations?.map((salesLocation) => salesLocation.code),
  };
};
