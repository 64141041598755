import { Checkbox } from '@fluentui/react';
import React from 'react';

interface IAllActiveCompanyCodesProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
const AllActiveCompanyCodes = (props: IAllActiveCompanyCodesProps): JSX.Element => {
  const { checked, onChange, disabled } = props;
  return <Checkbox label="All active" checked={checked} onChange={onChange} disabled={disabled} />;
};
export default AllActiveCompanyCodes;
