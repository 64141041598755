import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_USER_POLICIES } from '../../utils/api/UsersApi';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { UserProvider } from '../../utils/authorization/UserContext';
import LoadingPageContent from '../../common/LoadingPageContent';
import { getAuthInstance } from '../../utils/api/ApiProvider';

interface IAuthorizationCheckerProps {
  graphId: string;
  children: JSX.Element;
}

const AuthorizationChecker = (props: IAuthorizationCheckerProps): JSX.Element => {
  const { graphId, children } = props;

  const [getPolicies, { loading, error }] = useLazyQuery(GET_USER_POLICIES);
  const history = useHistory();

  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    const authInstance = getAuthInstance();
    if (authInstance && authInstance.getAuthError()) {
      history.push('/autherror');
    }
  }, []);

  useEffect(() => {
    if (graphId && !policies?.length) {
      getPolicies({ onCompleted: (data) => setPolicies(data?.currentUserPolicies) });
    }
  }, [graphId]);

  if (error) {
    return <LoadingErrorMessage error={error} loading={loading} />;
  }

  if (!graphId && !loading) {
    return <LoadingPageContent />;
  }

  return <UserProvider value={policies}>{children}</UserProvider>;
};

export default AuthorizationChecker;
