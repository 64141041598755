import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { getSingleGraphUser } from '../../utils/api/GraphUserApi';
import IAadUser from '../../utils/types/IAadUser';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

interface IGraphUsersListDialogProps {
  onSubmit: (childData: IAadUser[]) => void;
  onClose: () => void;
  isOpen: boolean;
}

const GraphUsersListDialog = (props: IGraphUsersListDialogProps): JSX.Element => {
  const { onSubmit, onClose: onDismiss, isOpen } = props;
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleInputChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setInputValue(newValue);
  };

  const getUserListFromGraph = async (): Promise<IAadUser[]> => {
    if (inputValue) {
      const emails = inputValue.split(',').filter((email) => email !== '');

      const graphUserListTasks = emails.map(async (email: string) => {
        const graphUser = await getSingleGraphUser(email.trim());
        return graphUser;
      });

      const userList = await Promise.all(graphUserListTasks);
      const flatUserList = userList.map((user) => user.emailAddress);

      const missingItems = emails
        .filter((email: string) => !flatUserList.includes(email.trim()))
        .map((email: string) => email.trim());

      if (missingItems.length > 0) {
        throw new Error(`The following users were not found in graph: ${missingItems.join(', ')}`);
      }

      return userList;
    }

    return [];
  };

  const handleClose = () => {
    onDismiss();
    setError(null);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const userList = await getUserListFromGraph();
      setLoading(false);
      onSubmit(userList);
      handleClose();
    } catch (ex) {
      setLoading(false);
      setError({
        name: 'Error',
        message: ex.message,
      });
    }
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Add multiple users to list',
    subText:
      'Type or paste a comma separated list of users in the format alias@domain.com to add to the user list.',
  };

  return (
    <Dialog hidden={!isOpen} onDismiss={handleClose} dialogContentProps={dialogContentProps}>
      <LoadingErrorMessage loading={loading} error={error} label="Getting users from Graph" />
      <TextField label="User list" onChange={handleInputChange} multiline autoAdjustHeight />
      <DialogFooter>
        <DefaultButton text="Cancel" onClick={handleClose} ariaLabel="Cancel" />
        <PrimaryButton text="Add" onClick={handleSubmit} ariaLabel="Add" />
      </DialogFooter>
    </Dialog>
  );
};

export default GraphUsersListDialog;
