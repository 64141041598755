import React from 'react';
import {
  CoherencePagination,
  ICoherencePageSizeProps,
  ICoherencePaginationProps,
  IComboBoxNumberOption,
} from '@coherence-design-system/controls';
import IPaginationMetadata, { IPageInfo } from '../../utils/types/PaginationTypes';
import { MOBILE_NAV_MAX_WIDTH_BREAKPOINT } from '../constants/SiteConstants';

/**
 * Default system wide max records per paginated page
 */
export const DEFAULT_RECORDS_PER_PAGE = 15;

export function getInitialPageSize(defaultPageSize?: number): number {
  return defaultPageSize != null && defaultPageSize !== 0
    ? defaultPageSize
    : DEFAULT_RECORDS_PER_PAGE;
}

export function getTotalPageCount(totalRecordCount: number, defaultPageSize: number): number {
  // check for 0
  if (defaultPageSize <= 0) return 1;
  return Math.ceil(totalRecordCount / defaultPageSize);
}
export function getPaginationInfo(
  pageInfo: IPageInfo,
  selectedPage: number,
  newPageNumber: number,
  defaultPageSize: number,
): { first: number; after: string; last: number; before: string } {
  const { hasNextPage, hasPreviousPage, endCursor, startCursor } = pageInfo;
  const goingBackward = newPageNumber < selectedPage && hasPreviousPage;
  const goingForward = newPageNumber > selectedPage && hasNextPage;
  return {
    first: goingForward ? defaultPageSize : null,
    after: goingForward ? endCursor : null,
    last: goingBackward ? defaultPageSize : null,
    before: goingBackward ? startCursor : null,
  };
}
const SharedPagination = (props: IPaginationMetadata): JSX.Element => {
  const { pageCount, selectedPage, pageSize, onPageChange, onPageSizeChange } = props;
  const paginationProps: ICoherencePaginationProps = {
    styles: {
      root: {
        height: 'auto',
        selectors: {
          [MOBILE_NAV_MAX_WIDTH_BREAKPOINT]: {
            gridTemplateColumns: 'minmax(225px, 1fr)',
          },
        },
      },
    },
    pageCount: pageCount === 0 ? 1 : pageCount,
    selectedPage,
    previousPageAriaLabel: 'previous page',
    nextPageAriaLabel: 'next page',
    inputFieldAriaLabel: 'page number text box disabled',
    isIndexed: false,
    onPageChange,
    canJumpToPage: false, // to enable this we will need to make sure that the backend can handle it
  };

  const paginationPageSizeProps: ICoherencePageSizeProps = {
    pageSize,
    pageSizeList: [
      { key: 5, text: '5' },
      { key: 15, text: '15' },
      { key: 25, text: '25' },
      { key: 50, text: '50' },
      { key: 100, text: '100' },
    ] as IComboBoxNumberOption[],
    comboBoxAriaLabel: 'Items displayed per page',
    endingPageSizeLabelText: 'items displayed per page',
    initialPageSizeLabelText: '',
    onPageSizeChange,
  };

  return <CoherencePagination {...paginationProps} pageSize={paginationPageSizeProps} />;
};

export default SharedPagination;
