import { INavLinkGroup, INavLink } from '@m365-admin/nav';
import React, { useContext } from 'react';
import AppConfigValues from '../utils/appConfigValues/AppConfigValues';
import AppConfigValueContext from '../utils/appConfigValues/AppConfigValuesContext';
import { IFcwPolicy } from '../utils/types/IFcwPolicy';
import hasAccess from '../utils/authorization/authorizationCheck';

const getToolLinks = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
): INavLink[] => {
  const toolLinks: INavLink[] = [];

  toolLinks.push({
    name: 'Inactive Users',
    key: 'Inactiveusers',
    href: '/Inactiveusers',
    ariaLabel: 'Inactive Users',
    title: 'Inactive Users',
    onClick: handleLinkClick,
    isSelected: pathname.startsWith('/Inactiveusers'),
  });
  return toolLinks;
};
const getIntegratedApps = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
): INavLink[] => {
  const toolLinks: INavLink[] = [];

  toolLinks.push({
    name: 'Stat Processes',
    key: 'statprocesses',
    href: '/stat/processes',
    ariaLabel: 'Stat Processes',
    title: 'Stat Processes',
    onClick: handleLinkClick,
    isSelected: pathname.startsWith('/stat'),
  });
  return toolLinks;
};
const getNavLinks = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
  polices: IFcwPolicy[],
): INavLink[] => {
  const appConfigValues: AppConfigValues = useContext(AppConfigValueContext);
  const { flags } = appConfigValues;

  const taskSublinks: INavLink[] = [
    {
      key: 'tasks',
      name: 'Tasks by fiscal period',
      href: '/tasks',
      ariaLabel: 'Tasks',
      isSelected: pathname.startsWith('/tasks') && !pathname.startsWith('/tasks/all'),
      onClick: handleLinkClick,
    },
    {
      key: 'tasks',
      name: 'All tasks',
      href: '/tasks/all',
      ariaLabel: 'Tasks',
      isSelected: pathname.startsWith('/tasks/all'),
      onClick: handleLinkClick,
    },
  ];

  const links: INavLink[] = [
    {
      key: 'home',
      name: 'Home',
      icon: 'Home',
      ariaLabel: 'Home',
      url: '/',
      isSelected: pathname === '/',
      onClick: handleLinkClick,
    },
    {
      key: 'tasks',
      name: 'Tasks',
      icon: 'ProductList',
      href: '/tasks',
      ariaLabel: 'Tasks',
      isSelected: pathname.startsWith('/tasks'),
      onClick: handleLinkClick,
      links: taskSublinks,
    },
    {
      key: 'fiscalCalendar',
      name: 'Fiscal Calendar',
      icon: 'CalendarSettings',
      href: '/fiscalCalendar',
      ariaLabel: 'Fiscal Calendar',
      isSelected: pathname.startsWith('/fiscalCalendar'),
      onClick: handleLinkClick,
    },
    {
      key: 'savedfilters',
      name: 'Saved Filters',
      icon: 'QueryList',
      href: '/savedfilters',
      ariaLabel: 'Saved Filters',
      isSelected: pathname.startsWith('/savedfilters'),
      onClick: handleLinkClick,
    },
  ];

  if (hasAccess(polices, 'ViewDraftTask')) {
    const draftTaskNavItem = {
      key: 'draftTasks',
      name: 'Draft Tasks',
      icon: 'CheckList',
      href: '/draftTasks',
      ariaLabel: 'Draft Tasks',
      isSelected: pathname.startsWith('/draftTasks'),
      onClick: handleLinkClick,
    };
    links.splice(2, 0, draftTaskNavItem);
  }

  if (hasAccess(polices, 'ViewStagedTask')) {
    const stagedTaskNavItem = {
      key: 'stagedTasks',
      name: 'Staged Tasks',
      icon: 'TaskManager',
      href: '/stagedTasks',
      ariaLabel: 'Staged Tasks',
      isSelected: pathname.startsWith('/stagedTasks'),
      onClick: handleLinkClick,
    };
    links.splice(3, 0, stagedTaskNavItem);
  }

  const processesLink: INavLink = {
    key: 'hierarchies',
    name: 'Hierarchies',
    icon: 'Processing',
    href: '/hierarchies/operations',
    ariaLabel: 'Hierarchies',
    isSelected: pathname.startsWith('/hierarchies'),
    onClick: handleLinkClick,
  };
  const toolsLinks = getToolLinks(handleLinkClick, pathname);

  if (toolsLinks.length && hasAccess(polices, 'ReplaceUser')) {
    links.push({
      key: 'tools',
      name: 'Tools',
      icon: 'DeveloperTools',
      ariaLabel: 'Tools',
      links: toolsLinks,
    });
  }

  const integratedApps = getIntegratedApps(handleLinkClick, pathname);

  if (flags.StatIntegration && integratedApps.length) {
    links.push({
      key: 'experiences',
      name: 'Experiences',
      icon: 'PreviewLink',
      ariaLabel: 'Experiences',
      links: integratedApps,
    });
  }

  links.splice(links.length - 1, 0, processesLink);

  return links;
};

const navLinkGroups = (
  handleLinkClick: (ev: React.MouseEvent<HTMLElement>, item: INavLink) => void,
  pathname: string,
  polices: IFcwPolicy[],
): INavLinkGroup[] => {
  const navLinks: INavLinkGroup[] = [
    {
      key: 'mainNav',
      links: getNavLinks(handleLinkClick, pathname, polices),
    },
  ];

  return navLinks;
};

export default navLinkGroups;
