import {
  FontWeights,
  IButtonStyles,
  IconButton,
  Modal,
  getTheme,
  mergeStyleSets,
} from '@fluentui/react';
import React from 'react';

interface EnhancedModalProps {
  header: string;
  isOpen: boolean;
  onDismiss: () => void;
}

const EnhanceModal: React.FC<EnhancedModalProps> = ({ header, children, isOpen, onDismiss }) => {
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  return (
    <Modal isOpen={isOpen} containerClassName={contentStyles.container} onDismiss={onDismiss}>
      <div className={contentStyles.header}>
        <h2 className={contentStyles.heading}>{header}</h2>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>{children}</div>
    </Modal>
  );
};

export default EnhanceModal;
