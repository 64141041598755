import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../common/lists/GeneralEntityList';
import PaginationWrapperManaged from '../../../common/lists/PaginationWrapperManaged';
import {
  GetVariableDates,
  AddVariableDateValue,
  EditVariableDateValue,
} from '../../../utils/api/CalendarApi';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../utils/listHelpers';
import { IFiscalCalendar } from '../../../utils/types/ICalendarDate';
import { IListQueryVariables, IEntityListState } from '../../../utils/types/IList';
import { IQuerySortDefinition, SortDirection } from '../../../utils/types/IListSortDefinition';
import { PaginationQueryVariables } from '../../../utils/types/PaginationTypes';
import PanelTypes from '../../../utils/types/PanelTypes';
import ColumnConfigPanel from '../../tasks/list/ColumnConfigPanel';
import CalendarListEdit from '../details/CalendarListEdit';
import { getCalendarListMenu, getVariableDateColumnList } from './CalendarList.config';

interface IVariableDateCalendarListState extends IEntityListState {
  showEditPanel: boolean;
  showAddPanel: boolean;
  selectedItem: IFiscalCalendar;
}
const VariableDateManagement = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.variableCalendarListColumns,
    [],
  );

  const defaultFiltersAndSort = {
    sortDir: SortDirection.ASC,
    sortKey: 'taskDateCode',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.variableCalendarQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IVariableDateCalendarListState>({
    showAddPanel: false,
    showEditPanel: false,
    selectedItem: null,
    ...getDefaultStateProps(taskQueryCache),
  });

  const { data, loading, error, refetch } = useQuery(GetVariableDates, {
    variables: {
      ...taskQueryCache?.pageInfo,
      /* We have the sort def saved in cache right? cant we use that here */
      order: [{ [taskQueryCache.sortKey]: taskQueryCache.sortDir } as IQuerySortDefinition],
    },
  });

  const refreshPage = () => {
    const { sortDir, sortKey } = pageState;
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];

    refetch({
      ...pageState.pageInfo,
      order: sortArray,
    });
  };

  const openAddPanel = (): void => {
    setPagesState({
      ...pageState,
      showAddPanel: true,
      selectedItem: null,
    });
  };
  const closePanels = (shouldRefresh: boolean): void => {
    setPagesState({
      ...pageState,
      showEditPanel: false,
      showAddPanel: false,
    });
    if (shouldRefresh) {
      refreshPage();
    }
  };

  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };

  const dataLoaded = !loading && !error;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<IFiscalCalendar>(data?.variableDatesPagination)
    : getEmptyResults<IFiscalCalendar>();

  const cachedListColumns = getColumnConfigByKey(
    getVariableDateColumnList((selectedItem: IFiscalCalendar) => {
      setPagesState({
        ...pageState,
        selectedItem,
        showEditPanel: true,
        showAddPanel: false,
      });
    }, true),
    listColumnsCache,
  );
  const detailsListConfig: IGeneralEntityListProps<IFiscalCalendar> = {
    data: dataResult.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems: getCalendarListMenu(openAddPanel, onRefreshClick),
    disableSelect: true,
    loading,
    error,
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    setListColumns: (columns: ILocalStorageColumnConfig[]) => {
      setListColumnsCache(columns);
    },
  };
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  useEffect(() => {
    setCache();
  }, [
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  return (
    <>
      <PaginationWrapperManaged<IFiscalCalendar>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>

      {pageState.showEditPanel && (
        <CalendarListEdit
          variableDate={pageState.selectedItem}
          closePanel={closePanels}
          mutation={EditVariableDateValue}
          panelMode={PanelTypes.Edit}
        />
      )}
      {pageState.showAddPanel && (
        <CalendarListEdit
          variableDate={{
            description: '',
            taskDateCode: '',
          }}
          closePanel={closePanels}
          mutation={AddVariableDateValue}
          panelMode={PanelTypes.Add}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </>
  );
};

export default VariableDateManagement;
