import { Stack } from '@fluentui/react';
import React, { ReactNode } from 'react';
import FullWidthHeader from '../headers/FullWidthHeader';
import {
  bodyContentContainer,
  bodyContentContainerAlternateBg,
} from '../styles/CommonStyleObjects';

interface IPageContainerProps {
  title: string | (() => JSX.Element);
  alternateBackground?: boolean;
  children: ReactNode;
}

const PageContainer = (props: IPageContainerProps): JSX.Element => {
  const { title, alternateBackground, children } = props;

  const renderHeader = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
        <h1>{title}</h1>
      </Stack>
    );
  };

  const headerFunction = title instanceof Function ? (title as () => JSX.Element) : renderHeader;

  return (
    <>
      <FullWidthHeader title={headerFunction} alternateBackground={alternateBackground} />
      <div
        className={
          alternateBackground ? `${bodyContentContainerAlternateBg}` : `${bodyContentContainer}`
        }
      >
        {children}
      </div>
    </>
  );
};

export default PageContainer;
