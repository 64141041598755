import React from 'react';
import { siteHeroContent } from '../styles/CommonStyleObjects';

interface ErrorComponentProps {
  headerMessage: string;
  contentMessage: string;
  actionButtonsElement: React.ReactElement;
  className?: string;
}

const SiteError: React.FunctionComponent<ErrorComponentProps> = (props: ErrorComponentProps) => {
  const { className, headerMessage, contentMessage, actionButtonsElement } = props;
  const Styles = siteHeroContent();
  return (
    <div className={className || ''}>
      <h1 className={Styles.largeHeroHeader}>{headerMessage}</h1>
      <p className={Styles.content}>{contentMessage}</p>
      {actionButtonsElement && (
        <div className={Styles.actionItemsWrapper}>{actionButtonsElement}</div>
      )}
    </div>
  );
};
export default SiteError;
