import React from 'react';
import {
  Stack,
  Label,
  IStackStyles,
  mergeStyles,
  IStyle,
  NeutralColors,
  FontWeights,
} from '@fluentui/react';

const labelStyles = {
  root: { color: NeutralColors.gray200, fontWeight: FontWeights.regular, fontSize: 12 },
};

const subSpanStyles = mergeStyles({
  fontSize: '.7rem',
});

interface ISpanWithLabelProps {
  labelText: string;
  subLabelText?: string;
  value?: string | boolean | Date | number;
  stackStyles?: IStackStyles;
  children?: JSX.Element;
  spanOverrideStyles?: IStyle;
}

const SpanWithLabel = ({
  labelText,
  value,
  stackStyles,
  subLabelText,
  children,
  spanOverrideStyles,
}: ISpanWithLabelProps): JSX.Element => {
  let valueString;
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (typeof value === 'boolean') {
    valueString = value ? 'Yes' : 'No';
  } else if (typeof value === 'number') {
    valueString = value.toString();
  } else if ((value as Date) && typeof (value as Date).toDateString === 'function') {
    valueString = (value as Date).toDateString();
  } else if (undefined === value || value === null || value === '') {
    valueString = '--';
  } else {
    valueString = value;
  }

  const spanStyles = mergeStyles(
    {
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      hyphens: 'auto',
    },
    spanOverrideStyles,
  );

  return (
    <Stack styles={stackStyles}>
      <Label styles={labelStyles}>{labelText}</Label>
      {valueString && !children && <span className={spanStyles}>{valueString}</span>}
      {children && !value && <span className={spanStyles}>{children}</span>}
      {subLabelText && <span className={subSpanStyles}>{subLabelText}</span>}
    </Stack>
  );
};

export default SpanWithLabel;
