import React from 'react';
import { IconButton, Stack } from '@fluentui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { retrieveUrl } from '../../utils/Helpers';

interface IBackButtonProps {
  ariaLabel: string;
  children: React.ReactNode;
  backDepth?: number;
  goToUrl?: string;
}

const BackButton = ({ ariaLabel, children, backDepth, goToUrl }: IBackButtonProps): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Stack horizontal verticalAlign="center" wrap>
      <IconButton
        iconProps={{ iconName: 'Back' }}
        title={ariaLabel}
        ariaLabel={ariaLabel}
        onClick={(): void => {
          history.push(retrieveUrl(goToUrl, backDepth, match));
        }}
      />
      {children}
    </Stack>
  );
};

export default BackButton;
