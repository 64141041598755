import { ITag } from '@fluentui/react';
import React from 'react';
import { IGenericFilterTag } from '../../utils/types/IGenericFilterTag';
import Collapsible from './Collapsible';
import GenericChecklist from './GenericChecklist';

export interface IChecklistImplementationProps {
  onSelectedItems?: (selectedItems: IGenericFilterTag) => void;
  initialValues?: IGenericFilterTag;
  disabledValues?: IGenericFilterTag;
  label: string;
  dataKey: string;
}

const YesNoChecklistProps = (props: IChecklistImplementationProps): JSX.Element => {
  const { onSelectedItems, initialValues, label, dataKey, disabledValues } = props;

  const yesNo = [
    { key: 'Yes', name: 'Yes' },
    { key: 'No', name: 'No' },
  ];

  const onCheckChanged = (items: ITag[]): void => {
    onSelectedItems({ dataKey, title: label, values: items });
  };

  return (
    <Collapsible text={label}>
      <GenericChecklist
        items={yesNo}
        onCheckChanged={onCheckChanged}
        selections={initialValues?.values ?? []}
        disabledItems={disabledValues?.values ?? []}
      />
    </Collapsible>
  );
};
export default YesNoChecklistProps;
