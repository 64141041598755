import { IDropdownOption, ITextFieldProps, TextField } from '@fluentui/react';
import { FieldValue } from 'react-hook-form';
import { FormEvent } from 'react';
import IFieldsetProps from '../../../../utils/types/IFieldsetProps';
import CompanyPicker, { ICompanyPickerProps } from '../../../../common/formFields/CompanyPicker';
import ICompany from '../../../../utils/types/ICompany';
import GraphUsersPeoplePicker, {
  IGraphUsersPeoplePicker,
} from '../../../../common/formFields/GraphUsersPeoplePicker';
import IAadUser from '../../../../utils/types/IAadUser';
import { IOpsOrgDetail } from '../../../../utils/types/IHierarchy';

export interface IOpsDetailFormInputChoice extends IDropdownOption {
  component: (props: unknown) => React.ReactElement;
  props?: () =>
    | IFieldsetProps
    | IGraphUsersPeoplePicker
    | Omit<ICompanyPickerProps, 'defaultValue'>
    | ITextFieldProps
    | Partial<IDropdownOption>;

  isMultiValue?: boolean;
}

export const getFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>,
  ) => void,
): IOpsDetailFormInputChoice[] => {
  return [
    {
      key: 'companyCodes',
      text: 'Co. code',
      component: CompanyPicker,
      props: () => {
        return {
          id: 'companyCodes',
          width: '100%',
          onChange: (companyCodeTags: ICompany[]): void => {
            setValue('companyCodes', companyCodeTags.length > 0 ? companyCodeTags : null);
          },
        } as Omit<ICompanyPickerProps, 'defaultValue'>;
      },
      isMultiValue: true,
    },
    {
      key: 'checklistOwners',
      text: 'Checklist Owners',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'checklistOwners',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('checklistOwners', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupTaskPerformers',
      text: 'Backup TaskPerformers',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backup',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupTaskPerformers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },

    {
      key: 'backupJeReviewers',
      text: 'Backup JE reviewer(s)',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backupJeReviewers',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupJeReviewers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupJePosters',
      text: 'Backup JE poster(s)',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backupJePosters',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupJePosters', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupRiReconcilers',
      text: 'Backup reconciler(s)',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backupRiReconcilers',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupRiReconcilers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupRiReviewers',
      text: 'Backup reviewer(s)',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backupRiReviewers',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupRiReviewers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'backupRiApprovers',
      text: 'Backup approver(s)',
      component: GraphUsersPeoplePicker,
      props: () => {
        return {
          id: 'backupRiApprovers',
          width: '100%',
          onChange: (users: IAadUser[]): void => {
            setValue('backupRiApprovers', users);
          },
        } as IGraphUsersPeoplePicker;
      },
      isMultiValue: true,
    },
    {
      key: 'deadline',
      text: 'Deadline',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('deadline', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
    {
      key: 'autoPublish',
      text: 'Auto Publish',
      component: TextField,
      props: () => {
        return {
          onChange: (
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            setValue('autoPublish', newValue);
          },
        } as ITextFieldProps;
      },
      isMultiValue: false,
    },
  ];
};

export const getUpdateOperationsMapper = (operationsInputData: IOpsOrgDetail) => {
  return {
    checklistOwners: operationsInputData.checklistOwners,
    backupTaskPerformers: operationsInputData.backupTaskPerformers,
    companyCodes: operationsInputData.companyCodes?.map((cc) => cc.companyCode),
    backupJeReviewers: operationsInputData.backupJeReviewers,
    backupJePosters: operationsInputData.backupJePosters,
    backupRiReconcilers: operationsInputData.backupRiReconcilers,
    backupRiReviewers: operationsInputData.backupRiReviewers,
    backupRiApprovers: operationsInputData.backupRiApprovers,
    deadline: operationsInputData.deadline,
    autoPublish: operationsInputData.autoPublish,
  };
};

export const getRemoveOperationsMapper = (operationsInputData: IOpsOrgDetail) => {
  return {
    checklistOwners: operationsInputData.checklistOwners,
    backupTaskPerformers: operationsInputData.backupTaskPerformers,
    companyCodes: operationsInputData.companyCodes?.map((cc) => cc.companyCode),
    backupJeReviewers: operationsInputData.backupJeReviewers,
    backupJePosters: operationsInputData.backupJePosters,
    backupRiReconcilers: operationsInputData.backupRiReconcilers,
    backupRiReviewers: operationsInputData.backupRiReviewers,
    backupRiApprovers: operationsInputData.backupRiApprovers,
  };
};
