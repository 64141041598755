import React, { useEffect, useState } from 'react';
import {
  ITag,
  FontSizes,
  FontWeights,
  Label,
  Stack,
  TagPicker,
  Text,
  IPickerItemProps,
} from '@fluentui/react';
import InlineFormInputErrorMessage from '../errorContent/InlineFormInputErrorMessage';
import ISalesLocation from '../../utils/types/ISalesLocation';
import { mapSalesLocationsToTags } from './SalesLocationPicker';

const labelStyles = {
  fontWeight: FontWeights.semibold,
  fontSize: FontSizes.size14,
  paddingTop: '5px',
  paddingBottom: '5px',
};
const tagItemStyle = {
  root: {
    backgroundColor: 'rgb(237, 235, 233)',
    borderRadius: '2px',
    padding: '5px 12px',
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    margin: '2px',
  },
};
const tagItemTextStyle = {
  root: {
    minWidth: 30,
    margin: '0px 8px',
  },
};

export interface ISalesLocationTagPickerProps {
  selectedSalesLocations: ISalesLocation[];
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  onInputClicked?: () => void;
}

const SalesLocationTagPicker = (props: ISalesLocationTagPickerProps): JSX.Element => {
  const { selectedSalesLocations, errorMessage, onInputClicked, label, disabled, placeholder } =
    props;
  const [selectedSalesLocationTags, setSelectedSalesLocationTags] = useState<ITag[]>(
    mapSalesLocationsToTags(selectedSalesLocations),
  );

  useEffect(() => {
    setSelectedSalesLocationTags(mapSalesLocationsToTags(selectedSalesLocations));
  }, [selectedSalesLocations]);

  return (
    <Stack>
      {label && (
        <Label style={labelStyles} disabled={disabled}>
          {label}
        </Label>
      )}
      <div
        role="button"
        onClick={onInputClicked}
        // Accessibility Properties:
        aria-label={label || 'Value'} // Set the aria-label to the label or 'Value'
        tabIndex={0} // Make the div focusable
        onKeyDown={(event) => {
          // Allow the div to be clicked with the keyboard
          if (event.key === 'Enter' || event.key === ' ') {
            onInputClicked();
          }
        }}
      >
        <TagPicker
          onInputChange={() => ''} // Do nothing for input as it is disabled
          onResolveSuggestions={() => []} // Do not resolve any suggestions
          selectedItems={selectedSalesLocationTags}
          inputProps={{
            id: 'salesLocations',
            'aria-label': label || 'Value',
            placeholder: placeholder || 'Select Sales Location',
            required: true,
            tabIndex: -1, // Prevent tabbing into the input manually
          }}
          onRenderItem={(pickerProps: IPickerItemProps<ITag>) => {
            return (
              <Stack horizontal styles={tagItemStyle}>
                <Text styles={tagItemTextStyle}>{pickerProps?.item?.name}</Text>
              </Stack>
            );
          }}
          styles={{
            root: { pointerEvents: 'none' },
          }}
        />
      </div>
      <InlineFormInputErrorMessage errorMessage={errorMessage} />
    </Stack>
  );
};

export default SalesLocationTagPicker;
