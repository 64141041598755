import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import {
  bodyContentContainer,
  flexWrapper,
  pivotWrapperStyles,
} from '../../../common/styles/CommonStyleObjects';
import { HierarchiesPivotKeys } from './HierarchiesList.config';
import OpsSummary from './operations/OpsSummary';
import OpsOrg from './operations/OpsOrg';
import OpsOrgDetail from './operations/OpsOrgDetail';
import FullView from './operations/FullView';

const HiearchiesOperationsListPage = () => {
  return (
    <div className={`${bodyContentContainer}`}>
      <Pivot style={flexWrapper} styles={pivotWrapperStyles} overflowBehavior="menu">
        <PivotItem
          style={flexWrapper}
          headerText="Ops summary"
          itemKey={HierarchiesPivotKeys.opsSummary}
        >
          <OpsSummary />
        </PivotItem>
        <PivotItem style={flexWrapper} headerText="Ops org" itemKey={HierarchiesPivotKeys.opsOrg}>
          <OpsOrg />
        </PivotItem>
        <PivotItem
          style={flexWrapper}
          headerText="Ops detail"
          itemKey={HierarchiesPivotKeys.opsDetail}
        >
          <OpsOrgDetail />
        </PivotItem>
        <PivotItem
          style={flexWrapper}
          headerText="Full view"
          itemKey={HierarchiesPivotKeys.fullView}
        >
          <FullView />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default HiearchiesOperationsListPage;
