import React from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import { defaultPaginationProps } from '../../../utils/listHelpers';
import { SortDirection } from '../../../utils/types/IListSortDefinition';
import PublishedTaskListPageTemplate from './PublishedTaskListPageTemplate';
import { DateRangeType, IHomeQueryVariables } from '../../home/Home';
import { formatDate, fiscalPeriodToMonth } from '../../../utils/formatters/DateFormatters';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import addFiltersForPeriodAndYear from '../../../utils/formatters/AddFiscalYearPeriod';

const PublishedAllAAGTaskListPage = (): JSX.Element => {
  const queryString = new URLSearchParams(useLocation().search);

  const opsDetailId = queryString.get('opsDetailId');
  const opsDetailName = queryString.get('opsDetailName');
  const taskState = queryString.getAll('taskState');
  const dueDateRangeStart = queryString.get('dueDateRangeStart');
  const dueDateRangeEnd = queryString.get('dueDateRangeEnd');
  /** Query state  cache */

  const homeLocalStorage = window.localStorage.getItem(LocalStorageKeys.homeQueryState);

  const homeLocalStorageParsed = homeLocalStorage ? JSON.parse(homeLocalStorage) : {};
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IHomeQueryVariables>(
    `${LocalStorageKeys.level3PageCache}`,
    {
      ...homeLocalStorageParsed,
      ...defaultPaginationProps,
      taskStateFilter: null,
    } as IHomeQueryVariables,
  );

  // filter out any unsupported filters
  // remove the saved filter prop on filters
  const listSupportedFilters = ['companies', 'opsOrgDetails', 'forMeOnly'];
  const removeSavedFilters = (taskQuery: IHomeQueryVariables) => {
    taskQuery.filterTags = taskQuery.filterTags.filter((f: IGenericFilterTag) => {
      return listSupportedFilters.includes(f.dataKey);
    });
    taskQuery.filterTags.forEach((f: IGenericFilterTag) => {
      f.values.forEach((v) => {
        v.isSavedFilter = false;
      });
    });
  };
  // map one off filters
  const mapOtherFilters = (taskQuery: IHomeQueryVariables) => {
    if (taskQuery?.forMeOnly === true) {
      taskQueryCache.filterTags.push({
        dataKey: 'forMeOnly',
        title: 'For me only',
        values: [
          {
            key: 'true',
            name: 'True',
          },
        ],
      });
    }
    if (taskQuery.dateRangeType === DateRangeType.rangeDueDate) {
      const endDate = dueDateRangeEnd || taskQuery.dueDateRangeEnd;
      const startDate = dueDateRangeStart || taskQuery.dueDateRangeStart;

      taskQueryCache.filterTags.push({
        dataKey: 'dueDateRangeStart',
        title: 'Due date start',
        values: [
          {
            key: formatDate(startDate),
            name: formatDate(startDate),
          },
        ],
      });
      taskQueryCache.filterTags.push({
        dataKey: 'dueDateRangeEnd',
        title: 'Due date end',
        values: [
          {
            key: formatDate(endDate),
            name: formatDate(endDate),
          },
        ],
      });
    }
    if (opsDetailId) {
      const existingOpsDetailFilter = taskQueryCache.filterTags.find(
        (f) => f.dataKey === 'opsOrgDetails',
      );
      if (existingOpsDetailFilter) {
        existingOpsDetailFilter.values = [
          {
            key: parseInt(opsDetailId, 10),
            name: opsDetailName,
          },
        ];
      }
      taskQueryCache.filterTags.push({
        dataKey: 'opsOrgDetails',
        title: 'Ops Detail',
        values: [
          {
            key: parseInt(opsDetailId, 10),
            name: opsDetailName,
          },
        ],
      });
    }
    if (taskState) {
      const taskStateArray = taskState.map((t) => {
        return {
          key: t,
          name: t,
        };
      });
      taskQueryCache.filterTags.push({
        dataKey: 'taskState',
        title: 'Task State',
        values: [...taskStateArray],
      });
    }
    if (
      dueDateRangeStart &&
      !taskQueryCache.filterTags.some((f) => f.dataKey === 'dueDateRangeStart')
    ) {
      taskQueryCache.filterTags.push({
        dataKey: 'dueDateRangeStart',
        title: 'Due date start',
        values: [
          {
            key: formatDate(dueDateRangeStart),
            name: formatDate(dueDateRangeStart),
          },
        ],
      });
    }

    if (
      dueDateRangeEnd &&
      !taskQueryCache.filterTags.some((f) => f.dataKey === 'dueDateRangeEnd')
    ) {
      taskQueryCache.filterTags.push({
        dataKey: 'dueDateRangeEnd',
        title: 'Due date start',
        values: [
          {
            key: formatDate(dueDateRangeEnd),
            name: formatDate(dueDateRangeEnd),
          },
        ],
      });
    }
  };

  const addDefaultSorting = (taskQuery: IHomeQueryVariables) => {
    if (!taskQuery.sortKey) {
      taskQueryCache.sortKey = 'dueDate';
      taskQueryCache.sortDir = SortDirection.ASC;
    }
  };

  removeSavedFilters(taskQueryCache);
  addDefaultSorting(taskQueryCache as IHomeQueryVariables);
  mapOtherFilters(taskQueryCache as IHomeQueryVariables);

  taskQueryCache.filterTags =
    taskQueryCache.dateRangeType === DateRangeType.rangePeriod
      ? addFiltersForPeriodAndYear(
          taskQueryCache.filterTags,
          taskQueryCache.fiscalYear.toString(),
          taskQueryCache.fiscalPeriod.toString(),
        )
      : [
          ...taskQueryCache.filterTags.filter(
            (f) => f.dataKey !== 'fiscalYear' && f.dataKey !== 'period',
          ),
        ];
  const generateTitle = () => {
    let title = 'Tasks';
    const { fiscalPeriod: period, fiscalYear } = taskQueryCache;
    const fiscalMonth = fiscalPeriodToMonth(period);

    if (
      taskQueryCache.filterTags.find((f) => f.dataKey === 'fiscalYear') &&
      taskQueryCache.filterTags.find((f) => f.dataKey === 'period')
    ) {
      title = `Tasks for ${fiscalMonth} (P${period} FY${fiscalYear?.toString().slice(2)})`;
    }
    return title;
  };
  return (
    <>
      <PublishedTaskListPageTemplate
        queryVariables={taskQueryCache}
        setTaskQueryCache={setTaskQueryCache}
        hideSavedFilter
        pageTitle={generateTitle()}
      />
    </>
  );
};
export default PublishedAllAAGTaskListPage;
