import { Stack, IDropdownOption, TextField, IconButton, Label, ITag, Link } from '@fluentui/react';
import React from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import TaskPropertyDropdown from '../../../common/formFields/TaskPropertyValuesDropdown';
import { formGridLabel, formGridRowStyles } from '../../../common/styles/CommonStyleObjects';
import ICompany from '../../../utils/types/ICompany';
import { IDataSourceOption } from '../../../utils/types/ITask';
import { IBaseTask } from '../../../utils/types/IBaseTask';
import FormGridCard from '../../../common/formFields/FormGridCard';
import FormGridColumn, { FormGridColumnWidth } from '../../../common/formFields/FormGridColumn';
import DataSourcesPicker from '../../../common/formFields/DataSourcesPicker';
import StagedTaskPicker from '../../../common/formFields/StagedTaskPicker';
import PanelTypes from '../../../utils/types/PanelTypes';
import arrayMustNotBeEmpty from '../../../utils/validation/CustomValidations';
import InlineFormInputErrorMessage from '../../../common/errorContent/InlineFormInputErrorMessage';
import TaskTagPicker from '../../../common/formFields/TaskTagPicker';
import { TaskType } from '../list/BaseTaskListfilters.config';
import config from '../../../utils/ConfigLoader';
import PublishTypeDropdown from '../../../common/formFields/PublishTypeDropdown';
import BcpPriorityDropdown from '../../../common/formFields/BcpPriorityPeakDropdown';
import EstimatedTimeSpinButton from '../../../common/formFields/EstimatedTimeSpinButton';

export const getCountriesLabel = (inputCompanies: ICompany[]) => {
  switch (inputCompanies?.length) {
    case null:
    case 0:
      return null;
    case 1:
      return inputCompanies[0].countryCode;
    default:
      return 'Multiple companies';
  }
};

interface IDraftTaskDetailTaskCardProps {
  form: UseFormMethods<IBaseTask>;
  panelMode: PanelTypes;
  id?: number;
  taskType: TaskType;
  publishTypes: string;
  disableStagedTaskFields?: boolean;
}
const DraftTaskDetailTaskCard = (props: IDraftTaskDetailTaskCardProps): JSX.Element => {
  const { panelMode, form, id, taskType, publishTypes, disableStagedTaskFields } = props;
  const { control, errors, setValue, getValues, register } = form;
  const {
    name,
    description,
    level,
    type,
    scopeOfActivity,
    estimatedTime,
    publishType,
    dependencyOnStagedTasks,
    dependencyForStagedTasks,
    dataSources,
    bcpCategory,
    bcpPriorityPeak,
    bcpPriorityNonPeak,
    taskTags,
  } = getValues();

  const editCardLabel = (): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Stack.Item>
          <h2>
            <Label styles={formGridLabel}>{`Task id ${id}`}</Label>
          </h2>
        </Stack.Item>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: 'copy' }}
            title="Copy task id"
            onClick={() => {
              navigator.clipboard.writeText(id.toString());
            }}
            ariaLabel="Copy task id"
          />
        </Stack.Item>
      </Stack>
    );
  };

  const cardLabel = panelMode === PanelTypes.Edit ? editCardLabel : 'Task';
  // we need to explicitly register levelId to force state update
  // this is required because level dropdown is prepopulated from first
  // query result item
  register('levelId');
  return (
    <FormGridCard cardLabel={cardLabel}>
      <Stack horizontal wrap styles={formGridRowStyles}>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            as={TextField}
            label="Task name"
            id="name"
            required
            name="name"
            control={control}
            resizable={false}
            defaultValue={name}
            placeholder="Type name"
            value={name}
            errorMessage={errors?.name?.message}
            rules={{
              required: 'Please provide a task name',
              maxLength: {
                value: 250,
                message: 'Name cannot have more than 250 characters',
              },
            }}
            disabled={disableStagedTaskFields}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Double}>
          <Controller
            as={TextField}
            label="Task description"
            id="description"
            required
            name="description"
            placeholder="Type description"
            multiline
            control={control}
            resizable={false}
            defaultValue={description}
            value={description}
            errorMessage={errors?.description?.message}
            rules={{
              required: 'Please provide a task description',
              maxLength: {
                value: 1500,
                message: 'Name cannot have more than 1500 characters',
              },
            }}
            disabled={disableStagedTaskFields}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            as={TaskPropertyDropdown}
            label="Task level"
            id="levelId"
            ariaLabel="Task level"
            name="levelId"
            valueKey="levelId"
            firstAsDefault
            taskPropertyName="Task level"
            required
            control={control}
            defaultValue={level?.id.toString()}
            value={level?.id.toString()}
            width="100%"
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setValue(valueKey, newValue.key);
            }}
            errors={errors}
            errorMessage={errors?.levelId?.message}
            rules={{
              required: 'Please provide a task level',
            }}
            disabled={disableStagedTaskFields}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            as={TaskPropertyDropdown}
            required
            label="Task type"
            id="typeId"
            name="typeId"
            valueKey="typeId"
            ariaLabel="Task type"
            taskPropertyName="Task type"
            width="100%"
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setValue(valueKey, newValue.key);
            }}
            errors={errors}
            control={control}
            defaultValue={type?.id.toString()}
            value={type?.id.toString()}
            errorMessage={errors?.typeId?.message}
            rules={{
              required: 'Please provide a task type',
            }}
            disabled={disableStagedTaskFields}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            as={TaskPropertyDropdown}
            label="Scope of activity"
            id="scopeOfActivityId"
            name="scopeOfActivityId"
            valueKey="scopeOfActivityId"
            ariaLabel="Scope of activity"
            taskPropertyName="Scope of activity"
            required
            control={control}
            defaultValue={scopeOfActivity?.id.toString()}
            value={scopeOfActivity?.id.toString()}
            width="100%"
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setValue(valueKey, newValue.key);
            }}
            errors={errors}
            errorMessage={errors?.scopeOfActivityId?.message}
            rules={{
              required: 'Please provide a scope of activity',
            }}
            disabled={disableStagedTaskFields}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="estimatedTime"
            control={control}
            value={estimatedTime?.toString()}
            rules={{
              required: 'Please provide an estimated time',
              min: {
                value: 1,
                message: 'Please provide an estimated time',
              },
            }}
            render={({ value }) => (
              <div>
                <Label
                  id="estimatedTimeLabel"
                  required
                  htmlFor="estimatedTime"
                  disabled={disableStagedTaskFields}
                >
                  Estimated Time (in minutes)
                </Label>
                <EstimatedTimeSpinButton
                  disabled={disableStagedTaskFields}
                  value={value}
                  id="estimatedTime"
                  handleChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    newValue?: string,
                  ): void => {
                    return setValue('estimatedTime', newValue);
                  }}
                />
                <InlineFormInputErrorMessage errorMessage={errors?.estimatedTime?.message} />
              </div>
            )}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="dataSources"
            defaultValue={dataSources}
            value={dataSources}
            render={({ value }) => {
              return (
                <DataSourcesPicker
                  label="Data sources"
                  disabled={disableStagedTaskFields}
                  defaultValue={value}
                  onChange={(dataSourcesTags: IDataSourceOption[]) => {
                    setValue('dataSources', dataSourcesTags.length > 0 ? dataSourcesTags : []);
                  }}
                  errorMessage={(errors?.dataSources as unknown as FieldError)?.message}
                />
              );
            }}
            control={control}
            rules={{
              validate: { arrayMustNotBeEmpty },
            }}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="publishType"
            control={control}
            value={publishType}
            defaultValue={publishType}
            rules={{
              required: 'Please provide a publish type',
            }}
            render={({ value }) => {
              return (
                <PublishTypeDropdown
                  disabled={disableStagedTaskFields}
                  id="publishType"
                  label="Publish type"
                  required
                  errorMessage={errors?.publishType?.message}
                  value={value}
                  taskType={taskType}
                  handleChange={(option?: IDropdownOption): void => {
                    return setValue('publishType', option.key);
                  }}
                />
              );
            }}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            control={control}
            name="dependencyOnStagedTasks"
            label="Dependency on task ID(s)"
            defaultValue={dependencyOnStagedTasks}
            value={dependencyOnStagedTasks}
            render={({ value }) => {
              return (
                <StagedTaskPicker
                  id="taskDependencyOn"
                  label="Dependency on task ID(s)"
                  value={value}
                  defaultValue={value}
                  disabled={disableStagedTaskFields}
                  onChange={(stagedTasks: IBaseTask[]) => {
                    setValue(
                      'dependencyOnStagedTasks',
                      stagedTasks.length > 0 ? stagedTasks : null,
                    );
                  }}
                  errorMessage={(errors?.dependencyOnStagedTasks as unknown as FieldError)?.message}
                  placeholder="Type one or more task(s)"
                />
              );
            }}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="dependencyForStagedTasks"
            label="Dependency for task ID(s)"
            defaultValue={dependencyForStagedTasks}
            value={dependencyForStagedTasks}
            render={({ value }) => {
              return (
                <StagedTaskPicker
                  id="taskDependencyFor"
                  label="Dependency for task ID(s)"
                  value={value}
                  defaultValue={value}
                  disabled={disableStagedTaskFields}
                  onChange={(stagedTasks: IBaseTask[]) => {
                    setValue(
                      'dependencyForStagedTasks',
                      stagedTasks.length > 0 ? stagedTasks : null,
                    );
                  }}
                  errorMessage={
                    (errors?.dependencyForStagedTasks as unknown as FieldError)?.message
                  }
                  placeholder="Type one or more task(s)"
                />
              );
            }}
            control={control}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            as={TaskPropertyDropdown}
            label="BCP category"
            id="bcpCategoryId"
            name="bcpCategoryId"
            valueKey="bcpCategoryId"
            taskPropertyName="BCP Category"
            ariaLabel="BCP category"
            required
            control={control}
            defaultValue={bcpCategory?.id.toString()}
            value={bcpCategory?.id.toString()}
            width="100%"
            disabled={disableStagedTaskFields}
            handleChange={(newValue: IDropdownOption, valueKey: string): void => {
              setValue(valueKey, newValue.key);
            }}
            errors={errors}
            errorMessage={errors?.bcpCategoryId?.message}
            rules={{
              required: 'Please provide a BCP Category',
            }}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="bcpPriorityPeak"
            control={control}
            value={bcpPriorityPeak}
            defaultValue={bcpPriorityPeak}
            rules={{
              required: 'Please provide a BCP peak value',
            }}
            render={({ value }) => (
              <BcpPriorityDropdown
                id="bcpPriorityPeak"
                label="BCP peak"
                required
                errorMessage={errors?.bcpPriorityPeak?.message}
                defaultSelectedKey={value}
                placeholder="Select BCP peak"
                disabled={disableStagedTaskFields}
                handleChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption,
                  index?: number,
                ): void => {
                  return setValue('bcpPriorityPeak', option.key);
                }}
              />
            )}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="bcpPriorityNonPeak"
            control={control}
            value={bcpPriorityNonPeak}
            defaultValue={bcpPriorityNonPeak}
            rules={{
              required: 'Please provide a BCP non-peak  value',
            }}
            render={({ value }) => (
              <BcpPriorityDropdown
                id="bcpPriorityNonPeak"
                label="BCP non-peak"
                required
                errorMessage={errors?.bcpPriorityNonPeak?.message}
                defaultSelectedKey={value}
                placeholder="Select BCP non-peak"
                disabled={disableStagedTaskFields}
                handleChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption,
                  index?: number,
                ): void => {
                  return setValue('bcpPriorityNonPeak', option.key);
                }}
              />
            )}
          />
        </FormGridColumn>
        <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
          <Controller
            name="taskTags"
            control={control}
            defaultValue={taskTags}
            render={({ value }) => (
              <TaskTagPicker
                pickerId="taskTags"
                label="Tags"
                defaultSelectedKey={value}
                disabled={disableStagedTaskFields}
                errorMessage={(errors?.taskTags as unknown as FieldError)?.message}
                onChange={(tags: ITag[]) => {
                  setValue('taskTags', tags.length > 0 ? tags : null);
                }}
                enableNew
              />
            )}
            rules={{
              validate: {
                tagLengthCheck: (tags: ITag[]) => {
                  if (tags && tags.length > 0) {
                    const allComply = tags.every((tag) => tag.name.length <= 30);
                    return allComply || 'Tag must be less than 30 characters';
                  }
                  return true;
                },
              },
            }}
          />
        </FormGridColumn>
        {taskType === TaskType.PublishedTasks && publishTypes === 'JEM_CHECKLIST' && (
          <FormGridColumn columnWidth={FormGridColumnWidth.Single}>
            <span>
              For a Link to your Journal Entry Task, Please visit the
              <strong>
                <Link
                  href={config?.settings?.jemConfig?.siteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  JEM Action Dashboard
                </Link>
              </strong>
            </span>
          </FormGridColumn>
        )}
      </Stack>
    </FormGridCard>
  );
};
export default DraftTaskDetailTaskCard;
