import { useMutation, useQuery } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import BulkEditMode from '../../../utils/types/IBulkEdit';
import {
  GetStagedTaskDeletePreviewCounts,
  RemoveStagedTasksMutation,
} from '../../../utils/api/StagedTaskApi';

interface IStagedTaskDeleteProps {
  selectedTasks?: number[];
  selectedTaskCount?: number;
  keyword?: string;
  bulkDeleteMode?: BulkEditMode;
  filters?: unknown;
  showDeleteModal: boolean;
  hideBulkDeleteModal: (shouldRefetch: boolean) => void;
}

const StagedTaskDeleteModal = (props: IStagedTaskDeleteProps): JSX.Element => {
  const {
    selectedTasks,
    selectedTaskCount,
    keyword,
    bulkDeleteMode,
    filters,
    showDeleteModal,
    hideBulkDeleteModal,
  } = props;

  const getModeVariables = () => {
    return {
      selectedItems: bulkDeleteMode === BulkEditMode.BulkEditSelected ? selectedTasks : null,
      keyword: bulkDeleteMode === BulkEditMode.BulkEditAllFiltered ? keyword : null,
      filters: bulkDeleteMode === BulkEditMode.BulkEditAllFiltered ? filters : null,
      appendOnly: false,
    };
  };

  const {
    data,
    loading: countLoading,
    error: countError,
  } = useQuery(GetStagedTaskDeletePreviewCounts, {
    variables: {
      taskBulkDto: getModeVariables(),
    },
    fetchPolicy: 'network-only',
  });

  const [deleteTasks, { loading, error }] = useMutation(RemoveStagedTasksMutation, {
    onCompleted: () => {
      hideBulkDeleteModal(true);
    },
  });

  const onDeleteClick = () => {
    deleteTasks({
      variables: {
        itemBulkDto: getModeVariables(),
      },
    });
  };

  const getMessage = () => {
    return (
      <>
        <p>
          Clicking Delete/inactivate will{' '}
          <strong> permanently delete staged tasks that have not been drafted or published.</strong>{' '}
          They will need to be recreated if needed. Any dependency references will also be deleted.
        </p>
        <ul>
          <li key="unpublished">
            <strong>Unpublished: </strong>
            {data?.stagedTaskDeletePreviewCounts?.unpublishedCount}
          </li>
        </ul>
        <p>
          Staged tasks that have been drafted or published cannot be deleted and will be
          inactivated.
        </p>
        <ul>
          <li key="published">
            <strong>Drafted/published: </strong>
            {data?.stagedTaskDeletePreviewCounts?.draftPublishedCount}
          </li>
        </ul>
        <p>
          <strong>Total:</strong> {selectedTaskCount}
        </p>
      </>
    );
  };

  return (
    <CoherenceModal
      isOpen={showDeleteModal}
      modalWidth="small"
      height="responsive"
      title="Delete/inactivate staged tasks"
      onDismiss={() => {
        hideBulkDeleteModal(false);
      }}
    >
      <Stack verticalFill>
        <Stack grow>
          <LoadingErrorMessage
            loading={loading}
            error={error}
            label="Deleting/inactivating staged tasks"
          />
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 10 }}>
              <LoadingErrorMessage
                loading={countLoading}
                error={countError}
                label="Loading task counts"
              />
              <span>{getMessage()}</span>
            </Stack>
          </MessageBar>
          <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
            <PrimaryButton
              text="Delete/inactivate"
              onClick={onDeleteClick}
              ariaLabel="Delete"
              disabled={loading}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                hideBulkDeleteModal(false);
              }}
              ariaLabel="Cancel"
              disabled={loading}
            />
          </Stack>
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export default StagedTaskDeleteModal;
