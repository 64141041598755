import React from 'react';
import { IDashboardFilters } from 'fcw-dashboard-components';
import TileClientApp from '../TileClientApp';
import config from '../../../utils/ConfigLoader';

const StatProcessesCard = (props: { filters: IDashboardFilters }): JSX.Element => {
  const { filters } = props;
  const microUiSettings = config?.settings?.statConfig;

  return (
    <TileClientApp
      scriptUrl={`${microUiSettings?.microUiUrl}${microUiSettings?.microUiFilePath}`}
      appName="StatExternalApp"
      data={{
        filters,
        baseUrl: microUiSettings?.siteUrl,
        baseName: 'stat',
      }}
      supportLink="https://stat.microsoft.com/" // TODO Need to update this link
      friendlyAppName="Stat"
    />
  );
};

export default StatProcessesCard;
