import React from 'react';
import { Icon, Link, Stack, Text } from '@fluentui/react';

const helpSectionHeaderStyles = {
  root: { fontWeight: '600', paddingBottom: 10 },
};
interface IHelpSectionLink {
  href: string;
  name: string;
}
const resourcesConfig = [
  {
    href: 'https://aka.ms/fccwresources',
    name: 'FCCW Resources Sharepoint',
  },
  {
    href: 'http://go.microsoft.com/fwlink/?LinkId=518021',
    name: 'Microsoft Data Privacy Notice',
  },
] as IHelpSectionLink[];

const getExternalLink = (link: IHelpSectionLink) => {
  return (
    <Link key={link.name} target="_blank" rel="noopener noreferrer" href={link.href}>
      {link.name} <Icon iconName="NavigateExternalInline" />
    </Link>
  );
};
const HelpPanel = (
  <Stack>
    <Text variant="mediumPlus" styles={helpSectionHeaderStyles}>
      Resources
    </Text>
    <p>Use these documents to learn about FCCW:</p>

    <Stack tokens={{ childrenGap: 5 }}>
      {resourcesConfig.map((link) => {
        return getExternalLink(link);
      })}
    </Stack>
  </Stack>
);

export default HelpPanel;
