export enum TaskChangeStateOption {
  ALL = 'ALL',
  STARTED = 'STARTED',
  NOT_STARTED = 'NOT_STARTED',
  CLOSED = 'CLOSED',
  NOT_PERFORMED = 'NOT_PERFORMED',
  STARTED_AUTOMATION = 'STARTED_AUTOMATION',
}
export enum TaskChangeStateRequiredInputs {
  REASONMISSEDSLA,
  REASONREACTIVATED,
  REASONNOTPERFORMED,
  TIMETAKEN,
}
export interface ITaskChangeStateOption {
  taskState: TaskChangeStateOption;
  taskStateLabel: string;
}
