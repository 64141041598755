import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import PageContainer from '../../../common/page/PageContainer';
import { flexWrapper, pivotWrapperStyles } from '../../../common/styles/CommonStyleObjects';
import { CalendarPivotKeys } from './CalendarList.config';
import FiscalCalendar from './FiscalCalendar';
import VariableDateManagement from './VariableDateManagement';

const CalendarListPage = () => {
  return (
    <PageContainer title="Fiscal calendar">
      <Pivot style={flexWrapper} styles={pivotWrapperStyles} overflowBehavior="menu">
        <PivotItem
          style={flexWrapper}
          headerText="Fiscal Calendar"
          itemKey={CalendarPivotKeys.fiscalCalendar}
        >
          <FiscalCalendar />
        </PivotItem>
        <PivotItem
          style={flexWrapper}
          headerText="Variable Date Management"
          itemKey={CalendarPivotKeys.variableDateManagement}
        >
          <VariableDateManagement />
        </PivotItem>
      </Pivot>
    </PageContainer>
  );
};

export default CalendarListPage;
