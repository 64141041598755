import React from 'react';
import useLocalStorage, { LocalStorageKeys } from '../../../utils/hooks/useLocalStorage';
import { defaultPaginationProps } from '../../../utils/listHelpers';
import { SortDirection } from '../../../utils/types/IListSortDefinition';
import PublishedTaskListPageTemplate, {
  IPublishedTaskListQueryVariables,
} from './PublishedTaskListPageTemplate';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';

const PublishedAllTaskListPage = (): JSX.Element => {
  const defaultFiltersAndSort = {
    keyword: '',
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'dueDate',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IPublishedTaskListQueryVariables>(
    `${LocalStorageKeys.allPublishedTaskQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IPublishedTaskListQueryVariables,
  );

  return (
    <PublishedTaskListPageTemplate
      queryVariables={taskQueryCache}
      setTaskQueryCache={setTaskQueryCache}
      pageTitle="Tasks"
      hideExportAll
    />
  );
};
export default PublishedAllTaskListPage;
