import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import { GetOpsOrgDetailList } from '../../../../utils/api/HierarchiesApi';
import { IOpsOrgDetail } from '../../../../utils/types/IHierarchy';
import PanelTypes from '../../../../utils/types/PanelTypes';
import OpsOrgDetailPanel from './OpsOrgDetailPanel';

interface IOpsOrgDetailEditProps {
  opsOrgDetail: IOpsOrgDetail;
  closePanel: (shouldRefresh: boolean) => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const OpsOrgDetailEdit = (props: IOpsOrgDetailEditProps): JSX.Element => {
  const { mutation, opsOrgDetail, closePanel, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetOpsOrgDetailList }];
  const [updateOpsOrgDetailValue, { loading, error }] = useMutation(mutation, {
    onCompleted: () => closePanel(true),
    refetchQueries,
  });
  const submitForm = (opsOrgDetailDtoData: IOpsOrgDetail) => {
    const opsOrgId = parseInt(opsOrgDetailDtoData?.opsOrgId?.toString(), 10);

    updateOpsOrgDetailValue({
      variables: {
        opsOrgDetailDto: {
          id: opsOrgDetail.id,
          name: opsOrgDetailDtoData.name,
          opsOrgId,
          isActive: opsOrgDetailDtoData.isActive,
          autoPublish: opsOrgDetailDtoData.autoPublish,
          companyCodes: opsOrgDetailDtoData.companyCodes.map((cc) => cc.companyCode),
          checklistOwners: opsOrgDetailDtoData.checklistOwners,
          backupTaskPerformers: opsOrgDetailDtoData.backupTaskPerformers,
          backupJeReviewers: opsOrgDetailDtoData.backupJeReviewers,
          backupJePosters: opsOrgDetailDtoData.backupJePosters,
          backupRiReconcilers: opsOrgDetailDtoData.backupRiReconcilers,
          backupRiReviewers: opsOrgDetailDtoData.backupRiReviewers,
          backupRiApprovers: opsOrgDetailDtoData.backupRiApprovers,
          deadline: opsOrgDetailDtoData.deadline,
        },
      },
    });
  };

  return (
    <OpsOrgDetailPanel
      data={opsOrgDetail}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default OpsOrgDetailEdit;
