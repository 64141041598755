import React from 'react';
import {
  Checkbox,
  GroupedList,
  IGroup,
  IGroupHeaderProps,
  Icon,
  Label,
  PrimaryButton,
  SelectionMode,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';
import { truncateString } from '../../utils/Helpers';

export interface IGenericGroupedChecklistProps {
  itemGroups: IGroup[];
  // Note: The items need to be ordered by the groups starting from the top level group going to the bottom level group
  // i.e. If you are grouping by Area, Region, and SubRegion, the items need to be ordered by Area first, then Region, then SubRegion
  items: IGroupedChecklistItem[];
  onCheckChanged?: (currentSelections: IGroupedChecklistItem[]) => void;
  onClearAllSelections: () => void;
  topGroupsCollapsed?: boolean;
  multiSelect?: boolean;
}

const GenericGroupedChecklist = (props: IGenericGroupedChecklistProps): JSX.Element => {
  const {
    topGroupsCollapsed = true,
    onCheckChanged,
    onClearAllSelections,
    items,
    itemGroups,
    multiSelect,
  } = props;

  const onChange = (checklistItem: IGroupedChecklistItem, isChecked: boolean): void => {
    let selections = items?.filter((item) => item.selected); // Selected items
    selections = selections.map(
      (item) =>
        ({
          key: item.key,
          name: item.name,
          selected: item.selected,
        } as IGroupedChecklistItem),
    );

    // Add or remove checklist tag based on the isChecked value
    const indexOfValue = selections?.findIndex(
      (item) => (item.key ?? item.name) === (checklistItem.key ?? checklistItem.name),
    );
    if (isChecked) {
      if (multiSelect === false) {
        selections = [
          {
            key: checklistItem.key,
            name: checklistItem.name,
            selected: isChecked,
          } as IGroupedChecklistItem,
        ];
      } else {
        selections.push({
          key: checklistItem.key,
          name: checklistItem.name,
          selected: isChecked,
        } as IGroupedChecklistItem);
      }
    } else {
      selections.splice(indexOfValue, 1);
    }
    if (onCheckChanged) {
      onCheckChanged(selections);
    }
  };

  const getLabelStyle = (level: number, containsSelectedItem: boolean) => {
    // The label text needs to be bold if there is a selected item under it
    const baseStyle = {
      fontWeight: containsSelectedItem ? 'bold' : 'normal',
      fontSize: 14,
    };
    return baseStyle;
  };

  const onRenderCell = (
    nestingDepth?: number,
    item?: IGroupedChecklistItem,
    itemIndex?: number,
    group?: IGroup,
  ): JSX.Element => {
    return item ? (
      <TooltipHost content={item?.name} id="tooltip">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: `6px 10px 6px ${22 * nestingDepth}px`,
            minHeight: 29, // Minimum height to match the height of the header
          }}
        >
          <Checkbox
            label={truncateString(item?.name, 30)}
            defaultChecked={item?.selected}
            onChange={(ev, isChecked) => onChange(item, isChecked)}
          />
        </div>
      </TooltipHost>
    ) : null;
  };

  const onRenderHeader = (headerProps?: IGroupHeaderProps): JSX.Element | null => {
    if (headerProps) {
      const isCollapsed = headerProps.group?.isCollapsed;
      const containsSelectedItem = headerProps.group?.data?.containsSelectedItem;
      const level = headerProps.group?.level ?? 0;
      const indentSize = 18;
      const labelText = `${headerProps.group?.name} (${headerProps.group?.count ?? 0})`;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: `6px 10px 6px ${3 + level * indentSize}px`,
            cursor: 'pointer',
          }}
        >
          <Icon
            iconName="ChevronRight"
            onClick={() => headerProps.onToggleCollapse(headerProps.group)}
            style={{
              marginRight: '10px',
              transition: 'transform .1s linear',
              transform: `rotate(${isCollapsed ? 0 : 90}deg)`,
            }}
          />
          <Label style={getLabelStyle(level, containsSelectedItem)}>{labelText}</Label>
        </div>
      );
    }
    return null;
  };

  return (
    <Stack verticalAlign="start" verticalFill>
      <>
        {onClearAllSelections && (
          <Stack
            horizontal
            styles={{ root: { padding: '5px 0px 10px 0px' } }}
            tokens={{ childrenGap: 20 }}
          >
            <PrimaryButton
              text="Clear Selections"
              title="Clear Selections"
              ariaLabel="Clear Selections"
              onClick={onClearAllSelections}
              allowDisabledFocus
              disabled={false}
            />
          </Stack>
        )}
        <GroupedList
          items={items}
          selectionMode={SelectionMode.single}
          onShouldVirtualize={() => false}
          groups={itemGroups?.map((group) => ({
            ...group,
            isCollapsed: topGroupsCollapsed,
          }))}
          groupProps={{
            onRenderHeader,
          }}
          onRenderCell={onRenderCell}
        />
      </>
    </Stack>
  );
};
export default GenericGroupedChecklist;
