import { gql } from '@apollo/client';

const GetJemUserBasedFilter = gql`
  query jemUserBasedFilter($filters: DashboardFiltersDtoInput!) {
    jemUserBasedFilter(filters: $filters) {
      companyCodes
      opsOrgDetailsId
    }
  }
`;
export default GetJemUserBasedFilter;
