import { useQuery } from '@apollo/client';
import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import { GetOpsSummaryList } from '../../utils/api/HierarchiesApi';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../utils/formatters/PaginatedResultFormatter';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { IOpsSummary } from '../../utils/types/IHierarchy';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';

interface IOpsSummaryDropdown extends IFieldsetProps {
  valueKey: string;
}

export const mapOpsSummaryToDropdown = (hierarchiesList: IOpsSummary[]): IDropdownOption[] => {
  return hierarchiesList?.map((opsSummary: IOpsSummary): IDropdownOption => {
    return {
      key: opsSummary.id,
      text: opsSummary.name,
    };
  });
};

const OpsSummaryDropdown = (dropdownProps: IOpsSummaryDropdown): JSX.Element => {
  const {
    valueKey,
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    outputKey,
    required,
  } = dropdownProps;
  const { loading: dropdownLoading, data: dropdownData } = useQuery(GetOpsSummaryList, {
    variables: {
      first: 1000, // hack to get all the options
      after: null,
      last: null,
      before: null,
    },
  });
  const { opsSummaries } = !dropdownLoading && dropdownData ? dropdownData : [];
  const dataLoaded = !dropdownLoading;
  const dataResult = dataLoaded
    ? paginatedResultFormatter<IOpsSummary>(opsSummaries)
    : getEmptyResults<IOpsSummary>();

  return (
    <QueryBasedDropdown
      required={required}
      value={value}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={mapOpsSummaryToDropdown(dataResult.data)}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
    />
  );
};

export default OpsSummaryDropdown;
