import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { DefaultButton, Stack } from '@fluentui/react';
import SavedFilterCard from './SavedFilterCard';
import { FilterType, ISavedFilter } from '../../utils/types/SavedFilters';
import { SAVED_FILTER_LIST_QUERY } from '../../utils/api/SavedFilters';
import LoadingErrorMessage from '../../common/errorContent/LoadingErrorMessage';
import { expandButtonStyle } from '../../common/styles/CommonStyleObjects';

interface ISharedFiltersTabProps {
  filterType: FilterType;
}

const MySavedFiltersTab = (props: ISharedFiltersTabProps): JSX.Element => {
  const { filterType } = props;

  const [isAllCollapsed, setIsAllCollapsed] = useState(false);
  const toggleCollapseState = (): void => {
    setIsAllCollapsed(!isAllCollapsed);
  };

  const { data, loading, error } = useQuery(SAVED_FILTER_LIST_QUERY, {
    variables: {
      filterType: filterType.toString(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const filterTypeMessage =
    filterType === FilterType.PERSONAL
      ? 'Click the New Saved Filter button to create one.'
      : 'Have someone share a filter with you.';

  return (
    <>
      <LoadingErrorMessage loading={loading} error={error} />
      <DefaultButton
        className={expandButtonStyle}
        text="Collapse/Expand All"
        onClick={toggleCollapseState}
      />
      <br />
      <br />
      <Stack tokens={{ childrenGap: 10 }}>
        {!loading && !error && data?.savedFilters?.length === 0 && (
          <p>
            You do not have any {filterType.toString().toLowerCase()} saved filters yet.{' '}
            {filterTypeMessage}
          </p>
        )}
        {data?.savedFilters?.map((filter: ISavedFilter) => {
          return (
            <SavedFilterCard
              savedFilter={filter}
              filterType={filterType}
              allCollapsed={isAllCollapsed}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default MySavedFiltersTab;
