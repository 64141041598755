import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import {
  IOpsDetailFilterIds,
  IOpsOrgFilterIds,
  IOpsSummaryFilterIds,
} from '../../../utils/types/IHierarchy';

export const getTagValues = (tagName: string, tags: IGenericFilterTag[]): (string | number)[] => {
  const ids = tags?.find((tag) => tag.dataKey === tagName)?.values?.map((tag) => tag.key);
  return ids;
};

export const mapOpsSummaryFilterTagsToIds = (
  filters: IGenericFilterTag[],
): IOpsSummaryFilterIds => {
  const opsSummaryFitlers = {
    opsSummaryIds: getTagValues('opsSummaries', filters),
    isActive: getTagValues('isActive', filters),
  };

  return opsSummaryFitlers;
};

export const mapOpsOrgFilterTagsToIds = (filters: IGenericFilterTag[]): IOpsOrgFilterIds => {
  const opsOrgFilters = mapOpsSummaryFilterTagsToIds(filters) as IOpsOrgFilterIds;
  opsOrgFilters.opsOrgIds = getTagValues('opsOrgs', filters);

  return opsOrgFilters;
};

export const mapOpsDetailTagsToIds = (filters: IGenericFilterTag[]): IOpsDetailFilterIds => {
  const opsOrgFilters: IOpsOrgFilterIds = mapOpsOrgFilterTagsToIds(filters);
  const opsDetailFilters = {
    ...opsOrgFilters,
    companyCodes: getTagValues('companies', filters),
    opsOrgDetailIds: getTagValues('opsOrgDetails', filters),
    backupTaskPerformerGuids: getTagValues('backupTaskPerformer', filters),
    checklistOwnerGuids: getTagValues('checklistOwner', filters),
    backupJeReviewerGuids: getTagValues('backupJeReviewer', filters),
    backupJePosterGuids: getTagValues('backupJePoster', filters),
    backupRiReconcilerGuids: getTagValues('backupRiReconciler', filters),
    backupRiReviewerGuids: getTagValues('backupRiReviewer', filters),
    backupRiApproverGuids: getTagValues('backupRiApprover', filters),
    autoPublish: getTagValues('autoPublish', filters),
    invalidUserType: getTagValues('invalidUserType', filters),
  };

  return opsDetailFilters;
};
