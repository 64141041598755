import { DocumentNode, useMutation } from '@apollo/client';
import React from 'react';
import PanelTypes from '../../../../utils/types/PanelTypes';
import { GetDataSourcesList } from '../../../../utils/api/DataSourcesApi';
import DataSourceApplicationPanel from './DataSourceApplicationPanel';
import IDataSourceApplication from '../../../../utils/types/IDataSourceApplication';

interface IDataSourceApplicationEditProps {
  dataSourceApplication: IDataSourceApplication;
  closePanel: () => void;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const DataSourceApplicationEdit = (props: IDataSourceApplicationEditProps): JSX.Element => {
  const { mutation, dataSourceApplication, closePanel, panelMode } = props;
  const refetchQueries = panelMode === PanelTypes.Edit ? [] : [{ query: GetDataSourcesList }];
  const [updateDataSourceApplicationValue, { loading, error }] = useMutation(mutation, {
    onCompleted: closePanel,
    refetchQueries,
  });
  const submitForm = (dataSourceApplicationData: IDataSourceApplication) => {
    updateDataSourceApplicationValue({
      variables: {
        dataSourceApplicationDto: {
          id: dataSourceApplication.id,
          name: dataSourceApplicationData.name,
        },
      },
    });
  };

  return (
    <DataSourceApplicationPanel
      data={dataSourceApplication}
      closePanel={closePanel}
      mutation={submitForm}
      mutationError={error}
      mutationLoading={loading}
      panelMode={panelMode}
    />
  );
};
export default DataSourceApplicationEdit;
