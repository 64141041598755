import { useQuery } from '@apollo/client';
import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import QueryBasedDropdown from '../forms/QueryBasedDropdown';
import IFieldsetProps from '../../utils/types/IFieldsetProps';
import { TaskChangeStateOption } from '../../utils/types/ITaskChangeStateOption';
import { GetTaskStates } from '../../utils/api/PublishedTaskApi';
import { INamable } from '../../utils/types/IHierarchy';

const mapToDropdown = (item: INamable): IDropdownOption => {
  return { key: item.id, text: item.name };
};

const mapData = (data: { taskStates: { values: INamable[] } }): IDropdownOption[] => {
  if (!data) {
    return [];
  }
  return data.taskStates?.values?.map((tpv: INamable) => {
    return mapToDropdown(tpv);
  });
};
interface IChangeStateDropdownProps extends IFieldsetProps {
  showAllOption?: boolean;
}
const ChangeStateDropdown = (dropdownProps: IChangeStateDropdownProps): JSX.Element => {
  const {
    value,
    label,
    errors: dropdownErrors,
    handleChange,
    valueKey,
    outputKey,
    required,
    width,
    disabled,
    showAllOption,
  } = dropdownProps;
  const { loading, data } = useQuery(GetTaskStates);
  const allOptions = showAllOption
    ? ([
        {
          key: 'ALL',
          text: TaskChangeStateOption.ALL,
        },
        {
          key: 'NOT_STARTED',
          text: TaskChangeStateOption.NOT_STARTED,
        },
      ] as IDropdownOption[])
    : [];

  return (
    <QueryBasedDropdown
      ariaLabel={label}
      required={required}
      value={value}
      label={label}
      outputKey={outputKey}
      dropdownOptionsData={!loading && data ? allOptions.concat(mapData(data) || []) : []}
      errors={dropdownErrors}
      handleChange={handleChange}
      valueKey={valueKey}
      width={width}
      disabled={disabled}
    />
  );
};

export default ChangeStateDropdown;
