import React from 'react';
import { useMutation } from '@apollo/client';
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import BulkEditMode from '../../../utils/types/IBulkEdit';
import LoadingErrorMessage from '../../../common/errorContent/LoadingErrorMessage';
import { ActivateStagedTasksMutation } from '../../../utils/api/StagedTaskApi';

interface IStagedTaskActivationProps {
  selectedTasks?: number[];
  selectedTaskCount?: number;
  keyword?: string;
  bulkActivationMode?: BulkEditMode;
  filters?: unknown;
  showActivationModal: boolean;
  hideActivationModal: (shouldRefetch: boolean) => void;
}

const StagedTaskActivationModal = (props: IStagedTaskActivationProps): JSX.Element => {
  const {
    selectedTasks,
    selectedTaskCount,
    keyword,
    bulkActivationMode,
    filters,
    showActivationModal,
    hideActivationModal,
  } = props;

  const getModeVariables = () => {
    return {
      selectedItems: bulkActivationMode === BulkEditMode.BulkEditSelected ? selectedTasks : null,
      keyword: bulkActivationMode === BulkEditMode.BulkEditAllFiltered ? keyword : null,
      filters: bulkActivationMode === BulkEditMode.BulkEditAllFiltered ? filters : null,
      appendOnly: false,
    };
  };

  const [activateTasks, { loading, error }] = useMutation(ActivateStagedTasksMutation, {
    onCompleted: () => {
      hideActivationModal(true);
    },
  });

  const onActivateClick = () => {
    activateTasks({
      variables: {
        itemBulkDto: getModeVariables(),
      },
    });
  };

  const getMessage = () => {
    return (
      <>
        <p>
          Clicking Activate will{' '}
          <strong>
            re-activate {selectedTaskCount} Staged Task{selectedTaskCount === 1 ? '' : 's'}, making{' '}
            {selectedTaskCount === 1 ? 'it' : 'them'} eligible for publishing
          </strong>
        </p>
      </>
    );
  };

  return (
    <CoherenceModal
      isOpen={showActivationModal}
      modalWidth="small"
      height="responsive"
      title="Activate staged tasks"
      onDismiss={() => {
        hideActivationModal(false);
      }}
    >
      <Stack verticalFill>
        <Stack grow>
          <LoadingErrorMessage loading={loading} error={error} label="Activating staged tasks" />
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 10 }}>
              <span>{getMessage()}</span>
            </Stack>
          </MessageBar>
          <Stack horizontal styles={{ root: { padding: '20px 0' } }} tokens={{ childrenGap: 30 }}>
            <PrimaryButton
              text="Activate"
              onClick={onActivateClick}
              ariaLabel="Activate"
              disabled={loading}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                hideActivationModal(false);
              }}
              ariaLabel="Cancel"
              disabled={loading}
            />
          </Stack>
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export default StagedTaskActivationModal;
