import { gql } from '@apollo/client';

export const GetSalesLocationsByFilter = gql`
  query SalesLocations($keyword: String!) {
    salesLocations(keyword: $keyword) {
      code
      name
      area
      region
      subRegion
    }
  }
`;

export default GetSalesLocationsByFilter;
