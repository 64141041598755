import { ActionButton, IconButton, IIconProps, mergeStyleSets, Stack } from '@fluentui/react';
import React, { PropsWithChildren, useState } from 'react';
import { accordionFilterHeaderStyles } from '../styles/CommonStyleObjects';

const stackStyles = mergeStyleSets({ root: { borderBottom: '1px solid #E5E5E5' } });

type CollapsibleProps = PropsWithChildren<{
  text: string;
  defaultCollapsed?: boolean;
}>;

const Collapsible = (props: CollapsibleProps): JSX.Element => {
  const { text, defaultCollapsed, children } = props;
  const [contentIsCollapsed, setContentIsCollapsed] = useState(defaultCollapsed ?? true);

  const chevronDown: IIconProps = {
    iconName: 'chevronDown',
  };
  const chevronRight: IIconProps = {
    iconName: 'chevronRight',
  };

  return (
    <Stack className={stackStyles.root}>
      <Stack horizontal horizontalAlign="space-between">
        <ActionButton
          styles={accordionFilterHeaderStyles}
          onClick={(): void => {
            setContentIsCollapsed(!contentIsCollapsed);
          }}
        >
          {text}
        </ActionButton>
        <Stack verticalFill verticalAlign="center">
          <IconButton
            iconProps={contentIsCollapsed ? chevronRight : chevronDown}
            title={contentIsCollapsed ? `Expand ${text}` : `Collapse ${text}`}
            ariaLabel={contentIsCollapsed ? `Expand ${text}` : `Collapse ${text}`}
            aria-expanded={!contentIsCollapsed}
            onClick={(): void => {
              setContentIsCollapsed(!contentIsCollapsed);
            }}
          />
        </Stack>
      </Stack>
      {!contentIsCollapsed && (
        <Stack.Item styles={{ root: { padding: 10 } }}>{children}</Stack.Item>
      )}
    </Stack>
  );
};

export default Collapsible;
