import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../ConfigLoader';
import {
  silentGraphRequest,
  graphRequest,
  getSilentFcwApiRequest,
  getFcwApiRequest,
} from '../authentication/AuthConfig';
import { AuthModule } from '../authentication/AuthModule';

let msalInstance: AuthModule;

async function apiRequest(
  url: string,
  accessToken: string,
  httpMethod = 'GET',
  body?: FormData | string,
): Promise<Response> {
  const headers = new Headers();

  if (typeof body === 'string') {
    headers.set('content-type', 'application/json;charset=UTF-8');
  }

  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: httpMethod,
    headers,
    body,
  };

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    return null; // TODO: need to do something with error
  }
}

export const setAuthInstance = (auth: AuthModule): void => {
  msalInstance = auth;
};

export const getAuthInstance = (): AuthModule => {
  return msalInstance;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function graphApiRequest(endpoint: string, returnJson = true): Promise<any> {
  const graphUrl = endpoint;

  return msalInstance.acquireToken(silentGraphRequest, graphRequest).then((accessToken: string) => {
    return apiRequest(graphUrl, accessToken).then((apiResponse) =>
      returnJson ? apiResponse.json() : apiResponse,
    );
  });
}

const authLink = setContext((_, { headers }) => {
  return msalInstance
    .acquireToken(getSilentFcwApiRequest(), getFcwApiRequest())
    .then((accessToken: string) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      };
    });
});

export const getClient = (): ApolloClient<unknown> => {
  const httpLink = createHttpLink({
    uri: `${config?.settings?.baseURI}/graphql/`,
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(), // previously handled automatically
  });
};

export const getCurrentAppVersion = (): string => {
  return config?.settings?.appBuildId;
};

export async function restApiRequest(
  endpoint: string,
  method = 'GET',
  body?: FormData | string,
  returnJson = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const apiUrl = `${config?.settings?.baseURI}/api/${endpoint}`;

  const accessToken = await msalInstance.acquireToken(getSilentFcwApiRequest(), getFcwApiRequest());
  const apiResponse = await apiRequest(apiUrl, accessToken, method, body);
  return returnJson ? apiResponse.json() : apiResponse;
}

export async function restApiDocumentUploadRequest(
  endpoint: string,
  body: FormData,
  returnJson = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const apiUrl = `${config?.settings?.baseURI}/api/${endpoint}`;

  const accessToken = await msalInstance.acquireToken(getSilentFcwApiRequest(), getFcwApiRequest());
  const apiResponse = await apiRequest(apiUrl, accessToken, 'POST', body);

  return returnJson ? apiResponse?.json() : apiResponse?.text();
}
