import { gql } from '@apollo/client';
import { OPS_DETAIL_BASE_FRAGMENT } from './HierarchiesApi';
import { baseTaskProps } from './StagedTaskApi';

const PUBLISHED_TASK_FRAGMENT = gql`
  fragment PublishedTaskFull on PublishedTaskDto {
    ${baseTaskProps}
    dueDate
    stagedTaskId
    publishedTaskNotes
    lastAutomationRun{
      correlationId
    }
    lastAction {
      state
      notes
      modifiedOn
      modifiedBy {
        graphGuid
        displayName
        alias
        emailAddress
      }
    }
    status
    fiscalYear
    fiscalPeriod
  }
  ${OPS_DETAIL_BASE_FRAGMENT}
`;

export const GetPublishedTasks = gql`
  query publishedTasks(
    $keyword: String
    $filters: TaskFilterDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $order: [PublishedTaskDtoSortInput!]
  ) {
    publishedTasks(
      keyword: $keyword
      filters: $filters
      status: PUBLISHED
      first: $first
      after: $after
      last: $last
      before: $before
      order: $order
    ) {
      edges {
        node {
          ...PublishedTaskFull
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;

export const EditPublishedTask = gql`
  mutation editPublishedTask($publishedTaskDto: TaskSingleInputDtoInput!) {
    editPublishedTask(publishedTaskDto: $publishedTaskDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;
export const EditPublishedTaskBulk = gql`
  mutation editPublishedTaskBulk($itemBulkDto: TaskBulkInputDtoInput!) {
    editPublishedTaskBulk(itemBulkDto: $itemBulkDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;
export const EditPublishedTaskRemoveBulk = gql`
  mutation editPublishedTaskRemoveBulk($itemBulkDto: TaskBulkInputDtoInput!) {
    editPublishedTaskRemoveBulk(itemBulkDto: $itemBulkDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;
export const GetPublishedTask = gql`
  query PublishedTask($publishedTaskId: Int!) {
    publishedTask(publishedTaskId: $publishedTaskId) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;

export const PublishDraftTasksMutation = gql`
  mutation generatePublishedTasks($generateTasksDto: DraftTaskGeneratorDtoInput!) {
    generatePublishedTasks(generateTasksDto: $generateTasksDto) {
      id
    }
  }
`;

export const GetTaskStates = gql`
  query GetTaskStates {
    taskStates {
      id
      name
      values {
        id
        name
        isActive
      }
    }
  }
`;
export const GetChangeStateRequiredInputs = gql`
  query ChangeStateRequiredFields($changeStateTaskDto: ChangeStateTaskDtoInput!) {
    changeStateRequiredFields(changeStateTaskDto: $changeStateTaskDto) {
      isTimeTakenRequired
      isTimeTakenOptional
      isReasonMissedSLARequired
      isReasonNotPerformedRequired
      isReasonReactivatedRequired
    }
  }
`;
export const TaskChangeState = gql`
  mutation changeTaskState($changeStateTaskDto: ChangeStateTaskDtoInput!) {
    changeTaskState(changeStateTaskDto: $changeStateTaskDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;
export const AddPublishedTask = gql`
  mutation addPublishedTask($publishedTaskInputDto: PublishedTaskInputDtoInput!) {
    addPublishedTask(publishedTaskInputDto: $publishedTaskInputDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;

export const AddPublishedTaskCopy = gql`
  mutation addPublishedTaskCopy($publishedTaskInputDto: PublishedTaskInputDtoInput!) {
    addPublishedTaskCopy(publishedTaskInputDto: $publishedTaskInputDto) {
      ...PublishedTaskFull
    }
  }
  ${PUBLISHED_TASK_FRAGMENT}
`;

export const GetPublishedTaskActions = gql`
  query PublishedTaskActions($publishedTaskId: Int!) {
    publishedTaskActions(publishedTaskId: $publishedTaskId) {
      id
      state
      notes
      modifiedOn
      manualTime
      automatedTime
      reasons {
        id
        reasonType
        reasonSelection
      }
      modifiedBy {
        displayName
        graphGuid
        alias
        emailAddress
      }
    }
  }
`;

export const TriggerAutomationStart = gql`
  mutation triggerAutomationStart($publishedTaskIdDto: PublishedTaskIdDtoInput!) {
    triggerAutomationStart(publishedTaskIdDto: $publishedTaskIdDto) {
      correlationId
      planIdentifier
      opsDetailId
    }
  }
`;
