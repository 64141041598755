import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { THROTTLE_SEARCH_TIMEOUT } from '../../../../common/constants/SiteConstants';
import CompanyChecklist from '../../../../common/formFields/CompanyChecklist';
import CountryChecklist from '../../../../common/formFields/CountryChecklist';
import GraphUsersFilter from '../../../../common/formFields/GraphUsersFilter';
import YesNoChecklist from '../../../../common/formFields/YesNoChecklist';
import {
  getColumnConfigByKey,
  ILocalStorageColumnConfig,
} from '../../../../common/lists/ColumnConfigHelper';
import GeneralEntityList, {
  IGeneralEntityListProps,
} from '../../../../common/lists/GeneralEntityList';
import ListFilters from '../../../../common/lists/ListFilters';
import PaginationWrapperManaged from '../../../../common/lists/PaginationWrapperManaged';
import { bodyContentContainer } from '../../../../common/styles/CommonStyleObjects';
import GetCompanyCodeList, {
  EditCompanyCodeValue,
} from '../../../../utils/api/CompanyCodeAssignmentApi';
import paginatedResultFormatter, {
  getEmptyResults,
} from '../../../../utils/formatters/PaginatedResultFormatter';
import { convertDotPathToNestedObject } from '../../../../utils/Helpers';
import useLocalStorage, { LocalStorageKeys } from '../../../../utils/hooks/useLocalStorage';
import {
  defaultPaginationProps,
  getCurrentSortingDefinition,
  getDefaultStateProps,
  getResetPaginationProps,
} from '../../../../utils/listHelpers';
import ICompanyCodeAssignment from '../../../../utils/types/ICompanyCodeAssignment';
import { IGenericFilterTag } from '../../../../utils/types/IGenericFilterTag';
import { IListQueryVariables, IEntityListState } from '../../../../utils/types/IList';
import { IQuerySortDefinition, SortDirection } from '../../../../utils/types/IListSortDefinition';
import { PaginationQueryVariables } from '../../../../utils/types/PaginationTypes';
import PanelTypes from '../../../../utils/types/PanelTypes';
import ColumnConfigPanel from '../../../tasks/list/ColumnConfigPanel';
import CompanyCodeAssignmentEdit from '../../details/companycodeassignment/CompanyCodeAssignmentEdit';
import { getCompanyCodeMenu, getCompanyCodeColumnList } from './CompanyCodeAssignmentList.config';
import GenericFilterTagHandler, {
  filterOutSavedTags,
} from '../../../../common/lists/GenericFilterTagHandler';

interface IPanelState extends IEntityListState {
  showEditPanel: boolean;
  showAddPanel: boolean;
  selectedItem: ICompanyCodeAssignment;
}
const CompanyCodeAssignmentListPage = (): JSX.Element => {
  const [listColumnsCache, setListColumnsCache] = useLocalStorage<ILocalStorageColumnConfig[]>(
    LocalStorageKeys.companyCodeAssignmentColumns,
    [],
  );
  let filterTimeout: string | number | NodeJS.Timeout;
  const defaultFiltersAndSort = {
    filterTags: [] as IGenericFilterTag[],
    sortDir: SortDirection.ASC,
    sortKey: 'companyCode',
  };

  /** Query state  cache */
  const [taskQueryCache, setTaskQueryCache] = useLocalStorage<IListQueryVariables>(
    `${LocalStorageKeys.companyCodeAssignmentQueryState}`,
    {
      ...defaultPaginationProps,
      ...defaultFiltersAndSort,
    } as IListQueryVariables,
  );

  /** Page State */
  const [pageState, setPagesState] = useState<IPanelState>({
    showAddPanel: false,
    showEditPanel: false,
    selectedItem: null,
    ...getDefaultStateProps(taskQueryCache),
  });

  const [getData, { data, loading, error }] = useLazyQuery(GetCompanyCodeList, {
    variables: {
      ...taskQueryCache?.pageInfo,
      keyword: taskQueryCache.keyword,
      filters: {},
      /* We have the sort def saved in cache right? cant we use that here */
      order: [
        convertDotPathToNestedObject(taskQueryCache?.sortKey, taskQueryCache?.sortDir),
      ] as IQuerySortDefinition[],
    },
  });

  const onRefreshClick = () => {
    setPagesState({
      ...pageState,
      ...getResetPaginationProps(taskQueryCache?.paginationSize),
    });
  };
  const dataLoaded = !loading && !error;

  const dataResult = dataLoaded
    ? paginatedResultFormatter<ICompanyCodeAssignment>(data?.companyCodeAssignments)
    : getEmptyResults<ICompanyCodeAssignment>();

  const cachedListColumns = getColumnConfigByKey(
    getCompanyCodeColumnList((selectedItem: ICompanyCodeAssignment) => {
      setPagesState({
        ...pageState,
        selectedItem,
        showEditPanel: true,
        showAddPanel: false,
      });
    }),
    listColumnsCache,
  );
  const detailsListConfig: IGeneralEntityListProps<ICompanyCodeAssignment> = {
    data: dataResult.data || [],
    listColumns: cachedListColumns.filter((col) => {
      return (col as ILocalStorageColumnConfig).active;
    }),
    commandBarItems: getCompanyCodeMenu(onRefreshClick, {
      key: 'ExportTaskPropertyCompanyCodeAssignment',
      fileEndpoint: 'CompanyCodeAssignment',
      fileName: 'CompanyCodeAssignment',
    }),
    onEditColumnOrderClick: () => {
      setPagesState({
        ...pageState,
        showColumnConfigPanel: true,
      });
    },
    onSearchBoxChange: (ev, searchTerm) => {
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        setPagesState({
          ...pageState,
          keyword: searchTerm,
        });
      }, THROTTLE_SEARCH_TIMEOUT);
    },
    initialSearchItem: taskQueryCache.keyword,
    disableSelect: true,
    loading,
    error,
    sortDefinition: {
      sortDir: pageState.sortDir,
      sortKey: pageState.sortKey,
    },
    onSort: (col: ILocalStorageColumnConfig) => {
      setPagesState({
        ...pageState,
        ...getCurrentSortingDefinition(pageState, col.key),
      });
    },
    GeneralFilterTagHandler: () => {
      return (
        <GenericFilterTagHandler
          onChange={(filterTags: IGenericFilterTag[]) =>
            setPagesState({
              ...pageState,
              filterTags,
              ...getResetPaginationProps(taskQueryCache?.paginationSize),
            })
          }
          allFilterTags={pageState.filterTags}
        >
          <ListFilters>
            <CompanyChecklist dataKey="companies" label="Co. code" isActive={false} />
            <CountryChecklist dataKey="countries" label="Country" isActive={false} />
            <GraphUsersFilter
              dataKey="owners"
              label="Owners"
              removeButtonAriaLabel="Remove owner"
              controlName="checklistOwner"
              placeholder="Type one or more Owners"
            />
            <YesNoChecklist dataKey="isactive" label="Is active" />
            <YesNoChecklist dataKey="inglobalchecklist" label="In Global checklist" />
            <YesNoChecklist dataKey="inlocalchecklist" label="In Local checklist" />
          </ListFilters>
        </GenericFilterTagHandler>
      );
    },
  };
  const getTagValues = (tagName: string, tags: IGenericFilterTag[]): (string | number)[] => {
    const ids = tags?.find((tag) => tag.dataKey === tagName)?.values?.map((tag) => tag.key);
    return ids;
  };
  const getFilters = (filters: IGenericFilterTag[]) => {
    return {
      companyCodes: getTagValues('companies', filters),
      countryCodes: getTagValues('countries', filters),
      ownerGuids: getTagValues('owners', filters),
      isActive: getTagValues('isactive', filters),
      inGlobalChecklist: getTagValues('inglobalchecklist', filters),
      inLocalChecklist: getTagValues('inlocalchecklist', filters),
    };
  };

  /** UseEffects and use effect helper methods */
  const setCache = () => {
    const {
      keyword,
      filterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    } = pageState;
    const nonSavedFilterTags = filterOutSavedTags(filterTags);
    setTaskQueryCache({
      ...taskQueryCache,
      keyword,
      filterTags: nonSavedFilterTags,
      pageInfo,
      selectedPaginationPage,
      paginationSize,
      sortDir,
      sortKey,
    });
  };
  const refreshPage = () => {
    const { keyword, filterTags, sortDir, sortKey } = pageState;
    const filters = getFilters(filterTags);
    const sortArray = [convertDotPathToNestedObject(sortKey, sortDir?.toString())];
    getData({
      variables: {
        keyword,
        ...pageState.pageInfo,
        filters,
        order: sortArray,
      },
      fetchPolicy: 'network-only',
    });
  };

  const closePanels = (shouldRefetch = false): void => {
    setPagesState({
      ...pageState,
      showAddPanel: false,
      showEditPanel: false,
    });

    if (shouldRefetch === true) {
      refreshPage();
    }
  };

  useEffect(() => {
    setCache();
    refreshPage();
  }, [
    pageState.filterTags,
    pageState.keyword,
    pageState.pageInfo,
    pageState.selectedPaginationPage,
    pageState.paginationSize,
    pageState.sortDir,
    pageState.sortKey,
  ]);
  return (
    <div className={`${bodyContentContainer}`}>
      <PaginationWrapperManaged<ICompanyCodeAssignment>
        dataResult={dataResult}
        selectedPage={pageState.selectedPaginationPage}
        loadingData={loading}
        onSelectedPageChange={(value: number, variables: PaginationQueryVariables) => {
          setPagesState({
            ...pageState,
            selectedPaginationPage: value,
            pageInfo: {
              ...variables,
            },
          });
        }}
        paginationSize={pageState.paginationSize}
        onPageSizeChange={(newPageSize: number) => {
          setPagesState({
            ...pageState,
            ...getResetPaginationProps(newPageSize),
          });
        }}
      >
        {GeneralEntityList(detailsListConfig)}
      </PaginationWrapperManaged>
      {pageState.showEditPanel && (
        <CompanyCodeAssignmentEdit
          companyCodeAssignment={pageState.selectedItem}
          closePanel={closePanels}
          mutation={EditCompanyCodeValue}
          panelMode={PanelTypes.Edit}
        />
      )}
      {pageState?.showColumnConfigPanel && (
        <ColumnConfigPanel
          closePanel={() => {
            setPagesState({
              ...pageState,
              showColumnConfigPanel: false,
            });
          }}
          columns={cachedListColumns}
          setColumns={setListColumnsCache}
        />
      )}
    </div>
  );
};

export default CompanyCodeAssignmentListPage;
