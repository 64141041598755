export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface IListSortDefinition {
  sortKey: string;
  sortDir: SortDirection;
}
export interface IQuerySortDefinition {
  [key: string]: string | IQuerySortDefinition;
}
