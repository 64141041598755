import React from 'react';
import { ISpinnerStyles, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react';
import { ApolloError } from '@apollo/client';

interface IErrorMessageProps {
  loading: boolean;
  error?: ApolloError | Error;
  actionName?: string;
  label?: string;
  styles?: ISpinnerStyles;
}

const LoadingErrorMessage = ({
  loading,
  error,
  actionName,
  label,
  styles,
}: IErrorMessageProps): JSX.Element => {
  if (!loading && !error) {
    return null;
  }

  if (loading || !error) {
    return <Spinner styles={styles} label={label || 'Loading'} labelPosition="right" />;
  }
  const formatErrorMessage = (): string => {
    if (!actionName) {
      return 'Error loading data from the server - ';
    }

    return `Error ${actionName} - `;
  };

  return (
    <MessageBar messageBarType={MessageBarType.error}>
      <Stack tokens={{ childrenGap: 10 }}>
        <span>
          {formatErrorMessage()} {error.message}
        </span>
      </Stack>
    </MessageBar>
  );
};

export default LoadingErrorMessage;
