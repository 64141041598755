import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ColumnActionsMode,
  IColumn,
  ICommandBarItemProps,
  IContextualMenuItem,
} from '@fluentui/react';
import { rowClasses } from '../../common/styles/CommonStyleObjects';
import { IDraftTask } from '../../utils/types/ITask';
import getBaseTaskListColumns, { IBaseTaskListColumns } from './BaseTasks.config';
import { DateConstants } from '../../utils/formatters/DateConstants';
import { PublishDraftTasksMode } from './generators/PublishDraftTasks';
import { ILocalStorageColumnConfig } from '../../common/lists/ColumnConfigHelper';
import BulkEditMode from '../../utils/types/IBulkEdit';
import DownloadButton, { IDownloadButtonProps } from '../buttons/DownloadButton';

export const getDraftTaskListColumns = (
  props: IBaseTaskListColumns,
  fiscalYear: string,
  fiscalPeriod: string,
): ILocalStorageColumnConfig[] => {
  const columns = [...getBaseTaskListColumns(props)] as IColumn[];
  const nameColumn = columns.find((c) => c.key === 'name');
  nameColumn.onRender = (item: IDraftTask) => {
    return (
      <Link to={`/draftTasks/${fiscalYear}/${fiscalPeriod}/details/${item?.id}`}>{item?.name}</Link>
    );
  };

  const stagedTaskColumn = {
    fieldName: 'stagedTaskId',
    name: 'Staged task ID',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: IDraftTask) => {
      return (
        (item?.stagedTaskId && (
          <a
            href={`/stagedTasks/edit/${item.stagedTaskId}`}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
            target="_blank"
            rel="noreferrer"
            style={{ padding: ' 0px 5px' }}
          >
            {item.stagedTaskId}
          </a>
        )) || <></>
      );
    },
  } as IColumn;
  const dueDate = {
    key: 'dueDate',
    name: 'Due date',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IDraftTask) => {
      // To Do: Revisit once we figure out where to implement timezone rendering
      return item.dueDate ? moment(item.dueDate).format(DateConstants.DayBreakout) : '--';
    },
  } as IColumn;
  const publishedTaskNotes = {
    key: 'publishedTaskNotes',
    name: 'Published task notes',
    maxWidth: 130,
    minWidth: 130,
    className: rowClasses.medFont,
    isResizable: true,
    onRender: (item: IDraftTask) => {
      return item.publishedTaskNotes;
    },
  } as IColumn;

  columns.push(stagedTaskColumn);
  columns.splice(12, 0, publishedTaskNotes);
  columns.splice(4, 0, dueDate);
  return columns as ILocalStorageColumnConfig[];
};
export interface IDraftTaskMenuProps {
  itemsSelected: boolean;
  itemsSelectedCount?: number;
  totalItemsCount?: number;
  onBulkEditClick?: (editMode: BulkEditMode) => void;
  onPublishClick?: (editMode: PublishDraftTasksMode) => void;
  onBulkDeleteClick?: (deleteMode: BulkEditMode) => void;
  downloadAllButtonProps?: IDownloadButtonProps;
  downloadFilteredButtonProps?: IDownloadButtonProps;
  onRefreshTasksClick?: () => void;
}
const getDraftTasksMenu = (props: IDraftTaskMenuProps): ICommandBarItemProps[] => {
  const {
    itemsSelected,
    itemsSelectedCount,
    totalItemsCount,
    onBulkEditClick,
    onPublishClick,
    onBulkDeleteClick,
    downloadAllButtonProps,
    downloadFilteredButtonProps,
    onRefreshTasksClick,
  } = props;
  const listCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'refreshData',
      text: 'Refresh data',
      cacheKey: 'refresh', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Refresh' },
      onClick: onRefreshTasksClick,
    },
    {
      key: 'exportDraftTasks',
      text: 'Export to Excel',
      cacheKey: 'exportDraftTasks', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ExcelLogo' },
      onRender: (item: IContextualMenuItem) => {
        return (
          <DownloadButton
            text="Export to Excel"
            renderedInOverflow={item.renderedInOverflow}
            key="exportDraftTasks"
            subItems={[downloadAllButtonProps, downloadFilteredButtonProps]}
            fileEndpoint=""
            fileName=""
          />
        );
      },
    },
  ];

  const selectedCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'editMain',
      text: 'Bulk edit',
      cacheKey: 'editBulk', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Edit' },
      subMenuProps: {
        items: [
          {
            key: 'edit',
            text: `Bulk edit selected (${itemsSelectedCount})`,
            cacheKey: 'editBulk', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Edit' },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'editAll',
            text: `Bulk edit all filtered (${totalItemsCount})`,
            cacheKey: 'editAll', // changing this key will invalidate this item's cache
            iconProps: {
              iconName: 'TripleColumnEdit',
            },
            onClick: (ev) => {
              return onBulkEditClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'publishMain',
      text: 'Publish tasks',
      cacheKey: 'publishBulk', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CloudUpload' },
      subMenuProps: {
        items: [
          {
            key: 'publishSelected',
            text: `Publish selected (${itemsSelectedCount})`,
            cacheKey: 'publishBulkSelected', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'CloudUpload' },
            onClick: (ev) => {
              return onPublishClick(PublishDraftTasksMode.PublishDraftsSelected);
            },
          },
          {
            key: 'publishAll',
            text: `Publish all filtered (${totalItemsCount})`,
            cacheKey: 'publishAll', // changing this key will invalidate this item's cache
            iconProps: {
              iconName: 'CloudUpload',
            },
            onClick: (ev) => {
              return onPublishClick(PublishDraftTasksMode.PublishDraftsAllFiltered);
            },
          },
        ],
      },
    },
    {
      key: 'deleteMain',
      text: 'Bulk delete',
      cacheKey: 'deleteBulk',
      iconProps: { iconName: 'Delete' },
      subMenuProps: {
        items: [
          {
            key: 'delete',
            text: `Bulk delete selected (${itemsSelectedCount})`,
            cacheKey: 'deleteBulk',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return onBulkDeleteClick(BulkEditMode.BulkEditSelected);
            },
          },
          {
            key: 'deleteAll',
            text: `Bulk delete all filtered (${totalItemsCount})`,
            cacheKey: 'deleteAll',
            iconProps: { iconName: 'Delete' },
            onClick: (ev) => {
              return onBulkDeleteClick(BulkEditMode.BulkEditAllFiltered);
            },
          },
        ],
      },
    },
  ];

  return itemsSelected ? selectedCommandBarItems : listCommandBarItems;
};
export default getDraftTasksMenu;
