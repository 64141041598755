import { gql } from '@apollo/client';

const GetJemTaskSummary = gql`
  query jemTaskSummary($filters: DashboardFiltersDtoInput!) {
    jemTaskSummary(filters: $filters) {
      jeSummary {
        jeType
        jeTypeInt
        totalComplete
        totalNotRequired
        totalOverDue
        totalPending
        totalTasks
        percentage
      }
      total
      remaining
      complete
      percentage
      notRequired
      pending
      overdue
      summaryType
    }
  }
`;
export default GetJemTaskSummary;
