import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DocumentNode } from 'graphql';
import { useMutation } from '@apollo/client';
import { mapFunctionsSummaryNoTypename } from '../../../common/formFields/FunctionsSummaryPicker';
import { GetStagedTasks } from '../../../utils/api/StagedTaskApi';
import { retrieveUrl } from '../../../utils/Helpers';
import { IStagedTask } from '../../../utils/types/ITask';
import PanelTypes from '../../../utils/types/PanelTypes';
import { TaskType } from '../list/BaseTaskListfilters.config';
import TaskDetailForm from './TaskDetailForm';

interface IStagedTasksDetailFormProps {
  stagedTask: IStagedTask;
  mutation: DocumentNode;
  panelMode: PanelTypes;
}
const StagedTasksDetailForm = (props: IStagedTasksDetailFormProps): JSX.Element => {
  const { stagedTask, mutation, panelMode } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const parentRouteDepth = panelMode === PanelTypes.Add ? 1 : 2;
  const [updateStagedTask, { loading, error }] = useMutation(mutation, {
    refetchQueries: [{ query: GetStagedTasks, variables: { awaitRefetchQueries: true } }],
    onCompleted: () => {
      history.push(retrieveUrl(null, parentRouteDepth, match));
    },
  });

  const submitForm = (stagedTaskInputData: IStagedTask) => {
    const estimatedTime = parseInt(stagedTaskInputData.estimatedTime?.toString(), 10);

    updateStagedTask({
      variables: {
        stagedTaskDto: {
          id: stagedTask.id,
          name: stagedTaskInputData.name,
          description: stagedTaskInputData.description,
          dueTime: stagedTaskInputData.dueTime,
          frequencyCode: stagedTaskInputData.frequencyCode,
          operationsDetailId: stagedTaskInputData.operationsDetailId,
          typeId: stagedTaskInputData.typeId,
          levelId: stagedTaskInputData.levelId,
          scopeOfActivityId: stagedTaskInputData.scopeOfActivityId,
          estimatedTime,
          publishType: stagedTaskInputData.publishType,
          supplierId: stagedTaskInputData.supplierId,
          primaries: stagedTaskInputData.primaries,
          backupTaskPerformers: stagedTaskInputData.backupTaskPerformers,
          stakeholders: stagedTaskInputData.stakeholders,
          companies: stagedTaskInputData.companies.map((company) => company.companyCode),
          dtpUpdateDate: stagedTaskInputData.dtpUpdateDate,
          dtpDocLink: stagedTaskInputData.dtpDocLink,
          permanentNotes: stagedTaskInputData.permanentNotes,
          dataSources: stagedTaskInputData.dataSources,
          dependencyOnStagedTasks:
            stagedTaskInputData.dependencyOnStagedTasks?.map((task) => task.id) || [],
          dependencyForStagedTasks:
            stagedTaskInputData.dependencyForStagedTasks?.map((task) => task.id) || [],
          functionsSummaries: mapFunctionsSummaryNoTypename(stagedTaskInputData.functionsSummaries),
          timeZoneId: stagedTaskInputData.timeZoneId,
          bcpCategoryId: stagedTaskInputData.bcpCategoryId,
          bcpPriorityPeak: stagedTaskInputData.bcpPriorityPeak,
          bcpPriorityNonPeak: stagedTaskInputData.bcpPriorityNonPeak,
          taskTags: stagedTaskInputData.taskTags || [],
          reportTypeId: stagedTaskInputData.reportTypeId,
          analysisTypeId: stagedTaskInputData.analysisTypeId,
          outputId: stagedTaskInputData.outputId,
          salesLocations:
            stagedTaskInputData.salesLocations?.map((salesLocation) => salesLocation.code) || [],
          workflowId: stagedTaskInputData.workflowId,
        },
      },
    });
  };
  const getOverrideDefaults = () => {
    return {
      frequencyCode: !stagedTask.frequencyCode ? 'M=:' : stagedTask.frequencyCode,
    };
  };
  return (
    <TaskDetailForm
      task={stagedTask}
      taskType={TaskType.StagedTasks}
      publishTypes={stagedTask.publishType}
      panelMode={panelMode}
      loading={loading}
      error={error}
      updateTask={submitForm}
      getOverrideDefaults={getOverrideDefaults}
    />
  );
};

export default StagedTasksDetailForm;
