import React from 'react';
import DateRangePicker from '../../../common/dateRangePicker/DateRangerPicker';
import CompanyChecklist from '../../../common/formFields/CompanyChecklist';
import GraphUsersFilter from '../../../common/formFields/GraphUsersFilter';
import InvalidUsersChecklist from '../../../common/formFields/InvalidUsersChecklist';
import PublishTypeChecklist from '../../../common/formFields/PublishTypeChecklist';
import TaskIdNameChecklist from '../../../common/formFields/TaskIdNameChecklist';
import TaskPropertyValuesChecklist from '../../../common/formFields/TaskPropertyValuesChecklist';
import YesNoChecklist from '../../../common/formFields/YesNoChecklist';
import ListFilters from '../../../common/lists/ListFilters';
import {
  GetOpsSummaryListSearch,
  GetOpsOrgListSearch,
  GetOpsOrgDetailListSearch,
} from '../../../utils/api/HierarchiesApi';
import { GetStagedTasksSearch } from '../../../utils/api/StagedTaskApi';
import { IGenericFilterTag } from '../../../utils/types/IGenericFilterTag';
import CollapsibleGenericChecklist from '../../../common/formFields/CollapsibleGenericChecklist';
import EstimatedTimeRangePicker from '../../../common/timeRangePicker/EstimatedTimeRangePicker';
import BcpPriorityChecklist from '../../../common/formFields/BcpPriorityChecklist';
import GetFunctionsSummaryTagsByFilter from '../../../utils/api/FunctionsSummaryApi';
import { GetDataSourceOptionsByKeyword } from '../../../utils/api/DataSourcesApi';
import StringFilter from '../../../common/formFields/StringFilter';
import TimeRangePicker from '../../../common/timeRangePicker/TimeRangePicker';
import TaskTagFilter from '../../../common/formFields/TaskTagFilter';
import CountryChecklist from '../../../common/formFields/CountryChecklist';
import SalesLocationGroupedChecklistFilter from '../../../common/formFields/SalesLocationGroupedChecklistFilter';

/** move to central place */
export interface IListFiltersState {
  filterTags: IGenericFilterTag[];
  filterPanelVisible: boolean;
}
export interface IListPageFiltersProps {
  onClosePanel?: () => void;
  currentFilterValues?: IGenericFilterTag[];
  onFilterUpdate?: (formData: IGenericFilterTag[]) => void;
}
const StagedTaskListPageFilters = (props: IListPageFiltersProps): JSX.Element => {
  const { onClosePanel, currentFilterValues, onFilterUpdate } = props;
  return (
    <ListFilters
      onClosePanel={onClosePanel}
      onFilterUpdate={onFilterUpdate}
      currentFilterValues={currentFilterValues}
    >
      <TaskIdNameChecklist
        dataKey="stagedTasks"
        label="Task Id or name"
        query={GetStagedTasksSearch}
        queryKey="stagedTasks"
      />
      <CollapsibleGenericChecklist
        dataKey="opsSummaries"
        label="Ops summaries"
        query={GetOpsSummaryListSearch}
        queryKey="opsSummaryNames"
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgs"
        label="Ops org"
        query={GetOpsOrgListSearch}
        queryKey="opsOrgNames"
      />
      <CollapsibleGenericChecklist
        dataKey="opsOrgDetails"
        label="Ops org detail"
        query={GetOpsOrgDetailListSearch}
        queryKey="opsDetailNames"
      />
      <CompanyChecklist dataKey="companies" label="Co. code" isActive={false} />
      <TaskPropertyValuesChecklist
        dataKey="levels"
        label="Task level"
        taskPropertyName="Task level"
      />
      <TaskPropertyValuesChecklist dataKey="types" label="Task type" taskPropertyName="Task type" />
      <TaskPropertyValuesChecklist
        dataKey="scopeOfActivities"
        label="Scope of activity"
        taskPropertyName="Scope of activity"
      />
      <TaskPropertyValuesChecklist
        dataKey="suppliers"
        label="Supplier"
        taskPropertyName="Supplier"
      />
      <GraphUsersFilter
        dataKey="primaries"
        label="Primary"
        removeButtonAriaLabel="Remove primary"
        controlName="primaries"
        placeholder="Type one or more Primary"
      />
      <GraphUsersFilter
        dataKey="backups"
        label="Backup"
        removeButtonAriaLabel="Remove backup"
        controlName="backups"
        placeholder="Type one or more Backup"
      />
      <GraphUsersFilter
        dataKey="stakeholders"
        label="Stakeholder"
        removeButtonAriaLabel="Remove stakeholder"
        controlName="stakeholders"
        placeholder="Type one or more Stakeholder"
      />
      <PublishTypeChecklist dataKey="publishType" label="Publish type" />
      <InvalidUsersChecklist dataKey="invalidUserType" label="Invalid users" />
      <YesNoChecklist dataKey="isActive" label="Is active" />
      <DateRangePicker
        wrapperLabel="Last modified date between"
        labels={['Last modified date start', 'Last modified date end']}
        dataKeys={['modifiedOnDateRangeStart', 'modifiedOnDateRangeEnd']}
      />
      <TimeRangePicker
        wrapperLabel="Due time between"
        labels={['Due time start', 'Due time end']}
        dataKeys={['dueTimeRangeStart', 'dueTimeRangeEnd']}
      />
      <GraphUsersFilter
        dataKey="modifiedByUsers"
        label="Modified by user(s)"
        removeButtonAriaLabel="Remove modified by user"
        controlName="modifiedByUsers"
        placeholder="Type one or more users"
      />
      <DateRangePicker
        wrapperLabel="DTP doc update date between"
        labels={['DTP update start', 'DTP update end']}
        dataKeys={['dtpDocDateRangeStart', 'dtpDocDateRangeEnd']}
      />
      <StringFilter
        dataKey="permanentNotes"
        label="Permanent notes"
        wrapperLabel="Permanent notes"
      />
      <StringFilter dataKey="frequencyCode" label="Frequency code" wrapperLabel="Frequency code" />
      <TaskPropertyValuesChecklist
        dataKey="workTimezone"
        label="Work timezone"
        taskPropertyName="Work TimeZone"
      />
      <CollapsibleGenericChecklist
        dataKey="dataSourceOptions"
        label="Data source(s)"
        query={GetDataSourceOptionsByKeyword}
        queryKey="dataSourceOptions"
      />
      <TaskIdNameChecklist
        dataKey="dependencyOn"
        label="Dependency on task(s)"
        query={GetStagedTasksSearch}
        queryKey="stagedTasks"
      />
      <TaskIdNameChecklist
        dataKey="dependencyFor"
        label="Dependency for task(s)"
        query={GetStagedTasksSearch}
        queryKey="stagedTasks"
      />
      <CollapsibleGenericChecklist
        dataKey="functionSummaries"
        label="Functions Summaries"
        query={GetFunctionsSummaryTagsByFilter}
        queryKey="functionsSummaries"
      />
      <TaskPropertyValuesChecklist
        dataKey="bcpCategories"
        label="BCP Categories"
        taskPropertyName="BCP Category"
      />
      <BcpPriorityChecklist dataKey="bcpPeakPriority" label="BCP peak priority" />
      <BcpPriorityChecklist dataKey="bcpNonPeakPriority" label="BCP non-peak priority" />
      <EstimatedTimeRangePicker
        wrapperLabel="Estimated time"
        labels={['Estimated time at least', 'Estimated time at most']}
        dataKeys={['estimatedTimeRangeStart', 'estimatedTimeRangeEnd']}
      />
      <TaskTagFilter dataKey="tags" label="Tags" />
      <CountryChecklist dataKey="countries" label="Country" isActive={false} />
      <SalesLocationGroupedChecklistFilter dataKey="salesLocations" label="Sales Location" />
      <TaskPropertyValuesChecklist
        dataKey="reportTypes"
        label="Report type"
        taskPropertyName="Report Type"
      />
      <TaskPropertyValuesChecklist
        dataKey="analysisTypes"
        label="Analysis type"
        taskPropertyName="Analysis Type"
      />
      <TaskPropertyValuesChecklist dataKey="outputs" label="Output" taskPropertyName="Output" />
    </ListFilters>
  );
};
export default StagedTaskListPageFilters;
